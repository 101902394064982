import { Action } from 'redux';

import ContactRequest from '../../Domain/ContactRequest';

export const contactRequestPostInitAction = '@@contact_request/POST_INIT';
export const contactRequestPostRequestLoadingAction = '@@contact_request/POST_REQUEST_LOADING';
export const contactRequestPostRequestSuccessAction = '@@contact_request/POST_REQUEST_SUCCESS';
export const contactRequestPostRequestFailureAction = '@@contact_request/POST_REQUEST_FAILURE';

export interface ContactRequestPostInitAction extends Action<typeof contactRequestPostInitAction> {
    payload: {}
}

export interface ContactRequestPostRequestLoadingAction extends Action<typeof contactRequestPostRequestLoadingAction> {
    payload: {}
}

export interface ContactRequestPostRequestSuccessAction extends Action<typeof contactRequestPostRequestSuccessAction> {
    payload: {
        contactRequest: ContactRequest
    }
}

export interface ContactRequestPostRequestFailureAction extends Action<typeof contactRequestPostRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const contactRequestPostInitActionCreator = (): ContactRequestPostInitAction => {

    return {
        type: contactRequestPostInitAction,
        payload: {}
    }
}

export const contactRequestPostRequestLoadingActionCreator = (): ContactRequestPostRequestLoadingAction => {

    return {
        type: contactRequestPostRequestLoadingAction,
        payload: {}
    }
}

export const contactRequestPostRequestSuccessActionCreator = (contactRequest: ContactRequest): ContactRequestPostRequestSuccessAction => {

    return {
        type: contactRequestPostRequestSuccessAction,
        payload: {
            contactRequest
        }
    }
}

export const contactRequestPostRequestFailureActionCreator = (errorMessage: string): ContactRequestPostRequestFailureAction => {

    return {
        type: contactRequestPostRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};