import IDataContactRequestSettings from './IDataContactRequestSettings';

export const initialDataContactRequestSettings: IDataContactRequestSettings = {
    senderEmail: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    senderName: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    senderNoReply: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    successPageId: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    provider: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    providerAPIKey: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    providerTemplateId: {
        inputValue: 0,
        validation: {
            inError: false,
            message: ''
        }
    }
};