import { ContactRequestStatusEnum } from "./Enum/ContactRequestStatusEnum";

class ContactRequest {

    public id: number;
    public subject: string;
    public senderEmail: string;
    public content: string;
    public status: ContactRequestStatusEnum;
    public createdDate: Date;
    public modifiedDate: Date | null;
    public modifiedBy: number | null;

    public constructor(id: number, subject: string, senderEmail: string, content: string, status: ContactRequestStatusEnum, createdDate: Date, modifiedDate: Date | null, modifiedBy: number | null) {
        this.id = id;
        this.subject = subject;
        this.senderEmail = senderEmail;
        this.content = content;
        this.status = status;
        this.createdDate = createdDate;
        this.modifiedDate = modifiedDate;
        this.modifiedBy = modifiedBy;
    }

}

export default ContactRequest;