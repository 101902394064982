import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppReduxState } from '../../../../../store';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContent from '../../../../Domain/PageContent';

import { IComponentData } from '../IComponentData';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';
import { IComponentSectionsCallToActionData } from './IComponentSectionsCallToActionData';
import { pageBuilderUpdatePageContentDataActionCreator } from '../../../../Infrastructure/Redux/page-builder.actions';

interface IHandleComponentSectionsCallToActionProps {
    pageContentId: string
}

const HandleComponentSectionsCallToAction: React.FunctionComponent<IHandleComponentSectionsCallToActionProps> = ({ pageContentId }): React.ReactElement => {
    const dispatch = useDispatch();
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentSectionsCallToActionData = pageContent.data as IComponentSectionsCallToActionData;

    const handleOnChange = (name: string, value: string): void => {
        setNewData(name, value);
    }

    const setNewData = (name: string, value: string): void => {
        dispatch(
            pageBuilderUpdatePageContentDataActionCreator(
                pageContentId,
                {
                    ...data as IComponentData<string|boolean|IComponentData<string|undefined>[]>,
                    [name]: value
                }
            )
        );
    }

    return (
        <div className="page-builder--sections-call-to-action">
            <div className="form--group">
                <label htmlFor="sections-call-to-action-title">
                    <LocaleMessage id="page_builder.component-sections-call-to-action-type.title.label" />
                </label>
                <div className="form--group-input">
                    <input type="text" id="sections-call-to-action-title" name="title" value={ data.title } onChange={(event) => handleOnChange('title', event.currentTarget.value)} />
                </div>
            </div>

            <div className="form--group">
                <label htmlFor="sections-call-to-action-button-label">
                    <LocaleMessage id="page_builder.component-sections-call-to-action-type.button_label.label" />
                </label>
                <div className="form--group-input">
                    <input type="text" id="sections-call-to-action-button-label" name="buttonLabel" value={ data.buttonLabel } onChange={(event) => handleOnChange('buttonLabel', event.currentTarget.value)} />
                </div>
            </div>

            <div className="form--group">
                <label htmlFor="sections-call-to-action-button-url">
                    <LocaleMessage id="page_builder.component-sections-call-to-action-type.url.label" />
                </label>
                <div className="form--group-input">
                    <input type="text" id="sections-call-to-action-button-url" name="url" value={ data.url } onChange={(event) => handleOnChange('url', event.currentTarget.value)} />
                </div>
            </div>
        </div>
    );
}

export default HandleComponentSectionsCallToAction;