import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SortableTree, { TreeItem, getFlatDataFromTree } from 'react-sortable-tree';
import SortableTreeFileExplorerTheme from 'react-sortable-tree-theme-file-explorer';
import 'react-sortable-tree/style.css';

import LoadingSpinner from '../../App/Infrastructure/Style/Svg/LoadingSpinner';
import { navigationGetPagesService, navigationGetStructureService, navigationUpdatePositionsService } from '../Infrastructure/Redux/navigation.services';
import { navigationPostRequestLoadingActionCreator, navigationRequestLoadingActionCreator } from '../Infrastructure/Redux/navigation.actions';
import { 
    navigationIsDeleteRequestSuccessSelector,
    navigationIsPostRequestLoadingSelector,
    navigationIsPostRequestSuccessSelector,
    navigationIsRequestFailureSelector,
    navigationIsRequestLoadingSelector,
    navigationIsRequestSuccessSelector,
    navigationSelector
} from '../Infrastructure/Redux/navigation.selectors';

interface IGetNavigationProps {
    handleNavigationEdit: (id: number, title: React.ReactNode) => void
}

export const GetNavigation: React.FunctionComponent<IGetNavigationProps> = ({ handleNavigationEdit }): React.ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    
    const dispatch = useDispatch();
    const navigation = useSelector(navigationSelector);

    const shouldGetStructure = useSelector(navigationIsRequestLoadingSelector);
    const isGetRequestFailed = useSelector(navigationIsRequestFailureSelector);
    const isGetRequestSuccess = useSelector(navigationIsRequestSuccessSelector);

    const isPostLoading = useSelector(navigationIsPostRequestLoadingSelector);
    const isPostSuccess = useSelector(navigationIsPostRequestSuccessSelector);
    const isDeleteRequestSuccess = useSelector(navigationIsDeleteRequestSuccessSelector);

    const [treeData, setTreeData] = useState<TreeItem[]>([]);

    useEffect(() => {
        if (shouldGetStructure) {
            navigationGetStructureService();
            navigationGetPagesService();
        }
    }, [shouldGetStructure]);

    useEffect(() => {
        if(navigation !== null) {
            setTreeData(navigation.generateTreeStructureForSortableTree(navigation));
        }

    }, [navigation]);

    useEffect(() => {
        setIsLoading(false);
    }, [isGetRequestSuccess]);

    useEffect(() => {
        if (isPostSuccess) {
            dispatch(navigationRequestLoadingActionCreator());
        }
    }, [isPostSuccess, dispatch]);

    useEffect(() => {
        if (isDeleteRequestSuccess) {
            handleNavigationEdit(0, '');
            dispatch(navigationRequestLoadingActionCreator());
        }
    }, [isDeleteRequestSuccess, handleNavigationEdit, dispatch]);

    const handleMoveNode = (): void => {
        //@ts-ignore
        const flatData = getFlatDataFromTree({ treeData: treeData, getNodeKey: ({ node }) => node.id, ignoreCollapsed: false });

        navigationUpdatePositionsService(flatData);
        dispatch(navigationPostRequestLoadingActionCreator());
    }

    if (isLoading) {

        return (
            <div className="icons is-loading">
                { LoadingSpinner }
            </div>
        )
    }

    if (isGetRequestFailed) {

        return (
            <React.Fragment>
                Something went wrong
            </React.Fragment>
        );
    }

    return (
        <div className={ `navigation--tree ${ isPostLoading ? 'is-loading' : '' }` }>
            <SortableTree 
                treeData={ treeData }
                canDrag={ !isPostLoading }
                onChange={ (newTreeData) => setTreeData(newTreeData) }
                onMoveNode={ () => { handleMoveNode() }}
                theme={ SortableTreeFileExplorerTheme }
                maxDepth={ 3 }
                //@ts-ignore
                getNodeKey={ ({ node }) => node.id !== undefined ? `navigation-${node.id}` : 'null' }
                generateNodeProps={(rowInfo) => {

                    return {
                      buttons: [
                        <button
                            className="button is-small"
                            onClick={() => {
                                //@ts-ignore
                                handleNavigationEdit(rowInfo.node.id, rowInfo.node.title);
                            }}
                            >
                                <i className="material-icons">edit</i>
                            </button>
                      ]
                    }
                }}
                />
        </div>
    );
}