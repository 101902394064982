import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../Infrastructure/Redux/Locale/Provider/LocaleMessage';

import Theme from '../../../Domain/Theme';
import ValidateTheme from './ValidateTheme';
import { initialColors } from './InitialColors';
import { initialDataTheme } from './InitialDataTheme';
import { initialHeaderVariants } from './InitialHeaderVariants';
import { initialHeaderLogoPositions } from './InitialHeaderLogoPositions';

import IData from '../../../../_utils/Forms/IData';
import IDataTheme from './IDataTheme';
import FormGroup from '../../../../_utils/Forms/FormGroup';
import SelectInput from '../../../../_utils/Forms/SelectInput';
import CheckboxInput from '../../../../_utils/Forms/CheckboxInput';
import ColorPickerInput from '../../../../_utils/Forms/ColorPickerInput';
import SegmentedControl from '../../../../_utils/SegmentedControl/SegmentedControl';
import { getThemeService } from '../../../Infrastructure/Redux/Theme/get-theme.services';
import { putThemeService } from '../../../Infrastructure/Redux/Theme/put-theme.services';
import { themeGetInitActionCreator, themeGetRequestLoadingActionCreator } from '../../../Infrastructure/Redux/Theme/get-theme.actions';
import { themePutInitActionCreator, themePutRequestLoadingActionCreator } from '../../../Infrastructure/Redux/Theme/put-theme.actions';
import { getThemeIsRequestFailureSelector, getThemeIsRequestLoadingSelector, getThemeIsRequestSuccessSelector, themeSelector } from '../../../Infrastructure/Redux/Theme/get-theme.selectors';
import { putThemeIsRequestFailureSelector, putThemeIsRequestLoadingSelector, putThemeIsRequestSuccessSelector, putThemeRequestErrorsSelector } from '../../../Infrastructure/Redux/Theme/put-theme.selectors';

const validateTheme: ValidateTheme = new ValidateTheme();

const HandleTheme: React.FunctionComponent = (): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [tab, setTab] = useState<string>('colors');
    const [data, setData] = useState<IDataTheme>(initialDataTheme);

    const theme = useSelector(themeSelector);
    const isThemeRequestLoading = useSelector(getThemeIsRequestLoadingSelector);
    const isThemeRequestSuccess = useSelector(getThemeIsRequestSuccessSelector);
    const isThemeRequestFailure = useSelector(getThemeIsRequestFailureSelector);
    const putThemeRequestLoading = useSelector(putThemeIsRequestLoadingSelector);
    const putThemeRequestSuccess = useSelector(putThemeIsRequestSuccessSelector);
    const putThemeRequestFailure = useSelector(putThemeIsRequestFailureSelector);
    const errors = useSelector(putThemeRequestErrorsSelector);

    const refSegmentControls = useRef(null);
    const refSegmentControlsColors = useRef(null);
    const refSegmentControlsHeader = useRef(null);
    const refSegmentControlsFooter = useRef(null);

    const handleEditData = useCallback((theme: Theme) => {
        const newData: IDataTheme = Object.assign({}, data, {
            primaryColor: {
                inputValue: theme.primaryColor,
                validation: { inError: false, message: '' }
            },
            secondaryColor: {
                inputValue: theme.secondaryColor,
                validation: { inError: false, message: '' }
            },
            textColor: {
                inputValue: theme.textColor,
                validation: { inError: false, message: '' }
            },
            textHeadersColor: {
                inputValue: theme.textHeadersColor,
                validation: { inError: false, message: '' }
            },
            headerVariant: {
                inputValue: theme.headerVariant,
                validation: { inError: false, message: '' }
            },
            headerLogoPosition: {
                inputValue: theme.headerLogoPosition,
                validation: { inError: false, message: '' }
            },
            headerTextColor: {
                inputValue: theme.headerTextColor,
                validation: { inError: false, message: '' }
            },
            headerColor: {
                inputValue: theme.headerColor,
                validation: { inError: false, message: '' }
            },
            headerBackgroundBlur: {
                inputValue: theme.headerBackgroundBlur,
                validation: { inError: false, message: '' }
            },
            footerColor: {
                inputValue: theme.footerColor,
                validation: { inError: false, message: '' }
            }
        });

        setData(newData);
    }, [data, setData]);

    // --- Redux GET
    useEffectOnce(() => {
        dispatch(themeGetInitActionCreator());
        dispatch(themeGetRequestLoadingActionCreator());
    });

    useEffect(() => {
        if (isThemeRequestLoading) {
            getThemeService();
        }
    }, [isThemeRequestLoading]);

    useEffect(() => {
        if (isThemeRequestFailure) {
            toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });
        }
    }, [isThemeRequestFailure]);
    
    useEffect(() => {
        if (theme !== null && isThemeRequestSuccess) {
            handleEditData(theme);
            dispatch(themeGetInitActionCreator());
        }
    }, [dispatch, handleEditData, theme, isThemeRequestSuccess]);
    
    // --- Redux PUT
    useEffect(() => {
        if (putThemeRequestLoading) {
            putThemeService(data);
        }
    }, [putThemeRequestLoading]);

    useEffect(() => {
        if (putThemeRequestSuccess) {
            toast.success(<LocaleMessage id="app.settings.customisation.theme.form.success" />, { position: 'bottom-center' });
            dispatch(themePutInitActionCreator());
        }
    }, [putThemeRequestSuccess]);

    useEffect(() => {
        if (putThemeRequestFailure) {
            setData(validateTheme.handleApiResponse(data, errors));

            toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });
            dispatch(themePutInitActionCreator());
        }
    }, [dispatch, putThemeRequestFailure]);

    // --- Handle Form states
    const handleOnChange = (name: string, value: string | boolean): void => {
        const newData: IDataTheme = Object.assign({}, data, {
            [name]: {
                inputValue: value,
                validation: { inError: false, message: '' }
            }
        });

        setData(validateTheme.validateForm(newData));
    }

    const handSubmit = (): void => {
        if(validateTheme.canBeSubmitted(data)) {
            dispatch(themePutRequestLoadingActionCreator());
        }
    }

    const renderPortletBody = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="form--segmented-controls">
                    <SegmentedControl
                        name="app-settings-theme-tabs"
                        segments={
                            [
                                {
                                    label: <LocaleMessage id="app.settings.customisation.theme.tabs.colors" />,
                                    value: 'colors',
                                    ref: refSegmentControlsColors
                                },
                                {
                                    label: <LocaleMessage id="app.settings.customisation.theme.tabs.header" />,
                                    value: 'header',
                                    ref: refSegmentControlsHeader
                                },
                                {
                                    label: <LocaleMessage id="app.settings.customisation.theme.tabs.footer" />,
                                    value: 'footer',
                                    ref: refSegmentControlsFooter
                                }
                            ]
                        }
                        defaultIndex={ 0 }
                        controlRef={ refSegmentControls }
                        onChangeSegment={(value) => setTab(value)}
                    />
                </div>

                { tab === 'colors' && renderColorsTab() }
                { tab === 'header' && renderHeaderTab() }
                { tab === 'footer' && renderFooterTab() }
            </React.Fragment>
        )
    }

    const renderColorsTab = (): React.ReactElement => {

        return (
            <React.Fragment>
                <ColorPickerInput inputName="primaryColor" label="app.settings.customisation.theme.form.primary_color.label" inputValue={ data.primaryColor.inputValue as string } errorMessage={ data.primaryColor.validation.inError ? data.primaryColor.validation.message : undefined } colors={ initialColors } onChange={(name, value) => handleOnChange(name, value)} disabled={ isThemeRequestLoading || putThemeRequestLoading } />
                <ColorPickerInput inputName="secondaryColor" label="app.settings.customisation.theme.form.secondary_color.label" inputValue={ data.secondaryColor.inputValue as string } errorMessage={ data.secondaryColor.validation.inError ? data.secondaryColor.validation.message : undefined } colors={ initialColors } onChange={(name, value) => handleOnChange(name, value)} disabled={ isThemeRequestLoading || putThemeRequestLoading } />
                <ColorPickerInput inputName="textColor" label="app.settings.customisation.theme.form.text_color.label" inputValue={ data.textColor.inputValue as string } errorMessage={ data.textColor.validation.inError ? data.textColor.validation.message : undefined } colors={ initialColors } onChange={(name, value) => handleOnChange(name, value)} disabled={ isThemeRequestLoading || putThemeRequestLoading } />
                <ColorPickerInput inputName="textHeadersColor" label="app.settings.customisation.theme.form.text_headers_color.label" inputValue={ data.textHeadersColor.inputValue as string } errorMessage={ data.textHeadersColor.validation.inError ? data.textHeadersColor.validation.message : undefined } colors={ initialColors } onChange={(name, value) => handleOnChange(name, value)} disabled={ isThemeRequestLoading || putThemeRequestLoading } />
            </React.Fragment>
        );
    }

    const generateHeaderVariantsRecord = (): Record<string, string> => {
        const headerVariantsRecord: Record<string, string> = {};

        initialHeaderVariants.forEach((value: string) => {
            headerVariantsRecord[value] = intl.formatMessage({ id: `app.settings.customisation.theme.form.header_variant.choice_${value}`});
        });

        return headerVariantsRecord;
    }

    const generateHeaderLogoPositionRecord = (): Record<string, string> => {
        const headerLogoPositionsRecord: Record<string, string> = {};

        initialHeaderLogoPositions.forEach((value: string) => {
            headerLogoPositionsRecord[value] = intl.formatMessage({ id: `app.settings.customisation.theme.form.header_logo_position.choice_${value}`});
        });

        return headerLogoPositionsRecord;
    }

    const renderHeaderTab = (): React.ReactElement => {
        const headerVariantMessage = intl.formatMessage({ id: 'app.settings.customisation.theme.form.header_variant.additional_information' });
        const headerBackgroundBlurMessage = intl.formatMessage({ id: 'app.settings.customisation.theme.form.header_background_blur.additional_information' });

        return (
            <React.Fragment>
                <ColorPickerInput inputName="headerColor" label="app.settings.customisation.theme.form.header_color.label" inputValue={ data.headerColor.inputValue as string } errorMessage={ data.headerColor.validation.inError ? data.headerColor.validation.message : undefined } colors={ initialColors } onChange={(name, value) => handleOnChange(name, value)} disabled={ isThemeRequestLoading || putThemeRequestLoading } />
                <ColorPickerInput inputName="headerTextColor" label="app.settings.customisation.theme.form.header_text_color.label" inputValue={ data.headerTextColor.inputValue as string } errorMessage={ data.headerTextColor.validation.inError ? data.headerTextColor.validation.message : undefined } colors={ initialColors } onChange={(name, value) => handleOnChange(name, value)} disabled={ isThemeRequestLoading || putThemeRequestLoading } />
                <SelectInput inputName="headerVariant" label="app.settings.customisation.theme.form.header_variant.label" inputValue={ data.headerVariant.inputValue } errorMessage={ data.headerVariant.validation.inError ? data.headerVariant.validation.message : undefined } choices={ generateHeaderVariantsRecord() } onChange={(name, value) => handleOnChange(name, value)} additionalInformation={ headerVariantMessage } disabled={ isThemeRequestLoading || putThemeRequestLoading } />
                <SelectInput inputName="headerLogoPosition" label="app.settings.customisation.theme.form.header_logo_position.label" inputValue={ data.headerLogoPosition.inputValue } errorMessage={ data.headerLogoPosition.validation.inError ? data.headerLogoPosition.validation.message : undefined} choices={ generateHeaderLogoPositionRecord() } onChange={(name, value) => handleOnChange(name, value)} disabled={ isThemeRequestLoading || putThemeRequestLoading } />
                <CheckboxInput inputName="headerBackgroundBlur" label="app.settings.customisation.theme.form.header_background_blur.label" inputData={ data as IData<boolean> } onChange={(name, value) => handleOnChange(name, value)} additionalInformation={ headerBackgroundBlurMessage } disabled={ isThemeRequestLoading || putThemeRequestLoading } />
            </React.Fragment>
        );
    }

    const renderFooterTab = (): React.ReactElement => {

        return (
            <React.Fragment>
                <ColorPickerInput inputName="footerColor" label="app.settings.customisation.theme.form.footer_color.label" inputValue={ data.footerColor.inputValue as string } errorMessage={ data.footerColor.validation.inError ? data.footerColor.validation.message : undefined } colors={ initialColors } onChange={(name, value) => handleOnChange(name, value)} disabled={ isThemeRequestLoading || putThemeRequestLoading } />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="portlet is-compact">
                <div className="portlet--title">
                    <div className="portlet--title-label">
                        <LocaleMessage id="app.settings.customisation.theme.page.title" />
                    </div>
                    <div className="portlet--title-toolbar"></div>
                </div>

                <div className="portlet--body">
                    { renderPortletBody() }
                </div>
            </div>

            <button type="button" className={ `button is-primary ${ isThemeRequestLoading ? 'is-disabled' : '' } ${ putThemeRequestLoading ? 'is-loading' : '' }` } onClick={() => handSubmit()}>
                Enregistrer
            </button>
        </React.Fragment>
    )
}

export default HandleTheme;