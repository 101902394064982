import IDataNavigation from './IDataNavigation';
import NavigationType from '../../Domain/NavigationType';

export const initialNavigationData: IDataNavigation = {
    id: {
        inputValue: undefined,
        validation: {
            inError: false,
            message: ''
        }
    },
    title: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    type: {
        inputValue: NavigationType.Folder,
        validation: {
            inError: false,
            message: ''
        }
    },
    pageId: {
        inputValue: "",
        validation: {
            inError: false,
            message: ''
        }
    },
    moduleSlug: {
        inputValue: "",
        validation: {
            inError: false,
            message: ''
        }
    },
    parentId: {
        inputValue: '0',
        validation: {
            inError: false,
            message: ''
        }
    },
    order: {
        inputValue: '0',
        validation: {
            inError: false,
            message: ''
        }
    }
}