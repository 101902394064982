import { store } from '../../../store';
import IDataPage from '../../Application/Forms/IDataPage';

import PagesRepository from '../Repositories/PagesRepository';
import { pagePostRequestFailureActionCreator, pagePostRequestSuccessActionCreator } from './postpage.actions';

const pagesRepository: PagesRepository = new PagesRepository();

export const postPageService = (data: IDataPage): void => {
    pagesRepository.post(data)
        .then(() => {

            store.dispatch(pagePostRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(pagePostRequestFailureActionCreator(error.response.data.message));
        });
}

export const putPageService = (id: number, data: IDataPage): void => {
    pagesRepository.put(id, data)
        .then(() => {

            store.dispatch(pagePostRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(pagePostRequestFailureActionCreator(error.response.data.message));
        });
}