import * as React from 'react';

const IconLadderComponent = (
    <svg viewBox="0 0 47 47" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(10.000000, 10.000000)">
            <path d="M24.127907,3.05232558 L18.3139535,3.05232558 C17.9069767,3.05232558 17.5872093,3.37209302 17.5872093,3.77906977 L17.5872093,8.69186047 L17.5872093,8.86627907 L17.3546512,8.86627907 L12.5,8.86627907 C12.0930233,8.86627907 11.7732558,9.18604651 11.7732558,9.59302326 L11.7732558,13.0523256 L11.7732558,14.6802326 L9.59302326,14.6802326 L6.68604651,14.6802326 C6.27906977,14.6802326 5.95930233,15 5.95930233,15.4069767 L5.95930233,17.4127907 L5.95930233,20.494186 L1.83139535,20.494186 L0.872093023,20.494186 C0.465116279,20.494186 0.145348837,20.8139535 0.145348837,21.2209302 C0.145348837,21.627907 0.465116279,21.9476744 0.872093023,21.9476744 L6.68604651,21.9476744 C7.09302326,21.9476744 7.4127907,21.627907 7.4127907,21.2209302 L7.4127907,16.3081395 L7.4127907,16.1337209 L7.64534884,16.1337209 L12.5,16.1337209 C12.9069767,16.1337209 13.2267442,15.8139535 13.2267442,15.4069767 L13.2267442,11.9476744 L13.2267442,10.3197674 L15.4069767,10.3197674 L18.3139535,10.3197674 C18.7209302,10.3197674 19.0406977,10 19.0406977,9.59302326 L19.0406977,7.5872093 L19.0406977,4.50581395 L23.1686047,4.50581395 L24.127907,4.50581395 C24.5348837,4.50581395 24.8546512,4.18604651 24.8546512,3.77906977 C24.8546512,3.37209302 24.5348837,3.05232558 24.127907,3.05232558 Z" id="IconLadderComponentShape" fill="#E81E25" fillRule="nonzero"/>
            <path d="M24.8255814,15.2034884 C24.8255814,15.1744186 24.8255814,15.1453488 24.7965116,15.1453488 C24.7965116,15.1162791 24.7674419,15.0872093 24.7674419,15.0872093 C24.7674419,15.0581395 24.7383721,15.0581395 24.7383721,15.0290698 C24.6802326,14.9418605 24.622093,14.8837209 24.5348837,14.8255814 C24.505814,14.8255814 24.505814,14.7965116 24.4767442,14.7965116 C24.4476744,14.7965116 24.4186047,14.7674419 24.4186047,14.7674419 C24.3895349,14.7674419 24.3604651,14.7383721 24.3313953,14.7383721 C24.3023256,14.7383721 24.3023256,14.7383721 24.2732558,14.7093023 C24.2151163,14.7093023 24.1860465,14.7093023 24.127907,14.7093023 L18.3139535,14.7093023 C17.9069767,14.7093023 17.5872093,15.0290698 17.5872093,15.4360465 C17.5872093,15.8430233 17.9069767,16.1627907 18.3139535,16.1627907 L22.3837209,16.1627907 L14.8837209,23.6046512 C14.5930233,23.8953488 14.5930233,24.3604651 14.8837209,24.622093 C15.0290698,24.7674419 15.2034884,24.8255814 15.4069767,24.8255814 C15.6104651,24.8255814 15.7848837,24.7674419 15.9302326,24.622093 L23.4011628,17.1511628 L23.4011628,21.2209302 C23.4011628,21.627907 23.7209302,21.9476744 24.127907,21.9476744 C24.5348837,21.9476744 24.8546512,21.627907 24.8546512,21.2209302 L24.8546512,15.4069767 C24.8546512,15.3488372 24.8546512,15.3197674 24.8546512,15.2616279 C24.8255814,15.2325581 24.8255814,15.2325581 24.8255814,15.2034884 Z" id="IconLadderComponentShape" fill="#142040" fillRule="nonzero"/>
            <path d="M0.174418605,9.79651163 C0.174418605,9.8255814 0.174418605,9.85465116 0.203488372,9.85465116 C0.203488372,9.88372093 0.23255814,9.9127907 0.23255814,9.9127907 C0.23255814,9.94186047 0.261627907,9.94186047 0.261627907,9.97093023 C0.319767442,10.0581395 0.377906977,10.1162791 0.465116279,10.1744186 C0.494186047,10.1744186 0.494186047,10.2034884 0.523255814,10.2034884 C0.552325581,10.2034884 0.581395349,10.2325581 0.581395349,10.2325581 C0.610465116,10.2325581 0.639534884,10.2616279 0.668604651,10.2616279 C0.697674419,10.2616279 0.697674419,10.2616279 0.726744186,10.2906977 C0.784883721,10.2906977 0.813953488,10.2906977 0.872093023,10.2906977 L6.68604651,10.2906977 C7.09302326,10.2906977 7.4127907,9.97093023 7.4127907,9.56395349 C7.4127907,9.15697674 7.09302326,8.8372093 6.68604651,8.8372093 L2.61627907,8.8372093 L10.0872093,1.36627907 C10.377907,1.0755814 10.377907,0.610465116 10.0872093,0.348837209 C9.79651163,0.0581395349 9.33139535,0.0581395349 9.06976744,0.348837209 L1.59883721,7.84883721 L1.59883721,3.77906977 C1.59883721,3.37209302 1.27906977,3.05232558 0.872093023,3.05232558 C0.465116279,3.05232558 0.145348837,3.37209302 0.145348837,3.77906977 L0.145348837,9.59302326 C0.145348837,9.65116279 0.145348837,9.68023256 0.145348837,9.73837209 C0.174418605,9.76744186 0.174418605,9.76744186 0.174418605,9.79651163 Z" id="IconLadderComponentShape" fill="#142040" fillRule="nonzero"/>
        </g>
    </svg>
);

export default IconLadderComponent;