import React, { useState } from 'react';

import IconArrowDown from '../../../Style/svg/IconArrowDown';

interface IDisplayComponentAccordionItemProps {
    question: string,
    answer: string
}

const DisplayComponentAccordionItem: React.FunctionComponent<IDisplayComponentAccordionItemProps> = ({ question, answer }): React.ReactElement => {
    const [opened, setOpened] = useState<boolean>(false);

    return (
        <div className={ `page-builder--web-accordion-list-item  ${ opened ? 'is-opened' : '' }` }>
            <div className="page-builder--web-accordion-list-item-question" onClick={() => setOpened(!opened)}>
                { question }
                { IconArrowDown }
            </div>
            <div className="page-builder--web-accordion-list-item-answer" dangerouslySetInnerHTML={{ __html: answer }}></div>
        </div>
    );
}

export default DisplayComponentAccordionItem;