import React from 'react';

const IconScreenHeight: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <path d="M3 22H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M3 2H21" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M12 6V18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M14.8299 7.71989L11.9999 4.88989L9.16992 7.71989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M14.8299 15.8899L11.9999 18.7199L9.16992 15.8899" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
);

export default IconScreenHeight;