import IDataLogin from './IDataLogin';

class ValidateLogin {

    private validateEmail = (data: IDataLogin): IDataLogin => {
        if (data.email.inputValue.length === 0) {
            data.email.validation.inError = true;
            data.email.validation.message = 'login.email.missing';

            return data;
        }

        if (data.email.inputValue.length < 4) {
            data.email.validation.inError = true;
            data.email.validation.message = 'login.email.too_short';

            return data;
        }

        return data;
    }

    private validatePassword = (data: IDataLogin): IDataLogin => {
        if (data.password.inputValue.length === 0) {
            data.password.validation.inError = true;
            data.password.validation.message = 'login.password.missing';

            return data;
        }

        if (data.password.inputValue.length < 4) {
            data.password.validation.inError = true;
            data.password.validation.message = 'login.password.too_short';

            return data;
        }

        return data;
    }

    public validateForm = (data: IDataLogin): IDataLogin => {
        data = this.validateEmail(data);
        data = this.validatePassword(data);

        return data;
    }

    public canBeSubmitted = (data: IDataLogin): boolean => {
        return data.email.inputValue.length !== 0 && 
               data.password.inputValue.length !== 0 &&
               Object.keys(data).every((field) => !data[field].validation.inError);
    }

}

export default ValidateLogin;