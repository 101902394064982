class ContactRequestSettings {

    public senderName: string;
    public senderEmail: string;
    public senderNoReply: string;
    public successPageId: string;
    public provider: string;
    public providerAPIKey: string;
    public providerTemplateId: number;

    public constructor(senderName: string, senderEmail: string, senderNoReply: string, successPageId: string, provider: string, providerAPIKey: string, providerTemplateId: number) {
        this.senderName = senderName;
        this.senderEmail = senderEmail;
        this.senderNoReply = senderNoReply;
        this.successPageId = successPageId;
        this.provider = provider;
        this.providerAPIKey = providerAPIKey;
        this.providerTemplateId = providerTemplateId;
    }

}

export default ContactRequestSettings;