import React from 'react';
import { AppReduxState } from '../../../../../store';
import { useSelector } from 'react-redux';

import PageContent from '../../../../Domain/PageContent';

import { IComponentAccordionData } from '../../../../Application/Forms/ComponentType/Texts/IComponentAccordionData';
import { IComponentAccordionItemData } from '../../../../Application/Forms/ComponentType/Texts/IComponentAccordionItemData';

import DisplayComponentAccordionItem from './DisplayComponentAccordionItem';
import { pageBuilderPageContentSelector } from '../../../Redux/page-builder.selectors';

interface IDisplayComponentAccordionProps {
    id: string
}

const DisplayComponentAccordion: React.FunctionComponent<IDisplayComponentAccordionProps> = ({ id }): React.ReactElement => {
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, id));
    const data: IComponentAccordionData = pageContent.data as IComponentAccordionData;

    const renderAccordionItems = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        data.items.forEach((item: IComponentAccordionItemData, index: number) => {
            elements.push(<DisplayComponentAccordionItem question={ item.question } answer={ item.answer } key={ index } />)
        });

        return elements;
    }

    return (
        <React.Fragment>
            <div className={ `page-builder--web-accordion-list ${data.isBordered ? 'is-bordered' : ''}` }>
                { renderAccordionItems() }
            </div>
        </React.Fragment>
    );
}

export default DisplayComponentAccordion;