import React, { useRef } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import IconEditor from '../Style/svg/IconEditor';
import LogoPageBuilder from '../Style/svg/LogoPageBuilder';
import LogoPageBuilderIcon from '../Style/svg/LogoPageBuilderIcon';
import IconDeviceMobile from '../Style/svg/IconDeviceMobile';
import IconDeviceDesktop from '../Style/svg/IconDeviceDesktop';
import SegmentedControl from '../../../_utils/SegmentedControl/SegmentedControl';

import { userCurrentInformation } from '../../../User/Infrastructure/Redux/user.selectors';
import { pageBuilderChangeEditorModeActionCreator } from '../Redux/page-builder.actions';

const PageBuilderHeader: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector(userCurrentInformation);

    const handleChangeEditorMode = (viewport: string): void => {
        dispatch(pageBuilderChangeEditorModeActionCreator(viewport as 'editor' | 'desktop' | 'mobile'));
    }

    return (
        <div className="page-builder--header">
            <div className="page-builder--header-logo">
                <span onClick={ () => history.push('/') }>{ LogoPageBuilder }</span>
            </div>
            <div className="page-builder--header-logo-mobile">
                <span onClick={ () => history.push('/') }>{ LogoPageBuilderIcon }</span>
            </div>

            <div className="page-builder--header-breakpoint-switcher">
                <SegmentedControl name="breakpoint-switcher"
                    segments={
                        [
                            {
                                label: <React.Fragment>{ IconEditor }&nbsp;Editor</React.Fragment>,
                                value: 'editor',
                                ref: useRef(null)
                            },
                            {
                                label: <React.Fragment>{ IconDeviceDesktop }&nbsp;Desktop</React.Fragment>,
                                value: 'desktop',
                                ref: useRef(null)
                            },
                            {
                                label: <React.Fragment>{ IconDeviceMobile }&nbsp;Mobile</React.Fragment>,
                                value: 'mobile',
                                ref: useRef(null)
                            }
                        ]
                    }
                    onChangeSegment={(value: string) => { handleChangeEditorMode(value) } }
                    defaultIndex={ 0 }
                    controlRef={ useRef(null) }
                />
            </div>

            <div className="page-builder--header-profile">
                {
                    user !== null && 
                    <div className="page-builder--header-profile-user">
                        <span>{ user.firstName } { user.lastName }</span> <div className="page-builder--header-profile-user-avatar">RC</div>
                    </div>
                }
            </div>
        </div>
    );
}

export default PageBuilderHeader;