import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ComponentBackgroundPositionEnum from '../../PageBuilders/Domain/Enum/ComponentBackgroundPositionEnum';
import IconSettingsPositionCenter from '../../PageBuilders/Infrastructure/Style/svg/IconSettingsPositionCenter';

interface INinePositionsInputProps {
    label?: string | React.ReactElement,
    inputName: string,
    inputValue: string,
    onChange: (name: string, value: string) => void
}

const NinePositionsInput: React.FunctionComponent<INinePositionsInputProps> = ({ label, inputName, inputValue, onChange }): React.ReactElement => {
    const intl = useIntl();

    const renderPositionIcons = (value: string): React.ReactElement => {
        console.log(value);

        switch (ComponentBackgroundPositionEnum[value]) {
            case ComponentBackgroundPositionEnum.CenterCenter:
                return IconSettingsPositionCenter;

            default:
                return <React.Fragment></React.Fragment>;
        }
    }

    const renderPositionButtons = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        Object.keys(ComponentBackgroundPositionEnum).forEach((value: string, index: number) => {
            elements.push(
                <div className={ `form--group-nine-position-btn ${ inputValue === ComponentBackgroundPositionEnum[value] ? 'is-active' : '' }`} onClick={() => onChange(inputName, ComponentBackgroundPositionEnum[value])} key={ index }>
                    {
                        renderPositionIcons(value)
                    }
                </div>
            );
        });

        return elements;
    }

    return (
        <div className="input">
            {
                label !== undefined &&
                <label htmlFor={ inputName }>
                    { label }
                </label>
            }
            <div className="form--group-nine-position">
                { renderPositionButtons() }
            </div>
        </div>
    );
}

export default NinePositionsInput;