export enum BlackModeColors {
    SYMBIOZE = '#FECE2E',
    SYMBIOZE_RGB = '224, 227, 79',
    DEFAULT_TEXT = '#FFFFFF',
    NEUTRAL_100 = '#000000',
    NEUTRAL_100_RGB = '8, 8, 8',
    NEUTRAL_200 = '#0E0F0F',
    NEUTRAL_200_RGB = '20, 22, 22',
    NEUTRAL_300 = '#232425',
    NEUTRAL_300_RGB = '35, 36, 37',
    NEUTRAL_400 = '#2D2F31',
    NEUTRAL_400_RGB = '45, 47, 49',
    NEUTRAL_500 = '#35373A',
    NEUTRAL_500_RGB = '53, 55, 58',
}