import {composeWithDevTools} from 'redux-devtools-extension';
import {createStore, combineReducers, Reducer} from 'redux';

import { LocaleReduxState, localeReducer } from './App/Infrastructure/Redux/Locale/locale.reducer';
import { ApplicationReduxState, applicationReducer } from './App/Infrastructure/Redux/App/app.reducer';
import { ModalReduxState, modalReducer } from './_utils/Modal/Redux/modal.reducer';
import { AuthReduxState, authReducer } from './Auth/Infrastructure/Redux/auth.reducer';
import { SearchFiltersReduxState, searchFiltersReducer } from './_utils/SearchFilters/Redux/searchFilters.reducer';
import { UserReduxState, userReducer } from './User/Infrastructure/Redux/user.reducer';
import { navigationReducer, NavigationReduxState } from './Navigation/Infrastructure/Redux/navigation.reducer';
// --- APP
import { GetThemeReducer, GetThemeReduxState } from './App/Infrastructure/Redux/Theme/get-theme.reducer';
import { PutThemeReducer, PutThemeReduxState } from './App/Infrastructure/Redux/Theme/put-theme.reducer';
import { GetSettingsReducer, GetSettingsReduxState } from './App/Infrastructure/Redux/Settings/get-settings.reducer';
import { PutSettingsReducer, PutSettingsReduxState } from './App/Infrastructure/Redux/Settings/put-settings.reducer';
// --- PAGES
import { GetPageReducer, GetPageReduxState } from './Pages/Infrastructure/Redux/get.reducer';
import { GetPagesReducer, GetPagesReduxState } from './Pages/Infrastructure/Redux/getpages.reducer';
import { PostPageReducer, PostPageReduxState } from './Pages/Infrastructure/Redux/postpage.reducer';
import { DeletePageReducer, DeletePageReduxState } from './Pages/Infrastructure/Redux/deletepage.reducer';
import { PageBuilderReducer, PageBuilderReduxState } from './PageBuilders/Infrastructure/Redux/page-builder.reducer';
import { PageBuilderQueryReducer, PageBuilderQueryReduxState } from './PageBuilders/Infrastructure/Redux/page-builder-get.reducer';
import { PageBuilderRequestReducer, PageBuilderRequestReduxState } from './PageBuilders/Infrastructure/Redux/page-builder-post.reducer';
// --- CONTACT REQUESTS
import { GetContactRequestsReducer, GetContactRequestsReduxState } from './ContactRequests/Infrastructure/Redux/get-contact-requests.reducer';
import { PostContactRequestReducer, PostContactRequestReduxState } from './ContactRequests/Infrastructure/Redux/post-contact-request.reducer';
import { PutContactRequestReducer, PutContactRequestReduxState } from './ContactRequests/Infrastructure/Redux/put-contact-request.reducer';
import { DeleteContactRequestReducer, DeleteContactRequestReduxState } from './ContactRequests/Infrastructure/Redux/delete-contact-request.reducer';

export interface AppReduxState {
    app: ApplicationReduxState;
    auth: AuthReduxState;
    user: UserReduxState;
    locale: LocaleReduxState;
    modales: ModalReduxState;
    searchFilters: SearchFiltersReduxState;
    // --- APP THEME
    getTheme: GetThemeReduxState;
    putTheme: PutThemeReduxState;
    getSettings: GetSettingsReduxState;
    putSettings: PutSettingsReduxState;
    // --- NAVIGATION
    navigation: NavigationReduxState;
    // --- PAGES
    getPage: GetPageReduxState;
    getPages: GetPagesReduxState;
    postPage: PostPageReduxState;
    deletePage: DeletePageReduxState;
    // --- PAGE BUILDER
    pageBuilder: PageBuilderReduxState;
    pageBuilderQuery: PageBuilderQueryReduxState;
    pageBuilderRequest: PageBuilderRequestReduxState;
    // --- CONTACT REQUESTS
    getContactRequests: GetContactRequestsReduxState;
    postContactRequest: PostContactRequestReduxState;
    putContactRequest: PutContactRequestReduxState;
    deleteContactRequest: DeleteContactRequestReduxState;
}

const combineReducer = combineReducers<AppReduxState>({
    app: applicationReducer,
    auth: authReducer,
    user: userReducer,
    locale: localeReducer,
    modales: modalReducer,
    searchFilters: searchFiltersReducer,
    // --- APP
    getTheme: GetThemeReducer,
    putTheme: PutThemeReducer,
    getSettings: GetSettingsReducer,
    putSettings: PutSettingsReducer,
    // --- NAVIGATION
    navigation: navigationReducer,
    // --- PAGES
    getPage: GetPageReducer,
    getPages: GetPagesReducer,
    postPage: PostPageReducer,
    deletePage: DeletePageReducer,
    // --- PAGE BUILDER
    pageBuilder: PageBuilderReducer,
    pageBuilderQuery: PageBuilderQueryReducer,
    pageBuilderRequest: PageBuilderRequestReducer,
    // --- CONTACT REQUESTS
    getContactRequests: GetContactRequestsReducer,
    postContactRequest: PostContactRequestReducer,
    putContactRequest: PutContactRequestReducer,
    deleteContactRequest: DeleteContactRequestReducer
});

export const rootReducer: Reducer<AppReduxState> = (state, action) => {
    /* Should be used for logout
    if (action.type === auth.USER_LOGOUT_SUCCESS) {
        action = {
            type: action.type,
            data: {
                locale: state.locale
            }
        };

        state = undefined;
    }
    */

    return combineReducer(state, action);
}

export const store = createStore(
    rootReducer,
    composeWithDevTools(),
);