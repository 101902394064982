import Tournament from "../Domain/Tournament";
import Discipline from "../Domain/Discipline";

import HttpClient from "../../_utils/HttpClient/HttpClient";

class RestTournamentRepository {
    private HttpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_URL);

    public getTournaments = async (): Promise<Tournament[]> => {
        const response: any = await this.HttpClient.get(this.baseUrl, '/games/call-of-duty-black-ops-2/tournaments/platforms/ps3');

        if (!response.data.hasOwnProperty('tournaments')) {
            return [];
        }

        return response.data.tournaments.map((tournament: any) => {

            return new Tournament(
                tournament.id,
                tournament.title,
                tournament.is_premium,
                tournament.format,
                new Date(tournament.start_date),
                new Date(tournament.registration_start_date),
                new Date(tournament.registration_end_date),
                new Discipline(
                    tournament.discipline.title,
                    tournament.discipline.player_number,
                    tournament.discipline.is_solo
                ),
                tournament.min_player_number,
                tournament.max_player_number,
                tournament.registered_player_number,
                tournament.cash_value,
                tournament.gift_value,
                tournament.currency
            );
        });
    }

}

export default RestTournamentRepository;