import React from 'react';

const IconThreeColumns: React.ReactElement = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <g transform="translate(1.000000, 2.000000)">
            <path d="M6,17.9 L6,2.1 C6,0.6 5.54823529,0 4.42588235,0 L1.57411765,0 C0.451764706,0 0,0.6 0,2.1 L0,17.9 C0,19.4 0.451764706,20 1.57411765,20 L4.42588235,20 C5.54823529,20 6,19.4 6,17.9 Z"></path>
            <path d="M14,17.9 L14,2.1 C14,0.6 13.5482353,0 12.4258824,0 L9.57411765,0 C8.45176471,0 8,0.6 8,2.1 L8,17.9 C8,19.4 8.45176471,20 9.57411765,20 L12.4258824,20 C13.5482353,20 14,19.4 14,17.9 Z"></path>
            <path d="M22,17.9 L22,2.1 C22,0.6 21.5482353,0 20.4258824,0 L17.5741176,0 C16.4517647,0 16,0.6 16,2.1 L16,17.9 C16,19.4 16.4517647,20 17.5741176,20 L20.4258824,20 C21.5482353,20 22,19.4 22,17.9 Z" opacity="0.4"></path>
        </g>
    </svg>
);

export default IconThreeColumns;