import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IconWarning from '../../../App/Infrastructure/Style/Svg/IconWarning';
import { modalRemoveActionCreator } from '../../../_utils/Modal/Redux/modal.actions';

const ConfirmationEmptyCacheModal: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCloseModal = (): void => {
        dispatch(modalRemoveActionCreator());
    }

    return (
        <div className="modal--confirmation">
            <div className="modal--icon">
                { IconWarning }
            </div>

            <div className="modal--information">
                <LocaleMessage id="medias.empty_cache_modal.info" />
            </div>

            <div className="modal--actions">
                <button className={ `button is-primary ${isLoading ? 'is-loading' : ''}` }>
                    <LocaleMessage id="medias.empty_cache_modal.validation_button" />
                </button>
                <button className="button is-primary is-transparent" onClick={ handleCloseModal }>
                    <LocaleMessage id="modales.confirmation.cancel" />
                </button>
            </div>
        </div>
    );
}

export default ConfirmationEmptyCacheModal;