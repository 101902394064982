import React from 'react';
import { Link } from 'react-router-dom';

interface PaginationProps {
    baseUrl: string,
    pages: number,
    activePage: number
}

const Pagination: React.FunctionComponent<PaginationProps> = ({ baseUrl, pages, activePage }) => {
    const prevPage = activePage - 1 <= 1 ? 1 : activePage - 1;
    const nextPage = activePage + 1 > pages ? pages : activePage + 1;

    const renderPagesLinks = (): React.ReactElement[] => {
        const linksNodes: React.ReactElement[] = [];
        const limit: number =  pages <= 20 ? pages : 20;
        for (let page = 0; page < limit; page++) {
            const link: React.ReactElement = (
                <li className="pagination--item" key={ page }>
                    <Link to={ `${baseUrl}?page=${page + 1}` } title={ `Page ${page + 1}` } className={ activePage === (page + 1) ? 'is-active' : '' }>
                        { page + 1 }
                    </Link>
                </li>
            );

            linksNodes.push(link);
        }
        if (limit === 20) {
            const link: React.ReactElement = (
                <li className="pagination--item" key="lastPage">
                    <Link to={ `${baseUrl}?page=${limit + 1}` } title="...">
                        ...                    
                    </Link>
                </li>
            );

            linksNodes.push(link);
            const lastLink: React.ReactElement = (
                <li className="pagination--item" key={ pages + 1 }>
                    <Link to={ `${baseUrl}?page=${pages}` } title={ `Page ${pages}` } className={ activePage === (pages) ? 'is-active' : '' }>
                        { pages }
                    </Link>
                </li>
            );

            linksNodes.push(lastLink);
        }

        return linksNodes;
    }

    return (
        <ul className="pagination">
            <li className="pagination--item" key="prev-page">
                <Link to={ `${baseUrl}?page=${prevPage}` } className={ activePage === prevPage ? 'is-disabled' : ''}>
                    Prev
                </Link>
            </li>
            { renderPagesLinks() }
            <li className="pagination--item" key="next-page">
                <Link to={ `${baseUrl}?page=${nextPage}` } className={ activePage === nextPage ? 'is-disabled' : ''}>
                    Next
                </Link>
            </li>
        </ul>
    );

}

export default Pagination;