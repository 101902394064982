import React from 'react';

interface IFormGroupProps {
    children: React.ReactElement,
    additionalClassname?: string
}

const FormGroup: React.FunctionComponent<IFormGroupProps> = ({ children, additionalClassname = '' }): React.ReactElement => {

    return (
        <div className={ `form--group ${ additionalClassname }` }>
            { children }
        </div>
    )
}

export default FormGroup;