import React, { useState } from 'react';
import slugify from 'slugify';
import { useIntl } from 'react-intl';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IData from './IData';
import FormValidationMessage from './FormValidationMessage';

interface IPasswordInputProps {
    label: string,
    inputName: string,
    inputData: IData<string|undefined>,
    onChange: (name: string, value: string) => void,
    placeholder?: string,
    disabled?: boolean,
    additionalClassname?: string
}

const PasswordInput: React.FunctionComponent<IPasswordInputProps> = ({ label, inputName, inputData, placeholder, onChange, disabled, additionalClassname }): React.ReactElement => {
    const intl = useIntl();
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.currentTarget;

        onChange(name, value);
    }

    const placeholderI18n = placeholder !== undefined ? intl.formatMessage({ id: placeholder }) : '';

    return (
        <div className={ `input ${ isFocused ? 'is-focused' : '' } ${ disabled !== undefined && disabled === true ? 'is-disabled' : '' } ${ additionalClassname !== undefined ? additionalClassname : '' }` }>
            <div className="input--container">
                <label htmlFor={ slugify(inputName) }>
                    <LocaleMessage id={ label } />
                </label>
                <input 
                    type="password"
                    name={ inputName }
                    value={ inputData[inputName] !== undefined ? inputData[inputName].inputValue : '' }
                    placeholder={ placeholderI18n }
                    disabled={ disabled !== undefined ? disabled : false }
                    onChange={ (event) => handleOnChange(event) }
                    onFocus={ () => setIsFocused(true) }
                    onBlur={ () => setIsFocused(false) }
                    />
            </div>

            { (inputData[inputName] !== undefined && inputData[inputName].validation.inError) && <FormValidationMessage message={ inputData[inputName].validation.message } /> }
        </div>
    );
}

export default PasswordInput;