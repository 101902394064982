import { IComponentSlideshowData } from './IComponentSlideshowData';

import initialComponentDefaultData from '../InitialComponentDefaultData';

export const initialComponentSlideshowData: IComponentSlideshowData = {
    imagesGroup: [],
    effect: 'slide',
    navigation: true,
    pagination: 'dots',
    slidesPerView: 4,
    ...initialComponentDefaultData
}