import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import User from '../Domain/User';
import { RoleEnum } from '../Domain/Enum/RoleEnum';
import { IUsersList } from '../Domain/Scaffolding/IUsersList';

import Pagination from '../../App/Infrastructure/Partials/Pagination';
import { UsersRepository} from '../Infrastructure/Repositories/UsersRepository';
import SearchFilters from '../../_utils/SearchFilters/SearchFilters';
import { searchFiltersTagsSelector } from '../../_utils/SearchFilters/Redux/searchFilters.selectors';

export interface GetUsersProps {
    actualPage: number;
}

export interface SearchItem {
    field: string | undefined;
    value: string | undefined;
}

export const GetUsers: React.FunctionComponent<GetUsersProps> = ({ actualPage = 1 }): React.ReactElement => {
    const history = useHistory();
    const searchedItems = useSelector(searchFiltersTagsSelector);
    const usersRepository: UsersRepository = React.useMemo(() => new UsersRepository(), []);

    const [users, setUsers] = useState<User[]>([]);
    const [pages, setPages] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInError, setIsInError] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        usersRepository.get(20, actualPage, query)
            .then((response: IUsersList) => {
                setUsers(response.docs);
                setPages(response.pages);
                setIsLoading(false);
            }, () => {
                setIsInError(true);
                setIsLoading(false);
            })
    }, [actualPage, usersRepository, query]);

    useEffect(() => {
        if (searchedItems.length > 0) {
            const urlParams = new URLSearchParams();
            for(let item of searchedItems) {
                urlParams.append('field', item.type);
                urlParams.append('value', item.value);
            }
    
            setQuery(`&${ urlParams.toString() }`);
        }
    }, [searchedItems]);

    const renderUserStatusBadge = (status: string): React.ReactElement => {
        switch (status) {
            case RoleEnum.ROLE_USER:
                return <span className="badge is-success">User</span>
            case RoleEnum.ROLE_ADMIN:
                return <span className="badge is-g4g">Administrator</span>
            default:
                return <span className="badge is-success">User</span>
        }
    }

    const renderHeadTable = (): React.ReactElement => {

        return (
            <thead>
                <tr>
                    <th>Username</th>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Email</th>
                    <th className="text-center">Role</th>
                    <th className="is-actions-column">Actions</th>
                </tr>
            </thead>
        )
    }

    const renderBody = (): React.ReactElement => {
        if (isLoading) {

            return (
              <tr>
                  <td colSpan={ 6 }>
                      Is Loading...
                  </td>
              </tr>
            );
        }

        if (isInError) {

            return (
                <tr>
                    <td colSpan={ 6 }>
                        An error has occured, please refresh
                    </td>
                </tr>
            );
        }

        return (
            <React.Fragment>
                {
                    users.map((user: User, index: number) => {
                        return (
                            <tr data-testid="users--user-item" key={ user.id }>
                                <td>
                                    { user.username }
                                </td>
                                <td>
                                    { `${user.firstName}` }
                                </td>
                                <td>
                                    { `${user.lastName}` }
                                </td>
                                <td>
                                    <a href={ `mailto:${user.email}` } title={ `mailto:${user.email}` } target="_blank" rel="noopener noreferrer">
                                        { user.email }
                                    </a>
                                </td>
                                <td className="text-center">
                                    { renderUserStatusBadge(user.role) }
                                </td>
                                <td>
                                    <div className="actions-buttons">
                                        <button type="button" className="button is-small is-primary" onClick={ () => history.push(`/users/${user.id}`)}>
                                            <i className="material-icons">edit</i>
                                        </button>&nbsp;
                                    </div>
                                </td>
                            </tr>
                        );
                    })
                }
            </React.Fragment>
        )
    }

    const renderPagination = (): React.ReactElement | null => {
        if (!isInError && !isLoading) {
            return <Pagination baseUrl="/users" pages={ pages } activePage={ actualPage }/>;
        }

        return null;
    }

    return (
        <React.Fragment>
            <div className="portlet">
                <div className="portlet--body">
                    <SearchFilters availableTypes={ ['email', 'nickname', 'code', 'id'] } />
                    <div className="table-responsive">

                        <table className="has-row-details">
                            { renderHeadTable() }
                            <tbody>
                                { renderBody() }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            { renderPagination() }
        </React.Fragment>
    );
};