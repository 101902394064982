import React from 'react';
import { useSelector } from 'react-redux';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';
import { AppReduxState } from '../../../../../store';

import { IComponentData } from '../IComponentData';

import FormGroup from '../../../../../_utils/Forms/FormGroup';
import GroupCollapse from '../../../../Infrastructure/Partials/GroupCollapse';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';

interface IHandleComponentAccordionSettingsProps {
    pageContentId: string,
    onChange: (inputName: string, value: boolean) => void
}

const HandleComponentAccordionSettings: React.FunctionComponent<IHandleComponentAccordionSettingsProps> = ({ pageContentId, onChange }): React.ReactElement => {
    const pageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentData<boolean> = pageContent.data as IComponentData<boolean>;

    return (
        <React.Fragment>
            <GroupCollapse label="page_builder.component-accordion-type.settings.properties">
                <div className="page-builder--settings-list">
                    <FormGroup>
                        <React.Fragment>
                            <label htmlFor="isBordered"><LocaleMessage id="page_builder.component-accordion-type.settings.with_borders" /></label>
                            <div className="form--group-input">
                                <input
                                    type="checkbox"
                                    id="isBordered"
                                    name="isBordered"
                                    onChange={(event) => { onChange('isBordered', event.currentTarget.checked)}}
                                    checked={ data.isBordered as boolean } />
                            </div>
                        </React.Fragment>
                    </FormGroup>
                </div>
            </GroupCollapse>
        </React.Fragment>
    );
}

export default HandleComponentAccordionSettings;