import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useEffectOnce } from 'react-use';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PagesRepository from '../../Infrastructure/Repositories/PagesRepository';
import ContactRequestSettings from '../../Domain/ContactRequestSettings';
import ContactRequestPagesCollection from '../../Domain/ContactRequestPagesCollection';
import ContactRequestSettingsRepository from '../../Infrastructure/Repositories/ContactRequestSettingsRepository';

import IData from '../../../_utils/Forms/IData';
import FormGroup from '../../../_utils/Forms/FormGroup';
import TextInput from '../../../_utils/Forms/TextInput';
import SelectInput from '../../../_utils/Forms/SelectInput';
import SegmentedControl from '../../../_utils/SegmentedControl/SegmentedControl';
import IDataContactRequestSettings from './IDataContactRequestSettings';
import ValidateContactRequestSettings from './ValidateContactRequestSettings';
import { initialDataContactRequestSettings } from './InitialDataContactRequestSettings';
import ContactRequestPage from '../../Domain/ContactRequestPage';
import InputsGroup from '../../../_utils/Forms/InputsGroup';

const validateContactRequestSettings: ValidateContactRequestSettings = new ValidateContactRequestSettings();
const pagesRepository: PagesRepository = new PagesRepository();
const contactRequestSettingsRepository: ContactRequestSettingsRepository = new ContactRequestSettingsRepository();

const HandleContactRequestSettings: React.FunctionComponent = (): React.ReactElement => {
    const intl = useIntl();
    const [tab, setTab] = useState<string>('settings');
    const [data, setData] = useState<IDataContactRequestSettings>(initialDataContactRequestSettings);
    const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
    const [canBeSubmitted, setCanBeSubmitted] = useState<boolean>(true);
    const [formRequestLoading, setFormRequestLoading] = useState<boolean>(false);
    const [pages, setPages] = useState<ContactRequestPagesCollection>();

    const refSegmentControls = useRef(null);
    const refSegmentControlOne = useRef(null);
    const refSegmentControlTwo = useRef(null);

    useEffectOnce(() => {
        setDataIsLoading(true);
        pagesRepository.get()
            .then((pagesCollection: ContactRequestPagesCollection) => {
                setPages(pagesCollection);
            });
        contactRequestSettingsRepository.get()
            .then((contactRequestSettings: ContactRequestSettings) => {
                const newData: IDataContactRequestSettings = {
                    senderName: {
                        inputValue: contactRequestSettings.senderName,
                        validation: { inError: false, message: '' }
                    },
                    senderEmail: {
                        inputValue: contactRequestSettings.senderEmail,
                        validation: { inError: false, message: '' }
                    },
                    senderNoReply: {
                        inputValue: contactRequestSettings.senderNoReply,
                        validation: { inError: false, message: '' }
                    },
                    successPageId: {
                        inputValue: contactRequestSettings.successPageId,
                        validation: { inError: false, message: '' }
                    },
                    provider: {
                        inputValue: contactRequestSettings.provider,
                        validation: { inError: false, message: '' }
                    },
                    providerAPIKey: {
                        inputValue: contactRequestSettings.providerAPIKey,
                        validation: { inError: false, message: '' }
                    },
                    providerTemplateId: {
                        inputValue: contactRequestSettings.providerTemplateId,
                        validation: { inError: false, message: '' }
                    }
                };
        
                setData(newData);
                setDataIsLoading(false);
            }, () => {
                toast.error(<LocaleMessage id="app.something_went_wrong" />);
            });
    });

    useEffect(() => {
        setCanBeSubmitted(validateContactRequestSettings.canBeSubmitted(data));
    }, [data]);

    const handleSubmit = (): void => {
        if (canBeSubmitted && !formRequestLoading) {
            setFormRequestLoading(true);
            contactRequestSettingsRepository.put(data)
                .then(() => {
                    toast.success(<LocaleMessage id="contact_request_settings.put.success" />);

                    setFormRequestLoading(false);
                }, (error: any) => {
                    setData(validateContactRequestSettings.handleApiResponse(data, error.response.data.errors));
                    toast.error(<LocaleMessage id="app.something_went_wrong" />);

                    setFormRequestLoading(false);
                });
        }
    }

    const handleOnChange = (name: string, value: string | number): void => {
        const newData: IDataContactRequestSettings = Object.assign({}, data, {
            [name]: {
                inputValue: value,
                validation: { inError: false, message: '' }
            }
        });

        setData(validateContactRequestSettings.validateForm(newData));
    }

    const renderPortletBody = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="form--segmented-controls">
                    <SegmentedControl
                        name="contact-request-settings-tabs"
                        segments={
                            [
                                { label: "Paramètres", value: 'settings', ref: refSegmentControlOne },
                                { label: "Providers", value: 'providers', ref: refSegmentControlTwo }
                            ]
                        }
                        defaultIndex={0}
                        onChangeSegment={(value) => setTab(value)}
                        controlRef={ refSegmentControls }
                    />
                </div>

                { tab === 'settings' && renderSettingsTab() }
                { tab === 'providers' && renderProvidersTab() }
            </React.Fragment>
        );
    }

    const generatePagesRecord = (): Record<string, string> => {
        const pagesRecord: Record<string, string> = {};

        pages?.forEach((page: ContactRequestPage) => {
            pagesRecord[page.id] = page.title;
        });

        return pagesRecord;
    }

    const renderSettingsTab = (): React.ReactElement => {
        const senderEmailInfo = intl.formatMessage({id: "contact_request_settings.sender_email.info"});
        const senderNoReplyInfo = intl.formatMessage({id: "contact_request_settings.sender_no_reply.info"});
        const successPageIdInfo = intl.formatMessage({id: "contact_request_settings.success_page_id.info"});

        return (
            <div>
                <InputsGroup name="Paramètres concernant les emails">
                    <React.Fragment>
                        <TextInput
                            inputName="senderEmail"
                            inputType="email"
                            label="contact_request_settings.sender_email.label"
                            additionalInformation={ senderEmailInfo }
                            inputValue={ data.senderEmail.inputValue }
                            errorMessage={ data.senderEmail.validation.inError ? data.senderEmail.validation.message : undefined }
                            placeholder="contact_request_settings.sender_email.placeholder"
                            onChange={(name, value) => handleOnChange(name, value)}
                            disabled={ dataIsLoading } />

                        <TextInput
                            inputName="senderNoReply"
                            inputType="email"
                            label="contact_request_settings.sender_no_reply.label"
                            additionalInformation={ senderNoReplyInfo }
                            inputValue={ data.senderNoReply.inputValue }
                            errorMessage={ data.senderNoReply.validation.inError ? data.senderNoReply.validation.message : undefined }
                            placeholder="contact_request_settings.sender_no_reply.placeholder"
                            onChange={(name, value) => handleOnChange(name, value)}
                            disabled={ dataIsLoading } />

                        <TextInput
                            inputName="senderName"
                            inputType="email"
                            label="contact_request_settings.sender_name.label"
                            inputValue={ data.senderName.inputValue }
                            errorMessage={ data.senderName.validation.inError ? data.senderName.validation.message : undefined }
                            placeholder="contact_request_settings.sender_name.placeholder"
                            onChange={(name, value) => handleOnChange(name, value)}
                            disabled={ dataIsLoading } />

                    </React.Fragment>
                </InputsGroup>
                <InputsGroup name="Paramètres concernant le formulaire de contact">
                    <React.Fragment>
                        <SelectInput inputName="successPageId" label="contact_request_settings.success_page_id.label" additionalInformation={ successPageIdInfo } inputValue={ data.successPageId.inputValue } errorMessage={ data.successPageId.validation.inError ? data.successPageId.validation.message : undefined } choices={ generatePagesRecord() } onChange={(name, value) => handleOnChange(name, value)} disabled={ dataIsLoading } />
                    </React.Fragment>
                </InputsGroup>
            </div>
        )
    }

    const generateProviderRecord = (): Record<string, string> => {
        const types: Record<string, string> = {};

        types['send_in_blue'] = "Send in Blue (recommended)";
        types['mailchimp'] = "Mailchimp (coming soon...)";

        return types;
    }

    const renderProvidersTab = (): React.ReactElement => {

        return (
            <React.Fragment>
                <SelectInput inputName="provider" label="contact_request_settings.provider.label" inputValue={ data.provider.inputValue } errorMessage={ data.provider.validation.inError ? data.provider.validation.message : undefined } choices={ generateProviderRecord() } emptyPlaceholder="contact_request_settings.provider.placeholder" onChange={(name, value) => handleOnChange(name, value)} disabled={ dataIsLoading } />
                <TextInput
                    inputName="providerAPIKey"
                    label="contact_request_settings.provider_api_key.label"
                    inputValue={ data.providerAPIKey.inputValue }
                    errorMessage={ data.providerAPIKey.validation.inError ? data.providerAPIKey.validation.message : undefined }
                    placeholder="contact_request_settings.provider_api_key.placeholder"
                    onChange={(name, value) => handleOnChange(name, value)}
                    disabled={ dataIsLoading } />
                <TextInput
                    inputName="providerTemplateId"
                    label="contact_request_settings.provider_template_id.label"
                    inputValue={ String(data.providerTemplateId.inputValue) }
                    errorMessage={ data.providerTemplateId.validation.inError ? data.providerTemplateId.validation.message : undefined }
                    placeholder="contact_request_settings.provider_template_id.placeholder"
                    onChange={(name, value) => handleOnChange(name, value)}
                    disabled={ dataIsLoading } />
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>

            <div className="portlet">
                <div className="portlet--title">
                    <div className="portlet--title-label">
                        <LocaleMessage id="contact_request_settings.page.title_2" />
                    </div>
                    <div className="portlet--title-toolbar"></div>
                </div>

                <div className="portlet--body">
                    { renderPortletBody() }
                </div>
            </div>

            <button type="button" className={ `button is-primary ${!canBeSubmitted && ' is-disabled'} ${formRequestLoading && 'is-loading'}` } onClick={ handleSubmit }>
                Enregistrer
            </button>
        </React.Fragment>
    );
}

export default HandleContactRequestSettings;