import { IComponentGalleryData } from './IComponentGalleryData';

import initialComponentDefaultData from '../InitialComponentDefaultData';

export const initialComponentGalleryData: IComponentGalleryData = {
    imagesGroup: [],
    masonry: false,
    positionInformation: "below",
    nbrColumnsDesktop: 4,
    borderRadiusDesktop: 0,
    nbrColumnsMobile: 2,
    borderRadiusMobile: 0,
    ...initialComponentDefaultData
}