import React from 'react';
import ReactDOM from 'react-dom';

import {App, AppProvider} from './App';

ReactDOM.render(
    <AppProvider>
        <App />
    </AppProvider>,
    document.getElementById('root'),
);