import { store } from '../../../store';
import { FlatDataItem } from 'react-sortable-tree';

import NavigationCollection from '../../Domain/NavigationCollection';
import IDataNavigation from '../../Application/Forms/IDataNavigation';
import { 
    navigationDeleteRequestFailureActionCreator,
    navigationDeleteRequestSuccessActionCreator,
    navigationPagesRequestSuccessActionCreator,
    navigationPostRequestFailureActionCreator,
    navigationPostRequestSuccessActionCreator,
    navigationRequestFailureActionCreator,
    navigationRequestSuccessActionCreator
} from './navigation.actions';

import NavigationRepository from '../Repositories/NavigationRepository';
import NavigationPagesRepository from '../Repositories/NavigationPagesRepository';
import NavigationPagesCollection from '../../Domain/NavigationPagesCollection';

const navigationRepository: NavigationRepository = new NavigationRepository();
const navigationPagesRepository: NavigationPagesRepository = new NavigationPagesRepository();

export const navigationGetStructureService = (): void => {
    navigationRepository.get()
        .then((navigation: NavigationCollection) => {

            store.dispatch(navigationRequestSuccessActionCreator(navigation));
        }, (error: any) => {

            store.dispatch(navigationRequestFailureActionCreator(error.response.data.message));
        });
}

export const navigationPostItemService = (data: IDataNavigation): void => {
    navigationRepository.post(data)
        .then(() => {

            store.dispatch(navigationPostRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(navigationPostRequestFailureActionCreator(error.response.data.message));
        });
}

export const navigationPutItemService = (id: number, data: IDataNavigation): void => {
    navigationRepository.put(id, data)
        .then(() => {

            store.dispatch(navigationPostRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(navigationPostRequestFailureActionCreator(error.response.data.message));
        });
}

export const navigationDeleteItemService = (id: number): void => {
    navigationRepository.delete(id)
        .then(() => {

            store.dispatch(navigationDeleteRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(navigationDeleteRequestFailureActionCreator(error.response.data.message));
        });
}

export const navigationGetPagesService = (): void => {
    navigationPagesRepository.get()
        .then((navigationPages: NavigationPagesCollection) => {

            store.dispatch(navigationPagesRequestSuccessActionCreator(navigationPages));
        },(error: any) => {
            console.error(error);
        });
}

export const navigationUpdatePositionsService = (flatDataItems: FlatDataItem[]): void => {
    const newPositions: Record<string, string> = {};
    const newParentIds: Record<string, string> = {};

    flatDataItems.forEach((flatDataItem: FlatDataItem) => {
        //@ts-ignore
        newPositions[flatDataItem.node.id] = flatDataItem.treeIndex;
        //@ts-ignore
        newParentIds[flatDataItem.node.id] = flatDataItem.parentNode !== null ? flatDataItem.parentNode.id : null;
    });

    navigationRepository.changeAllNavigationPositions(newPositions, newParentIds)
        .then((navigationCollection: NavigationCollection) => {

            store.dispatch(navigationRequestSuccessActionCreator(navigationCollection));
        }, (error: any) => {
            console.error(error);
            store.dispatch(navigationRequestFailureActionCreator(error.response.data.message));
        });
}