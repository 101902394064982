import React, { useState } from 'react';
import slugify from 'slugify';
import { useIntl } from 'react-intl';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IData from './IData';
import FormValidationMessage from './FormValidationMessage';
import IconWarning from '../../App/Infrastructure/Style/Svg/IconWarning';

interface ITextInputProps {
    label: string,
    inputName: string,
    inputValue: string,
    errorMessage?: string,
    onChange: (name: string, value: string) => void,
    inputType?: 'email' | 'text',
    placeholder?: string,
    disabled?: boolean,
    additionalClassname?: string,
    additionalInformation?: string
}

const TextInput: React.FunctionComponent<ITextInputProps> = ({ label, inputName, inputType, inputValue, errorMessage, placeholder, onChange, disabled, additionalClassname, additionalInformation }): React.ReactElement => {
    const intl = useIntl();
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.currentTarget;

        onChange(name, value);
    }

    const placeholderI18n = placeholder !== undefined ? intl.formatMessage({ id: placeholder }) : '';

    return (
        <React.Fragment>
            <div className={ `input ${ isFocused ? 'is-focused' : '' } ${ disabled !== undefined && disabled === true ? 'is-disabled' : '' } ${ additionalClassname !== undefined ? additionalClassname : '' }` }>
                <div className="input--container">
                    <label htmlFor={ slugify(inputName) }>
                        <LocaleMessage id={ label } />
                    </label>
                    <div className="input--text">
                        <input
                            id={ slugify(inputName) }
                            type={ inputType !== undefined ? inputType : 'text' }
                            name={ inputName }
                            value={ inputValue }
                            placeholder={ placeholderI18n }
                            disabled={ disabled !== undefined ? disabled : false }
                            onChange={ (event) => handleOnChange(event) }
                            onFocus={ () => setIsFocused(true) }
                            onBlur={ () => setIsFocused(false) }
                            />
                    </div>
                </div>

                { errorMessage !== undefined && <FormValidationMessage message={ errorMessage } /> }

                { additionalInformation !== undefined && <blockquote className="input--additional-information">{ IconWarning }&nbsp;<span dangerouslySetInnerHTML={{ __html: String(additionalInformation) }}></span></blockquote> }
            </div>
        </React.Fragment>
    )
}

export default TextInput;