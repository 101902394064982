import HttpClient from '../../../_utils/HttpClient/HttpClient';

import AppTheme from '../../Domain/AppTheme';

class AppThemeRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    private fromJson = (response: any): AppTheme => {
        const theme = new AppTheme(
            response.primary_color,
            response.secondary_color,
            response.text_color,
            response.text_headers_color,
            response.header_variant,
            response.header_logo_position,
            response.header_text_color,
            response.header_color,
            response.header_background_blur,
            response.footer_color
        );

        return theme;
    }

    public get = async (): Promise<AppTheme> => {
        const response = await this.httpClient.get(this.baseUrl, `/theme`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No theme found');
        }

        return this.fromJson(response.data.docs);
    }

}

export default AppThemeRepository;