class File {

    public fileName: string;
    public fileExtension: string;
    public fileSize: number;
    public path: string;

    public constructor(fileName: string, fileExtension: string, fileSize: number, path: string) {
        this.fileName = fileName;
        this.fileExtension = fileExtension;
        this.fileSize = fileSize;
        this.path = path;
    }

}

export default File;