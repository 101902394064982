import React, { useMemo } from 'react';
import { AppReduxState } from '../../../../../store';
import { useSelector } from 'react-redux';

import PageContent from '../../../../Domain/PageContent';

import IComponentContactRequestData from '../../../../Application/Forms/ComponentType/ContactForms/IComponentContactRequestData';
import IComponentContactRequestFieldData from '../../../../Application/Forms/ComponentType/ContactForms/IComponentContactRequestFieldData';
import IComponentContactRequestSubjectData from '../../../../Application/Forms/ComponentType/ContactForms/IComponentContactRequestSubjectData';

import { pageBuilderModeEditorSelector, pageBuilderPageContentSelector } from '../../../Redux/page-builder.selectors';

interface IDisplayComponentContactRequestProps {
    id: string
}

const DisplayComponentContactRequest: React.FunctionComponent<IDisplayComponentContactRequestProps> = ({ id }): React.ReactElement => {
    const viewport = useSelector(pageBuilderModeEditorSelector);
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, id));
    const data: IComponentContactRequestData = pageContent.data as IComponentContactRequestData;

    const { subjects } = useMemo(() => data, [data]);
    const { fields } = useMemo(() => data, [data]);

    return (
        <React.Fragment>
            <form>
                <div className={ `form--group ${viewport === 'mobile' ? 'is-vertical' : ''}` }>
                    <label>A quel sujet ?</label>
                    <div className="form--group-input">
                        <select name="subject">
                            {
                                subjects.map((value: IComponentContactRequestSubjectData, index: number) => {
                                    return <option key={ index }>{ value.subject }</option>;
                                })
                            }
                        </select>
                        <div className="form--validations-error"></div>
                    </div>
                </div>
                <div className={ `form--group ${viewport === 'mobile' ? 'is-vertical' : ''}` }>
                    <label>Votre adresse email</label>
                    <div className="form--group-input">
                        <input type="email" name="sender_email" placeholder="john.doe@email.com" required />
                        <div className="form--validations-error"></div>
                    </div>
                </div>

                {
                    fields.map((field: IComponentContactRequestFieldData, index: number) => {

                        return (
                            <div className={ `form--group ${viewport === 'mobile' ? 'is-vertical' : ''}` } key={ index }>
                                <label>{ field.labelName }</label>
                                <div className="form--group-input">
                                    { field.inputType === 'text-type' && <input type="text" placeholder={ field.inputPlaceholder } /> }
                                    { field.inputType === 'email-type' && <input type="email" placeholder={ field.inputPlaceholder } /> }
                                    { field.inputType === 'textarea-type' && <textarea placeholder={ field.inputPlaceholder }></textarea> }
                                </div>
                            </div>
                        );
                    })
                }
                <div className="form--group">
                    <label></label>
                    <button type="button" className="button">
                        Envoyer
                    </button>
                </div>
            </form>
        </React.Fragment>
    );
}

export default DisplayComponentContactRequest;