import {Action} from 'redux';

import {locales} from './Provider/LocaleProvider';
import {localeChangeAction, LocaleChangeAction} from './locale.actions';

export interface LocaleReduxState {
    locale: string;
}

export const locale: LocaleReduxState = {
    locale: locales[localStorage.getItem('locale') || window.navigator.language] ? localStorage.getItem('locale') || window.navigator.language : 'en-US',
};

export const localeReducer = (state: LocaleReduxState | undefined = locale, action: Action): LocaleReduxState => {
    switch (action.type) {
        case localeChangeAction: {
            const {locale} = (action as LocaleChangeAction).payload;
            return {
                ...state,
                locale,
            };
        }
        default:
            return state;
    }
};
