import { AnyAction } from 'redux';
import { DropResult } from 'react-beautiful-dnd';

import PageContent from '../../Domain/PageContent';

import {
    pageBuilderOnDragEndAction,
    PageBuilderOnDragEndAction,
    pageBuilderChangeEditorModeAction,
    PageBuilderChangeEditorModeAction,
    pageBuilderSelectPageContentAction,
    PageBuilderSelectPageContentAction,
    pageBuilderAddNewPageContentAction,
    PageBuilderAddNewPageContentAction,
    pageBuilderReorderPageContentAction,
    PageBuilderReorderPageContentAction,
    pageBuilderDeletePageContentAction,
    PageBuilderDeletePageContentAction,
    pageBuilderUpdatePageContentDataAction,
    PageBuilderUpdatePageContentDataAction,
    pageBuilderUpdateAllPageContentDataAction,
    PageBuilderUpdateAllPageContentDataAction
} from './page-builder.actions';

export interface PageBuilderReduxState {
    result: DropResult | null,
    pageContentItems: {
        byId: Record<string|number, PageContent>,
        ids: string[]
    },
    pageContentSelected: string | null,
    mode: 'editor' | 'desktop' | 'mobile'
}

const initialPageBuilderReduxState: PageBuilderReduxState = {
    result: null,
    pageContentItems: {
        byId: {},
        ids: []
    },
    pageContentSelected: null,
    mode: 'editor'
}

export const PageBuilderReducer = (state: PageBuilderReduxState = initialPageBuilderReduxState, action: AnyAction): PageBuilderReduxState => {

    switch(action.type) {

        case pageBuilderOnDragEndAction:
            {
                return {
                    ...state,
                    result: (action as PageBuilderOnDragEndAction).payload.result
                }
            }

        case pageBuilderAddNewPageContentAction:
            {
                const { id, position, pageContent } = (action as PageBuilderAddNewPageContentAction).payload

                return {
                    ...state,
                    pageContentItems: {
                        ...state.pageContentItems,
                        byId: {
                            ...state.pageContentItems.byId,
                            [id]: pageContent,
                        },
                        ids: [...state.pageContentItems.ids.slice(0, position), id, ...state.pageContentItems.ids.slice(position)]
                    }
                }
            }

        case pageBuilderReorderPageContentAction:
            {
                return {
                    ...state,
                    pageContentItems: {
                        ...state.pageContentItems,
                        ids: (action as PageBuilderReorderPageContentAction).payload.ids
                    }
                }
            }

        case pageBuilderDeletePageContentAction:
            {
                const { idToDelete } = (action as PageBuilderDeletePageContentAction).payload;
                const { [idToDelete]: deleted, ...allIds } = state.pageContentItems.byId;

                return {
                    ...state,
                    pageContentItems: {
                        byId: allIds,
                        ids: state.pageContentItems.ids.filter(id => id !== idToDelete)
                    }
                }
            }

        case pageBuilderUpdatePageContentDataAction:
            {
                const { id, data } = (action as PageBuilderUpdatePageContentDataAction).payload;
                const { [id]: modified, ...allIds } = state.pageContentItems.byId;

                return {
                    ...state,
                    pageContentItems: {
                        ...state.pageContentItems,
                        byId: {
                            ...allIds,
                            [id]: {
                                ...modified,
                                data: data
                            }
                        }
                    }
                }
            }

        case pageBuilderUpdateAllPageContentDataAction:
            {
                const { ids, pageContents } = (action as PageBuilderUpdateAllPageContentDataAction).payload;

                return {
                    ...state,
                    pageContentItems: {
                        byId: pageContents,
                        ids: ids
                    }
                }
            }

        case pageBuilderChangeEditorModeAction:
            {
                return {
                    ...state,
                    mode: (action as PageBuilderChangeEditorModeAction).payload.mode
                }
            }

        case pageBuilderSelectPageContentAction:
            {
                return {
                    ...state,
                    pageContentSelected: (action as PageBuilderSelectPageContentAction).payload.id
                }
            }

        default:
            {
                return state;
            }

    }

}