import { Action } from 'redux';
import { DropResult } from 'react-beautiful-dnd';

import PageContent from '../../Domain/PageContent';
import { IComponentData } from '../../Application/Forms/ComponentType/IComponentData';

export const pageBuilderOnDragEndAction                 = '@@page_builder/ON_DRAG_END';
export const pageBuilderSelectPageContentAction         = '@@page_builder/SELECT_PAGE_CONTENT';
export const pageBuilderAddNewPageContentAction         = '@@page_builder/ADD_NEW_PAGE_CONTENT';
export const pageBuilderReorderPageContentAction        = '@@page_builder/REORDER_PAGE_CONTENT';
export const pageBuilderDeletePageContentAction         = '@@page_builder/DELETE_PAGE_CONTENT';
export const pageBuilderUpdatePageContentDataAction     = '@@page_builder/UPDATE_PAGE_CONTENT_DATA';
export const pageBuilderUpdateAllPageContentDataAction  = '@@page_builder/UPDATE_ALL_PAGE_CONTENT_DATA'

export const pageBuilderChangeEditorModeAction          = '@@page_builder/CHANGE_EDITOR_MODE';

export interface PageBuilderOnDragEndAction extends Action<typeof pageBuilderOnDragEndAction> {
    payload: {
        result: DropResult
    }
}

export interface PageBuilderAddNewPageContentAction extends Action<typeof pageBuilderAddNewPageContentAction> {
    payload: {
        id: string,
        position: number,
        pageContent: PageContent
    }
}

export interface PageBuilderSelectPageContentAction extends Action<typeof pageBuilderSelectPageContentAction> {
    payload: {
        id: string | null
    }
}

export interface PageBuilderReorderPageContentAction extends Action<typeof pageBuilderReorderPageContentAction> {
    payload: {
        ids: string[]
    }
}

export interface PageBuilderDeletePageContentAction extends Action<typeof pageBuilderDeletePageContentAction> {
    payload: {
        idToDelete: string
    }
}

export interface PageBuilderUpdatePageContentDataAction extends Action<typeof pageBuilderUpdatePageContentDataAction> {
    payload: {
        id: string,
        data: IComponentData<string|number|boolean|IComponentData<string|undefined>[]>
    }
}

export interface PageBuilderUpdateAllPageContentDataAction extends Action<typeof pageBuilderUpdateAllPageContentDataAction> {
    payload: {
        ids: string[],
        pageContents: Record<string, PageContent>
    }
}

export interface PageBuilderChangeEditorModeAction extends Action<typeof pageBuilderChangeEditorModeAction> {
    payload: {
        mode: 'editor' | 'desktop' | 'mobile'
    }
}

// --- ActionCreator declaration
export const pageBuilderOnDragEndActionCreator = (result: DropResult): PageBuilderOnDragEndAction => {

    return {
        type: pageBuilderOnDragEndAction,
        payload: {
            result
        }
    }
}

export const pageBuilderAddNewPageContentActionCreator = (id: string, position: number, pageContent: PageContent): PageBuilderAddNewPageContentAction => {

    return {
        type: pageBuilderAddNewPageContentAction,
        payload: {
            id,
            position,
            pageContent
        }
    }
}

export const pageBuilderReorderPageContentActionCreator = (ids: string[]): PageBuilderReorderPageContentAction => {

    return {
        type: pageBuilderReorderPageContentAction,
        payload: {
            ids
        }
    }
}

export const pageBuilderDeletePageContentActionCreator = (idToDelete: string): PageBuilderDeletePageContentAction => {

    return {
        type: pageBuilderDeletePageContentAction,
        payload: {
            idToDelete
        }
    }
}

export const pageBuilderUpdatePageContentDataActionCreator = (id: string, data: IComponentData<string|number|boolean|IComponentData<string|undefined>[]>): PageBuilderUpdatePageContentDataAction => {

    return {
        type: pageBuilderUpdatePageContentDataAction,
        payload: {
            id,
            data
        }
    }
}

export const pageBuilderSelectPageContentActionCreator = (index: string | null): PageBuilderSelectPageContentAction => {

    return {
        type: pageBuilderSelectPageContentAction,
        payload: {
            id: index
        }
    }
}

export const pageBuilderUpdateAllPageContentDataActionCreator = (ids: string[], pageContents: Record<string, PageContent>): PageBuilderUpdateAllPageContentDataAction => {

    return {
        type: pageBuilderUpdateAllPageContentDataAction,
        payload: {
            ids,
            pageContents
        }
    }
}

export const pageBuilderChangeEditorModeActionCreator = (mode: 'editor' | 'desktop' | 'mobile'): PageBuilderChangeEditorModeAction => {

    return {
        type: pageBuilderChangeEditorModeAction,
        payload: {
            mode
        }
    }
}