import React, { useMemo } from 'react';
import { AppReduxState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContent from '../../../../Domain/PageContent';
import { IComponentData } from '../IComponentData';
import { IComponentSlideshowData } from './IComponentSlideshowData';
import { IComponentSlideshowImageData } from './IComponentSlideshowImageData';

import FormGroup from '../../../../../_utils/Forms/FormGroup';
import IconArrowDown from '../../../../Infrastructure/Style/svg/IconArrowDown';
import SimpleOrdonnableList from '../../../../../_utils/DragAndDrop/SimpleOrdonnableList';
import HandleComponentSlideshowImage from './HandleComponentSlideshowImage';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';
import { pageBuilderUpdatePageContentDataActionCreator } from '../../../../Infrastructure/Redux/page-builder.actions';
import DisplayImage from '../../../../../_utils/Image/DisplayImage';

interface IHandleComponentSlideshow {
    pageContentId: string
}

const HandleComponentSlideshow: React.FunctionComponent<IHandleComponentSlideshow> = ({ pageContentId }): React.ReactElement => {
    const dispatch = useDispatch();
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentSlideshowData = pageContent.data as IComponentSlideshowData;
    const { imagesGroup } = useMemo(() => data, [data]);

    const renderEmptySlideshow = (): React.ReactElement => {

        return (
            <div className="page-builder--content-slideshow-empty">
                <p><LocaleMessage id="page_builder.component-slideshow-type.empty" /></p>
                <div className="icons">
                    { IconArrowDown }
                </div>
            </div>
        )
    }

    const setNewData = (name: string, value: IComponentSlideshowImageData[]): void => {
        dispatch(
            pageBuilderUpdatePageContentDataActionCreator(
                pageContentId,
                {
                    ...data as IComponentData<string|boolean|IComponentData<string|undefined>[]>,
                    [name]: value
                }
            )
        );
    }

    const handleOnElementChange = (name: string, value: string, index: number): void => {
        const elements: IComponentSlideshowImageData[] = imagesGroup as IComponentSlideshowImageData[];

        if(elements[index][name] !== undefined) {
            elements[index][name] = value;
        } else {
            const newElement = value;

            elements[index][name] = newElement;
        }

        setNewData('imagesGroup', elements);
    }

    const generateElementsList = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        imagesGroup.forEach((image: IComponentSlideshowImageData, index: number) => {
            elements.push(
                <div className={ `page-builder--content-slideshow` }>
                    <div className="page-builder--content-slideshow-image">
                        <DisplayImage filename={ image.image } width={ 140 } height={ 140 } />
                    </div>

                    <div className="page-builder--content-slideshow-form">
                        <FormGroup additionalClassname="is-vertical">
                            <React.Fragment>
                                <label><LocaleMessage id="page_builder.component-slideshow-type.image_title.label" /></label>
                                <input type="text" name="title" value={ image.title } onChange={(event) => handleOnElementChange('title', event.currentTarget.value, index)} />
                            </React.Fragment>
                        </FormGroup>

                        <FormGroup additionalClassname="is-vertical">
                            <React.Fragment>
                                <label><LocaleMessage id="page_builder.component-slideshow-type.image_description.label" /></label>
                                <textarea name="description" value={ image.description } onChange={(event) => handleOnElementChange('description', event.currentTarget.value, index)} />
                            </React.Fragment>
                        </FormGroup>
                    </div>
                </div>
            );
        });

        return elements;
    }

    const handleOnImageUploaded = (path: string): void => {
        imagesGroup.push(
            {
                image: `/${path}`,
                title: `Image #${ imagesGroup.length + 1 }`,
                description: ""
            }
        );

        setNewData('imagesGroup', imagesGroup);
    }

    return (
        <div className={ `form--group` }>
            <label htmlFor="content">
                <LocaleMessage id="page_builder.component-slideshow-type.images_group.label" /> :
            </label>
            <div className="form--group-input">
                {
                    //@ts-ignore
                    imagesGroup.length === 0 && renderEmptySlideshow() 
                }
                <SimpleOrdonnableList
                    type="slideshow"
                    droppableId={ `slideshow-images-${ pageContentId }` }
                    draggableId={ `slideshow-images-item-${ pageContentId }` }
                    elementsList={ generateElementsList() }
                    elementsListData={ imagesGroup as IComponentSlideshowImageData[] }
                    onChangedElementsListData={(elementsListData) => setNewData('imagesGroup', elementsListData as IComponentSlideshowImageData[])} 
                    />

                <HandleComponentSlideshowImage onImageUploaded={(path) => { handleOnImageUploaded(path) }} />
            </div>
        </div>
    );
}

export default HandleComponentSlideshow;