import React from 'react';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import HandleUserSettings from './Forms/HandleUserSettings';

const GetGeneralSettings: React.FunctionComponent = (): React.ReactElement => {

    return (
        <div className="portlet">
            <div className="portlet--title">
                <div className="portlet--title-label">
                    <LocaleMessage id="users.settings.navigation.general_settings" />
                </div>
            </div>

            <div className="portlet--body">
                <HandleUserSettings />
            </div>
        </div>
    );
}

export default GetGeneralSettings;