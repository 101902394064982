import { store } from '../../../../store';
import IDataTheme from '../../../Application/Forms/Theme/IDataTheme';

import ThemeRepository from '../../Repositories/ThemeRepository';

import { themePutRequestFailureActionCreator, themePutRequestSuccessActionCreator } from './put-theme.actions';

const themeRepository: ThemeRepository = new ThemeRepository();
export const putThemeService = (data: IDataTheme): void => {

    themeRepository.put(data)
        .then(() => {

            store.dispatch(themePutRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(themePutRequestFailureActionCreator(error.response.data.message, error.response.data.errors));
        });

}