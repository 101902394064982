import HttpClient from '../../../_utils/HttpClient/HttpClient';

import Navigation from '../../Domain/Navigation';
import NavigationCollection from '../../Domain/NavigationCollection';
import IDataNavigation from '../../Application/Forms/IDataNavigation';

class NavigationRepository {

    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);
    private httpClient: HttpClient = new HttpClient();

    public get = async (): Promise<NavigationCollection> => {
        const response = await this.httpClient.get(this.baseUrl, `/navigation`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No navigation found');
        }

        const navigationCollection = this.generateNavigationTree(response.data.docs);

        return navigationCollection;
    };

    private generateNavigationTree = (elements: any): NavigationCollection => {
        const navigationCollection = new NavigationCollection();

        elements.forEach((navigation: any) => {
            const node = this.generateBranch(navigation);
            navigationCollection.push(node);
        });

        return navigationCollection;
    }

    private generateBranch = (element: any): Navigation => {

        return new Navigation(
            element.id,
            element.title,
            element.type,
            element.page_id,
            element.module_slug,
            element.parent_id,
            element.order,
            element.childrens ? this.generateNavigationTree(element.childrens) : null
        )
    }

    public post = async (data: IDataNavigation): Promise<any> => {
        const dataBody = new FormData();
        dataBody.append('title', data.title.inputValue);
        dataBody.append('type', data.type.inputValue);
        dataBody.append('page_id', data.pageId.inputValue);
        dataBody.append('module_slug', data.moduleSlug.inputValue);
        dataBody.append('parent_id', String(data.parentId.inputValue));
        dataBody.append('order', String(data.order.inputValue));

        const response = await this.httpClient.post(this.baseUrl, `/navigation`, dataBody);

        return response;
    }

    public put = async (id: number, data: IDataNavigation): Promise<any> => {
        const dataBody = new FormData();
        dataBody.append('title', data.title.inputValue);
        dataBody.append('type', data.type.inputValue);
        dataBody.append('page_id', data.pageId.inputValue);
        dataBody.append('module_slug', data.moduleSlug.inputValue);
        dataBody.append('parent_id', String(data.parentId.inputValue));
        dataBody.append('order', String(data.order.inputValue));

        const response = await this.httpClient.put(this.baseUrl, `/navigation/${id}`, dataBody);

        return response;
    }

    public changeAllNavigationPositions = async (positionByIds: Record<string, string>, parentByIds: Record<string, string>): Promise<NavigationCollection> => {
        const dataBody = new FormData();
        const ids = Object.keys(positionByIds);

        ids.forEach((id: string) => {
            const order: string = positionByIds[id];
            const parentId: string = parentByIds[id] !== null ? parentByIds[id] : '0';
            dataBody.append(`navigation_item[${id}][order]`, order);
            dataBody.append(`navigation_item[${id}][parent_id]`, String(parentId));
        });

        const response = await this.httpClient.put(this.baseUrl, `/navigation/positions`, dataBody);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No navigation found');
        }

        return this.generateNavigationTree(response.data.docs);
    }

    public delete = async (id: number): Promise<any> => {
        const response = await this.httpClient.delete(this.baseUrl, `/navigation/${id}`);

        return response;
    }

}

export default NavigationRepository;