import { AnyAction } from 'redux';

import { 
    pageDeleteInitAction, 
    pageDeleteRequestLoadingAction, 
    pageDeleteRequestSuccessAction, 
    pageDeleteRequestFailureAction, 
    PageDeleteRequestFailureAction
} from './deletepage.actions';

export interface DeletePageReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string
}

const initialDeletePageReduxState: DeletePageReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: ""
}

export const DeletePageReducer = (state: DeletePageReduxState = initialDeletePageReduxState, action: AnyAction): DeletePageReduxState => {
    switch(action.type) {
        case pageDeleteInitAction:
            return initialDeletePageReduxState;

        case pageDeleteRequestLoadingAction:
            return {
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: ""
            }

        case pageDeleteRequestSuccessAction:
            return {
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                errorMessage: ""
            }
        case pageDeleteRequestFailureAction:
            return {
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                errorMessage: (action as PageDeleteRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}