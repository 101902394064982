class Page {

    public static readonly BANNER_DIRECTORY_URI = 'uploads/pages/banners/';

    public id: number;
    public title: string;
    public banner: string;
    public createdDate: string;
    public createdBy: string;
    public seoTitle: string;
    public seoDescription: string;
    public modifiedDate: string | undefined;
    public modifiedBy: string | undefined;

    public constructor(id: number, title: string, banner: string, createdDate: string, createdBy: string, seoTitle: string, seoDescription: string, modifiedDate?: string, modifiedBy?: string) {
        this.id = id;
        this.title = title;
        this.banner = banner;
        this.createdDate = createdDate;
        this.createdBy = createdBy;
        this.seoTitle = seoTitle;
        this.seoDescription = seoDescription;
        this.modifiedDate = modifiedDate;
        this.modifiedBy = modifiedBy;
    }

}

export default Page;