import React, { useMemo } from 'react';
import { AppReduxState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContent from '../../../../Domain/PageContent';
import { IComponentData } from '../IComponentData';
import { IComponentAccordionData } from './IComponentAccordionData';
import { IComponentAccordionItemData } from './IComponentAccordionItemData';

import FormGroup from '../../../../../_utils/Forms/FormGroup';
import IconArrowDown from '../../../../Infrastructure/Style/svg/IconArrowDown';
import SimpleOrdonnableList from '../../../../../_utils/DragAndDrop/SimpleOrdonnableList';
import { localeSelector } from '../../../../../App/Infrastructure/Redux/Locale/locale.selectors';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';
import { pageBuilderUpdatePageContentDataActionCreator } from '../../../../Infrastructure/Redux/page-builder.actions';

interface IHandleComponentAccordionProps {
    pageContentId: string
}

const HandleComponentAccordion: React.FunctionComponent<IHandleComponentAccordionProps> = ({ pageContentId }): React.ReactElement => {
    const dispatch = useDispatch();
    const locale = useSelector(localeSelector);
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentAccordionData = pageContent.data as IComponentAccordionData;

    const { items } = useMemo(() => data, [data]);
    const initEditorParams = useMemo(() => ({
        base_url: "/tinymce",
        skin: "oxide-dark",
        menubar: "",
        quickbars_selection_toolbar: "h2 h3 h4 h5 h6",
        language: locale.replace('-', '_'),
        block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6; Blockquote=blockquote',
    }), [locale]);

    const setNewData = (name: string, value: IComponentAccordionItemData[]): void => {
        dispatch(
            pageBuilderUpdatePageContentDataActionCreator(
                pageContentId,
                {
                    ...data as IComponentData<string|boolean|IComponentData<string|undefined>[]>,
                    [name]: value
                }
            )
        );
    }

    const handleAddNewElement = () => {
        const elements = items as IComponentAccordionItemData[];

        elements.push(
            {
                question: "",
                answer: ""
            }
        );

        setNewData('items', elements);
    }

    const handleOnElementChange = (name: string, value: string, index: number): void => {
        const elements: IComponentAccordionItemData[] = items as IComponentAccordionItemData[];

        if(elements[index][name] !== undefined) {
            elements[index][name] = value;
        } else {
            const newElement = value;

            elements[index][name] = newElement;
        }

        setNewData('items', elements);
    }

    const renderEmptyAccordion = (): React.ReactElement => {

        return (
            <div className="page-builder--content-accordion-empty">
                <p><LocaleMessage id="page_builder.component-accordion-type.empty" /></p>
                <div className="icons">
                    { IconArrowDown }
                </div>
            </div>
        );
    }

    const generateElementsList = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        const itemGroups = items as IComponentAccordionItemData[];
        itemGroups.forEach((item: IComponentAccordionItemData, index: number) => {
            elements.push(
                <React.Fragment>
                    <div className="page-builder--content-accordion-form">
                        <FormGroup>
                            <React.Fragment>
                                <label><LocaleMessage id="page_builder.component-accordion-type.question.label" /></label>
                                <input type="text" name="question" value={ item.question } onChange={(event) => handleOnElementChange('question', event.currentTarget.value, index)} />
                            </React.Fragment>
                        </FormGroup>

                        <FormGroup>
                            <React.Fragment>
                                <label><LocaleMessage id="page_builder.component-accordion-type.answer.label" /></label>
                                <div className="form--group-input">
                                    <div className={ `page-builder--content-inline-wysiwyg ${ data.background === 'dark' && 'is-dark' }` }>
                                        { /* @ts-ignore*/ }
                                        <Editor
                                            //@ts-ignore
                                            init={ initEditorParams }
                                            id={ `tinymce-editor-accordion-${ pageContentId }-${index}` }
                                            plugins="fullscreen wordcount link lists help"
                                            toolbar="undo redo | formatselect | bold italic underline strikethrough | link | numlist bullist | removeformat | fullscreen"
                                            onEditorChange={ (value) => handleOnElementChange('answer', value, index) }
                                            value={ String(item.answer) }
                                            tinymceScriptSrc={ "/tinymce/tinymce.min.js" }
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        </FormGroup>
                    </div>
                </React.Fragment>
            );
        });

        return elements;
    }

    return (
        <div className={ `form--group` }>
            <label>
                <LocaleMessage id="page_builder.component-accordion-type.items_group.label" /> :
            </label>
            <div className="form--group-input">
                {
                    //@ts-ignore
                    items.length === 0 && renderEmptyAccordion() 
                }
                <SimpleOrdonnableList
                    type="accordion"
                    droppableId={ `accordion-${ pageContentId }` }
                    draggableId={ `accordion-item-${ pageContentId }` }
                    elementsList={ generateElementsList() }
                    elementsListData={ items as IComponentAccordionItemData[] }
                    onChangedElementsListData={(elementsListData) => setNewData('items', elementsListData as IComponentAccordionItemData[])} 
                    />
                <div className="page-builder--content-accordion-add-form">
                    <FormGroup>
                        <button type="button" className="button is-primary" onClick={() => handleAddNewElement()}>
                            <LocaleMessage id="page_builder.component-accordion-type.items_group.add_button_label" />
                        </button>
                    </FormGroup>
                </div>
            </div>
        </div>
    );
}

export default HandleComponentAccordion;