import React from 'react';

const ComingSoonController: React.FunctionComponent = (): React.ReactElement => {

    return (
        <div className="modal--information">
            <div className="modal--icon">
                <i className="material-icons">warning</i>
            </div>
            <h1>Coming soon...</h1>
        </div>
    );
}

export {ComingSoonController};