import React, { useCallback } from 'react';
import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageBuilderColumns from '../Domain/Enum/PageBuilderColumnsEnum';

import HandlePageContent from './Forms/HandlePageContent';
import IconComponents from '../Infrastructure/Style/svg/IconComponents';
import { pageBuilderSelectPageContentActionCreator } from '../Infrastructure/Redux/page-builder.actions';
import { pageBuilderPageContentByIdsSelector, pageBuilderPageContentIdsSelector } from '../Infrastructure/Redux/page-builder.selectors';

const PageContentsDroppableColumn: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const pageContentIds = useSelector(pageBuilderPageContentIdsSelector);
    const pageContentByIds = useSelector(pageBuilderPageContentByIdsSelector);

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            dispatch(pageBuilderSelectPageContentActionCreator(null));
        }
    }, [dispatch]);

    useEffectOnce(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    });

    const renderPageContents = (): React.ReactElement[] => {

        return pageContentIds.map((id: string, index: number) => {
            const pageContent = pageContentByIds[id]!;

            return (
                <Draggable draggableId={ String(`page-content-${index}`)} index={ index } key={ index }>
                    {(provided) => (
                        <div {...provided.draggableProps} ref={provided.innerRef} className="page-builder--content">

                            <HandlePageContent pageContent={ pageContent } provided={ provided } />

                        </div>
                    )}
                </Draggable>
            )
        });
    }

    return (
        <React.Fragment>
            <Droppable droppableId={ PageBuilderColumns.ColumnPageContents } type="list" isDropDisabled={ false }>
                {(provided) =>
                    <div ref={provided.innerRef} style={{ minHeight: 20 }}>
                        { renderPageContents() }
                        { provided.placeholder }
                    </div>
                }
            </Droppable>
            <div className="page-builder--content-placeholder">
                { IconComponents }
                <LocaleMessage id="page_builder.page_contents.placeholder">
                    {(message) => <p>{ message }</p>}
                </LocaleMessage>
            </div>
        </React.Fragment>
    );
}

export default PageContentsDroppableColumn;