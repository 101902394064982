import {Action} from 'redux';

export const localeChangeAction = '@@locale/CHANGE';

export interface LocaleChangeAction extends Action<typeof localeChangeAction> {
    payload: {
        locale: string;
    };
}

export const localeChangeActionCreator = (locale: string): LocaleChangeAction => {
    return {type: localeChangeAction, payload: {locale}};
};
