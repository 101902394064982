import { store } from '../../../store';
import ContactRequest from '../../Domain/ContactRequest';
import { ContactRequestStatusEnum } from '../../Domain/Enum/ContactRequestStatusEnum';

import ContactRequestsRepository from '../Repositories/ContactRequestsRepository';
import { contactRequestPutRequestFailureActionCreator, contactRequestPutRequestSuccessActionCreator } from './put-contact-request.actions';

const contactRequestsRepository: ContactRequestsRepository = new ContactRequestsRepository();

export const putContactRequestService = (id: number): void => {
    contactRequestsRepository.put(id, ContactRequestStatusEnum.ANSWERED)
        .then((contactRequest: ContactRequest) => {

            store.dispatch(contactRequestPutRequestSuccessActionCreator(contactRequest));
        }, (error: any) => {

            store.dispatch(contactRequestPutRequestFailureActionCreator(error.response.data.message));
        });
}

export const checkStatusContactRequestService = (id: number): void => {
    contactRequestsRepository.put(id, ContactRequestStatusEnum.DONE)
        .then((contactRequest: ContactRequest) => {

            store.dispatch(contactRequestPutRequestSuccessActionCreator(contactRequest));
        }, (error: any) => {

            store.dispatch(contactRequestPutRequestFailureActionCreator(error.response.data.message));
        });
}