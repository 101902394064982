import { store } from '../../../../store';

import Theme from '../../../Domain/Theme';
import ThemeRepository from '../../Repositories/ThemeRepository';

import { themeGetRequestFailureActionCreator, themeGetRequestSuccessActionCreator } from './get-theme.actions';

const themeRepository: ThemeRepository = new ThemeRepository();

export const getThemeService = (): void => {

    themeRepository.get()
        .then((response: Theme) => {

            store.dispatch(themeGetRequestSuccessActionCreator(response));
        }, (error: any) => {

            store.dispatch(themeGetRequestFailureActionCreator(error.response.data.message));
        });

}