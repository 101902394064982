import {Action} from 'redux';
import { IModalOptions } from '../IModalOptions';

export const modalOpenAction = '@@modal/OPEN';
export const modalAddAction = '@@modal/ADD';
export const modalRemoveAction = '@@modal/REMOVE';
export const modalCloseAllAction = '@@modal/CLOSE_ALL';

export interface ModalOpenAction extends Action<typeof modalOpenAction> {
    payload: {
        body: React.ReactElement,
        title: string,
        options?: IModalOptions | undefined
    }
}

export interface ModalAddAction extends Action<typeof modalAddAction> {
    payload: {
        body: React.ReactElement,
        title: string,
        options?: IModalOptions | undefined
    }
}

export interface ModalRemoveAction extends Action<typeof modalRemoveAction> {}

export interface ModalCloseAllAction extends Action<typeof modalCloseAllAction> {}

export const modalOpenActionCreator = (body: React.ReactElement, title: string, options?: IModalOptions): ModalOpenAction => {
    return {
        type: modalOpenAction,
        payload: {
            body: body,
            title: title,
            options: options
        }
    };
}

export const modalAddActionCreator = (body: React.ReactElement, title: string, options?: IModalOptions): ModalAddAction => {
    return {
        type: modalAddAction,
        payload: {
            body: body,
            title: title,
            options: options
        }
    };
}

export const modalRemoveActionCreator = (): ModalRemoveAction => {
    return {
        type: modalRemoveAction
    }
}

export const modalCloseAllActionCreator = (): ModalRemoveAction => {
    return {
        type: modalRemoveAction
    }
}