class AuthToken {
    
    public accessToken: string;
    public refreshToken: string;
    public typeToken: string;

    public constructor(accessToken: string, refreshToken: string, typeToken: string) {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
        this.typeToken = typeToken;
    }

}

export default AuthToken;