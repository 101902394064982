import * as React from 'react';
import { LocaleMessage } from '../Redux/Locale/Provider/LocaleMessage';

import Card from '../../../_utils/Cards/Card';
import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';

import IconHome from '../Style/Svg/IconHome';
import IconPages from '../Style/Svg/IconPages';
import IconUsers from '../Style/Svg/IconUsers';
import IconPicture from '../Style/Svg/IconPicture';
import IconSettings from '../Style/Svg/IconSettings';
import IconNavigation from '../Style/Svg/IconNavigation';
import IconDeviceMessage from '../Style/Svg/IconDeviceMessage';

export const DashboardController: React.FunctionComponent = () => {

    const renderEditHomepageCard = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="card--content">
                    <div className="card--content-subtitle">Pages</div>
                    <div className="card--content-title">Modifier la page d'accueil</div>
                </div>
                <div className="card--icon">{ IconHome }</div>
            </React.Fragment>
        );
    }

    const renderAllPagesCard = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="card--content">
                    <div className="card--content-title">Liste des pages</div>
                </div>
                <div className="card--icon">{ IconPages }</div>
            </React.Fragment>
        )
    }

    const renderNavigationCard = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="card--content">
                    <div className="card--content-subtitle">Contenu</div>
                    <div className="card--content-title">Navigation du site</div>
                </div>
                <div className="card--icon">{ IconNavigation }</div>
            </React.Fragment>
        );
    }

    const renderMediaCard = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="card--content">
                    <div className="card--content-title">Images &amp; Media</div>
                </div>
                <div className="card--icon">{ IconPicture }</div>
            </React.Fragment>
        );
    }
    
    const renderContactCard = (): React.ReactElement => {
        
        return (
            <React.Fragment>
                <div className="card--content">
                    <div className="card--content-subtitle">Contact</div>
                    <div className="card--content-title">Demande de contacts</div>
                </div>
                <div className="card--icon">{ IconDeviceMessage }</div>
            </React.Fragment>
        )
    }

    const renderContactSettingsCard = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="card--content">
                    <div className="card--content-title">Paramètres de contact</div>
                </div>
                <div className="card--icon">{ IconSettings }</div>
            </React.Fragment>
        );
    }

    const renderUserListCard = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="card--content">
                    <div className="card--content-subtitle">Members</div>
                    <div className="card--content-title">Liste des membres</div>
                </div>
                <div className="card--icon">{ IconUsers }</div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Breadcrumb items={[]} />

            <div className="dashboard">
                <div className="grid-wrapper">
                    <div className="page--header">
                        <h1><LocaleMessage id="app.title" /></h1>
                    </div>
                    <div className="dashboard--list">
                        <div className="grid-col-25p">
                            <Card body={ renderEditHomepageCard() } link="/pages/1/edit" />
                            <Card body={ renderAllPagesCard() } link="/pages" additionalClassname="is-smaller" />
                        </div>
                        <div className="grid-col-25p">
                            <Card body={ renderNavigationCard() } link="/navigation" />
                            <Card body={ renderMediaCard() } link="/medias/files" additionalClassname="is-smaller" />
                        </div>

                        <div className="grid-col-25p">
                            <Card body={ renderContactCard() } link="/contact-requests/requests" />
                            <Card body={ renderContactSettingsCard() } link="/contact-requests/settings" additionalClassname="is-smaller" />
                        </div>
                        <div className="grid-col-25p">
                            <Card body={ renderUserListCard() } link="/users" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}