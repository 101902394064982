import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { AppReduxState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import ComponentTypeEnum from '../../Domain/Enum/ComponentTypeEnum';
import ComponentThemeTypeEnum from '../../Domain/Enum/ComponentThemeTypeEnum';

import FormGroup from '../../../_utils/Forms/FormGroup';
import RangeInput from '../../../_utils/Forms/RangeInput';
import SelectInput from '../../../_utils/Forms/SelectInput';

import GroupCollapse from '../../Infrastructure/Partials/GroupCollapse';
import { pageBuilderPageContentSelector } from '../../Infrastructure/Redux/page-builder.selectors';
import { pageBuilderIsPostLoadingSelector } from '../../Infrastructure/Redux/page-builder-post.selectors';
import { pageBuilderUpdatePageContentDataActionCreator } from '../../Infrastructure/Redux/page-builder.actions';

// ComponentSettings
import { IComponentData } from './ComponentType/IComponentData';
import IconSettingsPaddingTop from '../../Infrastructure/Style/svg/IconSettingsPaddingTop';
import IconSettingsPaddingLeft from '../../Infrastructure/Style/svg/IconSettingsPaddingLeft';
import IconSettingsPaddingRight from '../../Infrastructure/Style/svg/IconSettingsPaddingRight';
import IconSettingsPaddingBottom from '../../Infrastructure/Style/svg/IconSettingsPaddingBottom';
import HandleComponentTextSettings from './ComponentType/Texts/HandleComponentTextSettings';
import HandleComponentGallerySettings from './ComponentType/ImagesAndMedias/HandleComponentGallerySettings';
import HandleComponentSlideshowSettings from './ComponentType/ImagesAndMedias/HandleComponentSlideshowSettings';
import HandleComponentAccordionSettings from './ComponentType/Texts/HandleComponentAccordionSettings';
import HandleComponentTwoColumnsSettings from './ComponentType/Layout/HandleComponentTwoColumnsSettings';
import HandleComponentSectionsHeroSectionSettings from './ComponentType/Sections/HandleComponentSectionsHeroSectionSettings';
import HandleComponentBackgroundSettings from './ComponentSettings/HandleComponentBackgroundSettings';

interface IHandleComponentSettingsProps {
    pageContentId: string
}

const HandleComponentSettings: React.FunctionComponent<IHandleComponentSettingsProps> = ({ pageContentId }): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const pageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentData<string | number | boolean> = pageContent.data as IComponentData<string | number | boolean>;

    const handleDataChange = (name: string, value: string | number | boolean): void => {
        const newData: IComponentData<string | number | boolean | IComponentData<string | undefined>[]> = Object.assign({}, data, {
            [name]: value
        });

        dispatch(
            pageBuilderUpdatePageContentDataActionCreator(pageContentId, newData)
        );
    }

    const renderSpecificComponentSettings = (): React.ReactElement => {
        if (pageContent) {
            switch(pageContent.type){

                case ComponentTypeEnum.Text:
                    return <HandleComponentTextSettings onChange={(inputName, value) => handleDataChange(inputName, value)} />;
                    
                case ComponentTypeEnum.Accordion:
                    return <HandleComponentAccordionSettings pageContentId={ pageContentId } onChange={(inputName, value) => handleDataChange(inputName, value)} />;

                case ComponentTypeEnum.Gallery:
                    return <HandleComponentGallerySettings pageContentId={ pageContentId } onChange={(inputName, value) => handleDataChange(inputName, value)} />;

                case ComponentTypeEnum.Slideshow:
                    return <HandleComponentSlideshowSettings pageContentId={ pageContentId } onChange={(inputName, value) => handleDataChange(inputName, value)} />;

                case ComponentTypeEnum.LayoutTwoColumns:
                    return <HandleComponentTwoColumnsSettings onChange={(inputName, value) => handleDataChange(inputName, value)} />;

                case ComponentTypeEnum.sectionsHeroSection:
                    return <HandleComponentSectionsHeroSectionSettings pageContentId={ pageContentId } onChange={(inputName, value) => handleDataChange(inputName, value)} />;

            }
        }

        return <React.Fragment></React.Fragment>;
    }

    const componentStyleRecords = (): Record<string, string> => {
        const records: Record<string, string> = {};

        records[ComponentThemeTypeEnum.Default] = intl.formatMessage({ id: `page_builder.component-block.themes.${ ComponentThemeTypeEnum.Default }` });
        records[ComponentThemeTypeEnum.Dark] = intl.formatMessage({ id: `page_builder.component-block.themes.${ ComponentThemeTypeEnum.Dark }` });

        return records;
    }

    return (
        <React.Fragment>
            { renderSpecificComponentSettings() }

            <GroupCollapse label="page_builder.settings.style_setting">
                <React.Fragment>
                    <div className="page-builder--settings-list">
                        <SelectInput inputName="theme" label="page_builder.settings.style.component_theme" inputValue={ data.theme as string } choices={ componentStyleRecords() } onChange={(name, value) => handleDataChange(name, value)} />
                    </div>
                </React.Fragment>
            </GroupCollapse>

            <HandleComponentBackgroundSettings data={ data } onDataChange={(name, value) => handleDataChange(name, value)} />

            <GroupCollapse label="page_builder.settings.padding_setting">
                <React.Fragment>
                    <div className="page-builder--settings-list">
                        <div className="page-builder--contextual-group-item-label">
                            Desktop
                        </div>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="paddingTop" label={ IconSettingsPaddingTop } value={ Number(data.paddingTop) } min={ 0 } max={ 400 } step={ 10 } unit="PX" onInputChange={(name, value) => handleDataChange(name, Number(value))} />
                        </FormGroup>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="paddingBottom" label={ IconSettingsPaddingBottom } value={ Number(data.paddingBottom) } min={ 0 } max={ 400 } step={ 10 } unit="PX" onInputChange={(name, value) => handleDataChange(name, Number(value))} />
                        </FormGroup>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="paddingLeft" label={ IconSettingsPaddingLeft } value={ Number(data.paddingLeft) } min={ 0 } max={ 400 } step={ 10 } unit="PX" onInputChange={(name, value) => handleDataChange(name, Number(value))} />
                        </FormGroup>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="paddingRight" label={ IconSettingsPaddingRight } value={ Number(data.paddingRight) } min={ 0 } max={ 400 } step={ 10 } unit="PX" onInputChange={(name, value) => handleDataChange(name, Number(value))} />
                        </FormGroup>
                        <br />
                        <div className="page-builder--contextual-group-item-label">
                            Mobile
                        </div>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="mobilePaddingTop" label={ IconSettingsPaddingTop } value={ Number(data.mobilePaddingTop) } min={ 0 } max={ 400 } step={ 10 } unit="PX" onInputChange={(name, value) => handleDataChange(name, Number(value))} />
                        </FormGroup>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="mobilePaddingBottom" label={ IconSettingsPaddingBottom } value={ Number(data.mobilePaddingBottom) } min={ 0 } max={ 400 } step={ 10 } unit="PX" onInputChange={(name, value) => handleDataChange(name, Number(value))} />
                        </FormGroup>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="mobilePaddingLeft" label={ IconSettingsPaddingLeft } value={ Number(data.mobilePaddingLeft) } min={ 0 } max={ 400 } step={ 10 } unit="PX" onInputChange={(name, value) => handleDataChange(name, Number(value))} />
                        </FormGroup>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="mobilePaddingRight" label={ IconSettingsPaddingRight } value={ Number(data.mobilePaddingRight) } min={ 0 } max={ 400 } step={ 10 } unit="PX" onInputChange={(name, value) => handleDataChange(name, Number(value))} />
                        </FormGroup>
                    </div>
                </React.Fragment>
            </GroupCollapse>

            <GroupCollapse label="page_builder.settings.display_setting">
                <React.Fragment>
                    <div className="page-builder--settings-list">
                        <FormGroup>
                            <React.Fragment>
                                <label htmlFor="displayOnDesktop"><LocaleMessage id="page_builder.settings.display.desktop" /></label>
                                <div className="form--group-input">
                                    <input type="checkbox" id="displayOnDesktop" name="displayOnDesktop" onChange={(event) => { handleDataChange('displayOnDesktop', event.currentTarget.checked)}} checked={ data.displayOnDesktop as boolean } />
                                </div>
                            </React.Fragment>
                        </FormGroup>
                        <FormGroup>
                            <React.Fragment>
                                <label htmlFor="displayOnMobile"><LocaleMessage id="page_builder.settings.display.mobile" /></label>
                                <div className="form--group-input">
                                    <input type="checkbox" id="displayOnMobile" name="displayOnMobile" onChange={(event) => { handleDataChange('displayOnMobile', event.currentTarget.checked)}} checked={ data.displayOnMobile as boolean } />
                                </div>
                            </React.Fragment>
                        </FormGroup>
                    </div>
                </React.Fragment>
            </GroupCollapse>
        </React.Fragment>
    );
}

export default HandleComponentSettings;