import React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';

import GetPages from '../../Application/GetPages';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const PagesController: React.FunctionComponent = (): React.ReactElement => {
    const query = useQuery();
    const breadcrumb: IBreadcrumbItem[] = [
        {
            title: 'page.page.title',
            url: null
        }
    ];

    const defaultPage = query.get('page') !== null ? Number(query.get('page')): 1;

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumb } />

            <div className="grid-wrapper">
                <div className="page--header">
                    <h2><LocaleMessage id="page.page.title" /></h2>
                    <div className="page--actions">
                        <Link to="/pages/create" className="button is-primary">CREATE</Link>
                    </div>
                </div>

                <div className="list-page">
                    <GetPages actualPage={ defaultPage } />
                </div>
            </div>
        </React.Fragment>
    );
}