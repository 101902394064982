import PageContentsRepository from '../Repositories/PageContentsRepository';

const pageContentsRepository: PageContentsRepository = new PageContentsRepository();

export const tinyMCEUploadImageHandlerService = (blobInfo: any, sucessCallback: (response: any) => void, errorCallback: (error: any) => void) => {
    const file = new File([blobInfo.blob()], blobInfo.filename());

    pageContentsRepository.uploadImageHandler(file)
        .then((response: any) => {

            return sucessCallback(`${process.env.REACT_APP_URL}/${response.data.location}`);
        }, (error: any) => {

            return errorCallback(error);
        });
}

export const componentSettingsImageHandlerService = async (file: File): Promise<string> => {

    return pageContentsRepository.uploadImageHandler(file)
        .then((response: any) => {

            return `/${ response.data.location }`;
        }, (error: any) => {

            console.error(error);
            return "";
        });
}