import HttpClient from '../../../_utils/HttpClient/HttpClient';
import IDataSettings from '../../Application/Forms/Settings/IDataSettings';

import Settings from '../../Domain/Settings';

class SettingsRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    public get = async (): Promise<Settings> => {
        const response = await this.httpClient.get(this.baseUrl, `/settings`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No settings found');
        }

        return this.fromJson(response.data.docs);
    }

    public put = async (data: IDataSettings): Promise<Settings> => {
        const dataBody = new FormData();
        dataBody.append('site_name', data.siteName.inputValue);
        if(data.siteLogo.inputValue !== undefined) {
            dataBody.append('site_logo', data.siteLogo.inputValue);
        }
        if(data.siteFavicon.inputValue !== undefined) {
            dataBody.append('site_favicon', data.siteFavicon.inputValue);
        }
        dataBody.append('address', data.address.inputValue);

        // --- Header
        dataBody.append('header_button', data.headerButton.inputValue ? '1' : '0');
        dataBody.append('header_button_label', data.headerButtonLabel.inputValue);
        dataBody.append('header_button_navigation_type', data.headerButtonNavigationType.inputValue);
        if(data.headerButtonNavigationId.inputValue !== null) {
            dataBody.append('header_button_navigation_id', String(data.headerButtonNavigationId.inputValue));
        }
        dataBody.append('header_button_navigation_mailto', data.headerButtonNavigationMailto.inputValue);
        dataBody.append('header_button_navigation_url', data.headerButtonNavigationUrl.inputValue);

        // --- Footer
        dataBody.append('footer_button', data.footerButton.inputValue ? '1' : '0');
        dataBody.append('footer_button_label', data.footerButtonLabel.inputValue);
        if(data.footerButtonNavigationId.inputValue !== null) {
            dataBody.append('footer_button_navigation_id', String(data.footerButtonNavigationId.inputValue));
        }
        dataBody.append('footer_text', data.footerText.inputValue);

        // --- SEO
        dataBody.append('site_meta_title', data.siteMetaTitle.inputValue);
        dataBody.append('site_meta_description', data.siteMetaDescription.inputValue);
        if(data.siteDefaultOpenGraph.inputValue !== undefined) {
            dataBody.append('site_default_open_graph', data.siteDefaultOpenGraph.inputValue);
        }

        const response = await this.httpClient.put(this.baseUrl, `/settings`, dataBody);
        if (! response.data.hasOwnProperty('docs')) {
            throw new Error('No settings found');
        }

        return this.fromJson(response.data.docs);
    }

    private fromJson = (response: any): Settings => {
        const settings = new Settings(
            response.site_name,
            response.site_logo !== "" ? response.site_logo : undefined,
            response.site_favicon !== "" ? response.site_favicon : undefined,
            response.address,
            response.header_button,
            response.header_button_label,
            response.header_button_navigation_type,
            response.header_button_navigation_id,
            response.header_button_navigation_url,
            response.header_button_navigation_mailto,
            response.footer_button,
            response.footer_button_label,
            response.footer_button_navigation_id,
            response.footer_text,
            response.site_meta_title,
            response.site_meta_description,
            response.site_default_open_graph !== "" ? response.site_default_open_graph : undefined
        );

        return settings;
    }

}

export default SettingsRepository;