import React from 'react';

const IconBorderRadius: React.ReactElement = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M2 9V6.5C2 4.01 4.01 2 6.5 2H9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M15 2H17.5C19.99 2 22 4.01 22 6.5V9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M22 16V17.5C22 19.99 19.99 22 17.5 22H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M9 22H6.5C4.01 22 2 19.99 2 17.5V15" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
);

export default IconBorderRadius;