import { Action } from 'redux';

import AppTheme from '../../Domain/AppTheme';
import AppSettings from '../../Domain/AppSettings';
import PageContent from '../../Domain/PageContent';

export const pageBuilderPageContentLoadingAction = '@@page_builder/PAGE_CONTENT_LOADING';
export const pageBuilderPageContentSuccessAction = '@@page_builder/PAGE_CONTENT_SUCCESS';
export const pageBuilderPageContentFailureAction = '@@page_builder/PAGE_CONTENT_FAILURE';

export const pageBuilderAppThemeLoadingAction = '@@page_builder/APP_THEME_LOADING';
export const pageBuilderAppThemeSuccessAction = '@@page_builder/APP_THEME_SUCCESS';
export const pageBuilderAppThemeFailureAction = '@@page_builder/APP_THEME_FAILURE';

export const pageBuilderAppSettingsLoadingAction = '@@page_builder/APP_SETTINGS_LOADING';
export const pageBuilderAppSettingsSuccessAction = '@@page_builder/APP_SETTINGS_SUCCESS';
export const pageBuilderAppSettingsFailureAction = '@@page_builder/APP_SETTINGS_FAILURE';

export interface PageBuilderPageContentLoadingAction extends Action<typeof pageBuilderPageContentLoadingAction> {
    payload: {}
}

export interface PageBuilderPageContentSuccessAction extends Action<typeof pageBuilderPageContentSuccessAction> {
    payload: {
        pageContents: PageContent[]
    }
}

export interface PageBuilderPageContentFailureAction extends Action<typeof pageBuilderPageContentFailureAction> {
    payload: {
        errorMessage: string
    }
}

export interface PageBuilderAppThemeLoadingAction extends Action<typeof pageBuilderAppThemeLoadingAction> {
    payload: {}
}

export interface PageBuilderAppThemeSuccessAction extends Action<typeof pageBuilderAppThemeSuccessAction> {
    payload: {
        theme: AppTheme
    }
}

export interface PageBuilderAppThemeFailureAction extends Action<typeof pageBuilderAppThemeFailureAction> {
    payload: {
        errorMessage: string
    }
}

export interface PageBuilderAppSettingsLoadingAction extends Action<typeof pageBuilderAppSettingsLoadingAction> {
    payload: {}
}

export interface PageBuilderAppSettingsSuccessAction extends Action<typeof pageBuilderAppSettingsSuccessAction> {
    payload: {
        settings: AppSettings
    }
}

export interface PageBuilderAppSettingsFailureAction extends Action<typeof pageBuilderAppSettingsFailureAction> {
    payload: {
        errorMessage: string
    }
}

export const pageBuilderPageContentLoadingActionCreator = (): PageBuilderPageContentLoadingAction => {

    return {
        type: pageBuilderPageContentLoadingAction,
        payload: {}
    }
}

export const pageBuilderPageContentSuccessActionCreator = (pageContents: PageContent[]): PageBuilderPageContentSuccessAction => {

    return {
        type: pageBuilderPageContentSuccessAction,
        payload: {
            pageContents
        }
    }
}

export const pageBuilderPageContentFailureActionCreator = (errorMessage: string): PageBuilderPageContentFailureAction => {

    return {
        type: pageBuilderPageContentFailureAction,
        payload: {
            errorMessage
        }
    }
}

export const pageBuilderAppThemeLoadingActionCreator = (): PageBuilderAppThemeLoadingAction => {

    return {
        type: pageBuilderAppThemeLoadingAction,
        payload: {}
    }
}

export const pageBuilderAppThemeSuccessActionCreator = (theme: AppTheme): PageBuilderAppThemeSuccessAction => {

    return {
        type: pageBuilderAppThemeSuccessAction,
        payload: {
            theme
        }
    }
}

export const pageBuilderAppThemeFailureActionCreator = (errorMessage: string): PageBuilderAppThemeFailureAction => {

    return {
        type: pageBuilderAppThemeFailureAction,
        payload: {
            errorMessage
        }
    }
}

export const pageBuilderAppSettingsLoadingActionCreator = (): PageBuilderAppSettingsLoadingAction => {

    return {
        type: pageBuilderAppSettingsLoadingAction,
        payload: {}
    }
}

export const pageBuilderAppSettingsSuccessActionCreator = (settings: AppSettings): PageBuilderAppSettingsSuccessAction => {

    return {
        type: pageBuilderAppSettingsSuccessAction,
        payload: {
            settings
        }
    }
}

export const pageBuilderAppSettingsFailureActionCreator = (errorMessage: string): PageBuilderAppSettingsFailureAction => {

    return {
        type: pageBuilderAppSettingsFailureAction,
        payload: {
            errorMessage
        }
    }
}