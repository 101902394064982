import HttpClient from '../../../_utils/HttpClient/HttpClient';
import IDataTheme from '../../Application/Forms/Theme/IDataTheme';

import Theme from '../../Domain/Theme';

class ThemeRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    private fromJson = (response: any): Theme => {
        const theme = new Theme(
            response.primary_color,
            response.secondary_color,
            response.text_color,
            response.text_headers_color,
            response.header_variant,
            response.header_logo_position,
            response.header_text_color,
            response.header_color,
            response.header_background_blur,
            response.footer_color
        );

        return theme;
    }

    public get = async (): Promise<Theme> => {
        const response = await this.httpClient.get(this.baseUrl, `/theme`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No theme found');
        }

        return this.fromJson(response.data.docs);
    }

    public put = async (data: IDataTheme): Promise<Theme> => {
        const dataBody = new FormData();
        dataBody.append('primary_color', data.primaryColor.inputValue);
        dataBody.append('secondary_color', data.secondaryColor.inputValue);
        dataBody.append('text_color', data.textColor.inputValue);
        dataBody.append('text_headers_color', data.textHeadersColor.inputValue);
        dataBody.append('header_variant', data.headerVariant.inputValue);
        dataBody.append('header_logo_position', data.headerLogoPosition.inputValue);
        dataBody.append('header_text_color', data.headerTextColor.inputValue);
        dataBody.append('header_color', data.headerColor.inputValue);
        dataBody.append('header_background_blur', data.headerBackgroundBlur.inputValue ? '1' : '0');
        dataBody.append('footer_color', data.footerColor.inputValue);

        const response = await this.httpClient.put(this.baseUrl, `/theme`, dataBody);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No theme found');
        }

        return this.fromJson(response.data.docs);
    }

}

export default ThemeRepository;