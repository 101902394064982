import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import ComponentTypeEnum from '../Domain/Enum/ComponentTypeEnum';
import DisplayComponentTypeIcons from '../Infrastructure/Partials/DisplayComponentTypeIcons';

interface IComponentDraggableItemProps {
    index: number,
    type: ComponentTypeEnum
}

const ComponentDraggableItem: React.FunctionComponent<IComponentDraggableItemProps> = ({ type, index }): React.ReactElement => {

    return (
        <Draggable draggableId={ type } index={ index }>
            {(provided) => 
                <div className="page-builder--components-list-item" ref={ provided.innerRef } {...provided.draggableProps} {...provided.dragHandleProps}>
                    <div className="page-builder--components-list-item-icon">
                        <DisplayComponentTypeIcons type={ type } />
                    </div>
                    <div className="page-builder--components-list-item-type">
                        <LocaleMessage id={ `page_builder.component-${ type }.bloc_name` } />
                    </div>
                </div>
            }
        </Draggable>
    )
}

export default ComponentDraggableItem;