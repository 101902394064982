import File from './File';

class Directory {

    public folderName: string;
    public path: string;
    public directories: Directory[];
    public files: File[];

    public constructor(folderName: string, path: string, directories: Directory[], files: File[]) {
        this.folderName = folderName;
        this.path = path;
        this.directories = directories;
        this.files = files;
    }

}

export default Directory;