import React from 'react';

const IconSectionsHeroSection = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M7.26022 2H16.7302C17.3802 2 17.9602 2.02003 18.4802 2.09003C21.2502 2.40003 22.0002 3.70001 22.0002 7.26001V13.58C22.0002 17.14 21.2502 18.44 18.4802 18.75C17.9602 18.82 17.3902 18.84 16.7302 18.84H7.26022C6.61022 18.84 6.03022 18.82 5.51022 18.75C2.74022 18.44 1.99023 17.14 1.99023 13.58V7.26001C1.99023 3.70001 2.74022 2.40003 5.51022 2.09003C6.03022 2.02003 6.61022 2 7.26022 2Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M13.5801 8.31982H17.2601" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M6.74023 14.1099H6.76022H17.2702" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M7 22H17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M7.1947 8.2998H7.20368" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M10.4945 8.2998H10.5035" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
);

export default IconSectionsHeroSection;