import * as React from 'react';
import { Link } from 'react-router-dom';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { IBreadcrumbItem } from './IBreadcrumbItem';

interface IBreadcrumbProps {
    items: IBreadcrumbItem[]
}

export const Breadcrumb: React.FunctionComponent<IBreadcrumbProps> = ({ items }): React.ReactElement => {

    const renderItems = (): React.ReactElement[] => {

        return items.map((item: IBreadcrumbItem, index: number) => {

            return (
                <React.Fragment key={ index }>
                    { item.url !== null ? <li className="breadcrumb--item"><Link to={ item.url }><LocaleMessage id={ item.title } /></Link></li> : <li className="breadcrumb-item active"><LocaleMessage id={ item.title } /></li> }
                </React.Fragment>
            );
        });
    }

    return (
        <div className="breadcrumb">
            <div className="grid-wrapper">
                <ol className="breadcrumb breadcrumb-alt">
                    <li className="breadcrumb--item">
                        { items.length > 0  ? <Link to="/"><LocaleMessage id="app.breadcrumb.dashboard" /></Link> : <LocaleMessage id="app.breadcrumb.dashboard" />}
                    </li>
                    { renderItems() }
                </ol>
            </div>
        </div>
    );
}