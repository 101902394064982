import { AnyAction } from 'redux';

import Theme from '../../../Domain/Theme';

import { 
    themeGetInitAction, 
    themeGetRequestLoadingAction, 
    themeGetRequestSuccessAction, 
    themeGetRequestFailureAction, 
    ThemeGetRequestFailureAction,
    ThemeGetRequestSuccessAction
} from './get-theme.actions';

export interface GetThemeReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    theme: Theme | null
}

const initialGetThemeReduxState: GetThemeReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: "",
    theme: null
}

export const GetThemeReducer = (state: GetThemeReduxState = initialGetThemeReduxState, action: AnyAction): GetThemeReduxState => {
    switch(action.type) {
        case themeGetInitAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                theme: null
            };

        case themeGetRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                theme: null,
                errorMessage: ""
            }

        case themeGetRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                theme: (action as ThemeGetRequestSuccessAction).payload.theme,
                errorMessage: ""
            }
        case themeGetRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                theme: null,
                errorMessage: (action as ThemeGetRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}