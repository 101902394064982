class Settings {

    public siteName: string;
    public siteLogo: string;
    public siteFavicon: string;
    public address: string;
    public headerButton: boolean;
    public headerButtonLabel: string;
    public headerButtonNavigationType: string;
    public headerButtonNavigationId: number;
    public headerButtonNavigationUrl: string;
    public headerButtonNavigationMailto: string;
    public footerButton: boolean;
    public footerButtonLabel: string;
    public footerButtonNavigationId: number;
    public footerText: string;
    public siteMetaTitle: string;
    public siteMetaDescription: string;
    public siteDefaultOpenGraph: string;

    public constructor(
        siteName: string,
        siteLogo: string,
        siteFavicon: string,
        address: string,
        headerButton: boolean,
        headerButtonLabel: string,
        headerButtonNavigationType: string,
        headerButtonNavigationId: number,
        headerButtonNavigationUrl: string,
        headerButtonNavigationMailto: string,
        footerButton: boolean,
        footerButtonLabel: string,
        footerButtonNavigationId: number,
        footerText: string,
        siteMetaTitle: string,
        siteMetaDescription: string,
        siteDefaultOpenGraph: string,
    ) {
        this.siteName = siteName;
        this.siteLogo = siteLogo;
        this.siteFavicon = siteFavicon;
        this.address = address;
        this.headerButton = headerButton;
        this.headerButtonLabel = headerButtonLabel;
        this.headerButtonNavigationType = headerButtonNavigationType;
        this.headerButtonNavigationId = headerButtonNavigationId;
        this.headerButtonNavigationUrl = headerButtonNavigationUrl;
        this.headerButtonNavigationMailto = headerButtonNavigationMailto;
        this.footerButton = footerButton;
        this.footerButtonLabel = footerButtonLabel;
        this.footerButtonNavigationId = footerButtonNavigationId;
        this.footerText = footerText;
        this.siteMetaTitle = siteMetaTitle;
        this.siteMetaDescription = siteMetaDescription;
        this.siteDefaultOpenGraph = siteDefaultOpenGraph;
    }

}

export default Settings;