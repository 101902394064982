import * as React from 'react';
import {useDispatch} from 'react-redux';

import {Breadcrumb} from '../../../_utils/Breadcrumb/Breadcrumb';
import {IBreadcrumbItem} from '../../../_utils/Breadcrumb/IBreadcrumbItem';

import GetTournaments from '../../Application/GetTournaments';
import {modalAddActionCreator} from '../../../_utils/Modal/Redux/modal.actions';
import {ChooseTournamentTypeController} from './ChooseTournamentTypeController';

export const TournamentsController: React.FunctionComponent = (): React.ReactElement => {
    const breadcrumb: IBreadcrumbItem[] = [
        {
            title: 'Tournaments',
            url: null
        }
    ];

    const dispatch = useDispatch();
    const handleGoToChooseTournamentType = () => {
        dispatch(
            modalAddActionCreator(
                <ChooseTournamentTypeController />,
                'Choix du type de tournoi'
            )
        );
    }

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumb } />
            <div className="list-page">
                <div className="grid-wrapper">
                    <div className="list-page--header">
                        <h2>Tournaments</h2>
                        <div className="list-page--header-actions">
                            <button type="button" className="button is-primary" onClick={ handleGoToChooseTournamentType }>
                                <i className="material-icons">add</i>&nbsp;Add
                            </button>
                        </div>
                    </div>
                    <div className="portlet">
                        <div className="portlet--title">
                            <div className="portlet--title-label">
                                <h3>Liste des tournois</h3>
                            </div>
                            <div className="portlet--title-toolbar">
                                <button type="button" className="button">
                                    <i className="material-icons">cloud_download</i>
                                </button>
                                <button type="button" className="button">
                                    <i className="material-icons">face</i>
                                </button>
                            </div>
                        </div>
                        <div className="portlet--body">
                            <GetTournaments />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}