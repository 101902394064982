import IDataPage from './IDataPage';

class ValidatePage {

    private validateTitle = (data: IDataPage): IDataPage => {
        if (data.title.inputValue.length === 0) {
            data.title.validation.inError = true;
            data.title.validation.message = 'page.title.missing';

            return data;
        }

        if (data.title.inputValue.length < 5) {
            data.title.validation.inError = true;
            data.title.validation.message = 'page.title.not_long_enough';
        }

        return data;
    }

    public validateForm = (data: IDataPage): IDataPage => {
        data = this.validateTitle(data);

        return data;
    }

    public canBeSubmitted = (data: IDataPage): boolean => {
        return  this.validateTitle(data) &&
               Object.keys(data).every((field) => !data[field].validation.inError);
    }

}

export default ValidatePage;