import React from 'react';

import ComponentTypeEnum from '../../Domain/Enum/ComponentTypeEnum';

import IconText from '../Style/svg/ComponentTypes/IconText';
import IconGalery from '../Style/svg/ComponentTypes/IconGalery';
import IconSlider from '../Style/svg/ComponentTypes/IconSlider';
import IconAccordion from '../Style/svg/ComponentTypes/IconAccordion';
import IconTwoColumns from '../Style/svg/ComponentTypes/IconTwoColumns';
import IconVideoPlayer from '../Style/svg/ComponentTypes/IconVideoPlayer';
import IconSectionsCta from '../Style/svg/ComponentTypes/IconSectionsCta';
import IconThreeColumns from '../Style/svg/ComponentTypes/IconThreeColumns';
import IconContactRequests from '../Style/svg/ComponentTypes/IconContactRequests';
import IconSectionsHeroSection from '../Style/svg/ComponentTypes/IconSectionsHeroSection';

interface IDisplayComponentTypeIconsProps {
    type: ComponentTypeEnum
}

const DisplayComponentTypeIcons: React.FunctionComponent<IDisplayComponentTypeIconsProps> = ({ type }): React.ReactElement => {

    switch (type) {
        // Layout
        case ComponentTypeEnum.LayoutTwoColumns:
            return <React.Fragment>{ IconTwoColumns }</React.Fragment>;

        case ComponentTypeEnum.LayoutThreeColumns:
            return <React.Fragment>{ IconThreeColumns }</React.Fragment>;

        // Texts
        case ComponentTypeEnum.Text:
            return <React.Fragment>{ IconText }</React.Fragment>;

        case ComponentTypeEnum.Accordion:
            return <React.Fragment>{ IconAccordion }</React.Fragment>;

        // Media
        case ComponentTypeEnum.Gallery:
            return <React.Fragment>{ IconGalery }</React.Fragment>;

        case ComponentTypeEnum.Slideshow:
            return <React.Fragment>{ IconSlider }</React.Fragment>;

        case ComponentTypeEnum.VideoPlayer:
            return <React.Fragment>{ IconVideoPlayer }</React.Fragment>;

        // Contact requests
        case ComponentTypeEnum.ContactRequests:
            return <React.Fragment>{ IconContactRequests }</React.Fragment>;

        // Sections
        case ComponentTypeEnum.sectionsCallToAction:
            return <React.Fragment>{ IconSectionsCta }</React.Fragment>;

        case ComponentTypeEnum.sectionsHeroSection:
            return <React.Fragment>{ IconSectionsHeroSection }</React.Fragment>;

        default:
            return <React.Fragment></React.Fragment>;
    }
}

export default DisplayComponentTypeIcons;