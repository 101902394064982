import IDataSettings from './IDataSettings';

class ValidateSettings {

    private setErrorMessage = (data: IDataSettings, field: string, message: string): IDataSettings => {
        data[field] = {
            inputValue: data[field].inputValue,
            validation: {
                inError: true,
                message: message
            }
        }

        return data;
    }

    private validateSiteName = (data: IDataSettings): IDataSettings => {

        return data;
    }

    public handleApiResponse = (data: IDataSettings, errors: any): IDataSettings => {
        if (errors.site_name !== undefined) {
            data = this.setErrorMessage(data, 'siteName', errors.site_name[0].code);
        }
        if (errors.site_logo !== undefined) {
            data = this.setErrorMessage(data, 'siteLogo', errors.site_logo[0].code);
        }
        if (errors.site_favicon !== undefined) {
            data = this.setErrorMessage(data, 'siteFavicon', errors.site_favicon[0].code);
        }
        if (errors.address !== undefined) {
            data = this.setErrorMessage(data, 'address', errors.address[0].code);
        }
        if (errors.header_button !== undefined) {
            data = this.setErrorMessage(data, 'headerButton', errors.header_button[0].code);
        }
        if (errors.header_button_label !== undefined) {
            data = this.setErrorMessage(data, 'headerButtonLabel', errors.header_button_label[0].code);
        }
        if (errors.header_button_navigation_type !== undefined) {
            data = this.setErrorMessage(data, 'headerButtonNavigationType', errors.header_button_navigation_type[0].code);
        }
        if (errors.header_button_navigation_id !== undefined) {
            data = this.setErrorMessage(data, 'headerButtonNavigationId', errors.header_button_navigation_id[0].code);
        }
        if (errors.header_button_navigation_mailto !== undefined) {
            data = this.setErrorMessage(data, 'headerButtonNavigationMailto', errors.header_button_navigation_mailto[0].code);
        }
        if (errors.header_button_navigation_url !== undefined) {
            data = this.setErrorMessage(data, 'headerButtonNavigationUrl', errors.header_button_navigation_url[0].code);
        }
        if (errors.footer_button !== undefined) {
            data = this.setErrorMessage(data, 'footerButton', errors.footer_button[0].code);
        }
        if (errors.footer_button_label !== undefined) {
            data = this.setErrorMessage(data, 'footerButtonLabel', errors.footer_button_label[0].code);
        }
        if (errors.site_default_open_graph !== undefined) {
            data = this.setErrorMessage(data, 'siteDefaultOpenGraph', errors.site_default_open_graph[0].code);
        }

        return data;
    }

    public validateForm = (data: IDataSettings): IDataSettings => {
        data = this.validateSiteName(data);

        return data;
    }

    public canBeSubmitted = (data: IDataSettings): boolean => {
        return  this.validateSiteName(data) &&
            Object.keys(data).every((field) => !data[field].validation.inError);
    }

}

export default ValidateSettings;