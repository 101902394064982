import { store } from '../../../store';

import PagesRepository from '../Repositories/PagesRepository';
import { pageDeleteRequestFailureActionCreator, pageDeleteRequestSuccessActionCreator } from './deletepage.actions';

const pagesRepository: PagesRepository = new PagesRepository();

export const deletePageService = (id: number): void => {
    pagesRepository.delete(id)
        .then(() => {

            store.dispatch(pageDeleteRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(pageDeleteRequestFailureActionCreator(error.response.data.message));
        });
}