export const dashCase = (str: string): string => {
    let string = str.toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => `-${chr.toUpperCase()}`)
        .toLowerCase();

    return string;
}

export const snakeCase = (str: string): string => {
    let string = str.toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
        .replace(/[A-Z]/g, letter => `${letter.toLowerCase()}`);

        return string;
}