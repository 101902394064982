import Navigation from './Navigation';

import { TreeItem } from 'react-sortable-tree';

class NavigationCollection extends Array<Navigation> {

    public findNavigationById = (id: number): Navigation | undefined => {
        const flattenNavigation = this.getChildrens(this);

        return flattenNavigation.find((navigation: Navigation) => navigation.id === id);
    }

    public generateTreeStructureForSortableTree = (navigationCollection: NavigationCollection): TreeItem[] => {
        const tree: TreeItem[] = [];

        navigationCollection.forEach((navigation: Navigation) => {
            tree.push(this.generateBranchForSortableTree(navigation));
        });
        
        return tree;
    }

    private generateBranchForSortableTree = (navigation: Navigation): TreeItem => {

        const treeItem: TreeItem = {
            //@ts-ignore
            id: navigation.id,
            title: navigation.title,
            expanded: true,
            isDirectory: true,
            children: navigation.childrens ? this.generateTreeStructureForSortableTree(navigation.childrens) : []
        };

        return treeItem;
    }

    private getChildrens = (navigationCollection: NavigationCollection): Navigation[] => {
        const flattenNavigation: Navigation[] = [];

        navigationCollection.forEach((navigation: Navigation) => {
            flattenNavigation.push(navigation);

            if(navigation.childrens) {
                const childrens = this.getChildrens(navigation.childrens);

                childrens.forEach((navigation: Navigation) => {
                    flattenNavigation.push(navigation);
                })
            }
        });

        return flattenNavigation;
    }
}

export default NavigationCollection;