import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

// Domain
import Page from '../../Domain/Page';
import IData from '../../../_utils/Forms/IData';
import IDataPage from './IDataPage';
import ValidatePage from './ValidatePage';
import { initialDataPage } from './InitialDataPage';

import IconWarning from '../../../App/Infrastructure/Style/Svg/IconWarning';
import LoadingSpinner from '../../../App/Infrastructure/Style/Svg/LoadingSpinner';
import IconPageStyleLarge from '../../../App/Infrastructure/Style/Svg/IconPageStyleLarge';
import DeletePageModalController from '../../Infrastructure/Pages/DeletePageModalController';
import IconPageStyleContained from '../../../App/Infrastructure/Style/Svg/IconPageStyleContained';

// Form
import FormGroup from '../../../_utils/Forms/FormGroup';
import TextInput from '../../../_utils/Forms/TextInput';
import FileInput from '../../../_utils/Forms/FileInput';
import TextareaInput from '../../../_utils/Forms/TextareaInput';

// Redux
import { pageSelector } from '../../Infrastructure/Redux/get.selectors';
import { getPageByIdService } from '../../Infrastructure/Redux/get.services';
import { modalAddActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { postPageService, putPageService } from '../../Infrastructure/Redux/postpage.services';
import { pagePostInitActionCreator, pagePostRequestLoadingActionCreator } from '../../Infrastructure/Redux/postpage.actions';
import { pageGetInitActionCreator, pageGetRequestLoadingActionCreator } from '../../Infrastructure/Redux/get.actions';
import { getPageIsRequestFailureSelector, getPageIsRequestLoadingSelector, getPageIsRequestSuccessSelector } from '../../Infrastructure/Redux/get.selectors';
import { postPageIsRequestFailureSelector, postPageIsRequestLoadingSelector, postPageIsRequestSuccessSelector } from '../../Infrastructure/Redux/postpage.selectors';
import SegmentedControl from '../../../_utils/SegmentedControl/SegmentedControl';

interface IHandlePageProps {
    pageId: number | null
}

const validatePageData: ValidatePage = new ValidatePage();

const HandlePage: React.FunctionComponent<IHandlePageProps> = ({ pageId }): React.ReactElement => {
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const page = useSelector(pageSelector);
    const isPageRequestLoading = useSelector(getPageIsRequestLoadingSelector);
    const isPageRequestSuccess = useSelector(getPageIsRequestSuccessSelector);
    const isPageRequestFailure = useSelector(getPageIsRequestFailureSelector);
    const postPageRequestLoading = useSelector(postPageIsRequestLoadingSelector);
    const postPageRequestSuccess = useSelector(postPageIsRequestSuccessSelector);
    const postPageRequestFailure = useSelector(postPageIsRequestFailureSelector);
    
    const [tab, setTab] = useState<string>('info');
    const [data, setData] = useState<IDataPage>(initialDataPage);
    const [formIsLoading, setFormIsLoading] = useState<boolean>(true);
    const [canBeSubmitted, setCanBeSubmitted] = useState<boolean>(false);

    const refSegmentControls = useRef(null);
    const refSegmentControlOne = useRef(null);
    const refSegmentControlTwo = useRef(null);
    const refSegmentControlThree = useRef(null);

    const displayDeletePageModal = (): void => {
        if (pageId !== null) {
            dispatch(
                modalAddActionCreator(
                    <DeletePageModalController pageId={ pageId } />,
                    'modales.confirmation.title'
                )
            );
        }
    }

    const handleEditData = useCallback((pageItem: Page): void => {
        const newData: IDataPage = Object.assign({}, data, {
            id: {
                inputValue: pageItem.id,
                validation: { inError: false, message: '' }
            },
            title: {
                inputValue: pageItem.title,
                validation: { inError: false, message: '' }
            },
            banner: {
                inputValue: pageItem.banner,
                validation: { inError: false, message: '' }
            },
            createdDate: {
                inputValue: pageItem.createdDate ? pageItem.createdDate : '',
                validation: { inError: false, message: '' }
            },
            modifiedDate: {
                inputValue: pageItem.modifiedDate ? pageItem.modifiedDate : '',
                validation: { inError: false, message: '' }
            },
            seoTitle: {
                inputValue: pageItem.seoTitle ? pageItem.seoTitle : '',
                validation: { inError: false, message: '' }
            },
            seoDescription: {
                inputValue: pageItem.seoDescription ? pageItem.seoDescription : '',
                validation: { inError: false, message: '' }
            }
        });

        setData(newData);
    }, [setData, data]);

    const handleOnChange = (name: string, value: string | File): void => {
        const newData: IDataPage = Object.assign({}, data, {
            [name]: {
                inputValue: value,
                validation: { inError: false, message: '' }
            }
        });

        setData(validatePageData.validateForm(newData));
    }

    const handleSubmit = (): void => {
        if (canBeSubmitted && !postPageRequestLoading) {
            dispatch(pagePostRequestLoadingActionCreator());
        }
    }

    useEffectOnce(() => {
        if (pageId !== null) {
            // Modification mode
            dispatch(pageGetInitActionCreator());
            dispatch(pageGetRequestLoadingActionCreator());
        } else {
            // Creation mode
            setFormIsLoading(false);
        }
    });

    useEffect(() => {
        if (isPageRequestLoading && pageId !== null) {
            getPageByIdService(pageId);
        }
    }, [isPageRequestLoading, pageId]);

    useEffect(() => {
        if (isPageRequestSuccess && page !== null && formIsLoading) {
            handleEditData(page);
            setFormIsLoading(false);

            dispatch(pageGetInitActionCreator());
        }
    }, [dispatch, handleEditData, setFormIsLoading, isPageRequestSuccess, page, formIsLoading]);

    useEffect(() => {
        setCanBeSubmitted(validatePageData.canBeSubmitted(data));
    }, [setCanBeSubmitted, data]);

    useEffect(() => {
        if (postPageRequestLoading) {
            if (pageId !== null) {
                putPageService(pageId, data);
            } else {
                postPageService(data);
            }
        }
    }, [postPageRequestLoading, pageId, data]);

    useEffect(() => {
        if (postPageRequestFailure) {
            toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });
        }
    }, [postPageRequestFailure]);

    useEffect(() => {
        if (postPageRequestSuccess) {
            if (pageId !== null) {
                toast.success(<LocaleMessage id="page.put.success" />, { position: 'bottom-center' });
            } else {
                toast.success(<LocaleMessage id="page.post.success" />, { position: 'bottom-center' });
            }
            dispatch(pagePostInitActionCreator());
            history.push('/pages');
        }
    }, [dispatch, history, postPageRequestSuccess, pageId]);

    const renderInfoTab = (): React.ReactElement => {

        return (
            <React.Fragment>
                { 
                    pageId !== null &&
                    <TextInput
                        inputName="id"
                        label="page.id.label"
                        inputValue={ String(data.id.inputValue) }
                        onChange={() => { return false; }}
                        disabled={ true } />
                }

                <TextInput
                    inputName="title"
                    label="page.title.label"
                    placeholder="page.title.placeholder"
                    inputValue={ data.title.inputValue }
                    errorMessage={ data.title.validation.inError ? data.title.validation.message : undefined }
                    onChange={(name, value) => handleOnChange(name, value)}
                    disabled={ postPageRequestLoading } />

                {
                    pageId !== null &&
                    <TextInput
                        inputName="createdDate"
                        label="page.created_date.label"
                        inputValue={ String(data.createdDate.inputValue) }
                        onChange={() => { return false; }}
                        disabled={ true } />
                }

                {
                    pageId !== null &&
                    <TextInput
                        inputName="modifiedDate"
                        label="page.modified_date.label"
                        inputValue={ String(data.modifiedDate.inputValue) }
                        onChange={() => { return false; }}
                        disabled={ true } />
                }
            </React.Fragment>
        )
    }

    const renderCustomizationTab = (): React.ReactElement => {

        return (
            <React.Fragment>
                <FileInput inputName="banner" label="page.banner.label" inputValue={ typeof data.banner.inputValue === 'string' ? data.banner.inputValue : undefined } onFileChange={(name, file) => handleOnChange(name, file)} />

                <div className="form--group" style={{ display: 'none' }}>
                    <label htmlFor="page-style">Style de la page</label>
                    <div className="form--group-input is-page-style">
                        <div className="radio radio-primary">
                            <input type="radio" value="large" name="pageStyle" id="page-style-large" />
                            <label htmlFor="page-style-large">
                                <div className="icons">
                                    { IconPageStyleLarge }
                                </div>
                            </label>
                            <input type="radio" value="contained" name="pageStyle" id="page-style-contained" />
                            <label htmlFor="page-style-contained">
                                <div className="icons">
                                    { IconPageStyleContained }
                                </div>
                            </label>
                            <input type="radio" defaultChecked={ true } value="default" name="pageStyle" id="page-style-default" />
                            <label htmlFor="page-style-default">Style par défaut</label>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const renderSeoTab = (): React.ReactElement => {
        const remainingCharsDescription =  data.seoDescription.inputValue !== undefined ? 160 - data.seoDescription.inputValue.length : 160;
        const remainingSeoDescriptionMessage = intl.formatMessage({ id: "page.seo_description.max_length_information" }, { remaining: remainingCharsDescription, strong1: '<strong>', strong2: '</strong>' });
        
        const remainingCharsTitle =  data.seoTitle.inputValue !== undefined ? 70 - data.seoTitle.inputValue.length : 70;
        const remainingSeoTitleMessage = intl.formatMessage({ id: "page.seo_title.max_length_information" }, { remaining: remainingCharsTitle, strong1: '<strong>', strong2: '</strong>' });

        return (
            <React.Fragment>
                <TextInput
                    inputName="seoTitle"
                    label="page.seo_title.label"
                    inputValue={ String(data.seoTitle.inputValue) }
                    errorMessage={ data.seoTitle.validation.inError ? data.seoTitle.validation.message : undefined }
                    onChange={(name, value) => handleOnChange(name, value)}
                    additionalInformation={ remainingSeoTitleMessage } />
                <TextareaInput inputName="seoDescription" label="page.seo_description.label" inputData={ data as IData<string> } onChange={(name, value) => handleOnChange(name, value)} additionalInformation={ remainingSeoDescriptionMessage } />
            </React.Fragment>
        );
    }

    const renderPortletBody = (): React.ReactElement => {
        if (formIsLoading) {
            if (isPageRequestLoading) {

                return (
                    <div className="icons is-loading">
                        { LoadingSpinner }
                    </div>
                );
            }

            if (isPageRequestFailure) {

                return (
                    <div className="icons">
                        { IconWarning }
                        <p><LocaleMessage id="app.something_went_wrong" /></p>
                    </div>
                );
            }
        }

        return (
            <React.Fragment>
                <div className="form--segmented-controls">
                    <SegmentedControl
                        name="page-tabs"
                        segments={
                            [
                                { label: "Information de page", value: 'info', ref: refSegmentControlOne },
                                { label: "Personnalisation", value: 'customization', ref: refSegmentControlTwo },
                                { label: "Référencement SEO", value: 'seo', ref: refSegmentControlThree }
                            ]
                        }
                        defaultIndex={0}
                        onChangeSegment={(value) => setTab(value)}
                        controlRef={ refSegmentControls }
                    />
                </div>

                { tab === 'info' && renderInfoTab() }
                { tab === 'customization' && renderCustomizationTab() }
                { tab === 'seo' && renderSeoTab() }
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="portlet">
                <div className="portlet--title">
                    <div className="portlet--title-label">
                        Edition de la page { data.title.inputValue }
                    </div>
                    <div className="portlet--title-toolbar">
                        {
                            pageId !== null &&
                            <React.Fragment>
                                <Link to={ `/pages/${pageId}/contents` } className="button is-small is-transparent">
                                    <i className="material-icons">settings</i>
                                </Link>
                                <button type="button" className="button is-small is-red" onClick={ displayDeletePageModal }>
                                    <i className="material-icons">delete</i>
                                </button>
                            </React.Fragment>
                        }
                    </div>
                </div>
    
                <div className="portlet--body">
                    { renderPortletBody() }
                </div>
            </div>
    
            <button type="button" className={ `button is-primary ${!canBeSubmitted && ' is-disabled'} ${postPageRequestLoading && 'is-loading'}` } onClick={ handleSubmit }>
                Enregistrer
            </button>
        </React.Fragment>
    );
}

export default HandlePage;