class Discipline {

    public title: string;
    public playerNumber: number;
    public isSolo: boolean;

    public constructor(title: string, playerNumber: number, isSolo: boolean) {
        this.title = title;
        this.playerNumber = playerNumber;
        this.isSolo = isSolo;
    }

}

export default Discipline;