import { Action } from 'redux';

export const pageBuilderPageContentPostInitAction       = '@@page_builder/PAGE_CONTENT_POST_INIT';
export const pageBuilderPageContentPostLoadingAction    = '@@page_builder/PAGE_CONTENT_POST_LOADING';
export const pageBuilderPageContentPostSuccessAction    = '@@page_builder/PAGE_CONTENT_POST_SUCCESS';
export const pageBuilderPageContentPostFailureAction    = '@@page_builder/PAGE_CONTENT_POST_FAILURE';

export interface PageBuilderPageContentPostInitAction extends Action<typeof pageBuilderPageContentPostInitAction> {
    payload: {}
}

export interface PageBuilderPageContentPostLoadingAction extends Action<typeof pageBuilderPageContentPostLoadingAction> {
    payload: {}
}

export interface PageBuilderPageContentPostSuccessAction extends Action<typeof pageBuilderPageContentPostSuccessAction> {
    payload: {}
}

export interface PageBuilderPageContentPostFailureAction extends Action<typeof pageBuilderPageContentPostFailureAction> {
    payload: {
        errorMessage: string
    }
}

export const pageBuilderPageContentPostInitActionCreator = (): PageBuilderPageContentPostInitAction => {

    return {
        type: pageBuilderPageContentPostInitAction,
        payload: {}
    }
}

export const pageBuilderPageContentPostLoadingActionCreator = (): PageBuilderPageContentPostLoadingAction => {

    return {
        type: pageBuilderPageContentPostLoadingAction,
        payload: {}
    }
}

export const pageBuilderPageContentPostSuccessActionCreator = (): PageBuilderPageContentPostSuccessAction => {

    return {
        type: pageBuilderPageContentPostSuccessAction,
        payload: {}
    }
}

export const pageBuilderPageContentPostFailureActionCreator = (errorMessage: string): PageBuilderPageContentPostFailureAction => {

    return {
        type: pageBuilderPageContentPostFailureAction,
        payload: {
            errorMessage
        }
    }
}