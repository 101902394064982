import { Action } from 'redux';

export const contactRequestDeleteInitAction = '@@contact_request/DELETE_INIT';
export const contactRequestDeleteRequestLoadingAction = '@@contact_request/DELETE_REQUEST_LOADING';
export const contactRequestDeleteRequestSuccessAction = '@@contact_request/DELETE_REQUEST_SUCCESS';
export const contactRequestDeleteRequestFailureAction = '@@contact_request/DELETE_REQUEST_FAILURE';

export interface ContactRequestDeleteInitAction extends Action<typeof contactRequestDeleteInitAction> {
    payload: {}
}

export interface ContactRequestDeleteRequestLoadingAction extends Action<typeof contactRequestDeleteRequestLoadingAction> {
    payload: {}
}

export interface ContactRequestDeleteRequestSuccessAction extends Action<typeof contactRequestDeleteRequestSuccessAction> {
    payload: {}
}

export interface ContactRequestDeleteRequestFailureAction extends Action<typeof contactRequestDeleteRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const contactRequestDeleteInitActionCreator = (): ContactRequestDeleteInitAction => {

    return {
        type: contactRequestDeleteInitAction,
        payload: {}
    }
}

export const contactRequestDeleteRequestLoadingActionCreator = (): ContactRequestDeleteRequestLoadingAction => {

    return {
        type: contactRequestDeleteRequestLoadingAction,
        payload: {}
    }
}

export const contactRequestDeleteRequestSuccessActionCreator = (): ContactRequestDeleteRequestSuccessAction => {

    return {
        type: contactRequestDeleteRequestSuccessAction,
        payload: {}
    }
}

export const contactRequestDeleteRequestFailureActionCreator = (errorMessage: string): ContactRequestDeleteRequestFailureAction => {

    return {
        type: contactRequestDeleteRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};