import { Action } from 'redux';

import Theme from '../../../Domain/Theme';

export const themeGetInitAction = '@@app_theme/GET_INIT';
export const themeGetRequestLoadingAction = '@@app_theme/GET_REQUEST_LOADING';
export const themeGetRequestSuccessAction = '@@app_theme/GET_REQUEST_SUCCESS';
export const themeGetRequestFailureAction = '@@app_theme/GET_REQUEST_FAILURE';

export interface ThemeGetInitAction extends Action<typeof themeGetInitAction> {
    payload: {}
}

export interface ThemeGetRequestLoadingAction extends Action<typeof themeGetRequestLoadingAction> {
    payload: {}
}

export interface ThemeGetRequestSuccessAction extends Action<typeof themeGetRequestSuccessAction> {
    payload: {
        theme: Theme
    }
}

export interface ThemeGetRequestFailureAction extends Action<typeof themeGetRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const themeGetInitActionCreator = (): ThemeGetInitAction => {

    return {
        type: themeGetInitAction,
        payload: {}
    }
}

export const themeGetRequestLoadingActionCreator = (): ThemeGetRequestLoadingAction => {

    return {
        type: themeGetRequestLoadingAction,
        payload: {}
    }
}

export const themeGetRequestSuccessActionCreator = (theme: Theme): ThemeGetRequestSuccessAction => {

    return {
        type: themeGetRequestSuccessAction,
        payload: {
            theme
        }
    }
}

export const themeGetRequestFailureActionCreator = (errorMessage: string): ThemeGetRequestFailureAction => {

    return {
        type: themeGetRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};