import { IComponentDefaultData } from './IComponentDefaultData';
import ComponentThemeTypeEnum from '../../../Domain/Enum/ComponentThemeTypeEnum';
import ComponentBackgroundTypeEnum from '../../../Domain/Enum/ComponentBackgroundTypeEnum';
import ComponentBackgroundSizeTypeEnum from '../../../Domain/Enum/ComponentBackgroundSizeTypeEnum';
import ComponentBackgroundPositionEnum from '../../../Domain/Enum/ComponentBackgroundPositionEnum';

const initialComponentDefaultData: IComponentDefaultData = {
    backgroundType: ComponentBackgroundTypeEnum.Color,
    background: 'FFFFFF',
    backgroundImage: '',
    backgroundImageWidth: 1366,
    backgroundImageHeight: 768,
    backgroundImageOverlay: 30,
    theme: ComponentThemeTypeEnum.Default,
    backgroundImageSizeType: ComponentBackgroundSizeTypeEnum.Manual,
    backgroundImageSize: 100,
    backgroundPosition: ComponentBackgroundPositionEnum.CenterCenter,
    displayOnDesktop: true,
    displayOnMobile: true,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 0,
    paddingRight: 0,
    mobilePaddingTop: 20,
    mobilePaddingBottom: 20,
    mobilePaddingLeft: 0,
    mobilePaddingRight: 0
}

export default initialComponentDefaultData;