import { AnyAction } from 'redux';
import User from '../../Domain/User';

import {
    currentInformationRequestLoadingAction,
    currentInformationRequestSuccessAction,
    CurrentInformationRequestSuccessAction,
    currentInformationRequestFailureAction
} from './user.actions';

export interface UserReduxState {
    user: User | null,
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean
}

const initialUserReduxState: UserReduxState = {
    user: null,
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false
}

export const userReducer = (state: UserReduxState = initialUserReduxState, action: AnyAction): UserReduxState => {
    switch(action.type) {
        case currentInformationRequestLoadingAction:
            return {
                user: null,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false
            }

        case currentInformationRequestSuccessAction:
            return {
                user: (action as CurrentInformationRequestSuccessAction).payload.user,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false
            }

        case currentInformationRequestFailureAction:
            return {
                user: null,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true
            }
    }

    return state;
}