import React from 'react';

const IconSettingsPaddingLeft: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <g strokeWidth="1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g transform="translate(12.000000, 12.000000) scale(-1, -1) rotate(-270.000000) translate(-12.000000, -12.000000) translate(3.000000, 2.500000)" strokeWidth="1.5">
                <line x1="0" y1="18.5" x2="18" y2="18.5"></line>
                <line x1="0" y1="0.5" x2="18" y2="0.5"></line>
                <g opacity="0.4" transform="translate(6.169920, 2.890140)">
                    <line x1="2.83008" y1="1.10986" x2="2.83008" y2="9.10986"></line>
                    <polyline points="5.65998 2.83 2.82998 0 0 2.83"></polyline>
                </g>
            </g>
        </g>
    </svg>
);

export default IconSettingsPaddingLeft;