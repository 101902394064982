export enum DarkModeColors {
    SYMBIOZE = '#FECE2E',
    SYMBIOZE_RGB = '224, 227, 79',
    DEFAULT_TEXT = '#EBF2FA',
    NEUTRAL_100 = '#0E0F0F',
    NEUTRAL_100_RGB = '14, 15, 15',
    NEUTRAL_200 = '#232425',
    NEUTRAL_200_RGB = '35, 36, 37',
    NEUTRAL_300 = '#2D2F31',
    NEUTRAL_300_RGB = '45, 47, 49',
    NEUTRAL_400 = '#35373A',
    NEUTRAL_400_RGB = '53, 55, 58',
    NEUTRAL_500 = '#6B6669',
    NEUTRAL_500_RGB = '107, 102, 105',
}