import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

// Domain
import { IColor } from '../../../../App/Domain/IColor';
import ComponentBackgroundTypeEnum from '../../../Domain/Enum/ComponentBackgroundTypeEnum';
import ComponentBackgroundSizeTypeEnum from '../../../Domain/Enum/ComponentBackgroundSizeTypeEnum';

// Application
import { IComponentData } from '../ComponentType/IComponentData';

// Inputs
import FileInput from '../../../../_utils/Forms/FileInput';
import RangeInput from '../../../../_utils/Forms/RangeInput';
import SelectInput from '../../../../_utils/Forms/SelectInput';
import ColorPickerInput from '../../../../_utils/Forms/ColorPickerInput';
import NinePositionsInput from '../../../../_utils/Forms/NinePositionsInput';

// Infrastructure & Icons
import FormGroup from '../../../../_utils/Forms/FormGroup';
import GroupCollapse from '../../../Infrastructure/Partials/GroupCollapse';
import IconSettingsWidth from '../../../Infrastructure/Style/svg/IconSettingsWidth';
import IconSettingsHeight from '../../../Infrastructure/Style/svg/IconSettingsHeight';
import IconSettingsOpacity from '../../../Infrastructure/Style/svg/IconSettingsOpacity';
import IconSettingsImageSize from '../../../Infrastructure/Style/svg/IconSettingsImageSize';

// Redux
import { pageBuilderIsPostLoadingSelector } from '../../../Infrastructure/Redux/page-builder-post.selectors';
import { componentSettingsImageHandlerService } from '../../../Infrastructure/Redux/uploadimage.services';

interface IHandleComponentBackgroundSettingsProps {
    data: IComponentData<string | number | boolean>,
    onDataChange: (name: string, value: string | number) => void
}

const HandleComponentBackgroundSettings: React.FunctionComponent<IHandleComponentBackgroundSettingsProps> = ({ data, onDataChange }): React.ReactElement => {
    const intl = useIntl();
    const isRequestLoading = useSelector(pageBuilderIsPostLoadingSelector);
    const [backgroundImageWidth, setBackgroundImageWidth] = useState<number>(data.backgroundImageWidth !== undefined ? Number(data.backgroundImageWidth) : 320);
    const [backgroundImageHeight, setBackgroundImageHeight] = useState<number>(data.backgroundImageHeight !== undefined ? Number(data.backgroundImageHeight) : 240);

    const backgroundColors: IColor[] = [
        { hex: 'FFFFFF' },
        { hex: '000000' },
        { hex: 'E0E34F' }
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            if (backgroundImageWidth !== data.backgroundImageWidth) {
                onDataChange('backgroundImageWidth', Number(backgroundImageWidth));
            }
            
            if (backgroundImageHeight !== data.backgroundImageHeight) {
                onDataChange('backgroundImageHeight', Number(backgroundImageHeight));
            }
        }, 1500);

        return () => clearTimeout(timer);
    }, [backgroundImageWidth, backgroundImageHeight]);

    const handleBackgroundImageChange = (name: string, value: File): void => {
        componentSettingsImageHandlerService(value)
            .then((location: string) => {
                onDataChange(name, location);
                toast.success('Success', { position: 'bottom-center' });
            }, (error: any) => {
                toast.error(`${error.message}`, { position: 'bottom-center' });
            });
    }

    const componentBackgroundTypeRecords = (): Record<string, string> => {
        const records: Record<string, string> = {};

        records[ComponentBackgroundTypeEnum.Color] = intl.formatMessage({ id: `page_builder.settings.component_background.type.${ ComponentBackgroundTypeEnum.Color }` });
        records[ComponentBackgroundTypeEnum.Image] = intl.formatMessage({ id: `page_builder.settings.component_background.type.${ ComponentBackgroundTypeEnum.Image }` });

        return records;
    }

    const componentBackgroundSizeTypeRecords = (): Record<string, string> => {
        const records: Record<string, string> = {};

        records[ComponentBackgroundSizeTypeEnum.Manual] = intl.formatMessage({ id: `page_builder.settings.component_background.size_type.${ ComponentBackgroundSizeTypeEnum.Manual }` });
        records[ComponentBackgroundSizeTypeEnum.Cover] = intl.formatMessage({ id: `page_builder.settings.component_background.size_type.${ ComponentBackgroundSizeTypeEnum.Cover }` });
        records[ComponentBackgroundSizeTypeEnum.Contain] = intl.formatMessage({ id: `page_builder.settings.component_background.size_type.${ ComponentBackgroundSizeTypeEnum.Contain }` });

        return records;
    }

    const renderBackgroundImageTypeInput = (): React.ReactElement => {

        return (
            <React.Fragment>
                <FileInput inputName="backgroundImage" inputValue={ data.backgroundImage as string } label="page_builder.settings.style.component_background_image.label" onFileChange={(name, value) => { handleBackgroundImageChange(name, value) }} disabled={ isRequestLoading } />

                <div className="page-builder--contextual-group-item-label">BACKGROUND SIZE & POSITION</div>
                <SelectInput inputName="backgroundImageSizeType" inputValue={ data.backgroundImageSizeType as string } label="page_builder.settings.component_background.size_type.label" choices={ componentBackgroundSizeTypeRecords() } onChange={(name, value) => onDataChange(name, value)} additionalClassname="pb-10px" />
                {
                    data.backgroundImageSizeType === ComponentBackgroundSizeTypeEnum.Manual &&
                    <FormGroup additionalClassname="has-svg pb-10px">
                        <RangeInput inputName="backgroundImageSize" value={ data.backgroundImageSize as number } label={ IconSettingsImageSize } min={ 10 } max={ 100 } step={ 1 } unit="%" onInputChange={(name, value) => onDataChange(name, Number(value))} />
                    </FormGroup>
                }
                <NinePositionsInput inputName="backgroundPosition" inputValue={ data.backgroundPosition as string } onChange={(name, value) => onDataChange(name, value)} />

                <div className="page-builder--contextual-group-item-label">IMAGE SIZE</div>
                <FormGroup additionalClassname="has-svg pb-10px">
                    <RangeInput inputName="backgroundImageWidth" label={ IconSettingsWidth } value={ Number(backgroundImageWidth) } min={ 320 } max={ 2560 } step={ 10 } unit="PX" onInputChange={(name, value) => setBackgroundImageWidth(Number(value))} />
                </FormGroup>
                <FormGroup additionalClassname="has-svg pb-30px">
                    <RangeInput inputName="backgroundImageHeight" label={ IconSettingsHeight } value={ Number(backgroundImageHeight) } min={ 240 } max={ 1440 } step={ 10 } unit="PX" onInputChange={(name, value) => setBackgroundImageHeight(Number(value))} />
                </FormGroup>

                <div className="page-builder--contextual-group-item-label">OVERLAY OPACITY & COLOR</div>
                <FormGroup additionalClassname="has-svg pb-20px">
                    <RangeInput inputName="backgroundImageOverlay" label={ IconSettingsOpacity } value={ Number(data.backgroundImageOverlay) } min={ 0 } max={ 90 } step={ 10 } unit="%" onInputChange={(name, value) => onDataChange(name, Number(value))} />
                </FormGroup>
            </React.Fragment>
        );
    }

    return (
        <GroupCollapse label="page_builder.settings.component_background_setting">
            <React.Fragment>
                <div className="page-builder--settings-list">
                    <SelectInput inputName="backgroundType" label="page_builder.settings.component_background.type.label" inputValue={ data.backgroundType as string } choices={ componentBackgroundTypeRecords() } onChange={(name, value) => onDataChange(name, value)} />

                    { data.backgroundType === ComponentBackgroundTypeEnum.Image && renderBackgroundImageTypeInput() }

                    <ColorPickerInput label="page_builder.settings.style.component_background" inputName="background" inputValue={ data.background as string } colors={ backgroundColors } onChange={ (name, value) => onDataChange(name, value) } disabled={ isRequestLoading } />
                </div>
            </React.Fragment>
        </GroupCollapse>
    );
}

export default HandleComponentBackgroundSettings;