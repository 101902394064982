import IDataSettings from './IDataSettings';

export const initialDataSettings: IDataSettings = {
    siteName: {
        inputValue: 'Symbioze',
        validation: { inError: false, message: '' }
    },
    siteLogo: {
        inputValue: undefined,
        validation: { inError: false, message: '' }
    },
    address: {
        inputValue: '',
        validation: { inError: false, message: '' }
    },
    siteFavicon: {
        inputValue: undefined,
        validation: { inError: false, message: '' }
    },
    siteMetaTitle: {
        inputValue: '',
        validation: { inError: false, message: '' }
    },
    siteMetaDescription: {
        inputValue: '',
        validation: { inError: false, message: '' }
    },
    siteDefaultOpenGraph: {
        inputValue: undefined,
        validation: { inError: false, message: '' }
    },
    headerButton: {
        inputValue: false,
        validation: { inError: false, message: '' }
    },
    headerButtonLabel: {
        inputValue: '',
        validation: { inError: false, message: '' }
    },
    headerButtonNavigationType: {
        inputValue: 'internal',
        validation: { inError: false, message: '' }
    },
    headerButtonNavigationId: {
        inputValue: undefined,
        validation: { inError: false, message: '' }
    },
    headerButtonNavigationUrl: {
        inputValue: '',
        validation: { inError: false, message: '' }
    },
    headerButtonNavigationMailto: {
        inputValue: '',
        validation: { inError: false, message: '' }
    },
    footerButton: {
        inputValue: false,
        validation: { inError: false, message: '' }
    },
    footerButtonLabel: {
        inputValue: "",
        validation: { inError: false, message: '' }
    },
    footerButtonNavigationId: {
        inputValue: undefined,
        validation: { inError: false, message: '' }
    },
    footerText: {
        inputValue: '',
        validation: { inError: false, message: '' }
    }
}