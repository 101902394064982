import React, { useState } from 'react';
import { useHistory } from 'react-router';

interface ICardProps {
    body: React.ReactElement,
    link?: string,
    additionalClassname?: string
}

const Card: React.FunctionComponent<ICardProps> = ({ body, link, additionalClassname }): React.ReactElement => {
    const history = useHistory();
    const [flareX, setFlareX] = useState<number>(0);
    const [flareY, setFlareY] = useState<number>(0);

    const handleFlarePosition = (event: React.MouseEvent): void => {
        const currentTargetRect = event.currentTarget.getBoundingClientRect();

        setFlareX((event.pageX - currentTargetRect.left) - 128);
        setFlareY((event.pageY - currentTargetRect.top) - 128);
    }

    const renderCardBody = (): React.ReactElement => {

        return (
            <React.Fragment>
                { body }
                <div className="card--flare" style={{ top: flareY, left: flareX }}></div>
            </React.Fragment>
        )
    }

    return (
        <div onClick={() => { link !== undefined ? history.push(link) : void(0) }} className={ `card ${ additionalClassname !== undefined ? additionalClassname : '' }` } onMouseMove={ (event) => handleFlarePosition(event) }>
            { renderCardBody() }
        </div>
    )
}

export default Card;