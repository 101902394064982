import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import Navigation from '../../Domain/Navigation';
import NavigationPage from '../../Domain/NavigationPage';
import NavigationType from '../../Domain/NavigationType';

import IDataNavigation from './IDataNavigation';
import ValidateNavigation from './ValidateNavigation';
import { initialNavigationData } from './InitialNavigationData';

// Form inputs
import TextInput from '../../../_utils/Forms/TextInput';
import SelectInput from '../../../_utils/Forms/SelectInput';

// Redux
import { navigationPostRequestLoadingActionCreator } from '../../Infrastructure/Redux/navigation.actions';
import { navigationPostItemService, navigationPutItemService } from '../../Infrastructure/Redux/navigation.services';
import { navigationIsPostRequestLoadingSelector, navigationIsPostRequestSuccessSelector, navigationPagesSelector, navigationSelector } from '../../Infrastructure/Redux/navigation.selectors';

interface IHandleNavigationProps {
    id: number | undefined
}

const validateNavigationData: ValidateNavigation = new ValidateNavigation();

export const HandleNavigation: React.FunctionComponent<IHandleNavigationProps> = ({ id }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const navigation = useSelector(navigationSelector);
    const navigationPages = useSelector(navigationPagesSelector);
    const isPostLoading = useSelector(navigationIsPostRequestLoadingSelector);
    const isPostSuccess = useSelector(navigationIsPostRequestSuccessSelector);

    const [data, setData] = useState<IDataNavigation>(initialNavigationData);
    const [canBeSubmitted, setCanBeSubmitted] = useState<boolean>(false);

    const handleEditData = useCallback((navigationItem: Navigation, data: IDataNavigation): void => {
        const newData: IDataNavigation = Object.assign({}, data, {
            id: {
                inputValue: navigationItem.id,
                validation: { inError: false, message: '' }
            },
            title: {
                inputValue: navigationItem.title,
                validation: { inError: false, message: '' }
            },
            type: {
                inputValue: navigationItem.type !== null ? navigationItem.type : "",
                validation: { inError: false, message: '' }
            },
            pageId: {
                inputValue: navigationItem.pageId !== null ? navigationItem.pageId : "",
                validation: { inError: false, message: '' }
            },
            moduleSlug: {
                inputValue: navigationItem.moduleSlug !== null ? navigationItem.moduleSlug : "",
                validation: { inError: false, message: '' }
            },
            parentId: {
                inputValue: navigationItem.parentId,
                validation: { inError: false, message: '' }
            },
            order: {
                inputValue: navigationItem.order,
                validation: { inError: false, message: '' }
            }
        });

        setData(newData);
    }, []);

    useEffect(() => {
        if (id !== undefined && navigation !== null && Number(data.id.inputValue) !== id) {
            const navigationItem = navigation.findNavigationById(id);

            if (navigationItem !== undefined) {
                handleEditData(navigationItem, data);
            }
        }
    }, [id, navigation, data, handleEditData]);

    useEffect(() => {
        setCanBeSubmitted(validateNavigationData.canBeSubmitted(data));
    }, [data]);

    useEffect(() => {
        if (isPostSuccess) {
            toast.success(<LocaleMessage id="navigation.toast.success" />, { position: 'bottom-center' });
        }
    }, [isPostSuccess]);

    useEffect(() => {
        if (isPostLoading) {
            data.id.inputValue !== undefined ? navigationPutItemService(Number(data.id.inputValue), data) : navigationPostItemService(data);
        }
    }, [isPostLoading, data]);

    const handleOnChange = (name: string, value: string): void => {
        const newData: IDataNavigation = Object.assign({}, data, {
            [name]: {
                inputValue: value,
                validation: { inError: false, message: '' }
            }
        });

        setData(validateNavigationData.validateForm(newData));
    }

    const handleSubmit = (): void => {
        if (canBeSubmitted) {
            dispatch(navigationPostRequestLoadingActionCreator());
        }
    }

    const generateTypeRecord = (): Record<string, string> => {
        const types: Record<string, string> = {};

        types[NavigationType.Folder] = intl.formatMessage({ id: 'navigation.type.choice_folder' });
        types[NavigationType.Page] = intl.formatMessage({ id: 'navigation.type.choice_page'});
        types[NavigationType.Module] = intl.formatMessage({ id: 'navigation.type.choice_module'});

        return types;
    }

    const generatePagesRecord = (): Record<string, string> => {
        const pages: Record<string, string> = {};

        if (navigationPages !== null) {
            navigationPages.forEach((navigationPage: NavigationPage) => {
                
                pages[navigationPage.id] = navigationPage.title;
            })
        }
        
        return pages;
    }
 
    return (
        <React.Fragment>
            <form>
                {
                    id !== undefined &&
                    <TextInput
                        label="navigation.id.label"
                        inputName="id"
                        inputValue={ String(data.id.inputValue) }
                        errorMessage={ data.id.validation.inError ? data.id.validation.message : undefined }
                        disabled={ true }
                        onChange={(name, value) => handleOnChange(name, value)} />
                }

                <TextInput
                    label="navigation.title.label"
                    inputName="title"
                    inputValue={ data.title.inputValue }
                    errorMessage={ data.title.validation.inError ? data.title.validation.message : undefined }
                    disabled={ isPostLoading }
                    onChange={(name, value) => handleOnChange(name, value)} />

                <SelectInput label="navigation.type.label" inputName="type" inputValue={ data.type.inputValue } errorMessage={ data.type.validation.inError ? data.type.validation.message : undefined } disabled={ isPostLoading } choices={ generateTypeRecord() } onChange={(name, value) => handleOnChange(name, value)} />

                {
                    data.type.inputValue === NavigationType.Page &&
                    <SelectInput label="navigation.page_type.label" inputName="pageId" inputValue={ data.pageId.inputValue } errorMessage={ data.pageId.validation.inError ? data.pageId.validation.message : undefined } disabled={ isPostLoading } choices={ generatePagesRecord() } emptyPlaceholder="navigation.page_type.empty_value" onChange={(name, value) => handleOnChange(name, value)} />
                }

                {
                    data.type.inputValue === NavigationType.Module &&
                    <SelectInput label="navigation.module_type.label" inputName="moduleSlug" inputValue={ data.moduleSlug.inputValue } errorMessage={ data.moduleSlug.validation.inError ? data.moduleSlug.validation.message : undefined } disabled={ isPostLoading } choices={ {} } emptyPlaceholder="navigation.module_type.empty_value" onChange={(name, value) => handleOnChange(name, value)} />
                }

                <br />

                <button type="button" className={ `button is-primary ${!canBeSubmitted ? 'is-disabled' : ''} ${isPostLoading ? 'is-loading' : ''}` } onClick={ handleSubmit }>
                    Enregistrer
                </button>
            </form>
        </React.Fragment>
    );
}