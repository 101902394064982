import React from 'react';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../../../../../store';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import FormGroup from '../../../../../_utils/Forms/FormGroup';
import RangeInput from '../../../../../_utils/Forms/RangeInput';
import GroupCollapse from '../../../../Infrastructure/Partials/GroupCollapse';

import { IComponentData } from '../IComponentData';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';
import IconBorderRadius from '../../../../Infrastructure/Style/svg/IconBorderRadius';
import IconNumberSlidesPerViewSetting from '../../../../Infrastructure/Style/svg/IconNumberSlidesPerViewSetting';

interface IHandleComponentGallerySettingsProps {
    pageContentId: string,
    onChange: (inputName: string, value: string | boolean) => void
}

const HandleComponentGallerySettings: React.FunctionComponent<IHandleComponentGallerySettingsProps> = ({ pageContentId, onChange }): React.ReactElement => {
    const pageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentData<string|boolean> = pageContent.data as IComponentData<boolean>;

    return (
        <React.Fragment>
            <GroupCollapse label="page_builder.component-gallery-type.settings.label">
                <div className="page-builder--settings-list">
                    <div className="page-builder--settings-list">
                        <div className="page-builder--contextual-group-item-label">
                            <LocaleMessage id="page_builder.component-gallery-type.settings.position-info" />
                        </div>
                        <div className="form--group-select">
                            <select name="positionInformation" id="component_gallery_position_info" value={ data.positionInformation as string } onChange={(event) => onChange('positionInformation', event.currentTarget.value)}>
                                <LocaleMessage id={ `page_builder.component-gallery-type.settings.position-info.below` }>
                                    {(message) => <option value={ 'below' }>{ message }</option>}
                                </LocaleMessage>
                                <LocaleMessage id={ `page_builder.component-gallery-type.settings.position-info.inside` }>
                                    {(message) => <option value={ 'inside' }>{ message }</option>}
                                </LocaleMessage>
                            </select>
                        </div>
                        <br />
                        <div className="page-builder--contextual-group-item-label">
                            <LocaleMessage id="page_builder.component-gallery-type.settings.masonry" />
                        </div>
                        <div className="form--group-select">
                            <select name="masonry" id="component_gallery_masonry" value={ data.masonry ? 'yes' : 'no' } onChange={(event) => onChange('masonry', event.currentTarget.value === 'yes' ? true : false)}>
                                <LocaleMessage id={ `page_builder.component-gallery-type.settings.masonry.no` }>
                                    {(message) => <option value={ 'no' }>{ message }</option>}
                                </LocaleMessage>
                                <LocaleMessage id={ `page_builder.component-gallery-type.settings.masonry.yes` }>
                                    {(message) => <option value={ 'yes' }>{ message }</option>}
                                </LocaleMessage>
                            </select>
                        </div>
                        <br />
                        <div className="page-builder--contextual-group-item-label">
                            <LocaleMessage id="page_builder.component-gallery-type.settings.desktop-settings" />
                        </div>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="nbrColumnsDesktop" label={ IconNumberSlidesPerViewSetting } value={ data.nbrColumnsDesktop !== undefined ? Number(data.nbrColumnsDesktop) : 4 } min={ 1 } max={ 5 } step={ 1 } unit="IMG" onInputChange={(name, value) => onChange(name, value)} />
                        </FormGroup>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="borderRadiusDesktop" label={ IconBorderRadius } value={ data.borderRadiusDesktop !== undefined ? Number(data.borderRadiusDesktop) : 4 } min={ 0 } max={ 40 } step={ 2 } unit="PX" onInputChange={(name, value) => onChange(name, value)} />
                        </FormGroup>
                        <br />
                        <div className="page-builder--contextual-group-item-label">
                            <LocaleMessage id="page_builder.component-gallery-type.settings.mobile-settings" />
                        </div>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="nbrColumnsMobile" label={ IconNumberSlidesPerViewSetting } value={ data.nbrColumnsMobile !== undefined ? Number(data.nbrColumnsMobile) : 2 } min={ 1 } max={ 3 } step={ 1 } unit="IMG" onInputChange={(name, value) => onChange(name, value)} />
                        </FormGroup>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="borderRadiusMobile" label={ IconBorderRadius } value={ data.borderRadiusMobile !== undefined ? Number(data.borderRadiusMobile) : 4 } min={ 0 } max={ 20 } step={ 2 } unit="PX" onInputChange={(name, value) => onChange(name, value)} />
                        </FormGroup>
                    </div>
                </div>
            </GroupCollapse>
        </React.Fragment>
    );
}

export default HandleComponentGallerySettings;