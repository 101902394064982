enum ComponentBackgroundPositionEnum {
    TopLeft = 'top left',
    TopCenter = 'top center',
    TopRight = 'top right',
    CenterLeft = 'center left',
    CenterCenter = 'center center',
    CenterRight = 'center right',
    BottomLeft = 'bottom left',
    BottomCenter = 'bottom center',
    BottomRight = 'bottom right'
}

export default ComponentBackgroundPositionEnum;