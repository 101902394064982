import * as React from 'react';

const IconBracketComponent = (
    <svg viewBox="0 0 47 47" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g transform="translate(22.950000, 22.200000) rotate(90.000000) translate(-22.950000, -22.200000) translate(9.000000, 6.000000)">
            <g>
                <path d="M10.3065455,8.08087542 L18.0659394,8.08087542 C18.5466061,8.08087542 18.9089091,7.57569024 18.9089091,6.90600673 L18.9089091,1.35857239 C18.9089091,0.688888889 18.5466061,0.183703704 18.0659394,0.183703704 L10.3065455,0.183703704 C9.82587879,0.183703704 9.46357576,0.688888889 9.46357576,1.35857239 L9.46357576,6.90600673 C9.46357576,7.57610774 9.82587879,8.08087542 10.3065455,8.08087542 Z M10.3120606,1.35857239 C10.3120606,1.19240404 10.3532121,1.07424916 10.3850303,1.01872054 L17.9878788,1.01872054 C18.019697,1.07466667 18.0608485,1.19240404 18.0608485,1.35857239 L18.0608485,6.90600673 C18.0608485,7.07217508 18.019697,7.19032997 17.9878788,7.24585859 L10.3850303,7.24585859 C10.3532121,7.18991246 10.3120606,7.07217508 10.3120606,6.90600673 L10.3120606,1.35857239 Z" id="IconBracketComponentShape" fill="#142040" fillRule="nonzero"/>
                <path d="M8.91248485,23.6046734 L1.15309091,23.6046734 C0.672424242,23.6046734 0.310121212,24.1098586 0.310121212,24.7795421 L0.310121212,30.3269764 C0.310121212,30.9966599 0.672424242,31.5018451 1.15309091,31.5018451 L8.91248485,31.5018451 C9.39315152,31.5018451 9.75545455,30.9966599 9.75545455,30.3269764 L9.75545455,24.7799596 C9.75545455,24.1098586 9.39315152,23.6046734 8.91248485,23.6046734 Z M8.9069697,30.3273939 C8.9069697,30.4935623 8.86581818,30.6117172 8.834,30.6672458 L1.23115152,30.6672458 C1.19933333,30.6112997 1.15818182,30.4935623 1.15818182,30.3273939 L1.15818182,24.7799596 C1.15818182,24.6137912 1.19933333,24.4956364 1.23115152,24.4401077 L8.834,24.4401077 C8.86581818,24.4960539 8.9069697,24.6137912 8.9069697,24.7799596 L8.9069697,30.3273939 Z" id="IconBracketComponentShape" fill="#142040" fillRule="nonzero"/>
                <path d="M26.7968485,23.6046734 L19.0374545,23.6046734 C18.5567879,23.6046734 18.1944848,24.1098586 18.1944848,24.7795421 L18.1944848,30.3269764 C18.1944848,30.9966599 18.5567879,31.5018451 19.0374545,31.5018451 L26.7968485,31.5018451 C27.2775152,31.5018451 27.6398182,30.9966599 27.6398182,30.3269764 L27.6398182,24.7799596 C27.6398182,24.1098586 27.2775152,23.6046734 26.7968485,23.6046734 Z M26.7913333,30.3273939 C26.7913333,30.4935623 26.7501818,30.6117172 26.7183636,30.6672458 L19.1159394,30.6672458 C19.0841212,30.6112997 19.0429697,30.4935623 19.0429697,30.3273939 L19.0429697,24.7799596 C19.0429697,24.6137912 19.0841212,24.4956364 19.1159394,24.4401077 L26.7187879,24.4401077 C26.7506061,24.4960539 26.7917576,24.6137912 26.7917576,24.7799596 L26.7917576,30.3273939 L26.7913333,30.3273939 Z" id="IconBracketComponentShape" fill="#142040" fillRule="nonzero"/>
                <path d="M22.5595152,21.4578451 C22.5595152,21.6887273 22.7491515,21.8753535 22.9837576,21.8753535 C23.2183636,21.8753535 23.408,21.6887273 23.408,21.4578451 L23.408,15.9550842 C23.408,15.724202 23.2183636,15.5375758 22.9837576,15.5375758 L14.4327273,15.5375758 L14.4327273,9.9400404 C14.4327273,9.70915825 14.2430909,9.52253199 14.0084848,9.52253199 C13.7738788,9.52253199 13.5842424,9.70915825 13.5842424,9.9400404 L13.5842424,15.5375758 L5.03278788,15.5375758 C4.79818182,15.5375758 4.60854545,15.724202 4.60854545,15.9550842 L4.60854545,21.4578451 C4.60854545,21.6887273 4.79818182,21.8753535 5.03278788,21.8753535 C5.26739394,21.8753535 5.4570303,21.6887273 5.4570303,21.4578451 L5.4570303,16.3725926 L14.0084848,16.3725926 L22.5595152,16.3725926 L22.5595152,21.4578451 Z" id="iconBracketLines" fill="#E81E25" fillRule="nonzero"/>
            </g>
        </g>
    </svg>
);

export default IconBracketComponent;