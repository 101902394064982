import { AppReduxState } from '../../../store';

export const navigationSelector = (state: AppReduxState) => state.navigation.navigation;
export const navigationPagesSelector = (state: AppReduxState) => state.navigation.navigationPages;

export const navigationIsRequestLoadingSelector = (state: AppReduxState) => state.navigation.isRequestLoading;
export const navigationIsRequestSuccessSelector = (state: AppReduxState) => state.navigation.isRequestSuccess;
export const navigationIsRequestFailureSelector = (state: AppReduxState) => state.navigation.isRequestFailure;

export const navigationIsPostRequestLoadingSelector = (state: AppReduxState) => state.navigation.isPostRequestLoading;
export const navigationIsPostRequestSuccessSelector = (state: AppReduxState) => state.navigation.isPostRequestSuccess;
export const navigationIsPostRequestFailureSelector = (state: AppReduxState) => state.navigation.isPostRequestFailure;

export const navigationIsDeleteRequestLoadingSelector = (state: AppReduxState) => state.navigation.isDeleteRequestLoading;
export const navigationIsDeleteRequestSuccessSelector = (state: AppReduxState) => state.navigation.isDeleteRequestSuccess;
export const navigationIsDeleteRequestFailureSelector = (state: AppReduxState) => state.navigation.isDeleteRequestFailure;