import cuid from 'cuid';
import HttpClient from '../../../_utils/HttpClient/HttpClient';

import PageContent from '../../Domain/PageContent';
import PageContentsCollection from '../../Domain/PageContentsCollection';

import { IDataPageContent } from '../../Application/Forms/IDataPageContent';
import { IComponentData } from '../../Application/Forms/ComponentType/IComponentData';

class PageContentsRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    public getPageContents = async (pageId: number): Promise<PageContentsCollection> => {
        const response = await this.httpClient.get(this.baseUrl, `/pages/${pageId}/contents`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No page contents found');
        }

        const pagesContentsCollection = new PageContentsCollection();

        response.data.docs.forEach((pageContent: any) => {
            pagesContentsCollection.push(
                this.fromJson(pageContent)
            );
        });

        return pagesContentsCollection;
    }
    
    public postPageContents = async (pageId: number, ids: string[], dataArray: Record<string, IDataPageContent>): Promise<any> => {
        const dataBody = new FormData();
        dataBody.append('page_id', String(pageId));
        
        ids.forEach((id: string) => {
            dataBody.append(`data[${id}][id]`, dataArray[id].id !== null ? String(dataArray[id].id) : '');
            dataBody.append(`data[${id}][type]`, dataArray[id].type);
            dataBody.append(`data[${id}][position]`, String(dataArray[id].position));
            dataBody.append(`data[${id}][serialized_data]`, dataArray[id].data);
        });
        
        const response = await this.httpClient.post(this.baseUrl, `/pages/${pageId}/contents`, dataBody);
        return response;
    }
    
    public uploadImageHandler = async (file: File): Promise<any> => {
        const formData = new FormData();
        formData.append('file', file, file.name);

        const response = await this.httpClient.post(this.baseUrl, `/pages/upload_images`, formData);
        return response;
    }

    private fromJson = (pageContent: any): PageContent => {

        return new PageContent(
            cuid(),
            pageContent.type,
            pageContent.position,
            JSON.parse(pageContent.serialized_data) as IComponentData<string|IComponentData<string>[]>,
            pageContent.id
        );
    }

}

export default PageContentsRepository;