import React, { useEffect } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { store } from './store';
import AdminLayout from './App/Infrastructure/Layout/AdminLayout';
import PageBuilderLayout from './PageBuilders/Infrastructure/Layout/PageBuilderLayout';
import { PagesController } from './Pages/Infrastructure/Pages/PagesController';
import { LoginController } from './App/Infrastructure/Pages/LoginController';
import { GamesController } from './Games/Infrastructure/Pages/GamesController';
import { UsersController } from './User/Infrastructure/Pages/UsersController';
import { MediasController } from './Medias/Infrastructure/Pages/MediasController';
import { PageEditController } from './Pages/Infrastructure/Pages/PageEditController';
import { SettingsController } from './User/Infrastructure/Pages/SettingsController';
import { SettingsController as AppSettingsController } from './App/Infrastructure/Pages/SettingsController';
import { BootstrapController } from './App/Infrastructure/Pages/BootstrapController';
import { DashboardController } from './App/Infrastructure/Pages/DashboardController';
import { EditUsersController } from './User/Infrastructure/Pages/EditUsersController';
import { NavigationController } from './Navigation/Infrastructure/Pages/NavigationController';
import { TournamentsController } from './Tournaments/Infrastructure/Pages/TournamentsController';
import { PageBuilderController } from './PageBuilders/Infrastructure/Pages/PageBuilderController';
import { ContactRequestsController } from './ContactRequests/Infrastructure/Pages/ContactRequestsController';

import { dashCase } from './_utils/Strings/Transform';
import { ModalUtils } from './_utils/Modal/ModalUtils';

import { LocaleProvider } from './App/Infrastructure/Redux/Locale/Provider/LocaleProvider';
import { isLoggedInSelector } from './Auth/Infrastructure/Redux/auth.selectors';
import { appThemeModeSelector } from './App/Infrastructure/Redux/App/app.selectors';
import { authRequestSuccessActionCreator } from './Auth/Infrastructure/Redux/auth.actions';
import { currentInformationRequestLoadingActionCreator } from './User/Infrastructure/Redux/user.actions';

import 'swiper/scss';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'react-toastify/dist/ReactToastify.css';
import './App/Infrastructure/Style/App.sass';
import './App/Infrastructure/Style/Web.sass';
import { BlackModeColors } from './App/Domain/Enum/BlackModeColors';
import { DarkModeColors } from './App/Domain/Enum/DarkModeColors';
import { LightModeColors } from './App/Domain/Enum/LightModeColors';

export const App: React.FunctionComponent = () => {
    const dispatch = useDispatch();

    const mode = useSelector(appThemeModeSelector);
    const isAuthenticated = useSelector(isLoggedInSelector);
    const hasAccessTokenItem = localStorage.hasOwnProperty('accessToken');

    useEffect(() => {
        if (hasAccessTokenItem) {
            dispatch(authRequestSuccessActionCreator());
            dispatch(currentInformationRequestLoadingActionCreator(dispatch)());
        }
    }, [ dispatch, hasAccessTokenItem ]);

    useEffect(() => {
        if (mode !== '') {
            document.body.classList.remove('black-mode');
            document.body.classList.remove('dark-mode');
            document.body.classList.remove('light-mode');
            document.body.classList.add(mode);

            switch(mode) {
                case 'black-mode':
                    for (const [color, value] of Object.entries(BlackModeColors)) {
                        document.documentElement.style.setProperty(`--${dashCase(color)}`, String(value));
                    }
                    break;
                    
                    case 'dark-mode':
                        for (const [color, value] of Object.entries(DarkModeColors)) {
                            document.documentElement.style.setProperty(`--${dashCase(color)}`, String(value));
                        }
                        break;
                    
                    default:
                        for (const [color, value] of Object.entries(LightModeColors)) {
                            document.documentElement.style.setProperty(`--${dashCase(color)}`, String(value));
                        }
                        break;
            }
        }
    }, [mode]);

    const PrivateRoute = ({ component: Component, authenticated, ...rest }: any): React.ReactElement => {
        if (!authenticated) {

            return <LoginController />;
        }

        return (
            <Route { ...rest } render={(props) => <AdminLayout><Component { ...props } /></AdminLayout> } />
        );
    }

    const PageBuilderRoute = ({ component: Component, layout: string, authenticated, ...rest }: any): React.ReactElement => {
        if (!authenticated) {

            return <LoginController />;
        }

        return (
            <Route { ...rest } render={(props) => <PageBuilderLayout><Component { ...props } /></PageBuilderLayout> } />
        );
    }

    return (
        <React.Fragment>
            <BrowserRouter>

                <Switch>
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/" component={ DashboardController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/navigation" component={ NavigationController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/pages" component={ PagesController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/pages/create" component={ PageEditController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/pages/:pageId/edit" component={ PageEditController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/games" component={ GamesController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/tournaments" component={ TournamentsController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/users" component={ UsersController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/users/:id" component={ EditUsersController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/users/settings/:page" component={ SettingsController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/contact-requests/:page" component={ ContactRequestsController } />
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/medias/:page" component={ MediasController } />
                    
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/contact-requests" component={ ContactRequestsController } />
                    <PrivateRoute authenticated={ isAuthenticated } path="/contact-requests/:page" component={ ContactRequestsController } />
                    
                    <PrivateRoute authenticated={ isAuthenticated } exact path="/settings" component={ AppSettingsController } />
                    <PrivateRoute authenticated={ isAuthenticated } path="/settings/:page" component={ AppSettingsController } />

                    <PageBuilderRoute authenticated={ isAuthenticated } exact path="/pages/:pageId/contents" component={ PageBuilderController } />

                    <PrivateRoute authenticated={ isAuthenticated } exact path="/bootstrap" component={ BootstrapController } />
                </Switch>

                <ModalUtils />
            </BrowserRouter>

            <ToastContainer />
        </React.Fragment>
    );
};

export const AppProvider: React.FunctionComponent = ({ children }) => {

    return (
        <Provider store={store}>
            <LocaleProvider>{children}</LocaleProvider>
        </Provider>
    );
};