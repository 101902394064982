import React, { useState, ChangeEvent } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import Game from '../../Domain/Game';

import { GamesRepository } from '../../Infrastructure/Repositories/GamesRepository';
import { modalCloseAllActionCreator } from '../../../_utils/Modal/Redux/modal.actions';

export interface CreateGame {
    name: string,
    value: string,
    slug: string,
}

export const CreateGames: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const gamesRepository: GamesRepository = React.useMemo(() => new GamesRepository(), []);
    const [game, setGame] = useState<CreateGame>({
        name: '',
        value: '',
        slug: ''
    });

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        setGame(Object.assign({}, game, {
            [name]: value,
        }));
    }

    const createGame = async (): Promise<any> => {
        if (game) {
            const payload = new Game(
                game.name,
                0,
                game.value,
                game.slug,
                [],
                [],
                { en: '', fr: '', es: ''},
                [],
                false,
                false,
                false,
                false,
                false,
                { darwin: '', windowsNT: '' },
                new Date()
            )
            await gamesRepository.create(payload).then((res: Game) => {
                toast.success(`${res.name} created`);
                dispatch(modalCloseAllActionCreator());
            }, (err: any) => {
                toast.error('Game not create')
            });
        }
    }
   
    return (
        <form>
            <div className="form--group">
                <label htmlFor="name">Name</label>
                <div className="form--group-input">
                    <input id="name" type="text" name="name" value={ game.name } placeholder="Name" onChange={ (event) => { onChange(event) }}/>
                </div>
            </div>

            <div className="form--group">
                <label htmlFor="value">Value</label>
                <div className="form--group-input">
                    <input id="value" type="text" name="value" value={ game.value } placeholder="Value" onChange={ (event) => { onChange(event) }}/>
                </div>
            </div>

            <div className="form--group">
                <label htmlFor="slug">Slug</label>
                <div className="form--group-input">
                    <input id="slug" type="text" name="slug" value={ game.slug } placeholder="Slug" onChange={ (event) => { onChange(event) }}/>
                </div>
            </div>

            <button className="button is-primary" onClick={ () => { createGame() }}>
                Create
            </button>
        </form>
    );
};