import { store } from '../../../store';

import ContactRequestsRepository from '../Repositories/ContactRequestsRepository';
import { contactRequestDeleteRequestFailureActionCreator, contactRequestDeleteRequestSuccessActionCreator } from './delete-contact-request.actions';

const contactRequestsRepository: ContactRequestsRepository = new ContactRequestsRepository();

export const deleteContactRequestService = (id: number): void => {
    contactRequestsRepository.delete(id)
        .then(() => {

            store.dispatch(contactRequestDeleteRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(contactRequestDeleteRequestFailureActionCreator(error.response.data.message));
        })
}