import { Action, Dispatch } from 'redux';

import User from '../../Domain/User';
import { UsersRepository } from '../Repositories/UsersRepository';

export const currentInformationRequestLoadingAction = '@@user/CURRENT_INFORMATION_REQUEST_LOADING';
export const currentInformationRequestSuccessAction = '@@user/CURRENT_INFORMATION_SUCCESS_LOADING';
export const currentInformationRequestFailureAction = '@@user/CURRENT_INFORMATION_REQUEST_LOADING';

export interface CurrentInformationRequestLoadingAction extends Action<typeof currentInformationRequestLoadingAction> {
    payload: {}
}

export interface CurrentInformationRequestSuccessAction extends Action<typeof currentInformationRequestSuccessAction> {
    payload: {
        user: User
    }
}

export interface CurrentInformationRequestFailureAction extends Action<typeof currentInformationRequestFailureAction> {
    payload: {}
}

const usersRepository: UsersRepository = new UsersRepository();
export const currentInformationRequestLoadingActionCreator = (dispatch: Dispatch) => (): CurrentInformationRequestLoadingAction => {
    usersRepository.current()
        .then((user: User) => {
            dispatch(currentInformationRequestSuccessActionCreator(user));
        }, (error: any) => {

            dispatch(currentInformationRequestFailureActionCreator());
        });

    return {
        type: currentInformationRequestLoadingAction,
        payload: {}
    }
};

export const currentInformationRequestSuccessActionCreator = (user: User): CurrentInformationRequestSuccessAction => {

    return {
        type: currentInformationRequestSuccessAction,
        payload:  {
            user: user
        }
    }
}

export const currentInformationRequestFailureActionCreator = (): CurrentInformationRequestFailureAction => {

    return {
        type: currentInformationRequestFailureAction,
        payload: {}
    }
}