import React from 'react';

const IconTwoColumns: React.ReactElement = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <g transform="translate(2.000000, 2.000000)">
            <path d="M8.5,17.9 L8.5,2.1 C8.5,0.6 7.86,0 6.27,0 L2.23,0 C0.64,0 0,0.6 0,2.1 L0,17.9 C0,19.4 0.64,20 2.23,20 L6.27,20 C7.86,20 8.5,19.4 8.5,17.9 Z"></path>
            <path d="M20,17.9 L20,2.1 C20,0.6 19.36,0 17.77,0 L13.73,0 C12.14,0 11.5,0.6 11.5,2.1 L11.5,17.9 C11.5,19.4 12.14,20 13.73,20 L17.77,20 C19.36,20 20,19.4 20,17.9 Z" opacity="0.4"></path>
        </g>
    </svg>
);

export default IconTwoColumns;