import { store } from '../../../store';

import { IContactRequestsList } from '../../Domain/Scaffolding/IContactRequestsList';

import ContactRequestsRepository from '../Repositories/ContactRequestsRepository';
import { contactRequestsGetRequestFailureActionCreator, contactRequestsGetRequestSuccessActionCreator } from './get-contact-requests.actions';

const contactRequestsRepository: ContactRequestsRepository = new ContactRequestsRepository();

export const getContactRequestsService = (page: number, query: string): void => {

    contactRequestsRepository.get(page, query)
        .then((response: IContactRequestsList) => {

            store.dispatch(contactRequestsGetRequestSuccessActionCreator(response.docs, response.pages, response.total));
        }, (error: any) => {

            store.dispatch(contactRequestsGetRequestFailureActionCreator(error.response.data.message));
        });

}