import * as React from 'react';

const LogoPageBuilder = (
    <svg viewBox="0 0 143 26">
        <g id="Builder/Layout/Header" transform="translate(-30.000000, -12.000000)">
            <g id="Logo" transform="translate(30.000000, 12.000000)">
                <rect id="Rectangle" fill="#E0E34F" x="0" y="0" width="26" height="26" rx="7"></rect>
                <g id="new_logo_icon_small">
                    <path d="M10.2555408,-4.14336123e-16 L15.7444592,4.14336123e-16 C19.3105342,-2.4074122e-16 20.6036791,0.371302445 21.9073828,1.06853082 C23.2110865,1.76575919 24.2342408,2.78891348 24.9314692,4.09261719 C25.6286976,5.39632089 26,6.68946584 26,10.2555408 L26,15.7444592 C26,19.3105342 25.6286976,20.6036791 24.9314692,21.9073828 C24.2342408,23.2110865 23.2110865,24.2342408 21.9073828,24.9314692 C20.6036791,25.6286976 19.3105342,26 15.7444592,26 L10.2555408,26 C6.68946584,26 5.39632089,25.6286976 4.09261719,24.9314692 C2.78891348,24.2342408 1.76575919,23.2110865 1.06853082,21.9073828 C0.371302445,20.6036791 1.60494146e-16,19.3105342 -2.76224082e-16,15.7444592 L2.76224082e-16,10.2555408 C-1.60494146e-16,6.68946584 0.371302445,5.39632089 1.06853082,4.09261719 C1.76575919,2.78891348 2.78891348,1.76575919 4.09261719,1.06853082 C5.39632089,0.371302445 6.68946584,2.4074122e-16 10.2555408,-4.14336123e-16 Z" id="Rectangle" fill="#E0E34F"></path>
                    <circle id="Oval" fill="#232425" cx="18.25" cy="15.25" r="1.25"></circle>
                    <path d="M11.280533,10.6489268 C11.280533,10.0349371 11.8418949,9.71917101 12.5611399,9.71917101 C13.3505552,9.71917101 13.9119172,10.0524797 14.2101407,10.719097 L15.8065138,9.82442637 C15.1925241,8.68415989 14.0171725,8 12.5611399,8 C11.6489268,8 10.8770541,8.24559586 10.2630644,8.73678757 C9.64907477,9.22797929 9.35085123,9.87705406 9.35085123,10.6840119 C9.3157661,12.5259808 11.0875648,13.0522577 12.4032569,13.3855663 C13.3330126,13.6136196 14.0698002,13.9469283 14.0698002,14.5082902 C14.0698002,15.157365 13.5786085,15.4906737 12.5786825,15.4906737 C11.5787565,15.4906737 10.9296817,15.0696522 10.6314582,14.2276093 L9,15.1749076 C9.56136196,16.4555145 10.8419689,17.2273872 12.5786825,17.2273872 C13.5610659,17.2273872 14.3680238,16.9817914 15.0170985,16.5081422 C15.6661733,16.0344931 15.999482,15.3678757 15.999482,14.5258328 C16.0345671,12.6487787 14.280311,12.0874168 12.9295337,11.7891932 C12.0173205,11.5260548 11.280533,11.2278313 11.280533,10.6489268 Z" fill="#232425" fillRule="nonzero"></path>
                </g>
                <g id="symbioze" transform="translate(32.998000, 0.444000)" fill="#FFFFFF" fillOpacity="0.496974705" fillRule="nonzero">
                    <path d="M1.56,4.212 C1.56,3.792 1.944,3.576 2.436,3.576 C2.976,3.576 3.36,3.804 3.564,4.26 L4.656,3.648 C4.236,2.868 3.432,2.4 2.436,2.4 C1.812,2.4 1.284,2.568 0.864,2.904 C0.444,3.24 0.24,3.684 0.24,4.236 C0.216,5.496 1.428,5.856 2.328,6.084 C2.964,6.24 3.468,6.468 3.468,6.852 C3.468,7.296 3.132,7.524 2.448,7.524 C1.764,7.524 1.32,7.236 1.116,6.66 L0,7.308 C0.384,8.184 1.26,8.712 2.448,8.712 C3.12,8.712 3.672,8.544 4.116,8.22 C4.56,7.896 4.788,7.44 4.788,6.864 C4.812,5.58 3.612,5.196 2.688,4.992 C2.064,4.812 1.56,4.608 1.56,4.212 Z"></path>
                    <path d="M9.124,6.912 L7.336,2.556 L5.92,2.556 L8.464,8.448 L8.404,8.568 C8.08,9.396 7.624,9.792 6.808,9.744 L6.808,10.956 C8.188,11.04 9.16,10.296 9.736,8.748 L12.04,2.556 L10.66,2.556 L9.124,6.912 Z"></path>
                    <path d="M20.408,2.4 C19.58,2.4 18.968,2.712 18.572,3.348 C18.2,2.712 17.636,2.4 16.868,2.4 C16.112,2.4 15.536,2.688 15.152,3.276 L15.152,2.556 L13.856,2.556 L13.856,8.556 L15.152,8.556 L15.152,5.196 C15.152,4.152 15.704,3.612 16.484,3.612 C17.204,3.612 17.612,4.092 17.612,4.92 L17.612,8.556 L18.908,8.556 L18.908,5.196 C18.908,4.14 19.412,3.612 20.216,3.612 C20.936,3.612 21.38,4.092 21.38,4.92 L21.38,8.556 L22.676,8.556 L22.676,4.836 C22.676,3.348 21.764,2.4 20.408,2.4 Z"></path>
                    <path d="M28.464,2.4 C27.552,2.4 26.856,2.736 26.364,3.408 L26.364,0.156 L25.068,0.156 L25.068,8.556 L26.364,8.556 L26.364,7.692 C26.856,8.376 27.552,8.712 28.464,8.712 C29.292,8.712 29.988,8.412 30.576,7.8 C31.164,7.188 31.452,6.432 31.452,5.556 C31.452,4.668 31.164,3.924 30.576,3.312 C29.988,2.7 29.292,2.4 28.464,2.4 Z M26.904,6.936 C26.544,6.564 26.364,6.108 26.364,5.556 C26.364,5.004 26.544,4.548 26.904,4.188 C27.264,3.816 27.72,3.636 28.26,3.636 C28.8,3.636 29.256,3.816 29.616,4.188 C29.976,4.548 30.156,5.004 30.156,5.556 C30.156,6.108 29.976,6.564 29.616,6.936 C29.256,7.296 28.8,7.476 28.26,7.476 C27.72,7.476 27.264,7.296 26.904,6.936 Z" id="Shape"></path>
                    <path d="M34.852,1.416 C35.188,1.08 35.188,0.576 34.864,0.252 C34.54,-0.084 34.012,-0.084 33.688,0.252 C33.364,0.576 33.364,1.08 33.688,1.416 C34.024,1.74 34.528,1.74 34.852,1.416 Z M34.924,8.556 L34.924,2.556 L33.628,2.556 L33.628,8.556 L34.924,8.556 Z" id="Shape"></path>
                    <path d="M40.256,8.712 C41.144,8.712 41.888,8.412 42.5,7.8 C43.112,7.188 43.424,6.444 43.424,5.556 C43.424,4.668 43.112,3.924 42.5,3.312 C41.888,2.7 41.144,2.4 40.256,2.4 C39.38,2.4 38.624,2.7 38.012,3.312 C37.4,3.924 37.088,4.668 37.088,5.556 C37.088,6.444 37.4,7.188 38.012,7.8 C38.624,8.412 39.38,8.712 40.256,8.712 Z M38.924,6.912 C38.564,6.552 38.384,6.096 38.384,5.556 C38.384,5.016 38.564,4.56 38.924,4.2 C39.284,3.84 39.728,3.66 40.256,3.66 C40.784,3.66 41.228,3.84 41.588,4.2 C41.948,4.56 42.128,5.016 42.128,5.556 C42.128,6.096 41.948,6.552 41.588,6.912 C41.228,7.272 40.784,7.452 40.256,7.452 C39.728,7.452 39.284,7.272 38.924,6.912 Z" id="Shape"></path>
                    <polygon points="49.584 3.42 49.584 2.556 45.144 2.556 45.144 3.78 47.832 3.78 45.024 7.692 45.024 8.556 49.704 8.556 49.704 7.332 46.788 7.332"></polygon>
                    <path d="M57.352,6.108 C57.388,5.88 57.4,5.7 57.4,5.568 C57.4,4.692 57.112,3.936 56.548,3.324 C55.984,2.712 55.264,2.4 54.388,2.4 C53.464,2.4 52.696,2.7 52.108,3.312 C51.52,3.912 51.22,4.656 51.22,5.556 C51.22,6.468 51.52,7.224 52.12,7.824 C52.72,8.412 53.512,8.712 54.484,8.712 C55.648,8.712 56.524,8.28 57.112,7.404 L56.044,6.78 C55.696,7.272 55.18,7.524 54.496,7.524 C53.5,7.524 52.768,7.02 52.576,6.108 L57.352,6.108 Z M52.564,5.052 C52.744,4.14 53.416,3.576 54.376,3.576 C55.228,3.576 55.912,4.08 56.092,5.052 L52.564,5.052 Z" id="Shape" transform="translate(54.310000, 5.556000) rotate(-25.000000) translate(-54.310000, -5.556000) "></path>
                    <path d="M59.84,6.9 C59.324,6.9 58.94,7.308 58.94,7.8 C58.94,8.292 59.348,8.7 59.84,8.7 C60.332,8.7 60.74,8.292 60.74,7.8 C60.74,7.308 60.332,6.9 59.84,6.9 Z"></path>
                </g>
                <g id="Page-Builder" transform="translate(33.000000, 13.000000)" fill="#FFFFFF" fillRule="nonzero">
                    <path d="M4.25942492,0.165745856 C5.13099042,0.165745856 5.88775293,0.308471455 6.52971246,0.593922652 C7.17167199,0.879373849 7.66602769,1.28453039 8.01277955,1.80939227 C8.35953142,2.33425414 8.53290735,2.9558011 8.53290735,3.67403315 C8.53290735,4.38305709 8.35953142,5.00230203 8.01277955,5.53176796 C7.66602769,6.06123389 7.17167199,6.46639042 6.52971246,6.74723757 C5.88775293,7.02808471 5.13099042,7.16850829 4.25942492,7.16850829 L2.27731629,7.16850829 L2.27731629,9.83425414 L2.85384577e-14,9.83425414 L2.85384577e-14,0.165745856 L4.25942492,0.165745856 Z M4.13290735,5.34530387 C4.8170394,5.34530387 5.3371672,5.20027624 5.69329073,4.91022099 C6.04941427,4.62016575 6.22747604,4.20810313 6.22747604,3.67403315 C6.22747604,3.13075506 6.04941427,2.7140884 5.69329073,2.42403315 C5.3371672,2.1339779 4.8170394,1.98895028 4.13290735,1.98895028 L2.27731629,1.98895028 L2.27731629,5.34530387 L4.13290735,5.34530387 Z" id="Shape"></path>
                    <path d="M16.770607,7.76243094 L12.2019169,7.76243094 L11.3303514,9.83425414 L8.99680511,9.83425414 L13.3827476,0.165745856 L15.6319489,0.165745856 L20.0319489,9.83425414 L17.6421725,9.83425414 L16.770607,7.76243094 Z M16.0536741,6.06353591 L14.4932907,2.36187845 L12.9329073,6.06353591 L16.0536741,6.06353591 Z" id="Shape"></path>
                    <path d="M27.7073482,4.8480663 L29.7878594,4.8480663 L29.7878594,8.77071823 C29.2536741,9.16666667 28.6351438,9.47053407 27.9322684,9.68232044 C27.229393,9.89410681 26.5218317,10 25.8095847,10 C24.7880724,10 23.8696486,9.7859116 23.0543131,9.35773481 C22.2389776,8.92955801 21.599361,8.33563536 21.1354633,7.57596685 C20.6715655,6.81629834 20.4396166,5.95764273 20.4396166,5 C20.4396166,4.04235727 20.6715655,3.18370166 21.1354633,2.42403315 C21.599361,1.66436464 22.2436635,1.07044199 23.0683706,0.642265193 C23.8930777,0.214088398 24.8208733,0 25.8517572,0 C26.713951,0 27.4964856,0.142725599 28.199361,0.428176796 C28.9022364,0.713627993 29.4926518,1.12799263 29.970607,1.67127072 L28.5086262,2.99723757 C27.8057508,2.26979742 26.9576145,1.90607735 25.9642173,1.90607735 C25.3363152,1.90607735 24.7787007,2.03499079 24.2913738,2.29281768 C23.8040469,2.55064457 23.4244941,2.91436464 23.1527157,3.3839779 C22.8809372,3.85359116 22.7450479,4.39226519 22.7450479,5 C22.7450479,5.5985267 22.8809372,6.13259669 23.1527157,6.60220994 C23.4244941,7.0718232 23.8017039,7.4378453 24.284345,7.70027624 C24.7669862,7.96270718 25.3175719,8.09392265 25.9361022,8.09392265 C26.5921193,8.09392265 27.1825346,7.9558011 27.7073482,7.67955801 L27.7073482,4.8480663 Z"></path>
                    <polygon points="39.515655 8.03867403 39.515655 9.83425414 31.8964856 9.83425414 31.8964856 0.165745856 39.3329073 0.165745856 39.3329073 1.96132597 34.1597444 1.96132597 34.1597444 4.06077348 38.7284345 4.06077348 38.7284345 5.80110497 34.1597444 5.80110497 34.1597444 8.03867403"></polygon>
                    <path d="M52.5329073,4.80662983 C53.0952077,4.98158379 53.5356763,5.27394107 53.8543131,5.68370166 C54.1729499,6.09346225 54.3322684,6.59760589 54.3322684,7.1961326 C54.3322684,8.04327808 53.9972311,8.69475138 53.3271565,9.15055249 C52.657082,9.60635359 51.6800852,9.83425414 50.3961661,9.83425414 L45.3073482,9.83425414 L45.3073482,0.165745856 L50.115016,0.165745856 C51.31459,0.165745856 52.2353568,0.391344383 52.8773163,0.842541436 C53.5192758,1.29373849 53.8402556,1.90607735 53.8402556,2.67955801 C53.8402556,3.14917127 53.7254526,3.56813996 53.4958466,3.93646409 C53.2662407,4.30478821 52.9452609,4.59484346 52.5329073,4.80662983 Z M47.570607,1.85082873 L47.570607,4.12983425 L49.8338658,4.12983425 C50.3961661,4.12983425 50.8225772,4.03314917 51.113099,3.83977901 C51.4036209,3.64640884 51.5488818,3.36095764 51.5488818,2.98342541 C51.5488818,2.60589319 51.4036209,2.32274401 51.113099,2.1339779 C50.8225772,1.94521179 50.3961661,1.85082873 49.8338658,1.85082873 L47.570607,1.85082873 Z M50.227476,8.14917127 C50.827263,8.14917127 51.2794462,8.05248619 51.5840256,7.85911602 C51.8886049,7.66574586 52.0408946,7.3664825 52.0408946,6.96132597 C52.0408946,6.16022099 51.4364217,5.75966851 50.227476,5.75966851 L47.570607,5.75966851 L47.570607,8.14917127 L50.227476,8.14917127 Z" id="Shape"></path>
                    <path d="M60.4332268,10 C59.027476,10 57.9333333,9.61786372 57.1507987,8.85359116 C56.3682641,8.0893186 55.9769968,6.99815838 55.9769968,5.5801105 L55.9769968,0.165745856 L58.2543131,0.165745856 L58.2543131,5.49723757 C58.2543131,7.22836096 58.9853035,8.09392265 60.4472843,8.09392265 C61.1595314,8.09392265 61.7030884,7.88443831 62.0779553,7.46546961 C62.4528222,7.04650092 62.6402556,6.39042357 62.6402556,5.49723757 L62.6402556,0.165745856 L64.8894569,0.165745856 L64.8894569,5.5801105 C64.8894569,6.99815838 64.4981896,8.0893186 63.715655,8.85359116 C62.9331203,9.61786372 61.8389776,10 60.4332268,10 Z"></path>
                    <polygon points="67.1386581 0.165745856 69.4159744 0.165745856 69.4159744 9.83425414 67.1386581 9.83425414"></polygon>
                    <polygon points="71.7495208 0.165745856 74.0268371 0.165745856 74.0268371 8.01104972 78.9610224 8.01104972 78.9610224 9.83425414 71.7495208 9.83425414"></polygon>
                    <path d="M80.2402556,0.165745856 L84.7105431,0.165745856 C85.7789137,0.165745856 86.7231097,0.366022099 87.543131,0.766574586 C88.3631523,1.16712707 89.000426,1.73112339 89.4549521,2.45856354 C89.9094782,3.18600368 90.1367412,4.03314917 90.1367412,5 C90.1367412,5.96685083 89.9094782,6.81399632 89.4549521,7.54143646 C89.000426,8.26887661 88.3631523,8.83287293 87.543131,9.23342541 C86.7231097,9.6339779 85.7789137,9.83425414 84.7105431,9.83425414 L80.2402556,9.83425414 L80.2402556,0.165745856 Z M84.5980831,7.99723757 C85.5821086,7.99723757 86.3669862,7.72790055 86.9527157,7.18922652 C87.5384452,6.65055249 87.8313099,5.92081031 87.8313099,5 C87.8313099,4.07918969 87.5384452,3.34944751 86.9527157,2.81077348 C86.3669862,2.27209945 85.5821086,2.00276243 84.5980831,2.00276243 L82.5175719,2.00276243 L82.5175719,7.99723757 L84.5980831,7.99723757 Z" id="Shape"></path>
                    <polygon points="99.4709265 8.03867403 99.4709265 9.83425414 91.8517572 9.83425414 91.8517572 0.165745856 99.2881789 0.165745856 99.2881789 1.96132597 94.115016 1.96132597 94.115016 4.06077348 98.6837061 4.06077348 98.6837061 5.80110497 94.115016 5.80110497 94.115016 8.03867403"></polygon>
                    <path d="M107.553994,9.83425414 L105.65623,7.14088398 L105.54377,7.14088398 L103.561661,7.14088398 L103.561661,9.83425414 L101.284345,9.83425414 L101.284345,0.165745856 L105.54377,0.165745856 C106.415335,0.165745856 107.172098,0.308471455 107.814058,0.593922652 C108.456017,0.879373849 108.950373,1.28453039 109.297125,1.80939227 C109.643876,2.33425414 109.817252,2.9558011 109.817252,3.67403315 C109.817252,4.39226519 109.641534,5.01151013 109.290096,5.53176796 C108.938658,6.05202578 108.439617,6.45027624 107.792971,6.72651934 L110,9.83425414 L107.553994,9.83425414 Z M107.511821,3.67403315 C107.511821,3.13075506 107.333759,2.7140884 106.977636,2.42403315 C106.621512,2.1339779 106.101384,1.98895028 105.417252,1.98895028 L103.561661,1.98895028 L103.561661,5.35911602 L105.417252,5.35911602 C106.101384,5.35911602 106.621512,5.21178637 106.977636,4.91712707 C107.333759,4.62246777 107.511821,4.20810313 107.511821,3.67403315 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
);

export default LogoPageBuilder;