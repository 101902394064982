import HttpClient from '../../../_utils/HttpClient/HttpClient';

import User from '../../Domain/User';
import { IUsersList } from '../../Domain/Scaffolding/IUsersList';

export class UsersRepository {
    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    public current = async (): Promise<User> => {
        const response = await this.httpClient.get(this.baseUrl, `/users/current`);
        if (response.data.length === 0) {
            throw new Error();
        }

        return new User(
            response.data.id,
            response.data.email,
            response.data.username,
            response.data.firstname,
            response.data.lastname,
            response.data.role
        );
    }

    public get = async (limit: number = 100, page: number = 0, search: string = ''): Promise<IUsersList> => {
        const response = await this.httpClient.get(this.baseUrl, `/users?limit=${limit}&start=${page}${search}`);
        if (response.data.length === 0) {
            throw new Error();
        }

        const users = response.data.docs.map((user: any) => {

            return new User(
                user.id,
                user.email,
                user.username,
                user.firstname,
                user.lastname,
                user.role
            );
        });

        return {
            docs: users,
            pages: response.data.pages,
            total: response.data.total
        }
    };

    public getUserById = async(id: string): Promise<User> => {
        if (!id) {
            throw new Error();
        }

        const res = await this.httpClient.get(this.baseUrl, `/users/${id}`);

        if (res.data.length === 0) {
            throw new Error();
        }

        return new User(
            res.data.identity.id,
            res.data.identity.email,
            res.data.identity.firstName ? res.data.identity.firstName : '',
            res.data.identity.lastName ? res.data.identity.lastName : '',
            res.data.identity.nickname,
            res.data.identity.code
        );
    };

    public updateUser = async(user: User):Promise<boolean> => {
        if (!user) {
            throw new Error();
        }
        let body = {};

        body['identity'] = user;

        try {
           await this.httpClient.put(this.baseUrl, `/users/${user.id}`, body);
        } catch (e) {
            return false;
        }

        return true;
    };

}