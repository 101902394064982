import { AnyAction } from 'redux';
import ContactRequest from '../../Domain/ContactRequest';

import { 
    contactRequestsGetInitAction, 
    contactRequestsGetRequestLoadingAction, 
    contactRequestsGetRequestSuccessAction, 
    contactRequestsGetRequestFailureAction, 
    ContactRequestsGetRequestFailureAction,
    ContactRequestsGetRequestSuccessAction
} from './get-contact-requests.actions';

export interface GetContactRequestsReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    contactRequests: ContactRequest[],
    nbrPages: number,
    total: number
}

const initialGetContactRequestsReduxState: GetContactRequestsReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: "",
    contactRequests: [],
    nbrPages: 0,
    total: 0
}

export const GetContactRequestsReducer = (state: GetContactRequestsReduxState = initialGetContactRequestsReduxState, action: AnyAction): GetContactRequestsReduxState => {
    switch(action.type) {
        case contactRequestsGetInitAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                contactRequests: []
            };

        case contactRequestsGetRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                contactRequests: [],
                errorMessage: ""
            }

        case contactRequestsGetRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                contactRequests: (action as ContactRequestsGetRequestSuccessAction).payload.contactRequests,
                nbrPages: (action as ContactRequestsGetRequestSuccessAction).payload.nbrPages,
                total: (action as ContactRequestsGetRequestSuccessAction).payload.total,
                errorMessage: ""
            }
        case contactRequestsGetRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                contactRequests: [],
                errorMessage: (action as ContactRequestsGetRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}