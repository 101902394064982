import React from 'react';

const IconPageStyleLarge: React.ReactElement = (
    <svg viewBox="0 0 100 100" version="1.1">
        <rect fillOpacity="0.05" x="0" y="0" width="100" height="100"></rect>
        <rect fillOpacity="0.1" x="0" y="34" width="100" height="66"></rect>
        <rect fillOpacity="0.05" x="0" y="54" width="100" height="1"></rect>
        <rect fillOpacity="0.05" x="0" y="76" width="100" height="1"></rect>
        <rect fillOpacity="0.05" x="25" y="15" width="50" height="4"></rect>
    </svg>
);

export default IconPageStyleLarge;