import cuid from 'cuid';

import { IComponentTwoColumnsData } from './IComponentTwoColumnsData';

import PageContent from '../../../../Domain/PageContent';
import ComponentTypeEnum from '../../../../Domain/Enum/ComponentTypeEnum';
import ComponentThemeTypeEnum from '../../../../Domain/Enum/ComponentThemeTypeEnum';

import initialComponentDefaultData from '../InitialComponentDefaultData';

export const initialComponentTwoColumnsData: IComponentTwoColumnsData = {
    firstColumn: [
        new PageContent(
            cuid(),
            ComponentTypeEnum.Text,
            1,
            {
                content: '<p>Sample text</p>',
                background: ComponentThemeTypeEnum.Default,
                displayOnDesktop: true,
                displayOnMobile: true
            },
            null
        )
    ],
    secondColumn: [],
    ...initialComponentDefaultData
}