import React, { useState } from 'react';
import { useAsync } from 'react-use';
import { toast } from 'react-toastify';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import File from '../Domain/File';
import Directory from '../Domain/Directory';
import { DirectoryEnum } from '../Domain/DirectoryEnum';

import DisplayFile from '../Infrastructure/Partials/DisplayFile';
import DisplayFolder from '../Infrastructure/Partials/DisplayFolder';
import LoadingSpinner from '../../App/Infrastructure/Style/Svg/LoadingSpinner';
import FilesFromDirectoryRepository from '../Infrastructure/Repositories/FilesFromDirectoryRepository';

import IconGalery from '../../App/Infrastructure/Style/Svg/IconGalery';
import IconArrowUp from '../../App/Infrastructure/Style/Svg/IconArrowUp';
import IconHomeSimple from '../../App/Infrastructure/Style/Svg/IconHomeSimple';
import IconRowHorizontal from '../../App/Infrastructure/Style/Svg/IconRowHorizontal';

const filesFromDirectoryRepository: FilesFromDirectoryRepository = new FilesFromDirectoryRepository();

const GetFiles: React.FunctionComponent = (): React.ReactElement => {
    const [path, setPath] = useState<string>(DirectoryEnum.BASE_DIRECTORY_PATH);
    const [listView, setListView] = useState<boolean>(false);
    const [filesCollection, setFilesCollection] = useState<Directory>();

    const state = useAsync(async () => {
        const response = await filesFromDirectoryRepository.getFilesFromDirectory(`${path}`)
            .then((collection: Directory) => {
                setFilesCollection(collection);
            }, () => {
                toast.error(<LocaleMessage id="app.something_went_wrong" />);
            });

        return response;
    }, [path]);

    const handleChangePath = (newPath: string): void => {
        setPath(newPath);
    }

    const handleBackButton = (): void => {
        if (path === DirectoryEnum.BASE_DIRECTORY_PATH) {

            return void(0);
        }

        const pathRTrim = path.substring(path.length -1, path.length) === '/' ? path.substring(0, path.length - 1) : path;
        const pathSplitted: string[] = pathRTrim.split('/');
        pathSplitted.pop();

        const newPath = `${ pathSplitted.join('/') }/`;

        handleChangePath(newPath);
    }

    const renderDirectories = (): React.ReactElement[] => {
        if (filesCollection === undefined) {
            return [];
        }

        const elements: React.ReactElement[] = [];

        filesCollection.directories.forEach((directory: Directory, index: number) => {
            elements.push(<DisplayFolder folderName={ directory.folderName } path={ directory.path } onDoubleClick={(newPath) => handleChangePath(newPath)} key={ index } />);
        });

        return elements;
    }

    const renderFiles = (): React.ReactElement[] => {
        if (filesCollection === undefined) {
            return [];
        }

        const elements: React.ReactElement[] = [];

        filesCollection.files.forEach((file: File, index: number) => {
            elements.push(<DisplayFile fileName={ file.fileName } fileExtension={ file.fileExtension } fileSize={ file.fileSize } path={ file.path } key={ index } />);
        });

        return elements;
    }

    return (
        <div className="file-manager">
            <div className="file-manager--toolbar">
                <div>
                    <button type="button" className={ `button is-small ${ path === DirectoryEnum.BASE_DIRECTORY_PATH ? 'is-disabled' : '' }` } onClick={() => handleBackButton()}>
                        { IconArrowUp }
                    </button>
                    <button type="button" className={ `button is-small ${ path === DirectoryEnum.BASE_DIRECTORY_PATH ? 'is-disabled' : '' }` } onClick={() => path !== DirectoryEnum.BASE_DIRECTORY_PATH ? handleChangePath(DirectoryEnum.BASE_DIRECTORY_PATH) : void(0) }>
                        { IconHomeSimple }
                    </button>
                </div>
                <div>
                    <button type="button" className="button is-small" onClick={() => setListView(!listView) }>
                        { listView ? IconGalery : IconRowHorizontal }
                    </button>
                </div>
            </div>
            <div className={ `file-manager--list ${ listView ? 'is-list-view' : '' }` }>
                { state.loading && <div className="icons is-loading">{ LoadingSpinner }</div> }
                { state.error && <div><LocaleMessage id="app.something_went_wrong" /></div> }
                { !state.loading && renderDirectories() }
                { !state.loading && renderFiles() }
            </div>
        </div>
    );
}

export default GetFiles;