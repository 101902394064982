import React from 'react';

const IconSettingsHeight: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M15 7.59504L12.51 5.10504C12.22 4.81504 11.73 4.81504 11.44 5.10504L8.95001 7.59504M11.9702 4.99496L11.9702 8.99496" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M11.9702 14.995L11.9702 18.995M15 16.3954L12.51 18.8854C12.22 19.1754 11.73 19.1754 11.44 18.8854L8.95001 16.3954" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default IconSettingsHeight;