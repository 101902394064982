class Theme {

    public primaryColor: string;
    public secondaryColor: string;
    public textColor: string;
    public textHeadersColor: string;
    public headerVariant: string;
    public headerColor: string;
    public headerTextColor: string;
    public headerLogoPosition: string;
    public headerBackgroundBlur: boolean;
    public footerColor: string;

    public constructor(
        primaryColor: string,
        secondaryColor: string,
        textColor: string,
        textHeadersColor: string,
        headerVariant: string,
        headerLogoPosition: string,
        headerTextColor: string,
        headerColor: string,
        headerBackgroundBlur: boolean,
        footerColor: string
    ) {
        this.primaryColor = primaryColor;
        this.secondaryColor = secondaryColor;
        this.textColor = textColor;
        this.textHeadersColor = textHeadersColor;
        this.headerVariant = headerVariant;
        this.headerLogoPosition = headerLogoPosition;
        this.headerTextColor = headerTextColor;
        this.headerColor = headerColor;
        this.headerBackgroundBlur = headerBackgroundBlur;
        this.footerColor = footerColor;
    }

}

export default Theme;