import { AnyAction } from 'redux';

import Settings from '../../../Domain/Settings';

import { 
    settingsGetInitAction, 
    settingsGetRequestLoadingAction, 
    settingsGetRequestSuccessAction, 
    settingsGetRequestFailureAction, 
    SettingsGetRequestFailureAction,
    SettingsGetRequestSuccessAction
} from './get-settings.actions';

export interface GetSettingsReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    settings: Settings | null
}

const initialGetSettingsReduxState: GetSettingsReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: "",
    settings: null
}

export const GetSettingsReducer = (state: GetSettingsReduxState = initialGetSettingsReduxState, action: AnyAction): GetSettingsReduxState => {
    switch(action.type) {
        case settingsGetInitAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                settings: null
            };

        case settingsGetRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                settings: null,
                errorMessage: ""
            };

        case settingsGetRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                settings: (action as SettingsGetRequestSuccessAction).payload.settings,
                errorMessage: ""
            };

        case settingsGetRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                settings: null,
                errorMessage: (action as SettingsGetRequestFailureAction).payload.errorMessage
            };

        default:
            return state;
    }
}