import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { authHeader } from './_helpers/auth-header';

import AuthService from '../../Auth/Infrastructure/Redux/auth.services';

class HttpClient {

    static refreshTokenPromise: Promise<void> | undefined = undefined;

    public constructor() {
        this.config();
    }

    public config = () => {
        axios.interceptors.response.use((response: AxiosResponse) => {
            return response;
        }, async (error: AxiosError) => {
            const { config, response } = error;

            if (response?.status === 401) {
                const authService: AuthService = new AuthService();

                if (!HttpClient.refreshTokenPromise) {
                    HttpClient.refreshTokenPromise = authService.refreshToken();
                }
                
                await HttpClient.refreshTokenPromise;
                HttpClient.refreshTokenPromise = undefined;
                config.headers['Authorization'] = authHeader();

                return axios(config);
            } else {
                throw error;
            }
        });
    }

    public get = (baseUrl: string, url: string, options?: AxiosRequestConfig) => {
        const httpOptions: AxiosRequestConfig = Object.assign(
            {
                method: 'GET',
                headers: {
                    'Authorization': authHeader()
                }
            },
            options
        );

        return axios.get(baseUrl + url, httpOptions);
    };

    public post = (baseUrl: string, url: string, data: any, options?: AxiosRequestConfig) => {
        const httpOptions: AxiosRequestConfig = Object.assign(
            {
                method: 'POST',
                headers: {
                    'Authorization': authHeader(),
                    'Content-Type': "multipart/form-data"
                }
            },
            options
        );

        return axios.post(baseUrl + url, data, httpOptions);
    };

    public put = (baseUrl: string, url: string, data: any, options?: AxiosRequestConfig) => {
        const httpOptions: AxiosRequestConfig = Object.assign(
            {
                method: 'PUT',
                headers: {
                    'Authorization': authHeader(),
                    'Content-Type': "multipart/form-data"
                }
            },
            options
        );

        return axios.put(baseUrl + url, data, httpOptions);
    };

    public delete = (baseUrl: string, url: string, options?: AxiosRequestConfig) => {
        const httpOptions: AxiosRequestConfig = Object.assign(
            {
                method: 'DELETE',
                headers: {
                    'Authorization': authHeader()
                }
            },
            options
        );

        return axios.delete(baseUrl + url, httpOptions);
    };

}

export default HttpClient;