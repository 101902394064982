import React from 'react';
import { useSelector } from 'react-redux';

import { Footer } from '../Partials/Footer';
import { Header } from '../Partials/Header';
import { appThemeModeSelector } from '../Redux/App/app.selectors';

const AdminLayout: React.FunctionComponent = ({ children }): React.ReactElement => {
    const mode = useSelector(appThemeModeSelector);

    return (
        <React.Fragment>
            <Header />
            <section className={ `content is-${ mode }` }>
                { children }
            </section>
            <Footer />
        </React.Fragment>
    );
}

export default AdminLayout;