import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { LocaleDate, LocaleTime } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import Page from '../Domain/Page';
import Pagination from '../../App/Infrastructure/Partials/Pagination';
import SearchFilters from '../../_utils/SearchFilters/SearchFilters';
import LoadingSpinner from '../../App/Infrastructure/Style/Svg/LoadingSpinner';

import { pagesGetService } from '../Infrastructure/Redux/getpages.services';
import { modalAddActionCreator } from '../../_utils/Modal/Redux/modal.actions';
import DeletePageModalController from '../Infrastructure/Pages/DeletePageModalController';
import { pagesGetRequestLoadingActionCreator } from '../Infrastructure/Redux/getpages.actions';
import { searchFiltersQuerySelector } from '../../_utils/SearchFilters/Redux/searchFilters.selectors';
import { getPagesIsRequestFailureSelector, getPagesIsRequestLoadingSelector, pagesNbrPageSelector, pagesSelector, pagesTotalSelector } from '../Infrastructure/Redux/getpages.selectors';

interface IGetPagesProps {
    actualPage: number
}

const GetPages: React.FunctionComponent<IGetPagesProps> = ({ actualPage }): React.ReactElement => {
    const history   = useHistory();
    const dispatch  = useDispatch();
    const pages     = useSelector(pagesSelector);
    const nbrPages  = useSelector(pagesNbrPageSelector);
    const total     = useSelector(pagesTotalSelector);
    const query     = useSelector(searchFiltersQuerySelector);
    const isLoading = useSelector(getPagesIsRequestLoadingSelector);
    const isInError = useSelector(getPagesIsRequestFailureSelector);

    useEffect(() => {
        if (isLoading) {
            pagesGetService(actualPage, query);
        }
    }, [isLoading, actualPage, query]);

    useEffect(() => {
        dispatch(pagesGetRequestLoadingActionCreator());
    }, [dispatch, actualPage, query]);

    const displayDeletePageModal = (pageId: number): void => {
        dispatch(
            modalAddActionCreator(
                <DeletePageModalController pageId={ pageId } />,
                'modales.confirmation.title'
            )
        )
    }

    const renderPagination = (): React.ReactElement | null => {
        if (!isInError && !isLoading) {
            return <Pagination baseUrl="/pages" pages={ nbrPages } activePage={ actualPage }/>;
        }

        return null;
    }

    const renderHeadTable = (): React.ReactElement => {

        return (
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Page title</th>
                    <th>Created date</th>
                    <th>Created by</th>
                    <th>Modified date</th>
                    <th>Modified by</th>
                    <th className="is-actions-column">Actions</th>
                </tr>
            </thead>
        )
    }

    const renderBody = (): React.ReactElement => {
        if (isLoading) {

            return (
                <tr>
                    <td colSpan={ 7 }>
                        <div className="icons is-loading">
                            { LoadingSpinner }
                        </div>
                    </td>
                </tr>
            );
        }

        if (isInError) {

            return (
                <tr>
                    <td colSpan={ 7 }>
                        An error has occured, please refresh
                    </td>
                </tr>
            );
        }

        if (pages !== null) {

            return (
                <React.Fragment>
                    {
                        pages.map((page: Page) => {
                            return (
                                <tr data-testid="users--user-item" key={ page.id }>
                                    <td>
                                        { page.id }
                                    </td>
                                    <td>
                                        { page.title }
                                    </td>
                                    <td>
                                        <LocaleDate value={ page.createdDate } day="numeric" weekday="long" month="short" year="numeric" /> à <LocaleTime value={ page.createdDate } />
                                    </td>
                                    <td>
                                        <span className="badge">{ `${page.createdBy}` }</span>
                                    </td>
                                    <td>
                                        { page.modifiedDate !== null && <React.Fragment><LocaleDate value={ page.modifiedDate } day="numeric" weekday="long" month="short" year="numeric" /> à <LocaleTime value={ page.modifiedDate } /></React.Fragment> }
                                    </td>
                                    <td>
                                        { page.modifiedBy !== null && <span className="badge">{ `${page.modifiedBy}` }</span> }
                                    </td>
                                    <td>
                                        <div className="actions-buttons">
                                            <button type="button" className="button is-small" onClick={ () => history.push(`/pages/${page.id}/edit`)}>
                                                <i className="material-icons">edit</i>
                                            </button>
                                            <button type="button" className="button is-small is-primary" onClick={ () => history.push(`/pages/${page.id}/contents`) }>
                                                <i className="material-icons">settings</i>
                                            </button>
                                            <button type="button" className="button is-small is-red" onClick={ () => displayDeletePageModal(page.id) }>
                                                <i className="material-icons">delete</i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })
                    }
                </React.Fragment>
            );
        }

        return <React.Fragment>lol</React.Fragment>;
    }

    return (
        <React.Fragment>
            <div className="portlet">
                <div className="portlet--body">
                    <SearchFilters availableTypes={ ['title', 'createdBy'] } />
                    <div className="table-responsive">

                        <table className="has-row-details">
                            { renderHeadTable() }
                            <tbody>
                                { renderBody() }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="page--header">
                { renderPagination() }
                <div>{ total } page(s)</div>
            </div>
        </React.Fragment>
    );
}

export default GetPages;