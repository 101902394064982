import React from 'react';

const IconAccordion: React.ReactElement = (
    <svg viewBox="0 0 22 22" version="1.1">
        <g strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g transform="translate(-1.000000, -1.000000)" strokeWidth="1.2">
                <g transform="translate(2.000000, 2.000000)">
                    <path d="M20,13 L20,7 C20,2 18,0 13,0 L7,0 C2,0 0,2 0,7 L0,13 C0,18 2,20 7,20 L13,20 C18,20 20,18 20,13 Z" opacity="0.4"></path>
                    <line x1="19" y1="9.5" x2="1" y2="9.5" opacity="0.4"></line>
                    <line x1="16.5" y1="13.5" x2="3.5" y2="13.5"></line>
                    <line x1="9.5" y1="15.5" x2="3.5" y2="15.5" id="Path-Copy"></line>
                    <line x1="9" y1="5" x2="3.5" y2="5"></line>
                    <polyline points="16.5907 3.5 14.0307 6.06 11.47071 3.5" opacity="0.4"></polyline>
                </g>
            </g>
        </g>
    </svg>
);

export default IconAccordion;