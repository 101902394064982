import {AnyAction} from 'redux';
import {IModal} from '../IModal';

import {modalAddAction, ModalAddAction} from './modal.actions';
import {modalOpenAction, ModalOpenAction} from './modal.actions';
import {modalRemoveAction} from './modal.actions';
import {modalCloseAllAction} from './modal.actions';

export interface ModalReduxState {
    modales: IModal[]
}

const initialModalReduxState: ModalReduxState = {
    modales: []
}

export const modalReducer = (state: ModalReduxState = initialModalReduxState, action: AnyAction) => {
    switch (action.type) {

        case modalOpenAction:
            return {
                ...state,
                modales: [
                    {
                        title: (action as ModalOpenAction).payload.title,
                        body: (action as ModalOpenAction).payload.body,
                        options: (action as ModalOpenAction).payload.options
                    }
                ]
            }

        case modalAddAction:
            const modales: IModal[] = [ ...state.modales ];
            modales.push(
                {
                    title: (action as ModalAddAction).payload.title,
                    body: (action as ModalAddAction).payload.body,
                    options: (action as ModalAddAction).payload.options
                }
            );
            return {
                ...state,
                modales: modales
            }

        case modalRemoveAction:
            const oldModales = [ ...state.modales ];
            oldModales.pop();
            return {
                ...state,
                modales: oldModales
            };

        case modalCloseAllAction:
            return {
                modales: []
            }

        default:
            return state;
    }
}