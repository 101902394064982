import React from 'react';

const IconComponents: React.ReactElement = (
    <svg viewBox="0 0 24 24" version="1.1" >
        <path d="M12.0000726,24.0000447 C10.6928058,24.0000447 9.47489121,23.4972498 8.55868711,22.5810457 L1.41897669,15.4413576 C0.502772588,14.5251535 0,13.2960992 0,12 C0,10.703912 0.502772588,9.47485769 1.41897669,8.55865359 L8.55868711,1.41895434 C9.47489121,0.502750242 10.703979,0 12.0000726,0 C13.2961662,0 14.5251088,0.502750242 15.4413129,1.41895434 L22.5811127,8.55865359 C23.4973168,9.47485769 24,10.703912 24,12 C24,13.2960992 23.4973168,14.5251535 22.5811127,15.4413576 L15.4413129,22.5810457 C14.5251088,23.4972498 13.3073395,24.0000447 12.0000726,24.0000447 Z M12.0000726,1.65361433 C11.1397346,1.65361433 10.3352627,1.9888333 9.74304851,2.581014 L2.60333809,9.7206462 C1.99998417,10.3240225 1.67598312,11.1284944 1.67598312,11.9776591 C1.67598312,12.8268239 2.01115739,13.642469 2.60333809,14.2346497 L9.74304851,21.3743378 C10.9386167,22.5698724 13.0614169,22.5698724 14.2569515,21.3743378 L21.3967513,14.2346497 C22.0001052,13.6312958 22.3240169,12.8379971 22.3240169,11.9776591 C22.3240169,11.1173211 21.988932,10.3128493 21.3967513,9.7206462 L14.2569515,2.581014 C13.6647708,1.9888333 12.8604106,1.65361433 12.0000726,1.65361433 Z"></path>
        <path d="M18.4243394,19.2514259 C18.2120482,19.2514259 17.999757,19.1732133 17.8321587,19.005615 L4.98289892,6.1564223 C4.65887552,5.8323989 4.65887552,5.29601726 4.98289892,4.97199386 C5.30692232,4.64797045 5.84323692,4.64797045 6.16726032,4.97199386 L19.0165201,17.8212536 C19.3405435,18.145277 19.3405435,18.6815916 19.0165201,19.005615 C18.8489218,19.1732133 18.6366306,19.2514259 18.4243394,19.2514259 Z"></path>
        <path d="M5.57507962,19.2514259 C5.36278843,19.2514259 5.15049723,19.1732133 4.98289892,19.005615 C4.65887552,18.6815916 4.65887552,18.145277 4.98289892,17.8212536 L17.8321587,4.97199386 C18.1561821,4.64797045 18.6924967,4.64797045 19.0165201,4.97199386 C19.3405435,5.29601726 19.3405435,5.8323989 19.0165201,6.1564223 L6.16726032,19.005615 C5.99966201,19.1732133 5.78737082,19.2514259 5.57507962,19.2514259 Z"></path>
    </svg>
);

export default IconComponents;