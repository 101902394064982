import React from 'react';
import { useDispatch } from 'react-redux';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { modalAddActionCreator } from '../../_utils/Modal/Redux/modal.actions';
import ConfirmationEmptyCacheModal from '../Infrastructure/Pages/ConfirmationEmptyCacheModal';

const GetCacheInformation: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();

    const handleDisplayEmptyCacheModal = (): void => {
        dispatch(
            modalAddActionCreator(
                <ConfirmationEmptyCacheModal />,
                'medias.empty_cache_modal.title'
            )
        );
    }

    return (
        <div className="portlet">
            <div className="portlet--title">
                <div className="portlet--title-label">
                    <LocaleMessage id="medias.navigation.empty_cache" />
                </div>
            </div>

            <div className="portlet--body">

                <button type="button" className="button is-red" onClick={() => handleDisplayEmptyCacheModal() }>
                    Vider le cache des images
                </button>

            </div>
        </div>
    );
}

export default GetCacheInformation;