import initialComponentDefaultData from '../InitialComponentDefaultData';
import { IComponentSectionsHeroSectionData } from './IComponentSectionsHeroSectionData';

export const initialComponentSectionsHeroSectionData: IComponentSectionsHeroSectionData = {
    title: '',
    subTitle: '',
    displayLogoBrand: 'no',
    align: 'left',
    buttonLabel: 'Button',
    url: '1-accueil',
    minHeightDesktop: 90,
    minHeightMobile: 90,
    buttonStyle: 'normal',
    ...initialComponentDefaultData
}