import { store } from '../../../store';

import { IPagesList } from '../../Domain/Scaffolding/IPagesList';
import PagesRepository from '../Repositories/PagesRepository';

import { pagesGetRequestFailureActionCreator, pagesGetRequestSuccessActionCreator } from './getpages.actions';

const pagesRepository: PagesRepository = new PagesRepository();

export const pagesGetService = (page: number, query: string): void => {
    pagesRepository.get(page, query)
        .then((response: IPagesList) => {

            store.dispatch(pagesGetRequestSuccessActionCreator(response.docs, response.pages, response.total));
        }, (error: any) => {

            store.dispatch(pagesGetRequestFailureActionCreator(error.response.data.message));
        });
}