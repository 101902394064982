import { AppReduxState } from '../../../store';

export const searchFiltersTagsSelector = (state: AppReduxState) => state.searchFilters.tags;
export const searchFiltersQuerySelector = (state: AppReduxState): string => {
    const tags = state.searchFilters.tags;
    
    if (tags.length > 0) {
        const urlParams = new URLSearchParams();
        for(let item of tags) {
            urlParams.append(item.type, item.value);
        }

        return urlParams.toString();
    } else {
        return '';
    }
}