import React from 'react';

interface IHandleComponentTextSettingsProps {
    onChange: (inputName: string, value: string) => void
}

const HandleComponentTextSettings: React.FunctionComponent<IHandleComponentTextSettingsProps> = ({ onChange }): React.ReactElement => {

    return <React.Fragment></React.Fragment>;
}

export default HandleComponentTextSettings;