import { store } from '../../../../store';
import IDataSettings from '../../../Application/Forms/Settings/IDataSettings';

import SettingsRepository from '../../Repositories/SettingsRepository';

import { settingsPutRequestFailureActionCreator, settingsPutRequestSuccessActionCreator } from './put-settings.actions';

const settingsRepository: SettingsRepository = new SettingsRepository();
export const putSettingsService = (data: IDataSettings): void => {

    settingsRepository.put(data)
        .then(() => {

            store.dispatch(settingsPutRequestSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(settingsPutRequestFailureActionCreator(error.response.data.message, error.response.data.errors));
        });

}