import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { FilterTag } from './Redux/searchFilters.reducer';
import { searchFiltersTagsSelector } from './Redux/searchFilters.selectors';
import { searchFiltersRemoveTagActionCreator, searchFiltersAddTagActionCreator } from './Redux/searchFilters.actions';
import IconClose from '../../App/Infrastructure/Style/Svg/IconClose';

interface ISearchFiltersProps {
    availableTypes: string[]
}

const SearchFilters: React.FunctionComponent<ISearchFiltersProps> = ({ availableTypes }): React.ReactElement => {
    const dispatch = useDispatch();
    const searchFiltersTags = useSelector(searchFiltersTagsSelector);

    const [searchType, setSearchType] = useState<string>(availableTypes[0]);
    const [searchText, setSearchText] = useState<string>('');
    const [searchedItems, setSearchedItems] = useState<FilterTag[]>([]);

    useEffect(() => {
        setSearchedItems(searchFiltersTags);
    }, [searchFiltersTags]);

    const handleRemoveItemFromSearchDispatch = (type: string) => {
        dispatch(searchFiltersRemoveTagActionCreator(type));
    }

    const handleAddItemFromSearchDispatch = () => {
        if (searchType !== '' && searchText !== '') {
            dispatch(searchFiltersAddTagActionCreator(searchType, searchText));
            setSearchText('');
        }
    }

    const handleChangeSearchText = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.currentTarget.value;

        setSearchText(value);
    }

    const handleChangeSearchType = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const value = event.target.value;

        setSearchType(value);
    }

    const renderItemsSearch = (): React.ReactElement[] => {

        return searchedItems.map((item: FilterTag, index: number) => {

            return (
                <div className="search--input-item" key={ index }>
                    <div className="search--input-item-info">
                        <div className="search--input-item-info-type">
                            { item.type }
                        </div>
                        <div className="search--input-item-info-value">
                            « { item.value } »
                        </div>
                    </div>
                    <div className="search--input-item-close">
                        <div className="search--input-item-close-icon" onClick={ () => { handleRemoveItemFromSearchDispatch(item.type) }}>
                            { IconClose }
                        </div>
                    </div>
                </div>
            );
        });
    }

    return (
        <React.Fragment>
            <form className="form--group-input search--input" onSubmit={(event) => { event.preventDefault() }}>
                <div className="form--group-select">
                    <select onChange={ (event) => handleChangeSearchType(event) }>
                        { availableTypes.map((value: string, index: number) => {

                            return <option value={ value } key={ index }>{ value }</option>;
                        }) }
                    </select>
                </div>
                <input type="text" value={ searchText } placeholder="Search" onChange={ (event) => handleChangeSearchText(event) } />
                <button className="button is-primary" onClick={() => { handleAddItemFromSearchDispatch() }}>
                    search
                </button>
            </form>
            <div className="search--input-items">
                { searchedItems.length !== 0 && renderItemsSearch() }
            </div>
        </React.Fragment>
    );
}

export default SearchFilters;