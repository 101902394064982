import { AnyAction } from 'redux';
import Page from '../../Domain/Page';

import { 
    pageGetInitAction, 
    pageGetRequestLoadingAction, 
    pageGetRequestSuccessAction, 
    pageGetRequestFailureAction, 
    PageGetRequestFailureAction,
    PageGetRequestSuccessAction
} from './get.actions';

export interface GetPageReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    page: Page | null
}

const initialGetPageReduxState: GetPageReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: "",
    page: null
}

export const GetPageReducer = (state: GetPageReduxState = initialGetPageReduxState, action: AnyAction): GetPageReduxState => {
    switch(action.type) {
        case pageGetInitAction:
            return {
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                page: null
            };

        case pageGetRequestLoadingAction:
            return {
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                page: null,
                errorMessage: ""
            }

        case pageGetRequestSuccessAction:
            return {
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                page: (action as PageGetRequestSuccessAction).payload.page,
                errorMessage: ""
            }
        case pageGetRequestFailureAction:
            return {
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                page: null,
                errorMessage: (action as PageGetRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}