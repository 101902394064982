import cuid from 'cuid';
import { store } from '../../../store';
import { toast } from 'react-toastify';

import PageContent from '../../Domain/PageContent';
import ComponentTypeEnum from '../../Domain/Enum/ComponentTypeEnum';

import { IComponentData } from '../../Application/Forms/ComponentType/IComponentData';
import { initialComponentTextData } from '../../Application/Forms/ComponentType/Texts/InitialComponentTextData';
import { initialComponentGalleryData } from '../../Application/Forms/ComponentType/ImagesAndMedias/InitialComponentGalleryData';
import { initialComponentSlideshowData } from '../../Application/Forms/ComponentType/ImagesAndMedias/InitialComponentSlideshowData';
import { initialComponentAccordionData } from '../../Application/Forms/ComponentType/Texts/InitialComponentAccordionData';
import { initialComponentTwoColumnsData } from '../../Application/Forms/ComponentType/Layout/InitialComponentTwoColumnsData';
import { initialComponentContactRequestData } from '../../Application/Forms/ComponentType/ContactForms/InitialComponentContactRequestData';
import { pageBuilderAddNewPageContentActionCreator, pageBuilderReorderPageContentActionCreator } from './page-builder.actions';
import { initialComponentSectionsCallToActionData } from '../../Application/Forms/ComponentType/Sections/InitialComponentSectionsCallToActionData';
import { initialComponentSectionsHeroSectionData } from '../../Application/Forms/ComponentType/Sections/initialComponentSectionsHeroSectionData';

export const pageBuilderOnDragEndService = (): void => {
    const { result, pageContentItems } = store.getState().pageBuilder;

    if (result !== null) {
        if (!result.destination) {

            return;
        }

        // multiple columns specificty
        if ((result.destination.droppableId === 'first-two-column-drop' && result.draggableId === ComponentTypeEnum.LayoutTwoColumns) ||
            (result.destination.droppableId === 'first-two-column-drop' && result.draggableId === ComponentTypeEnum.LayoutThreeColumns) ||
            (result.destination.droppableId === 'second-two-column-drop' && result.draggableId === ComponentTypeEnum.LayoutThreeColumns) ||
            (result.destination.droppableId === 'second-two-column-drop' && result.draggableId === ComponentTypeEnum.LayoutThreeColumns)) {
            toast.error("Can't drop a layout component inside another layout component");
            
            return;
        }

        if (result.source.droppableId === result.destination.droppableId) {
            const ids = reorderPageContentsService(pageContentItems.ids, result.source.index, result.destination.index);
            store.dispatch(pageBuilderReorderPageContentActionCreator(ids));

            return;
        }

        if (result.source.droppableId !== result.destination.droppableId) {
            const uid = cuid();

            let initialData = null;
            switch (result.draggableId) {
                case ComponentTypeEnum.LayoutTwoColumns:
                    initialData = initialComponentTwoColumnsData;
                    break;

                case ComponentTypeEnum.Text:
                    initialData = initialComponentTextData;
                    break;

                case ComponentTypeEnum.Accordion:
                    initialData = initialComponentAccordionData;
                    break;

                case ComponentTypeEnum.Slideshow:
                    initialData = initialComponentSlideshowData;
                    break;

                case ComponentTypeEnum.Gallery:
                    initialData = initialComponentGalleryData;
                    break;

                case ComponentTypeEnum.ContactRequests:
                    initialData = initialComponentContactRequestData;
                    break;

                case ComponentTypeEnum.sectionsCallToAction:
                    initialData = initialComponentSectionsCallToActionData;
                    break;

                case ComponentTypeEnum.sectionsHeroSection:
                    initialData = initialComponentSectionsHeroSectionData;
                    break;
            }

            const newPageContent = new PageContent(
                uid,
                result.draggableId as ComponentTypeEnum,
                result.destination.index,
                initialData as IComponentData<string|IComponentData<string>[]>,
                null
            );

            store.dispatch(pageBuilderAddNewPageContentActionCreator(uid, result.destination.index, newPageContent));
        }
    }
}

const reorderPageContentsService = (pageContentIds: string[], sourceIndex: number, destinationIndex: number): string[] => {
    const result = Array.from(pageContentIds);
    
    const [removed] = result.splice(sourceIndex, 1);
    result.splice(destinationIndex, 0, removed);

    return result;
}