import React from 'react';
import slugify from 'slugify';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import DisplayImage from '../Image/DisplayImage';
import FormValidationMessage from './FormValidationMessage';
import IconClose from '../../App/Infrastructure/Style/Svg/IconClose';
import IconWarning from '../../App/Infrastructure/Style/Svg/IconWarning';

interface IFileInputProps {
    label: string,
    inputName: string,
    onFileChange: (name: string, value: File) => void,
    inputValue?: string,
    errorMessage?: string,
    placeholder?: string,
    disabled?: boolean,
    additionalClassname?: string,
    additionalInformation?: string
}

const FileInput: React.FunctionComponent<IFileInputProps> = ({ label, inputName, inputValue, errorMessage, placeholder, onFileChange, disabled, additionalClassname, additionalInformation }): React.ReactElement => {

    const handleOnFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, files } = event.currentTarget;

        if(files !== null) {
            onFileChange(name, files[0]);
        }
    }

    const renderImage = (): React.ReactElement => {

        if (inputValue !== undefined && inputValue !== "") {
            const baseUrl = process.env.REACT_APP_AWS_CLOUDFRONT_BASE_URL !== "" ? process.env.REACT_APP_AWS_CLOUDFRONT_BASE_URL : process.env.REACT_APP_URL;

            return (
                <React.Fragment>
                    <div className="pages--images">
                        <div className="pages--images-picture">
                            <DisplayImage filename={ `${ inputValue }` } width={ 64 } height={ 64 } />
                        </div>
                        <div className="pages--images-info">
                            { `${ baseUrl }${ inputValue }` }
                        </div>
                        <div className="pages--images-delete">
                            { IconClose }
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return <React.Fragment></React.Fragment>;
    }

    return (
        <div className={ `input ${ additionalClassname !== undefined ? additionalClassname : '' }` }>
            <div className="input--container">
                <label htmlFor={ slugify(inputName) }>
                    <LocaleMessage id={ label } />
                </label>
                <div className="input--fileinput">
                    <input 
                        type="file"
                        name={ inputName }
                        onChange={(event) => handleOnFileChange(event)}
                        placeholder={ placeholder !== undefined ? placeholder : '' }
                        disabled={ disabled !== undefined ? disabled : false }
                        />
                </div>
            </div>
            { errorMessage !== undefined && <FormValidationMessage message={ errorMessage } /> }

            { renderImage() }

            { additionalInformation !== undefined && <blockquote className="input--additional-information">{ IconWarning }&nbsp;<span dangerouslySetInnerHTML={{ __html: String(additionalInformation) }}></span></blockquote> }
        </div>
    )
}

export default FileInput;