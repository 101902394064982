import React from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

// [Infrastructure] Icons
import IconHandle from '../../App/Infrastructure/Style/Svg/IconHandle';

interface ISimpleOrdonnableList {
    droppableId: string,
    draggableId: string,
    type: string,
    elementsList: React.ReactElement[],
    elementsListData: any[],
    onChangedElementsListData: (elementsList: any[]) => void
}

const SimpleOrdonnableList: React.FunctionComponent<ISimpleOrdonnableList> = ({ droppableId, draggableId, type, elementsList, elementsListData, onChangedElementsListData }): React.ReactElement => {
    const handleDragEnd = (result: DropResult): void => {
        if (result !== null) {
            if (!result.destination) {
                return;
            }

            if (result.source.droppableId !== result.destination.droppableId) {
                return;
            }

            if (result.source.droppableId === result.destination.droppableId) {
                const [removed] = elementsListData.splice(result.source.index, 1);
                elementsListData.splice(result.destination.index, 0, removed);

                onChangedElementsListData(elementsListData);
            }
        }
    }

    const handleDeleteElement = (indexToDelete: number): void => {
        const newElements: any[] = [];

        elementsListData.forEach((element: any, index: number) => {
            if (index !== indexToDelete) {
                newElements.push(element);
            }
        });

        onChangedElementsListData(newElements);
    }

    const renderElementsList = (): React.ReactElement[] => {
        if (elementsList.length === 0) {

            return [<React.Fragment key={ 0 }></React.Fragment>];
        }

        return elementsList.map((element: React.ReactElement, index: number) => {

            return (
                <Draggable draggableId={ `${draggableId}-${index}` } index={ index } key={ `${draggableId}-${index}` }>
                    {(provided) => (
                        <div {...provided.draggableProps} ref={provided.innerRef} className="beautifuldnd--item">
                            <div className="portlet is-compact">
                                <div className="portlet--title">
                                    <div className="portlet--title-label"  {...provided.dragHandleProps}>
                                        { IconHandle } Elément #{index + 1}
                                    </div>

                                    <div className="portlet--title-toolbar">
                                        <button type="button" className="button is-small is-red" onClick={ () => handleDeleteElement(index) }>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                </div>

                                <div className="portlet--body">
                                    { element }
                                </div>
                            </div>
                        </div>
                    )}
                </Draggable>
            );
        });
    }

    return (
        <React.Fragment>
            <DragDropContext onDragEnd={(result: DropResult) => handleDragEnd(result)}>
                <Droppable droppableId={ droppableId } direction="vertical" type={ type }>
                    {(provided) => 
                        <div ref={ provided.innerRef }>
                            { renderElementsList() }
                            { provided.placeholder }
                        </div>
                    }
                </Droppable>
            </DragDropContext>
        </React.Fragment>
    );
}

export default SimpleOrdonnableList;