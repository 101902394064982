import React from 'react';
import { useSelector } from 'react-redux';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { GetLocales } from '../../App/Application/GetLocales';
import IconComponentSettings from '../Infrastructure/Style/svg/IconComponentSettings';
import { pageBuilderPageContentSelectedSelector } from '../Infrastructure/Redux/page-builder.selectors';
import HandleComponentSettings from './Forms/HandleComponentSettings';
import IconComponents from '../Infrastructure/Style/svg/IconComponents';

const GetComponentSettings: React.FunctionComponent = (): React.ReactElement => {
    const pageContentSelected = useSelector(pageBuilderPageContentSelectedSelector);

    const renderComponentSettings = (): React.ReactElement => {
        if (pageContentSelected !== null) {

            return <HandleComponentSettings pageContentId={ pageContentSelected } />;
        }

        return (
            <div className="page-builder--settings-please-select">
                <div className="page-builder--settings-please-select-icon">
                    { IconComponents }
                </div>
                <p><LocaleMessage id="page_builder.settings.please_select" /></p>
            </div>
        );
    }

    return (
        <div className="page-builder--settings">
            <div className="page-builder--settings-title">
                { IconComponentSettings }&nbsp;<LocaleMessage id="page_builder.settings.title" />
            </div>

            { renderComponentSettings() }

            <div className="page-builder--settings-locales">
                <GetLocales />
            </div>
        </div>
    );
}

export default GetComponentSettings;