import React from 'react';
import { useSelector } from 'react-redux';
import { LocaleMessage } from '../Redux/Locale/Provider/LocaleMessage';

import HandleLogin from '../../Application/Forms/HandleLogin';
import IconBrand from '../Style/Svg/LogoBrand';
import { GetLocales } from '../../Application/GetLocales';
import { authIsRequestFailureSelector } from '../../../Auth/Infrastructure/Redux/auth.selectors';

const LoginController: React.FunctionComponent = () => {
    const isLoginFailed = useSelector(authIsRequestFailureSelector);

    const renderLoginCard = (): React.ReactElement => {

        return (
            <div className={ `login--main-card ${ isLoginFailed ? 'is-shaking' : '' }`}>
                <div className="login--main-card-brand">
                    { IconBrand }
                </div>
                <div className="login--main-card-title">
                    <h1><LocaleMessage id="login.welcome.title" /></h1>
                    <h2><LocaleMessage id="login.enter_credentials.title" /></h2>
                </div>

                <HandleLogin />
            </div>
        );
    }

    return (
        <React.Fragment>
            <div className="login">
                <div className="login--main">
                    { renderLoginCard() }
                    <div className="login--main-background">
                        <video autoPlay loop muted playsInline>
                            <source src={ `${process.env.PUBLIC_URL}/video/video-bg-light.mp4` } type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className="login--bottom">
                    <GetLocales />
                </div>
            </div>
        </React.Fragment>
    )
}

export { LoginController };