import React from 'react';
import { useParams } from 'react-router';

import { HandleUser } from '../../Application/Forms/HandleUser';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';
import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';

interface EditUsersControllerParams {
    id: string
}

export const EditUsersController: React.FunctionComponent = (): React.ReactElement => {
    const { id } = useParams<EditUsersControllerParams>();

    const breadcrumb: IBreadcrumbItem[] = [
        {
            title: 'Users',
            url: '/users'
        },
        {
            title: 'Edit user',
            url: null
        }
    ];

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumb } />
            <div className="list-page">
                <div className="grid-wrapper">
                    <div className="list-page--header">
                        <h2>User edit</h2>
                    </div>
                    <HandleUser _id={ id }/>
                </div>
            </div>
        </React.Fragment>
    );
};