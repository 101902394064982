import { store } from '../../../store';

import AuthToken from '../../Domain/AuthToken';
import AuthRepository from '../Repositories/AuthRepository';

import { authRequestFailureActionCreator, authRequestSuccessActionCreator, authLogOutCreator } from './auth.actions';

class AuthService {
    private authRepository: AuthRepository = new AuthRepository();

    public authServiceAuthenticate = (email: string, password: string): void => {
        this.authRepository.login(email, password)
            .then((authToken: AuthToken) => {
                localStorage.setItem('accessToken', authToken.accessToken);
                localStorage.setItem('refreshToken', authToken.refreshToken);
                localStorage.setItem('typeToken', authToken.typeToken);

                store.dispatch(authRequestSuccessActionCreator());
            }, (error: any) => {
                let errorMessage = error.response.data.error;

                if (errorMessage === 'invalid_grant') {
                    errorMessage = 'auth.login_validator.credentials_not_found';
                }

                store.dispatch(authRequestFailureActionCreator(errorMessage));
            });
    }

    public refreshToken = async () => {
        const refreshToken = localStorage.getItem('refreshToken');

        try {
            const authToken = await this.authRepository.refreshToken(refreshToken as string)
            localStorage.setItem('accessToken', authToken.accessToken);
            localStorage.setItem('refreshToken', authToken.refreshToken);
            localStorage.setItem('typeToken', authToken.typeToken);
        } catch (error) {
            this.authServiceLogout();
            throw error;
        }
    }

    public authServiceLogout = (): void => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('typeToken');

        store.dispatch(authLogOutCreator());
    }
}

export default AuthService;