import React from 'react';

interface IDisplayImageProps {
    filename: string,
    width: number,
    height?: number,
    extension?: 'png' | 'jpeg'
}

const DisplayImage: React.FunctionComponent<IDisplayImageProps> = ({ filename, width, height, extension = 'jpeg' }): React.ReactElement => {

    const renderImageBasedOnExtension = (): React.ReactElement => {
        const extension = filename.split('.')[1];

        switch (extension) {
            case 'svg':
                return renderSvg();
        
            default:
                return renderImage();
        }
    }

    const renderImage = (): React.ReactElement => {
        const size = height !== undefined ? `w_${ width },h_${ height }` : `w_${ width }`;

        return <img src={ `${ process.env.REACT_APP_URL }/image-resizer/${ size },c_1,q_85,o_${ extension }/${ process.env.REACT_APP_URL }${ filename }` } alt="" />
    }

    const renderSvg = (): React.ReactElement => {

        if (process.env.REACT_APP_AWS_CLOUDFRONT_BASE_URL !== "") {

            return <img src={ `${ process.env.REACT_APP_AWS_CLOUDFRONT_BASE_URL }${filename}` } alt="image" />;
        }

        return <img src={ `${ process.env.REACT_APP_URL }${filename}` } alt="image" />;
    }

    return (
        <React.Fragment>
            { renderImageBasedOnExtension() }
        </React.Fragment>
    );
}

export default DisplayImage;