import HttpClient from '../../../_utils/HttpClient/HttpClient';

import NavigationPage from '../../Domain/NavigationPage';
import NavigationPagesCollection from '../../Domain/NavigationPagesCollection';

class NavigationPagesRepository {
    
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);
    private httpClient: HttpClient = new HttpClient();

    public get = async (): Promise<NavigationPagesCollection> => {
        const response = await this.httpClient.get(this.baseUrl, `/navigation/pages`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No navigation pages found');
        }

        const navigationPagesCollection = new NavigationPagesCollection();

        response.data.docs.forEach((navigationPage: any) => {
            navigationPagesCollection.push(
                new NavigationPage(
                    navigationPage.id,
                    navigationPage.title
                )
            )
        });

        return navigationPagesCollection;
    }
}

export default NavigationPagesRepository;