import React from 'react';

const IconEditor: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <path d="M21.8095 3.94037C20.2695 7.78037 16.4095 13.0004 13.1795 15.5904L11.2095 17.1704C10.9595 17.3504 10.7095 17.5104 10.4295 17.6204C10.4295 17.4404 10.4195 17.2404 10.3895 17.0504C10.2795 16.2104 9.89953 15.4304 9.22953 14.7604C8.54953 14.0804 7.71953 13.6804 6.86953 13.5704C6.66953 13.5604 6.46953 13.5404 6.26953 13.5604C6.37953 13.2504 6.54953 12.9604 6.75953 12.7204L8.31953 10.7504C10.8995 7.52037 16.1395 3.64037 19.9695 2.11037C20.5595 1.89037 21.1295 2.05037 21.4895 2.42037C21.8695 2.79037 22.0495 3.36037 21.8095 3.94037Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M10.4293 17.6203C10.4293 18.7203 10.0093 19.7703 9.2193 20.5703C8.6093 21.1803 7.7793 21.6003 6.7893 21.7303L4.3293 22.0003C2.9893 22.1503 1.8393 21.0103 1.9993 19.6503L2.2693 17.1903C2.5093 15.0003 4.3393 13.6003 6.2793 13.5603C6.4793 13.5503 6.6893 13.5603 6.8793 13.5703C7.7293 13.6803 8.5593 14.0703 9.2393 14.7603C9.9093 15.4303 10.2893 16.2103 10.3993 17.0503C10.4093 17.2403 10.4293 17.4303 10.4293 17.6203Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M14.2398 14.4702C14.2398 11.8602 12.1198 9.74023 9.50977 9.74023" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M20.1188 12.73L20.8588 13.46C22.3488 14.95 22.3488 16.42 20.8588 17.91L17.8987 20.87C16.4287 22.34 14.9388 22.34 13.4688 20.87" strokeWidth="1.5" strokeLinecap="round" opacity="0.4"></path>
        <path d="M3.10836 10.5101C1.63836 9.02006 1.63836 7.55006 3.10836 6.06006L6.06836 3.10006C7.53836 1.63006 9.02836 1.63006 10.4984 3.10006L11.2384 3.84006" strokeWidth="1.5" strokeLinecap="round" opacity="0.4"></path>
        <path d="M11.2488 3.8501L7.54883 7.5501" strokeWidth="1.5" strokeLinecap="round" opacity="0.4"></path>
        <path d="M20.1202 12.73L17.1602 15.68" strokeWidth="1.5" strokeLinecap="round" opacity="0.4"></path>
    </svg>
);

export default IconEditor;