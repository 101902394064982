import {Action} from 'redux';

export const appOpenMenuAction = '@@app/OPEN_MENU';
export const appCloseMenuAction = '@@app/CLOSE_MENU';
export const appDarkModeAction = '@@app/DARK_MODE';
export const appBlackModeAction = '@@app/BLACK_MODE';
export const appLightModeAction = '@@app/LIGHT_MODE';

export interface AppOpenMenuAction extends Action<typeof appOpenMenuAction> {
    payload: {
        menuOpened: boolean
    }
}

export interface AppCloseMenuAction extends Action<typeof appCloseMenuAction> {
    payload: {
        menuOpened: boolean
    }
}

export interface AppBlackModeAction extends Action<typeof appBlackModeAction> {
    payload: {
        theme: string
    }
}

export interface AppDarkModeAction extends Action<typeof appDarkModeAction> {
    payload: {
        theme: string
    }
}

export interface AppLightModeAction extends Action<typeof appLightModeAction> {
    payload: {
        theme: string
    }
}

export const appOpenMenuActionCreator = (): AppOpenMenuAction => ({
    type: appOpenMenuAction,
    payload: {
        menuOpened: true
    }
});

export const appCloseMenuActionCreator = (): AppCloseMenuAction => ({
    type: appCloseMenuAction,
    payload: {
        menuOpened: false
    }
});

export const appBlackModeActionCreator = (): AppBlackModeAction => ({
    type: appBlackModeAction,
    payload: {
        theme: 'black-mode'
    }
});

export const appDarkModeActionCreator = (): AppDarkModeAction => ({
    type: appDarkModeAction,
    payload: {
        theme: 'dark-mode'
    }
});

export const appLightModeActionCreator = (): AppLightModeAction => ({
    type: appLightModeAction,
    payload: {
        theme: 'light-mode'
    }
});