import React from 'react';
import { useDispatch } from 'react-redux';
import { LocaleMessage } from '../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IconWarning from '../../../../App/Infrastructure/Style/Svg/IconWarning';
import { modalCloseAllActionCreator } from '../../../../_utils/Modal/Redux/modal.actions';
import { pageBuilderDeletePageContentActionCreator, pageBuilderSelectPageContentActionCreator } from '../../Redux/page-builder.actions';

interface IDeletePageContentModalProps {
    pageContentId: string
}

const DeletePageContentModal: React.FunctionComponent<IDeletePageContentModalProps> = ({ pageContentId }): React.ReactElement => {
    const dispatch = useDispatch();

    const handleDeletePageContentModal = (): void => {
        dispatch(pageBuilderDeletePageContentActionCreator(pageContentId));
        dispatch(pageBuilderSelectPageContentActionCreator(null));
        handleCloseModal();
    }

    const handleCloseModal = (): void => {
        dispatch(modalCloseAllActionCreator());
    }

    return (
        <div className="modal--confirmation">
            <div className="modal--icon">{ IconWarning }</div>
            <div className="modal--information">
                <LocaleMessage id="page_builder.page_contents.delete_page_content_modal.content" />
            </div>

            <div className="modal--actions">
                <button className="button is-primary" onClick={()=>handleDeletePageContentModal()}>
                    <LocaleMessage id="page_builder.page_contents.delete_page_content_modal.confirm_button" />
                </button>
                <button className="button is-primary is-transparent" onClick={()=>handleCloseModal()}>
                    <LocaleMessage id="modales.confirmation.cancel" />
                </button>
            </div>
        </div>
    );
}

export default DeletePageContentModal;