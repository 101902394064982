import React from 'react';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../../../../../store';

import PageContent from '../../../../Domain/PageContent';
import { pageBuilderPageContentSelector } from '../../../Redux/page-builder.selectors';
import { IComponentSectionsCallToActionData } from '../../../../Application/Forms/ComponentType/Sections/IComponentSectionsCallToActionData';

interface IDisplayComponentSectionsCallToActionProps {
    id: string
}

const DisplayComponentSectionsCallToAction: React.FunctionComponent<IDisplayComponentSectionsCallToActionProps> = ({ id }): React.ReactElement => {
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, id));
    const data: IComponentSectionsCallToActionData = pageContent.data as IComponentSectionsCallToActionData;

    return (
        <React.Fragment>
            <div className="page-builder--web-sections-call-to-action-title" dangerouslySetInnerHTML={{ __html: data.title }}></div>
            <div className="page-builder--web-sections-call-to-action-btn">
                <button type="button" className="button">{ data.buttonLabel }</button>
            </div>
        </React.Fragment>
    );
}

export default DisplayComponentSectionsCallToAction;