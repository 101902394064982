import Component from './Component';

class ComponentCategory {

    public label: string;
    public slug: string;
    public components: Component[];

    public constructor(label: string, slug: string, components: Component[]) {
        this.label = label;
        this.slug = slug;
        this.components = components;
    }
}

export default ComponentCategory;