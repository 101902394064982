import React from 'react';
import { Droppable } from 'react-beautiful-dnd';

import PageBuilderColumns from '../Domain/Enum/PageBuilderColumnsEnum';

const ComponentsDroppableColumn: React.FunctionComponent = ({ children }): React.ReactElement => {

    return (
        <Droppable droppableId={ PageBuilderColumns.ColumnComponents } direction="horizontal" type="list" isDropDisabled={ true }>
            {(provided) =>
                <div className="page-builder--components-list" ref={ provided.innerRef }>
                    { children }
                    { provided.placeholder }
                </div>
            }
        </Droppable>
    );
}

export default ComponentsDroppableColumn;