import { AnyAction } from 'redux';
import PagesCollection from '../../Domain/PagesCollection';

import { 
    pagesGetRequestLoadingAction, 
    pagesGetRequestSuccessAction, 
    PagesGetRequestSuccessAction,
    pagesGetRequestFailureAction, 
    PagesGetRequestFailureAction
} from './getpages.actions';

export interface GetPagesReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    pages: PagesCollection | null,
    nbrPages: number,
    total: number,
    errorMessage: string
}

const initialGetPagesReduxState: GetPagesReduxState = {
    isRequestLoading: true,
    isRequestSuccess: false,
    isRequestFailure: false,
    pages: null,
    nbrPages: 1,
    total: 1,
    errorMessage: ""
}

export const GetPagesReducer = (state: GetPagesReduxState = initialGetPagesReduxState, action: AnyAction): GetPagesReduxState => {
    switch(action.type) {
        case pagesGetRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false
            }

        case pagesGetRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                pages: (action as PagesGetRequestSuccessAction).payload.pages
            }
        case pagesGetRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                errorMessage: (action as PagesGetRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}