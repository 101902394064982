import React from 'react';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IconWarning from '../../App/Infrastructure/Style/Svg/IconWarning';

interface IFormValidationMessageProps {
    message: string
}
const FormValidationMessage: React.FunctionComponent<IFormValidationMessageProps> = ({ message }): React.ReactElement => {

    return (
        <div className="form--validations-error">
            { IconWarning } <LocaleMessage id={ message } />
        </div>
    );
}

export default FormValidationMessage;