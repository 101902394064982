import React, { useMemo } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { AppReduxState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContent from '../../../../Domain/PageContent';

import { IComponentData } from '../IComponentData';
import { IComponentTextData } from './IComponentTextData';
import { initialComponentTextData } from './InitialComponentTextData';

import { localeSelector } from '../../../../../App/Infrastructure/Redux/Locale/locale.selectors';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';
import { tinyMCEUploadImageHandlerService } from '../../../../Infrastructure/Redux/uploadimage.services';
import { pageBuilderUpdatePageContentDataActionCreator } from '../../../../Infrastructure/Redux/page-builder.actions';

interface IHandleComponentTextProps {
    pageContentId: string
}

const HandleComponentText: React.FunctionComponent<IHandleComponentTextProps> = ({ pageContentId }): React.ReactElement => {
    const dispatch = useDispatch();
    const locale = useSelector(localeSelector);
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentTextData = pageContent !== undefined ? pageContent.data as IComponentTextData : initialComponentTextData;

    const handleOnEditorChange = (value: string): void => {
        setNewData('content', value);
    }

    const setNewData = (name: string, value: string): void => {
        dispatch(
            pageBuilderUpdatePageContentDataActionCreator(
                pageContentId,
                {
                    ...data as IComponentData<string|boolean|IComponentData<string|undefined>[]>,
                    [name]: value
                }
            )
        );
    }

    const { content } = useMemo(() => data, [data]);
    const initEditorParams = useMemo(() => ({
        base_url: "/tinymce",
        skin: "oxide-dark",
        menubar: "file edit table help",
        block_formats: 'Paragraph=p; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Heading 6=h6; Blockquote=blockquote',
        language: locale.replace('-', '_'),
        images_upload_handler: async (blob: any, success: any, failure: any) => tinyMCEUploadImageHandlerService(blob, success, failure),
        templates: [
            {
                title: "Block 600px responsive",
                description: "Bloc de texte centrée, d'une largeur de 600 pixels mais responsive en mobile",
                content: '<div class="wysiwyg--block-600">Lorem ipsum dolor sit amet...</div>'
            }
        ]
    }), [locale]);

    return (
        <React.Fragment>
            <div className={ `form--group` }>
                <label htmlFor={ `tinymce-editor-text-${ pageContentId }` }>
                    <LocaleMessage id="page_builder.component-text-type.content.label" />
                </label>
                <div className="form--group-input">
                    <div className={ `page-builder--content-inline-wysiwyg ${ data.background === 'dark' && 'is-dark' }` }>
                        { /* @ts-ignore*/ }
                        <Editor
                            //@ts-ignore
                            init={ initEditorParams }
                            id={ `tinymce-editor-text-${ pageContentId }` }
                            plugins="template fullscreen wordcount link codesample image table lists help "
                            toolbar="undo redo | formatselect | bold italic underline strikethrough | template code link | alignleft aligncenter alignright alignjustify | image | numlist bullist | removeformat | fullscreen"
                            onEditorChange={ (value) => handleOnEditorChange(value) }
                            value={ String(content) }
                            tinymceScriptSrc={ "/tinymce/tinymce.min.js" }
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HandleComponentText;