import React from 'react';

import Swiper from 'react-id-swiper';
import { SwiperOptions } from 'swiper';

const GetPopularGames: React.FunctionComponent = (): React.ReactElement => {
    const swiperParams: SwiperOptions = {
        slidesPerView: 4,
        spaceBetween: 20,
        touchAngle: 20,
        navigation: {
            prevEl: '.tournament--match-roundsbattle-rounds-button-prev',
            nextEl: '.tournament--match-roundsbattle-rounds-button-next'
        }
    }

    const renderPopularGameList = (): React.ReactElement[] => {

        return [...Array(7)].map((index:number) => {

            return (
                <div key={ index }>
                    <div className="card">
                        <div className="card--content">
                            <div className="card--content-subtitle">Popular Game</div>
                            <div className="card--content-title">Call of Duty: Modern Warfare</div>
                        </div>
                        <div className="card--icon">
                            <img src="/images/icon-valorant.png" alt="Valorant" />
                        </div>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="games--popular">
            <Swiper { ...swiperParams }>
                { renderPopularGameList() }
            </Swiper>
        </div>
    )
}

export default GetPopularGames;