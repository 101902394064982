import React from 'react';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../../../../../store';

import AppSettings from '../../../../Domain/AppSettings';
import PageContent from '../../../../Domain/PageContent';

import DisplayImage from '../../../../../_utils/Image/DisplayImage';
import { pageBuilderAppSettingsSelector } from '../../../Redux/page-builder-get.selectors';
import { pageBuilderModeEditorSelector, pageBuilderPageContentSelector } from '../../../Redux/page-builder.selectors';
import { IComponentSectionsHeroSectionData } from '../../../../Application/Forms/ComponentType/Sections/IComponentSectionsHeroSectionData';

interface IDisplayComponentSectionsHeroSectionProps {
    id: string
}

const DisplayComponentSectionsHeroSection: React.FunctionComponent<IDisplayComponentSectionsHeroSectionProps> = ({ id }): React.ReactElement => {
    const settings: AppSettings = useSelector(pageBuilderAppSettingsSelector);
    const viewport = useSelector(pageBuilderModeEditorSelector);
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, id));
    const data: IComponentSectionsHeroSectionData = pageContent.data as IComponentSectionsHeroSectionData;

    const heroSectionMinHeight = viewport === 'desktop' ? data.minHeightDesktop : data.minHeightMobile;

    const renderLogoBrand = (): React.ReactElement => {

        return (
            <div className="page-builder--web-sections-hero-section-logo">
                <DisplayImage filename={ settings.siteLogo } width={ 160 } extension="png" />
            </div>
        );
    }

    return (
        <div
            className="page-builder--web-sections-hero-section-height"
            style={
                {
                    minHeight: `${ heroSectionMinHeight !== undefined ? heroSectionMinHeight : 90 }vh`
                }
            }
            >
            <div className={ `page-builder--web-sections-hero-section-container is-${ data.align }-aligned` }>
                { data.displayLogoBrand === 'yes' && renderLogoBrand() }
                <h1 className="page-builder--web-sections-hero-section-title" dangerouslySetInnerHTML={{ __html: data.title }}></h1>
                <div className="page-builder--web-sections-hero-section-subtitle" dangerouslySetInnerHTML={{ __html: data.subTitle }}></div>

                {
                    data.buttonLabel !== '' &&
                    <div className="page-builder--web-sections-hero-section-btn">
                        <button type="button" className={ `button is-${ data.buttonStyle }`}>{ data.buttonLabel }</button>
                    </div>
                }
            </div>
        </div>
    );
}

export default DisplayComponentSectionsHeroSection;