import React from 'react';
import { CreateGames } from '../../Application/Forms/CreateGames';

export const CreateGamesController: React.FunctionComponent = (): React.ReactElement => {
    return (
        <div className="games--create">
            <div className="grid-wrapper">
                <CreateGames/>
            </div>
        </div>
    );
};