import React from 'react';

const HandleComingSoon: React.FunctionComponent = (): React.ReactElement => {

    return (
        <div className="badge is-error">
            Component not developped yet. Coming soon...
        </div>
    );
}

export default HandleComingSoon;