import ComponentTypeEnum from './Enum/ComponentTypeEnum';
import { IComponentData } from '../Application/Forms/ComponentType/IComponentData';

class PageContent {

    public id: string;
    public type: ComponentTypeEnum;
    public position: number;
    public data: IComponentData<string | number | boolean | IComponentData<string|undefined>[]>;
    public databaseId: number | null;

    public constructor (id: string, type: ComponentTypeEnum, position: number, data: IComponentData<string | number | boolean | IComponentData<string>[]>, databaseId: number | null) {
        this.id = id;
        this.type = type;
        this.position = position;
        this.data = data;
        this.databaseId = databaseId;
    }

}

export default PageContent;