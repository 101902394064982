import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { AppReduxState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContent from '../../../../Domain/PageContent';
import { IComponentData } from '../IComponentData';
import IComponentContactRequestData from './IComponentContactRequestData';

import IComponentContactRequestFieldData from './IComponentContactRequestFieldData';
import IComponentContactRequestSubjectData from './IComponentContactRequestSubjectData';
import { pageBuilderUpdatePageContentDataActionCreator } from '../../../../Infrastructure/Redux/page-builder.actions';

import FormGroup from '../../../../../_utils/Forms/FormGroup';
import SimpleOrdonnableList from '../../../../../_utils/DragAndDrop/SimpleOrdonnableList';
import ContactRequestFieldTypeEnum from './Enum/ContactRequestFieldTypeEnum';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';

interface IHandleComponentContactRequestProps {
    pageContentId: string
}

const HandleComponentContactRequest: React.FunctionComponent<IHandleComponentContactRequestProps> = ({ pageContentId }): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentContactRequestData = pageContent.data as IComponentContactRequestData;
    const { subjects } = useMemo(() => data, [data]);
    const { fields } = useMemo(() => data, [data]);

    const setNewData = (name: string, value: IComponentContactRequestSubjectData[] | IComponentContactRequestFieldData[]): void => {
        dispatch(
            pageBuilderUpdatePageContentDataActionCreator(
                pageContentId,
                {
                    ...data as IComponentData<string|boolean|IComponentData<string|undefined>[]>,
                    [name]: value
                }
            )
        );
    }

    const handleOnSubjectChange = (name: string, value: string, index: number): void => {
        const elements: IComponentContactRequestSubjectData[] = subjects as IComponentContactRequestSubjectData[];

        if(elements[index][name] !== undefined) {
            elements[index][name] = value;
        } else {
            const newElement = value;

            elements[index][name] = newElement;
        }

        setNewData('subjects', elements);
    }

    const handleOnFieldChange = (name: string, value: string, index: number): void => {
        const elements: IComponentContactRequestFieldData[] = fields as IComponentContactRequestFieldData[];

        if(elements[index][name] !== undefined) {
            elements[index][name] = value;
        } else {
            const newElement = value;

            elements[index][name] = newElement;
        }

        setNewData('fields', elements);
    }

    const handleAddNewSubject = () => {
        const elements = subjects as IComponentContactRequestSubjectData[];

        elements.push(
            {
                subject: "",
                contactEmail: ""
            }
        );

        setNewData('subjects', elements);
    }

    const handleAddNewField = () => {
        const elements = fields as IComponentContactRequestFieldData[];

        elements.push(
            {
                inputType: ContactRequestFieldTypeEnum.Text,
                labelName: "",
                inputPlaceholder: ""
            }
        );

        setNewData('fields', elements);
    }

    const generateSubjectsList = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        const sujectEmailInfoMessage = intl.formatMessage({id: 'page_builder.component-contact-requests-type.subjects_email.info'}, {
            a: (...chunks) => `<a href="/contact-requests/settings" target="_blank">${chunks}</a>`,
        });

        const subjectData = subjects as IComponentContactRequestSubjectData[];
        subjectData.forEach((subject: IComponentContactRequestSubjectData, index: number) => {
            elements.push(
                <React.Fragment key={ index }>
                    <FormGroup>
                        <React.Fragment>
                            <label><LocaleMessage id="page_builder.component-contact-requests-type.subjects_subject.label" /></label>
                            <input type="text" name="subject" value={ subject.subject } onChange={(event) => handleOnSubjectChange('subject', event.currentTarget.value, index)} />
                        </React.Fragment>
                    </FormGroup>

                    <FormGroup>
                        <React.Fragment>
                            <label><LocaleMessage id="page_builder.component-contact-requests-type.subjects_email.label" /></label>
                            <div className="form--group-input">
                                <input type="text" name="contactEmail" value={ subject.contactEmail } onChange={(event) => handleOnSubjectChange('contactEmail', event.currentTarget.value, index)} />
                                <blockquote dangerouslySetInnerHTML={{ __html : sujectEmailInfoMessage}}></blockquote>
                            </div>
                        </React.Fragment>
                    </FormGroup>
                </React.Fragment>
            );
        });

        return elements;
    }

    const generateFieldsList = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        const fieldData = fields as IComponentContactRequestFieldData[];

        fieldData.forEach((field: IComponentContactRequestFieldData, index: number) => {
            elements.push(
                <React.Fragment key={ index }>
                    <div className={ `form--group` }>
                        <label><LocaleMessage id="page_builder.component-contact-requests-type.fields_input_type.label" /></label>
                        <div className="form--group-select">
                            <select name="inputType" value={ field.inputType } onChange={(event) => handleOnFieldChange('inputType', event.currentTarget.value, index)}>
                                <option value={ContactRequestFieldTypeEnum.Text }>{ContactRequestFieldTypeEnum.Text }</option>
                                <option value={ContactRequestFieldTypeEnum.Email }>{ContactRequestFieldTypeEnum.Email }</option>
                                <option value={ContactRequestFieldTypeEnum.Textarea }>{ContactRequestFieldTypeEnum.Textarea }</option>
                            </select>
                        </div>
                    </div>

                    <FormGroup>
                        <React.Fragment>
                            <label><LocaleMessage id="page_builder.component-contact-requests-type.fields_label_name.label" /></label>
                            <input type="text" name="labelName" value={ field.labelName } onChange={(event) => handleOnFieldChange('labelName', event.currentTarget.value, index)} />
                        </React.Fragment>
                    </FormGroup>

                    <FormGroup>
                        <React.Fragment>
                            <label><LocaleMessage id="page_builder.component-contact-requests-type.fields_input_placeholder.label" /></label>
                            <input type="text" name="inputPlaceholder" value={ field.inputPlaceholder } onChange={(event) => handleOnFieldChange('inputPlaceholder', event.currentTarget.value, index)} />
                        </React.Fragment>
                    </FormGroup>
                </React.Fragment>
            );
        });

        return elements;
    }

    return (
        <div className="page-builder--content-contact-requests">
            <div className={ `form--group` }>
                <label htmlFor="">
                    <LocaleMessage id="page_builder.component-contact-requests-type.subjects.label" /> :
                </label>
                <div className="form--group-input">
                    <SimpleOrdonnableList
                        type="contact-requests-subjects"
                        droppableId={ `contact-requests-subjects-${ pageContentId }` }
                        draggableId={ `contact-requests-subjects-item-${ pageContentId }` }
                        elementsList={ generateSubjectsList() }
                        elementsListData={ subjects as IComponentContactRequestSubjectData[] }
                        onChangedElementsListData={(elementsListData) => setNewData('subjects', elementsListData as IComponentContactRequestSubjectData[])} 
                        />
                    <div className="page-builder--content-contact-requests-add-form">
                        <button type="button" className="button is-small is-primary" onClick={ handleAddNewSubject }>
                            <LocaleMessage id="page_builder.component-contact-requests-type.items_group.add_button_label" />
                        </button>
                    </div>
                </div>
            </div>

            <div className={ `form--group` }>
                <label htmlFor="">
                    <LocaleMessage id="page_builder.component-contact-requests-type.fields.label" /> :
                </label>
                <div className="form--group-input">
                    <SimpleOrdonnableList
                        type="contact-requests-fields"
                        droppableId={ `contact-requests-fields-${ pageContentId }` }
                        draggableId={ `contact-requests-fields-item-${ pageContentId }` }
                        elementsList={ generateFieldsList() }
                        elementsListData={ fields as IComponentContactRequestFieldData[] }
                        onChangedElementsListData={(elementsListData) => setNewData('fields', elementsListData as IComponentContactRequestFieldData[])} 
                        />

                    <div className="page-builder--content-contact-requests-add-form">
                        <button type="button" className="button is-small is-primary" onClick={ handleAddNewField }>
                            <LocaleMessage id="page_builder.component-contact-requests-type.items_group.add_button_label" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HandleComponentContactRequest;