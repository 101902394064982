import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import Game from '../../Domain/Game';

import { GamesRepository } from '../../Infrastructure/Repositories/GamesRepository';
import { modalCloseAllActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { localeSelector } from '../../../App/Infrastructure/Redux/Locale/locale.selectors';

const gamesRepository: GamesRepository = new GamesRepository();

enum EditGamesTabs {
    INFO = 'info',
    TRAINING = 'training',
    TOURNAMENT = 'tournament',
    MEDIAS = 'medias',
}

export const EditGames: React.FunctionComponent<any> = ({ _id }): React.ReactElement => {
    const locale = useSelector(localeSelector);
    const dispatch = useDispatch();
    const [tab, setTab] = useState<EditGamesTabs>(EditGamesTabs.INFO);
    const [game, setGame] = useState<Game>();
    const [edit, setEdit] = useState<boolean>(false);

    useEffect( () => {
        getGameById(_id);
    }, [_id]);

    const getGameById = (_id: string) => {
        gamesRepository.getGameById(_id)
            .then((res: Game) => {
               setGame(res);
            });
    };

    const onChange = (event: any, optionalArgument: any = undefined): void => {
        if (game) {
            let { name, value } = event.currentTarget;
            setEdit(true);
            if (optionalArgument !== undefined) {
               value = optionalArgument;
            }
            setGame(Object.assign({}, game, {
                [name]: value,
            }));
        }
    };

    const saveGame = async () => {
        if (game) {
            const res = await gamesRepository.updateGame(game);
            if (res) {
                setEdit(false);
                toast.success('Game Saved');
                dispatch(modalCloseAllActionCreator());
            } else {
                toast.error('Game not saved');
            }
            getGameById(_id);
        }
    };

    const renderSaveGameButton = (): React.ReactElement | null => {
        if (!edit) {
            return null;
        }

        return (
            <button className="button is-primary" onClick={ () => { saveGame() }}>
                Save
            </button>
        );
    };

    const renderGameInfo = (): React.ReactElement => {
        if (!game) {
            return (
                <div>
                    No game found
                </div>
            );
        }

        return (
            <>
                <div className="row">
                    <div className="form--group col-4 align-items-center">
                        <label htmlFor="_id">_id</label>
                        <div>{ game._id }</div>
                    </div>
                    <div className="form--group col-4">
                        <label htmlFor="isActive">Active</label>
                        <div className="form--group-input">
                            <input id="isActive" type="checkbox" name="active" checked={ game.active } onChange={(event) => { onChange(event, !game.active)} } />
                        </div>
                    </div>
                    <div className="form--group col-4">
                        <label htmlFor="displayHolding">Display on holding</label>
                        <div className="form--group-input">
                            <input id="displayHolding" type="checkbox" name="displayHolding" checked={ game.displayHolding } onChange={(event) => { onChange(event, !game.displayHolding)} } />
                        </div>
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="name">Name</label>
                    <div className="form--group-input">
                        <input id="name" name="name" type="text" value={game.name} onChange={ (event) => { onChange(event) }}/>
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="value">Value</label>
                    <div className="form--group-input">
                        <input id="value" name="value" type="text" value={game.value} onChange={ (event) => { onChange(event) }}/>
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="slug">Slug</label>
                    <div className="form--group-input">
                        <input id="slug" name="slug" type="text" value={game.slug} onChange={ (event) => { onChange(event) }}/>
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="description">Description</label>
                    <div className="form--group-input">
                        <textarea rows={ 3 } id="description" name="description" value={game.description[locale] ? game.description[locale] : game.description['en']} onChange={ (event) => { onChange(event) }}/>
                    </div>
                </div>
            </>
        );
    };

    const renderGameTrainingInfo = (): React.ReactElement => {
        if (!game) {
            return (
                <div>
                    No training info for this game
                </div>
            );
        }

        return (
            <>
                <div className="form--group">
                    <label htmlFor="overwolfId">OverwolfID</label>
                    <div className="form--group-input">
                        <input id="overwolfId" name="overwolfId" type="text" value={game.overwolfId} onChange={ (event) => { onChange(event) }}/>
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="installPathDarwin">darwin</label>
                    <div className="form--group-input">
                        <input id="installPathDarwin" name="installPath" type="text" value={game.installPath.darwin} onChange={ (event) => { onChange(event) }}/>
                    </div>
                    <label htmlFor="installPathWinNT">windowsNT</label>
                    <div className="form--group-input">
                        <input id="installPathWinNT" name="installPath" type="text" value={game.installPath.windowsNT} onChange={ (event) => { onChange(event) }}/>
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="statApiAvailable">Show in data viz</label>
                    <div className="form--group-input">
                        <input type="checkbox" id="statApiAvailable" name="statApiAvailable" checked={ game.statApiAvailable } onChange={(event) => { onChange(event, !game.statApiAvailable)} } />
                    </div>
                </div>
            </>
        );
    };

    const renderGameTournamentInfo = (): React.ReactElement => {
        if (!game) {
            return (
                <div>
                    No tournament Infos
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="form--group">
                    <label htmlFor="showGameIdentifier">Show game identifier</label>
                    <div className="form--group-input">
                        <input type="checkbox" id="showGameIdentifier" name="showGameIdentifier" checked={ game.showGameIdentifier } onChange={(event) => { onChange(event, !game.showGameIdentifier)} } />
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="playersCanFillScore">Players Can Fill Score</label>
                    <div className="form--group-input">
                        <input type="checkbox" id="playersCanFillScore" name="playersCanFillScore" checked={ game.playersCanFillScore } onChange={(event) => { onChange(event, !game.playersCanFillScore)} } />
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const renderGameMedias = (): React.ReactElement => {
        return (
            <></>
        );
    };

    const renderSwitchTabs = (tab: EditGamesTabs): React.ReactElement => {
        switch (tab) {
            case EditGamesTabs.INFO:
                return renderGameInfo();
            case EditGamesTabs.TRAINING:
                return renderGameTrainingInfo();
            case EditGamesTabs.TOURNAMENT:
                return renderGameTournamentInfo();
            case EditGamesTabs.MEDIAS:
                return renderGameMedias();
            default:
                return renderGameInfo();
        }
    };

    return (
        <div>
            <ul className="tabs">
                <li className={ tab === EditGamesTabs.INFO ? 'is-active' : '' } onClick={() => {setTab(EditGamesTabs.INFO) }}>
                    <i className="fas fa-pencil"></i>&nbsp;Infos
                </li>
                <li className={ tab === EditGamesTabs.TRAINING ? 'is-active' : '' } onClick={() => {setTab(EditGamesTabs.TRAINING) }}>
                    <i className="fas fa-pencil"></i>&nbsp;Training
                </li>
                <li className={ tab === EditGamesTabs.TOURNAMENT ? 'is-active' : '' } onClick={() => {setTab(EditGamesTabs.TOURNAMENT) }}>
                    <i className="fas fa-pencil"></i>&nbsp;Tournament
                </li>
                <li className={ tab === EditGamesTabs.MEDIAS ? 'is-active' : '' } onClick={() => {setTab(EditGamesTabs.MEDIAS) }}>
                    <i className="fas fa-pencil"></i>&nbsp;Medias
                </li>
            </ul>
            { renderSaveGameButton() }

            <div className="p-3">
                <form>
                    { renderSwitchTabs(tab) }
                </form>
            </div>
        </div>
    );
};