import { Action } from 'redux';

import ContactRequest from '../../Domain/ContactRequest';

export const contactRequestPutInitAction = '@@contact_request/PUT_INIT';
export const contactRequestPutRequestLoadingAction = '@@contact_request/PUT_REQUEST_LOADING';
export const contactRequestPutRequestSuccessAction = '@@contact_request/PUT_REQUEST_SUCCESS';
export const contactRequestPutRequestFailureAction = '@@contact_request/PUT_REQUEST_FAILURE';

export interface ContactRequestPutInitAction extends Action<typeof contactRequestPutInitAction> {
    payload: {}
}

export interface ContactRequestPutRequestLoadingAction extends Action<typeof contactRequestPutRequestLoadingAction> {
    payload: {}
}

export interface ContactRequestPutRequestSuccessAction extends Action<typeof contactRequestPutRequestSuccessAction> {
    payload: {
        contactRequest: ContactRequest
    }
}

export interface ContactRequestPutRequestFailureAction extends Action<typeof contactRequestPutRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const contactRequestPutInitActionCreator = (): ContactRequestPutInitAction => {

    return {
        type: contactRequestPutInitAction,
        payload: {}
    }
}

export const contactRequestPutRequestLoadingActionCreator = (): ContactRequestPutRequestLoadingAction => {

    return {
        type: contactRequestPutRequestLoadingAction,
        payload: {}
    }
}

export const contactRequestPutRequestSuccessActionCreator = (contactRequest: ContactRequest): ContactRequestPutRequestSuccessAction => {

    return {
        type: contactRequestPutRequestSuccessAction,
        payload: {
            contactRequest
        }
    }
}

export const contactRequestPutRequestFailureActionCreator = (errorMessage: string): ContactRequestPutRequestFailureAction => {

    return {
        type: contactRequestPutRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};