import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DraggableProvided } from 'react-beautiful-dnd';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContent from '../../Domain/PageContent';
import ComponentTypeEnum from '../../Domain/Enum/ComponentTypeEnum';

import IconDelete from '../../Infrastructure/Style/svg/IconDelete';
import HandleComingSoon from './ComponentType/HandleComingSoon';
import HandleComponentText from './ComponentType/Texts/HandleComponentText';
import DeletePageContentModal from '../../Infrastructure/Pages/Modals/DeletePageContentModal';
import HandleComponentGallery from './ComponentType/ImagesAndMedias/HandleComponentGallery';
import HandleComponentAccordion from './ComponentType/Texts/HandleComponentAccordion';
import HandleComponentSlideshow from './ComponentType/ImagesAndMedias/HandleComponentSlideshow';
import DisplayComponentTypeIcons from '../../Infrastructure/Partials/DisplayComponentTypeIcons';
import HandleComponentTwoColumns from './ComponentType/Layout/HandleComponentTwoColumns';
import HandleComponentContactRequest from './ComponentType/ContactForms/HandleComponentContactRequest';
import HandleComponentSectionsHeroSection from './ComponentType/Sections/HandleComponentSectionsHeroSection';
import HandleComponentSectionsCallToAction from './ComponentType/Sections/HandleComponentSectionsCallToAction';

import { modalAddActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { pageBuilderPageContentSelectedSelector } from '../../Infrastructure/Redux/page-builder.selectors';
import { pageBuilderSelectPageContentActionCreator } from '../../Infrastructure/Redux/page-builder.actions';

interface IHandlePageContentProps {
    pageContent: PageContent,
    provided: DraggableProvided
}

const HandlePageContent: React.FunctionComponent<IHandlePageContentProps> = ({ pageContent, provided }): React.ReactElement => {
    const dispatch = useDispatch();
    const pageContentSelected = useSelector(pageBuilderPageContentSelectedSelector);

    const handlePageContentSelection = (index: string): void => {
        dispatch(pageBuilderSelectPageContentActionCreator(index));
    }

    const handleDeletePageContentModal = (id: string): void => {
        dispatch(
            modalAddActionCreator(
                <DeletePageContentModal pageContentId={ id } />,
                'page_builder.page_contents.delete_page_content_modal.title'
            )
        );
    }

    const renderPageContentType = (): React.ReactElement => {
        switch(pageContent.type) {
            case ComponentTypeEnum.LayoutTwoColumns:
                return <HandleComponentTwoColumns pageContent={ pageContent } />;

            case ComponentTypeEnum.Text:
                return <HandleComponentText pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.Accordion:
                return <HandleComponentAccordion pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.Gallery:
                return <HandleComponentGallery pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.Slideshow:
                return <HandleComponentSlideshow pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.ContactRequests:
                return <HandleComponentContactRequest pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.sectionsCallToAction:
                return <HandleComponentSectionsCallToAction pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.sectionsHeroSection:
                return <HandleComponentSectionsHeroSection pageContentId={ pageContent.id } />;

            default:
                return <HandleComingSoon />;
        }
    }

    return (
        <div className={ `portlet ${ pageContentSelected !== null && pageContentSelected === pageContent.id ? 'is-selected' : ''}` } onClick={()=>handlePageContentSelection(pageContent.id)}>
            <div className="portlet--title">
                <div className="portlet--title-label" {...provided.dragHandleProps}>
                    <DisplayComponentTypeIcons type={ pageContent.type } /> <LocaleMessage id={ `page_builder.component-${pageContent.type}.bloc_name` } />
                </div>
                <div className="portlet--title-toolbar">
                    <button className="button is-small is-red" onClick={()=>handleDeletePageContentModal(pageContent.id)}>
                        { IconDelete }
                    </button>
                </div>
            </div>

            <div className="portlet--body">
                { renderPageContentType() }
            </div>
        </div>
    );
}

export default HandlePageContent;