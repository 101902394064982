import React from 'react';

const IconSectionsCta: React.ReactElement = (
    <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="none">
        <g strokeWidth="1" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g transform="translate(2.000000, 6.000000)" strokeWidth="1.5">
                <path d="M5,0 L15,0 C15.62,0 16.17,0.02 16.66,0.09 C19.29,0.38 20,1.62 20,5 L20,7 C20,10.38 19.29,11.62 16.66,11.91 C16.17,11.98 15.62,12 15,12 L5,12 C4.38,12 3.83,11.98 3.34,11.91 C0.71,11.62 0,10.38 0,7 L0,5 C0,1.62 0.71,0.38 3.34,0.09 C3.83,0.02 4.38,0 5,0 Z"></path>
                <line x1="14" y1="6" x2="6" y2="6" opacity="0.4"></line>
            </g>
        </g>
    </svg>
);

export default IconSectionsCta;