import { Action } from 'redux';

export const pagePostInitAction = '@@pages/POST_INIT';
export const pagePostRequestLoadingAction = '@@pages/POST_REQUEST_LOADING';
export const pagePostRequestSuccessAction = '@@pages/POST_REQUEST_SUCCESS';
export const pagePostRequestFailureAction = '@@pages/POST_REQUEST_FAILURE';

export interface PagePostInitAction extends Action<typeof pagePostInitAction> {
    payload: {}
}

export interface PagePostRequestLoadingAction extends Action<typeof pagePostRequestLoadingAction> {
    payload: {}
}

export interface PagePostRequestSuccessAction extends Action<typeof pagePostRequestSuccessAction> {
    payload: {}
}

export interface PagePostRequestFailureAction extends Action<typeof pagePostRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const pagePostInitActionCreator = (): PagePostInitAction => {

    return {
        type: pagePostInitAction,
        payload: {}
    }
}

export const pagePostRequestLoadingActionCreator = (): PagePostRequestLoadingAction => {

    return {
        type: pagePostRequestLoadingAction,
        payload: {}
    }
}

export const pagePostRequestSuccessActionCreator = (): PagePostRequestSuccessAction => {

    return {
        type: pagePostRequestSuccessAction,
        payload: {}
    }
}

export const pagePostRequestFailureActionCreator = (errorMessage: string): PagePostRequestFailureAction => {

    return {
        type: pagePostRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};