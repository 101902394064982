import IDataTheme from './IDataTheme';

export const initialDataTheme: IDataTheme = {
    primaryColor: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
    secondaryColor: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
    textColor: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
    textHeadersColor: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
    headerVariant: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
    headerLogoPosition: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
    headerTextColor: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
    headerColor: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
    headerBackgroundBlur: {
        inputValue: false,
        validation: {
            inError: false,
            message: ''
        }
    },
    footerColor: {
        inputValue: '000000',
        validation: {
            inError: false,
            message: ''
        }
    },
}