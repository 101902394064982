import React from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import { GetGames } from '../../Application/GetGames';
import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';
import { modalAddActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { CreateGamesController } from '../../Infrastructure/Pages/CreateGamesController';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const GamesController: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const query = useQuery();
    const breadcrumb: IBreadcrumbItem[] = [
        {
            title: 'Games',
            url: null
        }
    ];

    const defaultPage = query.get('page') !== null ? Number(query.get('page')) : 1;

    const createGame = () => {
        dispatch(
            modalAddActionCreator(
                <CreateGamesController/>,
                'Create new game',
                {
                    extraClass: 'modal--form-edit',
                    openingAnimationClass: 'is-shownFromBottom'
                }
            )
        );
    };

    return (
      <React.Fragment>
          <Breadcrumb items={ breadcrumb } />
            <div className="list-page">
                <div className="grid-wrapper">
                    <div className="list-page--header">
                        <h2>Games</h2>
                        <div className="list-page--header-actions">
                            <button className="button is-primary" onClick={() => { createGame() }}>
                                Create
                            </button>
                        </div>
                    </div>
                    <GetGames actualPage={ defaultPage }/>
                </div>
            </div>
      </React.Fragment>
    );
};