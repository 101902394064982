export default {
    // App
    'app.title': 'Tableau de bord',
    'app.modes.light': 'Light Mode',
    'app.modes.dark': 'Dark Mode',
    'app.modes.black': 'Black Mode',
    'app.something_went_wrong': "Oups, quelque chose s'est mal passé",
    'app.breadcrumb.dashboard': "Tableau de bord",
    'app.settings.page.title': "Paramètres du site",
    'app.settings.navigation.settings': "Paramètres du site",
    'app.settings.navigation.settings.general_settings': "Paramètres généraux",
    'app.settings.navigation.customisation': "Personalisation",
    'app.settings.navigation.customisation.theme': "Thème",
    'app.settings.settings.theme.page.title': "Edition des paramètres du site",

    // APP THEME
    'app.settings.customisation.theme.page.title': "Edition du thème du site",
    'app.settings.customisation.theme.tabs.colors': "Couleurs",
    'app.settings.customisation.theme.tabs.header': "Header",
    'app.settings.customisation.theme.tabs.footer': "Footer",
    'app.settings.customisation.theme.form.primary_color.label': "Couleur principale",
    'app.settings.customisation.theme.form.secondary_color.label': "Couleur secondaire",
    'app.settings.customisation.theme.form.text_color.label': "Couleur des textes",
    'app.settings.customisation.theme.form.text_headers_color.label': "Couleur des titres",
    'app.settings.customisation.theme.form.header_variant.label': "Variante",
    'app.settings.customisation.theme.form.header_variant.choice_static': "Statique",
    'app.settings.customisation.theme.form.header_variant.choice_floating': "Flottant",
    'app.settings.customisation.theme.form.header_variant.choice_sticky': "Toujours en haut de l'écran",
    'app.settings.customisation.theme.form.header_variant.additional_information': "Le header peut être stylisé différemment grâce aux variantes :<ul><li><strong>Statique</strong> signifie que le header ne suit pas l'écran lors du scroll.</li><li><strong>Flottant</strong> signifie que le header suit l'écran lors du scroll et la largeur du header est égale à la largeur du site.</li><li><strong>En haut de l'écran</strong> signifie que le header suit l'écran lors du scroll et la largeur du header est égale à la largeur de l'écran.</li></ul>",
    'app.settings.customisation.theme.form.header_color.label': "Couleur du header",
    'app.settings.customisation.theme.form.header_text_color.label': "Couleur de la navigation",
    'app.settings.customisation.theme.form.header_logo_position.label': "Position du logo",
    'app.settings.customisation.theme.form.header_logo_position.choice_left': "Gauche",
    'app.settings.customisation.theme.form.header_logo_position.choice_center': "Au milleu",
    'app.settings.customisation.theme.form.header_background_blur.label': "Arrière plan flouté",
    'app.settings.customisation.theme.form.header_background_blur.additional_information': "Si l'option est activée, un effet de transparence à 90% est appliqué pour créer l'effet flouté",
    'app.settings.customisation.theme.form.footer_color.label': "Couleur du footer",
    'app.settings.customisation.theme.form.success': "Le nouveau thème du site a été enregistré avec succcès",

    // APP SETTINGS
    'app.settings.site.settings.tabs.info': "Informations",
    'app.settings.site.settings.tabs.seo': "Référencement SEO",
    'app.settings.site.settings.tabs.header': "Header",
    'app.settings.site.settings.tabs.footer': "Footer",
    'app.settings.site.settings.form.site_name.label': "Nom du site",
    'app.settings.site.settings.form.site_name.placeholder': "Symbioze.org",
    'app.settings.site.settings.form.site_logo.label': "Logo du site",
    'app.settings.site.settings.form.site_logo.additional_information': "Extensions disponibles : *.png, *.svg",
    'app.settings.site.settings.form.site_favicon.label': "Favicon",
    'app.settings.site.settings.form.site_favicon.additional_information': "Extensions disponibles : *.png. Taille obligatoire : 512 x 512 (en pixels)",
    'app.settings.site.settings.form.site_meta_title.label': "Meta title",
    'app.settings.site.settings.form.site_meta_title.additional_information': "Nous recommandons une longueur entre 50 et 60 caractères - {strong1}{remaining} restant(s){strong2}",
    'app.settings.site.settings.form.site_meta_description.label': "Meta description",
    'app.settings.site.settings.form.site_meta_description.additional_information': "Nous recommandons que celle-ci ne dépasse pas 160 caractères - {strong1}{remaining} restant(s){strong2}",
    'app.settings.site.settings.form.site_default_open_graph.label': "Image OpenGraph",
    'app.settings.site.settings.form.site_default_open_graph.additional_information': "Image utilisée lors des partages sur les réseaux sociaux qui sera affichée par défaut lorsqu'aucune image n'est renseignée dans les pages (Onglet &laquo;Référencement SEO&raquo; lors de l'édition d'une page).<br /><br /><strong>Extensions disponibles :</strong> *.png, *.jpg, *.jpeg.<br /><strong>Taille conseillée :</strong> 1200 x 630 (en pixels)",
    'app.settings.site.settings.form.address.label': "Adresse",
    'app.settings.site.settings.form.header_button.label': "Afficher bouton CTA header",
    'app.settings.site.settings.form.header_button_label.label': "Label du bouton",
    'app.settings.site.settings.form.header_button_label.placeholder': "Contactez-nous",
    'app.settings.site.settings.form.header_button_navigation_id.label': "Navigation du bouton",
    'app.settings.site.settings.form.header_button_navigation_id.additional_information': "Sélectionnez la page vers laquelle vous voulez rediriger l'utilisateur qui clique sur le bouton d'action dans le header",
    'app.settings.site.settings.form.header_button_navigation_type.label': "Type de navigation",
    'app.settings.site.settings.form.header_button_navigation_type.choice_internal': "Navigation vers une page interne",
    'app.settings.site.settings.form.header_button_navigation_type.choice_mailto': "Envoi d'e-mail",
    'app.settings.site.settings.form.header_button_navigation_type.choice_manual': "URL libre",
    'app.settings.site.settings.form.header_button_navigation_url.label': "URL libre",
    'app.settings.site.settings.form.header_button_navigation_mailto.label': "Email",
    'app.settings.site.settings.form.footer_button.label': "Afficher info button footer",
    'app.settings.site.settings.form.footer_button_label.label': "Label du button",
    'app.settings.site.settings.form.footer_button_label.placeholder': "On sera ravis de vous répondre",
    'app.settings.site.settings.form.footer_button_navigation_id.label': "Navigation du bouton",
    'app.settings.site.settings.form.footer_button_navigation_id.additional_information': "Sélectionnez la page vers laquelle vous voulez rediriger l'utilisateur qui clique sur le bouton d'action dans le footer",
    'app.settings.site.settings.form.footer_text.label': "Texte additionnel",
    'app.settings.site.settings.form.footer_text.placeholder': "Besoin d'informations ?",
    'app.settings.site.settings.form.footer_text.additional_information': "Renseignez un texte pour l'afficher juste au dessus du bouton. Laissez vide si vous ne voulez qu'aucun texte ne s'affiche",
    'app.settings.site.settings.form.success': "Les nouveaux paramètres du site ont été enregistré avec succès",

    'app_theme.api_errors.missing_primary_color': "Veuillez renseigner une couleur primaire",
    'app_theme.api_errors.primary_color_should_be_six_in_length': "Le code hexadécimal de la couleur principale doit comporter 6 caractères",
    'app_theme.api_errors.missing_secondary_color': "Veuillez renseigner une couleur secondaire",
    'app_theme.api_errors.secondary_color_should_be_six_in_length': "Le code hexadécimal de la couleur secondaire doit comporter 6 caractères",
    'app_theme.api_errors.missing_header_variant': "Veuillez renseigner une variante pour le header",
    'app_theme.api_errors.missing_header_logo_position': "Veuillez renseigner la position du logo",
    'app_theme.api_errors.missing_header_color': "Veuillez renseigner la couleur du header",
    'app_theme.api_errors.header_color_should_be_six_in_length': "Le code hexadécimal de la couleur du header doit comporter 6 caractères",
    'app_theme.api_errors.missing_footer_color': "Veuillez renseigner la couleur du footer",
    'app_theme.api_errors.footer_color_should_be_six_in_length': "The hexadecimal value for the footer color should be 6 caracters long",
    'app_theme.api_errors.missing_footer_contact_text': "The hexadecimal value for the footer color should be 6 caracters long",
    'app_theme.api_errors.footer_contact_text_should_be_six_length': "The hexadecimal value for the footer color should be 6 caracters long",

    'app_settings.api_errors.missing_site_name': "Veuillez renseigner le nom du site",
    'app_settings.api_errors.site_name_should_be_three_in_length': "Le nom du site doit comporter au moins 3 caractères",
    'app_settings.api_errors.missing_header_button_label': "Veuillez renseigner le label du bouton",
    'app_settings.api_errors.header_button_label_should_be_six_length': "Le label du bouton doit comporter au moins 6 caractères",
    'app_settings.api_errors.navigation_doesnt_exist': "La navigation sélectionnée n'existe pas",
    'app_settings.api_errors.missing_header_button_navigation_mailto': "Veuillez renseigner l'adresse email",
    'app_settings.api_errors.missing_header_button_navigation_url': "Veuillez renseigner l'URL",
    'app_settings.api_errors.missing_footer_contact_text': "Veuillez renseigner les informations de contact",
    'app_settings.api_errors.footer_contact_text_should_be_six_length': "Les informations de contact doivent comporter au moins 6 caractères",

    // Locales
    'locales.english': 'Anglais',
    'locales.french': 'Français',

    // Modales
    'modales.confirmation.title': "Confirmation",
    'modales.confirmation.delete': "Oui, supprimer",
    'modales.confirmation.cancel': "Annuler",

    // LOGIN
    'login.welcome.title': "Bienvenue ! 👋",
    'login.enter_credentials.title': "Veuillez renseigner vos identifiants pour vous connecter sur votre espace de gestion",
    'login.email.label': "Adresse email",
    'login.email.placeholder': "john.doe@email.com",
    'login.email.missing': "L'adresse email doit être renseignée",
    'login.email.too_short': "L'adresse email doit comporter au moins 4 caractères",
    'login.password.label': "Mot de passe",
    'login.password.placeholder': "*********",
    'login.password.missing': "Le mot de passe doit être renseigné",
    'login.password.too_short': "Le mot de passe doit comporter au moins 4 caractères",
    'login.password_lost.link_label': "Mot de passe oublié ?",
    'login.errors.auth.login_validator.credentials_not_found': "L'adresse email ou le mot de passe est incorrect",

    // LOGOUT
    'logout.modale.confirmation.title': "Déconnexion",
    'logout.modale.confirmation.info': "Êtes-vous sûr de vouloir vous deconnecter de votre espace d'administration ?",
    'logout.modale.confirmation.validation_button': "Oui, me déconnecter",
    'logout.modale.confirmation.cancel_button': "Annuler",

    // USERS
    'users.settings.page.title': "Mes paramètres",
    'users.settings.navigation.my_account': "Mon compte",
    'users.settings.navigation.personal_information': "Informations personnelles",
    'users.settings.navigation.password': "Mot de passe",
    'users.settings.navigation.settings': "Paramètres",
    'users.settings.navigation.general_settings': "Paramètres généraux",
    'users.settings.personal_information.avatar.label': "Avatar",
    'users.settings.personal_information.email.label': "Email",
    'users.settings.personal_information.firstname.label': "Prénom",
    'users.settings.personal_information.lastname.label': "Nom",

    // Navigation
    'navigation.page.title': 'Navigation',
    'navigation.left.site_hierarchy': 'Hiérarchie du site',
    'navigation.title.missing': 'Le titre doit être renseigné',
    'navigation.title.not_long_enough': 'Le titre doit comporter au moins 5 caractères',
    'navigation.toast.success': 'La navigation a été mise à jour',
    'navigation.delete.info': "Êtes-vous sûr de vouloir supprimer cet élément de navigation ?",
    'navigation.id.label': "ID",
    'navigation.title.label': "Titre",
    'navigation.type.label': "Type",
    'navigation.type.choice_folder': "~ Dossier",
    'navigation.type.choice_page': "Navigation vers une page",
    'navigation.type.choice_module': "Navigation vers un module",
    'navigation.page_type.label': "Page",
    'navigation.page_type.empty_value': "Veuillez sélectionner une page",
    'navigation.page_type.missing': "Veuillez sélectionner une page",
    'navigation.module_type.label': "Module",
    'navigation.module_type.empty_value': "Veuillez sélectionner un module",
    'navigation.module_type.missing': "Veuillez sélectionner un module",

    // Page
    'page.page.title': "Liste des pages",
    'page.page.create_title': "Création d'une nouvelle page",
    'page.page.edit_title': "Modification de la page",
    'page.title.missing': "Le champ titre doit être renseigné",
    'page.title.not_long_enough': "Le champ titre doit comporter au moins 5 caractères",
    'page.post.success': "La page a été créée avec succès",
    'page.put.success': "La page a été modifiée avec succès",
    'page.delete.info': "Êtes-vous sûr de vouloir supprimer cette page ?",
    'page.delete.success': "La page a été supprimée avec succès",
    'page.id.label': "ID",
    'page.title.label': "Titre",
    'page.title.placeholder': 'Exemple: "Qui sommes-nous ?"',
    'page.created_date.label': "Date de création",
    'page.modified_date.label': "Date de modification",
    'page.banner.label': "Bannière entête",
    'page.seo_title.label': "Meta Title",
    'page.seo_title.max_length_information': "Nous recommandons que celle-ci ne dépasse pas 70 caractères - {strong1}{remaining} restant(s){strong2}",
    'page.seo_description.label': "Meta Description",
    'page.seo_description.max_length_information': "Nous recommandons que celle-ci ne dépasse pas 160 caractères - {strong1}{remaining} restant(s){strong2}",

    // Medias
    'medias.page.title': "Gestion des médias",
    'medias.navigation.medias_and_pictures': "Médias & Images",
    'medias.navigation.file_manager': "Gestionnaire de fichiers",
    'medias.navigation.settings': "Paramètres",
    'medias.navigation.cache_management': "Gestion du cache",
    'medias.navigation.empty_cache': "Vider le cache",
    'medias.empty_cache_modal.title': "Vider le cache",
    'medias.empty_cache_modal.info': "Êtes-vous sûr de vouloir vider le cache ?",
    'medias.empty_cache_modal.validation_button': "Oui, vider le cache",

    // Page builder
    'page_builder.page.title': "Contenu de la page",
    'page_builder.page.header.back_button': "Retour",
    'page_builder.page.header.visualize_button': "Visualiser la page",
    'page_builder.page.header.publish_button': "Publier",
    'page_builder.page.back_button_modal.title': "Confirmation",
    'page_builder.page.back_button_modal.content': "Êtes-vous sûr de vouloir quitter la page sans sauvegarder les modifications ?",
    'page_builder.page.back_button_modal.confirm_button': "Oui, quitter la page",
    'page_builder.settings.title': "Paramètres",
    'page_builder.settings.please_select': "Sélectionnez un composant dans votre page pour afficher ses paramètres 👉",
    'page_builder.settings.display_setting': "Affichage",
    'page_builder.settings.display.desktop': "Afficher sur Desktop",
    'page_builder.settings.display.mobile': "Afficher sur Mobile",
    'page_builder.settings.disposal_setting': "Disposition",
    'page_builder.settings.component_background_setting': "Fond du composant",
    'page_builder.settings.component_background.type.label': "Type de fond",
    'page_builder.settings.component_background.type.color': "Couleur",
    'page_builder.settings.component_background.type.image': "Image de fond",
    'page_builder.settings.component_background.size_type.label': "Taille du fond",
    'page_builder.settings.component_background.size_type.manual': "Manuel",
    'page_builder.settings.component_background.size_type.cover': "Couvre toute la surface",
    'page_builder.settings.component_background.size_type.contain': "Contenu dans la surface",
    'page_builder.settings.component_background.size.label': "Taille de l'image",
    'page_builder.settings.style.component_background_image.label': "Image",
    'page_builder.settings.style.component_background': "Fond du composant",
    'page_builder.settings.style_setting': "Style",
    'page_builder.settings.style.component_theme': "Thème du composant",
    'page_builder.settings.style.component_contrast': "Eléments contrastés",
    'page_builder.settings.padding_setting': "Marges intérieures",
    'page_builder.components.title': "Composants",
    'page_builder.components.categories.layout': "Agencement",
    'page_builder.components.categories.sections': "Sections",
    'page_builder.components.categories.texts': "Textes & paragraphes",
    'page_builder.components.categories.images_media': "Images & Médias",
    'page_builder.components.categories.contact-requests': "Formulaires de contact",
    'page_builder.page_contents.placeholder': "Pour ajouter du contenu dans la page, glissez et déposez un composant au dessus de cette zone depuis la liste des composants sur votre droite 👉",
    'page_builder.page_contents.delete_page_content_modal.title': "Confirmation",
    'page_builder.page_contents.delete_page_content_modal.content': "Êtes-vous sûr de vouloir supprimer cet élément de la page ?",
    'page_builder.page_contents.delete_page_content_modal.confirm_button': "Oui, le supprimer",
    'page_builder.component-block.themes.default': "Paramètres par défaut",
    'page_builder.component-block.themes.dark': "Textes & éléments en blancs",
    'page_builder.toast.success': "Le contenu de la page a été mis à jour",

    // PageBuilder - Two columns type
    'page_builder.component-layout-two-columns-type.bloc_name': "Deux colonnes",

    // PageBuilder - Three columns type
    'page_builder.component-layout-three-columns-type.bloc_name': "Trois colonnes",

    // Page Builder - Accordion type
    'page_builder.component-accordion-type.bloc_name': "Accordéon",
    'page_builder.component-accordion-type.question.label': "Question",
    'page_builder.component-accordion-type.answer.label': "Réponse",
    'page_builder.component-accordion-type.items_group.label': "Liste d'élements",
    'page_builder.component-accordion-type.empty': "Ajoutez un premier élément à votre liste de question réponse en utilisant le bouton &laquo; Ajouter un élément &raquo; ci-dessous",
    'page_builder.component-accordion-type.items_group.add_button_label': "Ajouter un élement",
    'page_builder.component-accordion-type.settings.properties': "Propriété de Accordéon",
    'page_builder.component-accordion-type.settings.with_borders': "Afficher bordures",

    // Page Builder - Text type
    'page_builder.component-text-type.bloc_name': "Texte standard",
    'page_builder.component-text-type.content.label': "Contenu",

    // Page Builder - Slideshow type
    'page_builder.component-slideshow-type.bloc_name': "Carousel d'images",
    'page_builder.component-slideshow-type.images_group.label': "Liste des images",
    'page_builder.component-slideshow-type.empty': "Ajoutez un premier élément à votre carousel en utilisant le champ &laquo; Ajouter une image &raquo; ci-dessous",
    'page_builder.component-slideshow-type.add_image.label': "Ajouter une image",
    'page_builder.component-slideshow-type.add_image.button': "Upload",
    'page_builder.component-slideshow-type.image_title.label': "Titre de l'image",
    'page_builder.component-slideshow-type.image_description.label': "Description",
    'page_builder.component-slideshow-type.settings.properties': "Propriété du carousel d'images",
    'page_builder.component-slideshow-type.settings.effect': "Effet",
    'page_builder.component-slideshow-type.settings.effect-slide': "Slide",
    'page_builder.component-slideshow-type.settings.effect-fade': "Fade",
    'page_builder.component-slideshow-type.settings.navigation': "Afficher navigation",
    'page_builder.component-slideshow-type.settings.pagination': "Type de pagination",
    'page_builder.component-slideshow-type.settings.pagination-dots': "Pagination par dots",
    'page_builder.component-slideshow-type.settings.pagination-thumbnails': "Pagination par miniatures",
    'page_builder.component-slideshow-type.settings.slidesPerView': "Nombre de slides par vue",
    
    // Page Builder - Gallery type
    'page_builder.component-gallery-type.bloc_name': "Galerie d'images",
    'page_builder.component-gallery-type.images_group.label': "Images de la galerie",
    'page_builder.component-gallery-type.empty': "Ajoutez un premier élément à votre galerie en utilisant le champ &laquo; Ajouter une image &raquo; ci-dessous",
    'page_builder.component-gallery-type.add_image.label': "Ajouter une image",
    'page_builder.component-gallery-type.add_image.button': "Upload",
    'page_builder.component-gallery-type.image_title.label': "Titre de l'image",
    'page_builder.component-gallery-type.image_description.label': "Description",
    'page_builder.component-gallery-type.settings.label': "Params. de la galerie d'images",
    'page_builder.component-gallery-type.settings.desktop-settings': "Paramètres Desktop",
    'page_builder.component-gallery-type.settings.mobile-settings': "Paramètres Mobile",
    'page_builder.component-gallery-type.settings.masonry': "Masonry activé",
    'page_builder.component-gallery-type.settings.masonry.yes': "Oui",
    'page_builder.component-gallery-type.settings.masonry.no': "Non",
    'page_builder.component-gallery-type.settings.position-info': "Position des infos",
    'page_builder.component-gallery-type.settings.position-info.below': "En dessous",
    'page_builder.component-gallery-type.settings.position-info.inside': "Par dessus l'image",
    
    // Page Builder - Player video type
    'page_builder.component-video-player-type.bloc_name': "Player Vidéo",
    
    // Page Builder - Contact requests type
    'page_builder.component-contact-requests-type.bloc_name': "Demandes de contact",
    'page_builder.component-contact-requests-type.subjects.label': "Objets",
    'page_builder.component-contact-requests-type.subjects_subject.label': "Sujet de la demande",
    'page_builder.component-contact-requests-type.subjects_email.label': "Email lié à ce sujet",
    'page_builder.component-contact-requests-type.subjects_email.info': "Spécifiez l'adresse email qui sera utilisé à la place de l'addresse email par défaut spécifiée dans les <a>paramètres</a> du module",
    'page_builder.component-contact-requests-type.fields.label': "Champs",
    'page_builder.component-contact-requests-type.fields_input_type.label': "Type de champ",
    'page_builder.component-contact-requests-type.fields_label_name.label': "Nom du champ",
    'page_builder.component-contact-requests-type.fields_input_placeholder.label': "Placeholder",
    'page_builder.component-contact-requests-type.items_group.add_button_label': "Ajouter un élément",

    // Page Builder - Sections type
    'page_builder.component-sections-call-to-action-type.bloc_name': "Zone Call to Action",
    'page_builder.component-sections-call-to-action-type.title.label': "Titre",
    'page_builder.component-sections-call-to-action-type.button_label.label': "Label du bouton",
    'page_builder.component-sections-call-to-action-type.url.label': "URL",
    'page_builder.component-sections-hero-section-type.bloc_name': "Hero section",
    'page_builder.component-sections-hero-section-type.title.label': "Titre (h1)",
    'page_builder.component-sections-hero-section-type.subtitle.label': "Sous-titre",
    'page_builder.component-sections-hero-section-type.align.label': "Alignement",
    'page_builder.component-sections-hero-section-type.align.left': "Aligné à gauche",
    'page_builder.component-sections-hero-section-type.align.center': "Aligné au milieu",
    'page_builder.component-sections-hero-section-type.align.right': "Aligné à droite",
    'page_builder.component-sections-hero-section-type.label-button.label': "Label du bouton",
    'page_builder.component-sections-hero-section-type.url.label': "URL",
    'page_builder.component-sections-hero-section-type.settings.properties': "Paramètres Hero Section",
    'page_builder.component-sections-hero-section-type.display_logo_brand.label': "Logo du site",
    'page_builder.component-sections-hero-section-type.display_logo_brand.yes': "Afficher",
    'page_builder.component-sections-hero-section-type.display_logo_brand.no': "Ne pas afficher",
    'page_builder.component-sections-hero-section-type.button_style.label': "Style bouton CTA",

    // CONTACTS
    'contact_requests.page.title': "Demandes de contact",
    'contact_requests.navigation.contact_requests': "Demandes de contact",
    'contact_requests.navigation.contact_requests_list': "Liste des demandes de contact",
    'contact_requests.navigation.contact_requests_settings': "Paramètres",
    'contact_requests.check_status.info': "Êtes-vous sûr de vouloir changer le status de cette demande de contact en &laquo; Traitée &raquo; ?",
    'contact_requests.check_status.validation_button': "Oui, cette demande a été traitée",
    'contact_requests.delete_modal.info': "Êtes-vous sûr de vouloir supprimer cette demande de contact ?",
    'contact_requests.delete_modal.validation_button': "Oui, la supprimer",
    'contact_request_settings.page.title': "Paramètres",
    'contact_request_settings.page.title_2': "Paramètres des demandes de contact",
    'contact_request_settings.sender_email.label': "Email expéditeur",
    'contact_request_settings.sender_email.info': "L'email par défaut qui recevera les demandes de contact pour les sujets où un email n'aura pas été spécifié",
    'contact_request_settings.sender_email.placeholder': "john_doe@email.com",
    'contact_request_settings.sender_email.missing': "L'email expéditeur doit être renseigné",
    'contact_request_settings.sender_email.not_long_enough': "L'email expéditeur doit comporter au moins 5 caractères",
    'contact_request_settings.sender_name.label': "Nom de l'expéditeur",
    'contact_request_settings.sender_name.placeholder': "John Doe",
    'contact_request_settings.sender_no_reply.label': "Email no_reply",
    'contact_request_settings.sender_no_reply.info': "L'email no_reply utilisé pour les mails qui nécessitent pas de réponse de la part de l'utilisateur",
    'contact_request_settings.sender_no_reply.placeholder': "no_reply@email.com",
    'contact_request_settings.sender_no_reply.missing': "L'email no reply doit être renseigné",
    'contact_request_settings.sender_no_reply.not_long_enough': "L'email no reply doit comporter au moins 5 caractères",
    'contact_request_settings.success_page_id.label': "Page de confirmation",
    'contact_request_settings.success_page_id.info': "La page vers laquelle l'utilisateur sera redirigé pour confirmer l'envoi de sa demande de contact",
    'contact_request_settings.success_page_id.missing': "La page de confirmation doit être renseignée",
    'contact_request_settings.provider.label': "Provider",
    'contact_request_settings.provider.placeholder': "Choisissez un email provider",
    'contact_request_settings.provider_api_key.label': "Clé API",
    'contact_request_settings.provider_api_key.placeholder': "1111-2222-3333-xxxx-wwww-zzzz",
    'contact_request_settings.provider_template_id.label': "Template ID",
    'contact_request_settings.provider_template_id.placeholder': "1234",
    'contact_request_settings.put.success': "Les paramètres ont été sauvargée",

    'contact_request_settings.api_errors.sender_name_missing': "Le nom de l'expéditeur doit être renseigné",
    'contact_request_settings.api_errors.sender_email_missing': "L'email expéditeur doit être renseigné",
    'contact_request_settings.api_errors.sender_email_invalid': "L'email expéditeur renseigné n'est pas une adresse email valide",
    'contact_request_settings.api_errors.sender_no_reply_missing': "L'email no reply doit être renseigné",
    'contact_request_settings.api_errors.sender_no_reply_invalid': "L'email no reply renseigné n'est pas une adresse email valide",
    'contact_request_settings.api_errors.provider_missing': "Au moins un provider doit être sélectionné",
    'contact_request_settings.api_errors.provider_is_not_supported': "Le provider sélectionné n'est pas supporté",
    'contact_request_settings.api_errors.provider_api_key_missing': "La clé API doit être sélectionnée",
    'contact_request_settings.api_errors.provider_template_id_missing': "Le Template ID doit être sélectionné",

    // TOOLS
    'tools.api_errors.upload_file_extension_not_permitted': "L'extension du fichier que vous avez tenté de téléverser est erroné"
};