import IDataTheme from "./IDataTheme";

class ValidateTheme {

    private setErrorMessage = (data: IDataTheme, field: string, message: string): IDataTheme => {
        data[field] = {
            inputValue: data[field].inputValue,
            validation: {
                inError: true,
                message: message
            }
        }

        return data;
    }

    private validatePrimaryColor = (data: IDataTheme): IDataTheme => {

        return data;
    }

    public handleApiResponse = (data: IDataTheme, errors: any): IDataTheme => {
        if (errors.primary_color !== undefined) {
            data = this.setErrorMessage(data, 'primaryColor', errors.primary_color[0].code);
        }
        if (errors.secondary_color !== undefined) {
            data = this.setErrorMessage(data, 'secondaryColor', errors.secondary_color[0].code);
        }
        if (errors.header_variant !== undefined) {
            data = this.setErrorMessage(data, 'headerVariant', errors.header_variant[0].code);
        }
        if (errors.header_logo_position !== undefined) {
            data = this.setErrorMessage(data, 'headerLogoPosition', errors.header_logo_position[0].code);
        }
        if (errors.header_color !== undefined) {
            data = this.setErrorMessage(data, 'headerColor', errors.header_color[0].code);
        }
        if (errors.footer_color !== undefined) {
            data = this.setErrorMessage(data, 'footerColor', errors.footer_color[0].code);
        }

        return data;
    }

    public validateForm = (data: IDataTheme): IDataTheme => {
        data = this.validatePrimaryColor(data);

        return data;
    }

    public canBeSubmitted = (data: IDataTheme): boolean => {
        return  this.validatePrimaryColor(data) &&
            Object.keys(data).every((field) => !data[field].validation.inError);
    }

}

export default ValidateTheme;