import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useEffectOnce } from 'react-use';
import { LocaleDate } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import ContactRequest from '../Domain/ContactRequest';
import { ContactRequestStatusEnum } from '../Domain/Enum/ContactRequestStatusEnum';

import Pagination from '../../App/Infrastructure/Partials/Pagination';
import SearchFilters from '../../_utils/SearchFilters/SearchFilters';
import ConfirmationDeleteModal from '../Infrastructure/Pages/ConfirmationDeleteModal';
import { modalAddActionCreator } from '../../_utils/Modal/Redux/modal.actions';
import { getContactRequestsService } from '../Infrastructure/Redux/get-contact-requests.services';
import ConfirmationCheckStatusModal from '../Infrastructure/Pages/ConfirmationCheckStatusModal';
import { searchFiltersQuerySelector } from '../../_utils/SearchFilters/Redux/searchFilters.selectors';
import { putContactRequestIsRequestSuccessSelector } from '../Infrastructure/Redux/put-contact-request.selectors';
import { deleteContactRequestIsRequestSuccessSelector } from '../Infrastructure/Redux/delete-contact-request.selectors';
import { contactRequestsGetInitActionCreator, contactRequestsGetRequestLoadingActionCreator } from '../Infrastructure/Redux/get-contact-requests.actions';
import { contactRequestsNbrPagesSelector, contactRequestsSelector, contactRequestsTotalSelector, getContactRequestsIsRequestFailureSelector, getContactRequestsIsRequestLoadingSelector } from '../Infrastructure/Redux/get-contact-requests.selectors';

interface IGetContactRequestsProps {
    actualPage: number
}

const GetContactRequests: React.FunctionComponent<IGetContactRequestsProps> = ({ actualPage }): React.ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useSelector(searchFiltersQuerySelector);
    const contactRequests = useSelector(contactRequestsSelector);
    const nbrPages = useSelector(contactRequestsNbrPagesSelector);
    const total = useSelector(contactRequestsTotalSelector);
    const isLoading = useSelector(getContactRequestsIsRequestLoadingSelector);
    const isInError = useSelector(getContactRequestsIsRequestFailureSelector);

    const putRequestSucceeded = useSelector(putContactRequestIsRequestSuccessSelector);
    const deleteRequestSucceeded = useSelector(deleteContactRequestIsRequestSuccessSelector);

    useEffectOnce(() => {
        dispatch(contactRequestsGetInitActionCreator());
    });

    useEffect(() => {
        dispatch(contactRequestsGetRequestLoadingActionCreator());
    }, [actualPage, query, putRequestSucceeded, deleteRequestSucceeded, dispatch]);

    useEffect(() => {
        if (isLoading) {
            getContactRequestsService(actualPage, query);
        }
    }, [isLoading, actualPage, query, dispatch]);

    const handleCheckStatusButton = (contactRequestId: number): void => {
        dispatch(
            modalAddActionCreator(
                <ConfirmationCheckStatusModal id={ contactRequestId } />,
                'modales.confirmation.title'
            )
        );
    }

    const handleDeleteButton = (contactRequestId: number): void => {
        dispatch(
            modalAddActionCreator(
                <ConfirmationDeleteModal id={ contactRequestId } />,
                'modales.confirmation.title'
            )
        );
    }

    const renderHeadTable = (): React.ReactElement => {

        return (
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Subject</th>
                    <th>Email</th>
                    <th>Creation date</th>
                    <th>Modification date</th>
                    <th>Status</th>
                    <th className="is-actions-column">Actions</th>
                </tr>
            </thead>
        )
    }

    const renderSubjectBadge = (status: ContactRequestStatusEnum): React.ReactElement => {
        switch (status) {
            case ContactRequestStatusEnum.ANSWERED:
                return <span className="badge is-trial">En cours</span>;

            case ContactRequestStatusEnum.DONE:
                return <span className="badge is-success">Traité</span>;

            default:
                return <span className="badge is-error">En attente</span>;
        }
    }

    const renderBody = (): React.ReactElement => {
        
        return (
            <React.Fragment>
                {
                    contactRequests.map((contactRequest: ContactRequest, index: number) => {

                        return (
                            <tr data-testid="contactrequests--request-item" key={ index }>
                                <td>#{ contactRequest.id }</td>
                                <td><span className="badge">{ contactRequest.subject }</span></td>
                                <td><a href={ `mailto:${ contactRequest.senderEmail }` } target="_blank" rel="noopener noreferrer">{ contactRequest.senderEmail }</a></td>
                                <td><LocaleDate value={ contactRequest.createdDate } day="numeric" weekday="long" month="short" year="numeric" /></td>
                                <td>{ contactRequest.modifiedDate && <LocaleDate value={ contactRequest.modifiedDate } day="numeric" weekday="long" month="short" year="numeric" /> }</td>
                                <td>{ renderSubjectBadge(contactRequest.status) }</td>
                                <td>
                                    <div className="actions-buttons">
                                        <button type="button" className="button is-small" onClick={() => history.push(`/contact-requests/${contactRequest.id}/edit`)}>
                                            <i className="material-icons">edit</i>
                                        </button>
                                        { 
                                            contactRequest.status !== ContactRequestStatusEnum.DONE && 
                                            <button type="button" className="button is-primary is-small" onClick={() => handleCheckStatusButton(contactRequest.id)}>
                                                <i className="material-icons">check</i>
                                            </button>
                                        }
                                        <button type="button" className="button is-error is-small" onClick={() => handleDeleteButton(contactRequest.id)}>
                                            <i className="material-icons">delete</i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </React.Fragment>
        );
    }

    const renderPagination = (): React.ReactElement | null => {
        if (!isInError && !isLoading) {
            return <Pagination baseUrl="/contact-requests/requests" pages={ nbrPages } activePage={ actualPage }/>;
        }

        return null;
    }

    return (
        <React.Fragment>
            <div className="portlet">
                <div className="portlet--body">
                    <SearchFilters availableTypes={ ['id', 'sender_email', 'subject'] } />
                    
                    <div className="table-responsive">
                        <table className="has-row-details">
                            { renderHeadTable() }

                            <tbody>
                                { renderBody() }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="page--header">
                { renderPagination() }
                <div>{ total } contact request(s)</div>
            </div>
        </React.Fragment>
    );
}

export default GetContactRequests;