import React from 'react';
import { useLocation } from 'react-router';

import { GetUsers } from '../../Application/GetUsers';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';
import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const UsersController: React.FunctionComponent = (): React.ReactElement => {
    const query = useQuery();

    const breadcrumb: IBreadcrumbItem[] = [
        {
            title: 'Users',
            url: null
        }
    ];

    const defaultPage = query.get('page') !== null ? Number(query.get('page')): 1;

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumb } />
            <div className="users">
                <div className="grid-wrapper">
                    <div className="page--header">
                        <h2>Users</h2>
                    </div>
                    <GetUsers actualPage={ defaultPage } />
                </div>
            </div>
        </React.Fragment>
    );
};