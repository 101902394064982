import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import {IModal} from './IModal';
import {ModalOverlay} from './ModalOverlay';
import {modalesSelector} from './Redux/modal.selectors';
import {modalRemoveActionCreator} from './Redux/modal.actions';

export const ModalUtils: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const modales = useSelector(modalesSelector);
    const handleCloseModal = () => {
        dispatch(modalRemoveActionCreator());
    }

    useEffect(() => {
        if (modales.length > 0) {
            setTimeout(() => { document.body.classList.add('is-scroll-prevented') }, 1);
        } else {
            document.body.classList.remove('is-scroll-prevented');
        }
    }, [modales]);

    const renderTitle = (title: string, titleClassname?: string): React.ReactElement => {
        const titleClass = titleClassname !== undefined ? `modal--title ${titleClassname}` : 'modal--title';

        return (
            <div className={ titleClass }>
                <h2><LocaleMessage id={ title } /></h2>
                <button type="button" className="modal--title-close" onClick={ handleCloseModal }></button>
            </div>
        );
    }


    return (
        <React.Fragment>
            { 
                modales.map((modal: IModal, index: number) => {
                    const openingAnimationClassname = modal.options !== undefined ? modal.options.openingAnimationClass : 'is-shownFromUp';
                    const extraClassname = modal.options !== undefined ? modal.options.extraClass : '';
        
                    return (
                        <div className={ `modal is-opened ${openingAnimationClassname}` } key={ index }>
                            <div className={ `modal--inner ${extraClassname}` }>
                                <div className="modal--container">
                                    { modal.title !== '' && renderTitle(modal.title) }
                                    <div className="modal--body">
                                        { modal.body }
                                    </div>
                                </div>
                            </div>
        
                            <ModalOverlay />
                        </div>
                    );
                })
            }

        </React.Fragment>
    );
}