import { AnyAction } from 'redux';
import ContactRequest from '../../Domain/ContactRequest';

import { 
    contactRequestPostInitAction, 
    contactRequestPostRequestLoadingAction, 
    contactRequestPostRequestSuccessAction, 
    ContactRequestPostRequestSuccessAction,
    contactRequestPostRequestFailureAction, 
    ContactRequestPostRequestFailureAction
} from './post-contact-request.actions';

export interface PostContactRequestReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    contactRequest: ContactRequest | null
}

const initialPostContactRequestReduxState: PostContactRequestReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: "",
    contactRequest: null
}

export const PostContactRequestReducer = (state: PostContactRequestReduxState = initialPostContactRequestReduxState, action: AnyAction): PostContactRequestReduxState => {
    switch(action.type) {
        case contactRequestPostInitAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                contactRequest: null
            };

        case contactRequestPostRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                contactRequest: null,
                errorMessage: ""
            }

        case contactRequestPostRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                contactRequest: (action as ContactRequestPostRequestSuccessAction).payload.contactRequest,
                errorMessage: ""
            }
        case contactRequestPostRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                contactRequest: null,
                errorMessage: (action as ContactRequestPostRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}