import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IconWarning from '../../../App/Infrastructure/Style/Svg/IconWarning';
import { modalRemoveActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { deleteContactRequestService } from '../Redux/delete-contact-request.services';
import { contactRequestDeleteInitActionCreator, contactRequestDeleteRequestLoadingActionCreator } from '../Redux/delete-contact-request.actions';
import { deleteContactRequestIsRequestFailureSelector, deleteContactRequestIsRequestLoadingSelector, deleteContactRequestIsRequestSuccessSelector } from '../Redux/delete-contact-request.selectors';

interface IConfirmationDeleteModalProps {
    id: number
}

const ConfirmationDeleteModal: React.FunctionComponent<IConfirmationDeleteModalProps> = ({ id }): React.ReactElement => {
    const dispatch = useDispatch();
    
    const isLoading = useSelector(deleteContactRequestIsRequestLoadingSelector);
    const isInError = useSelector(deleteContactRequestIsRequestFailureSelector);
    const requestSucceeded = useSelector(deleteContactRequestIsRequestSuccessSelector);

    const handleCloseModal = (): void => {
        dispatch(modalRemoveActionCreator());
    }

    const handleDeleteContactRequestItem = (): void => {
        if (!isLoading) {
            dispatch(contactRequestDeleteRequestLoadingActionCreator());
        }
    }

    useEffect(() => {
        if (isLoading) {
            deleteContactRequestService(id);
        }
    }, [id, isLoading]);

    useEffect(() => {
        if (isInError) {
            toast.error(<LocaleMessage id="app.something_went_wrong" />);
        }
    }, [isInError]);

    useEffect(() => {
        if (requestSucceeded) {
            dispatch(contactRequestDeleteInitActionCreator());
            dispatch(modalRemoveActionCreator());
        }
    }, [requestSucceeded, dispatch]);

    return (
        <div className="modal--confirmation">
            <div className="modal--icon">
                { IconWarning }
            </div>
            <div className="modal--information">
                <LocaleMessage id="contact_requests.delete_modal.info" />
            </div>

            <div className="modal--actions">
                <button className={ `button is-primary ${isLoading ? 'is-loading' : ''}` } onClick={ handleDeleteContactRequestItem }>
                    <LocaleMessage id="contact_requests.delete_modal.validation_button" />
                </button>
                <button className="button is-primary is-transparent" onClick={ handleCloseModal }>
                    <LocaleMessage id="modales.confirmation.cancel" />
                </button>
            </div>
        </div>
    );
}

export default ConfirmationDeleteModal;