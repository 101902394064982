import HttpClient from '../../../_utils/HttpClient/HttpClient';

import Game from '../../Domain/Game';
import { IGamesList } from '../../Domain/Scaffolding/IGamesList';

export class GamesRepository {
    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_URL);

    public get = async (limit: number = 10, page: number = 1): Promise<IGamesList> => {
        const response = await this.httpClient.get(this.baseUrl, `/games?limit=${limit}&page=${page}`);
        if (!response.data.data.docs) {
            throw new Error();
        }

        const games = response.data.data.docs.map((game: any) => {
            const newGame =  new Game(
                game.name,
                game.overworldId,
                game.value,
                game.slug,
                game.regions,
                game.versions,
                game.description,
                game.platforms,
                game.active,
                game.displayHolding,
                game.showGameIdentifier,
                game.playersCanFillScore,
                game.statApiAvailable,
                game.installPath,
                game.createdAt || game.date_creation,
            );
            newGame._id = game._id;

            return newGame;
        });

        return {
            docs: games,
            pages: response.data.data.pages,
            total: response.data.data.total,
        }
    };

    public getGameById = async (_id: string): Promise<Game> => {
        if (!_id) {
            throw new Error();
        }
        const res = await this.httpClient.get( this.baseUrl, `/games/${_id}`);
        if (!res.data.game) {
            throw new Error();
        }
        const newGame = new Game(
            res.data.game.name,
            res.data.game.overwolfId,
            res.data.game.value,
            res.data.game.slug,
            res.data.game.regions,
            res.data.game.versions,
            res.data.game.description,
            res.data.game.platforms,
            res.data.game.active,
            res.data.game.displayHolding,
            res.data.game.showGameIdentifier,
            res.data.game.playersCanFillScore,
            res.data.game.statApiAvailable,
            res.data.game.installPath,
            res.data.game.createdAt,
        );
        newGame._id =res.data.game._id;

        return newGame;
    };

    public updateGame = async (game: Game): Promise<boolean> => {
        if (!game) {
            throw new Error();
        }
        try {
            await this.httpClient.put(
                this.baseUrl,
                `/games/${game._id}`,
                game,
            );
        } catch (exception) {
            throw exception.response.data.errors;
        }

        return true;
    };

    public create = async (game: Game): Promise<Game> => {
        if (!game) {
            throw new Error();
        }

        let res: any;
        try {
            res = await this.httpClient.post(
                this.baseUrl,
                '/games',
                game
            );
        } catch (e) {
            throw e;
        }

        const newGame: Game = new Game (
            res.game.name,
            res.game.overwolfId,
            res.game.value,
            res.game.slug,
            res.game.regions,
            res.game.versions,
            res.game.description,
            res.game.platforms,
            res.game.active,
            res.game.displayHolding,
            res.game.showGameIdentifier,
            res.game.playersCanFillScore,
            res.game.statApiAvailable,
            res.game.installPath,
            res.game.createdAt,
        )
        newGame._id = res.game._id;

        return newGame;
    }

    public delete = async (_id: string): Promise<boolean> => {
        if (!_id) {
            throw new Error();
        }

        try {
            await this.httpClient.delete(
                this.baseUrl,
                `/games/${_id}`
            );
        } catch (e) {
            return false;
        }

        return true;
    }
}