import React from 'react';

const IconSettingsWidth: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M7.60008 8.97021L5.11008 11.4602C4.82008 11.7502 4.82008 12.2402 5.11008 12.5302L7.60008 15.0202M5 12H9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path opacity="0.4" d="M15 12H19M16.4004 8.97021L18.8904 11.4602C19.1804 11.7502 19.1804 12.2402 18.8904 12.5302L16.4004 15.0202" strokeWidth="1.5" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default IconSettingsWidth;