import React, { useCallback, useEffect, useRef } from 'react';

interface IRangeInputProps {
    label: string | React.ReactElement,
    inputName: string,
    value: number,
    min: number,
    max: number,
    step: number,
    unit: string,
    onInputChange: (name: string, value: string) => void
}

const RangeInput: React.FunctionComponent<IRangeInputProps> = ({ label, inputName, value, min, max, step, unit, onInputChange }): React.ReactElement => {
    const ref = useRef(null);

    const updateRangeTrack = useCallback((value: number): void => {
        const trackSize = (value - min) * 100 / (max - min);
        if (ref.current !== null) {
            //@ts-ignore
            ref.current.style.setProperty('--rangeTrackSize', `${ trackSize }%`);
        }
    }, [min, max]);

    useEffect(() => {
        updateRangeTrack(value);
    }, [value, updateRangeTrack]);

    return (
        <React.Fragment>
            <label htmlFor={ inputName }>
                { label }
            </label>
            <div className="form--group-range">
                <div className="form--group-input">
                    <input
                        type="range"
                        id={ inputName }
                        name={ inputName }
                        min={ min } max={ max } step={ step }
                        value={ value }
                        onChange={(event) => { onInputChange(inputName, event.currentTarget.value); updateRangeTrack(Number(event.currentTarget.value)); }}
                        ref={ ref }
                        />
                </div>
                <div className="form--group-range-nbr">{ value } <span className="form--group-range-nbr-pixels">{ unit }</span></div>
            </div>
        </React.Fragment>
    );
}

export default RangeInput;