import React from 'react';

interface IInputsGroupProps {
    name: string,
    children: React.ReactElement
}

const InputsGroup: React.FunctionComponent<IInputsGroupProps> = ({ name, children }): React.ReactElement => {

    return (
        <div className="input--group">
            <div className="input--group-name">
                { name }
            </div>
            { children }
        </div>
    );
}

export default InputsGroup;