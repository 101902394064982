export default {
    // App
    'app.title': 'Dashboard',
    'app.modes.light': 'Light Mode',
    'app.modes.dark': 'Dark Mode',
    'app.modes.black': 'Black Mode',
    'app.something_went_wrong': "Oops, something went wrong",
    'app.breadcrumb.dashboard': "Dashboard",
    'app.settings.page.title': "Settings",
    'app.settings.navigation.settings': "Settings",
    'app.settings.navigation.settings.general_settings': "General settings",
    'app.settings.navigation.customisation': "Customisation",
    'app.settings.navigation.customisation.theme': "Theme",
    'app.settings.settings.theme.page.title': "General settings editing",

    // APP THEME
    'app.settings.customisation.theme.page.title': "Theme editing",
    'app.settings.customisation.theme.tabs.colors': "Colors",
    'app.settings.customisation.theme.tabs.header': "Header",
    'app.settings.customisation.theme.tabs.footer': "Footer",
    'app.settings.customisation.theme.form.primary_color.label': "Primary color",
    'app.settings.customisation.theme.form.secondary_color.label': "Secondary color",
    'app.settings.customisation.theme.form.text_color.label': "Texts color",
    'app.settings.customisation.theme.form.text_headers_color.label': "Headers color",
    'app.settings.customisation.theme.form.header_variant.label': "Variant",
    'app.settings.customisation.theme.form.header_variant.choice_static': "Static",
    'app.settings.customisation.theme.form.header_variant.choice_floating': "Floating",
    'app.settings.customisation.theme.form.header_variant.choice_sticky': "Sticky top of the screen",
    'app.settings.customisation.theme.form.header_variant.additional_information': "Header can be stylized with different variants.<ul><li><strong>Static</strong> means the header won't move when scrolling.</li><li><strong>Floating</strong> means the header move when scrolling and the header width is the same as the site width.</li><li><strong>Sticky to the top</strong> means the header move when scrolling and the header width is the same as the screen width.</li></ul>",
    'app.settings.customisation.theme.form.header_color.label': "Header color",
    'app.settings.customisation.theme.form.header_text_color.label': "Navigation texts color",
    'app.settings.customisation.theme.form.header_logo_position.label': "Logo position",
    'app.settings.customisation.theme.form.header_logo_position.choice_left': "Left",
    'app.settings.customisation.theme.form.header_logo_position.choice_center': "Center",
    'app.settings.customisation.theme.form.header_background_blur.label': "Background blurred",
    'app.settings.customisation.theme.form.header_background_blur.additional_information': "When this option is actived, background opacity is set to 90% in order to create the blurred effect",
    'app.settings.customisation.theme.form.footer_color.label': "Footer color",
    'app.settings.customisation.theme.form.success': "Theme customisation has been saved successfully",

    // APP SETTINGS
    'app.settings.site.settings.tabs.info': "Information",
    'app.settings.site.settings.tabs.seo': "SEO",
    'app.settings.site.settings.tabs.header': "Header",
    'app.settings.site.settings.tabs.footer': "Footer",
    'app.settings.site.settings.form.site_name.label': "Site name",
    'app.settings.site.settings.form.site_name.placeholder': "Symbioze.org",
    'app.settings.site.settings.form.site_logo.label': "Site logo",
    'app.settings.site.settings.form.site_logo.additional_information': "Formats : *.png, *.svg",
    'app.settings.site.settings.form.site_favicon.label': "Favicon",
    'app.settings.site.settings.form.site_favicon.additional_information': "Formats : *.png. Obligatory size : 512 x 512 (en pixels)",
    'app.settings.site.settings.form.site_meta_title.label': "Meta title",
    'app.settings.site.settings.form.site_meta_title.additional_information': "&laquo;meta title&raquo; length should be between 50 and 60 caracters - {strong1}{remaining} remaining{strong2}",
    'app.settings.site.settings.form.site_meta_description.label': "Meta description",
    'app.settings.site.settings.form.site_meta_description.additional_information': "&laquo;meta description&raquo; length should not be greater than 160 caracters - {strong1}{remaining} remaining{strong2}",
    'app.settings.site.settings.form.site_default_open_graph.label': "OpenGraph image",
    'app.settings.site.settings.form.site_default_open_graph.additional_information': "Image used for shared post on social networks. This one will be used when none is defined in the pages (in the &laquo;SEO&raquo; tabs when editing a page).<br /><br /><strong>Available formats :</strong> *.png, *.jpg, *.jpeg.<br /><strong>Advised size :</strong> 1200 x 630 (in pixels)",
    'app.settings.site.settings.form.address.label': "Address",
    'app.settings.site.settings.form.header_button.label': "Enable CTA button in header",
    'app.settings.site.settings.form.header_button_label.label': "Button label",
    'app.settings.site.settings.form.header_button_label.placeholder': "Contact us",
    'app.settings.site.settings.form.header_button_navigation_id.label': "Button navigation",
    'app.settings.site.settings.form.header_button_navigation_id.additional_information': "Select the page you want to redirect the user clicking the CTA button",
    'app.settings.site.settings.form.header_button_navigation_type.label': "Navigation type",
    'app.settings.site.settings.form.header_button_navigation_type.choice_internal': "Navigation toward an internal page of the system",
    'app.settings.site.settings.form.header_button_navigation_type.choice_mailto': "Email send",
    'app.settings.site.settings.form.header_button_navigation_type.choice_manual': "Manual URL",
    'app.settings.site.settings.form.header_button_navigation_url.label': "URL",
    'app.settings.site.settings.form.header_button_navigation_mailto.label': "Email",
    'app.settings.site.settings.form.footer_button.label': "Display info button in footer",
    'app.settings.site.settings.form.footer_button_label.label': "Button label",
    'app.settings.site.settings.form.footer_button_label.placeholder': "We will be pleased to answer",
    'app.settings.site.settings.form.footer_button_navigation_id.label': "Bouton navigation",
    'app.settings.site.settings.form.footer_button_navigation_id.additional_information': "Select the page you want to redirect the user clicking the CTA button",
    'app.settings.site.settings.form.footer_text.label': "Additionnel text",
    'app.settings.site.settings.form.footer_text.placeholder': "Need information ?",
    'app.settings.site.settings.form.footer_text.additional_information': "Define a text if you want to display one upside the button. Let the input empty if you don't want one",
    'app.settings.site.settings.form.success': "Settings has been saved successfully",

    'app_theme.api_errors.missing_primary_color': "The primary color is missing",
    'app_theme.api_errors.primary_color_should_be_six_in_length': "The hexadecimal value for primary color should be 6 caracters long",
    'app_theme.api_errors.missing_secondary_color': "The secondary color is missing",
    'app_theme.api_errors.secondary_color_should_be_six_in_length': "The hexadecimal value for secondary color should be 6 caracters long",
    'app_theme.api_errors.missing_header_variant': "The header style variant is missing",
    'app_theme.api_errors.missing_header_logo_position': "The header logo position is missing",
    'app_theme.api_errors.missing_header_color': "The header color is missing",
    'app_theme.api_errors.header_color_should_be_six_in_length': "The hexadecimal value for the header color should be 6 caracters long",
    'app_theme.api_errors.missing_footer_color': "The footer color is missing",
    'app_theme.api_errors.footer_color_should_be_six_in_length': "The hexadecimal value for the footer color should be 6 caracters long",
    'app_theme.api_errors.missing_footer_contact_text': "The hexadecimal value for the footer color should be 6 caracters long",
    'app_theme.api_errors.footer_contact_text_should_be_six_length': "The hexadecimal value for the footer color should be 6 caracters long",

    'app_settings.api_errors.missing_site_name': "Site name is missing",
    'app_settings.api_errors.site_name_should_be_three_in_length': "Site name should contains at least 3 caracters",
    'app_settings.api_errors.missing_header_button_label': "Button label is missing",
    'app_settings.api_errors.header_button_label_should_be_six_length': "Button label should contains at least 6 caracters",
    'app_settings.api_errors.navigation_doesnt_exist': "The selected navigation item doesn't exist",
    'app_settings.api_errors.missing_header_button_navigation_mailto': "The mailto email is mssing",
    'app_settings.api_errors.missing_header_button_navigation_url': "URL is missing",
    'app_settings.api_errors.missing_footer_contact_text': "Contact information is missing",
    'app_settings.api_errors.footer_contact_text_should_be_six_length': "Contact information should contains at least 6 caracters",

    // Locales
    'locales.english': 'English',
    'locales.french': 'French',

    // Modales
    'modales.confirmation.title': "Confirmation",
    'modales.confirmation.delete': "Yes, delete",
    'modales.confirmation.cancel': "Cancel",

    // LOGIN
    'login.welcome.title': "Welcome ! 👋",
    'login.enter_credentials.title': "Please enter your credentials",
    'login.email.label': "Email",
    'login.email.placeholder': "john.doe@email.com",
    'login.email.missing': "Email address should not be empty",
    'login.email.too_short': "Email address should have at least 4 caracters",
    'login.password.label': "Password",
    'login.password.placeholder': "*********",
    'login.password.missing': "The password should not be empty",
    'login.password.too_short': "The password should have at least 4 caracters",
    'login.password_lost.link_label': "Password lost ?",
    'login.errors.auth.login_validator.credentials_not_found': "Email and/or password are incorrects",

    // LOGOUT
    'logout.modale.confirmation.title': "Logout",
    'logout.modale.confirmation.info': "Are you sure you want to logout from your administration panel ?",
    'logout.modale.confirmation.validation_button': "Yes, log me out",
    'logout.modale.confirmation.cancel_button': "Cancel",

    // USERS
    'users.settings.page.title': "My settings",
    'users.settings.navigation.my_account': "My account",
    'users.settings.navigation.personal_information': "Personal information",
    'users.settings.navigation.password': "Password",
    'users.settings.navigation.settings': "Settings",
    'users.settings.navigation.general_settings': "General settings",
    'users.settings.personal_information.avatar.label': "Avatar",
    'users.settings.personal_information.email.label': "Email address",
    'users.settings.personal_information.firstname.label': "First name",
    'users.settings.personal_information.lastname.label': "Last name",

    // Navigation
    'navigation.page.title': 'Navigation',
    'navigation.left.site_hierarchy': 'Site Hierarchy',
    'navigation.title.missing': 'The title is missing',
    'navigation.title.not_long_enough': 'The title must be at least 5 characters long',
    'navigation.toast.success': "Navigation updated",
    'navigation.delete.info': "Are you sure you want to delete this element of the navigation ?",
    'navigation.id.label': "ID",
    'navigation.title.label': "Title",
    'navigation.type.label': "Type",
    'navigation.type.choice_folder': "~ Folder",
    'navigation.type.choice_page': "Navigate to a page",
    'navigation.type.choice_module': "Navigate to a module",
    'navigation.page_type.label': "Page",
    'navigation.page_type.empty_value': "Please select a page",
    'navigation.page_type.missing': "Please select a page",
    'navigation.module_type.label': "Module",
    'navigation.module_type.empty_value': "Please select a module",
    'navigation.module_type.missing': "Please select a module",

    // Page
    'page.page.title': "Page list",
    'page.page.create_title': "New page creation",
    'page.page.edit_title': "Page modification",
    'page.title.missing': "The title is missing",
    'page.title.not_long_enough': "The title must be at least 5 characters long",
    'page.post.success': "Page created successfully",
    'page.put.success': "Page modified successfully",
    'page.delete.info': "Are you sure you want to delete this page ?",
    'page.delete.success': "Page deleted successfully",
    'page.id.label': "ID",
    'page.title.label': "Title",
    'page.title.placeholder': 'Example: "Who are we ?"',
    'page.created_date.label': "Created date",
    'page.modified_date.label': "Modified date",
    'page.banner.label': "Header banner",
    'page.seo_title.label': "Meta Title",
    'page.seo_title.max_length_information': "When writing &laquo;meta title&raquo;, keep it between 60 and 70 characters - <strong>{remaining} remaining</strong>",
    'page.seo_description.label': "Meta Description",
    'page.seo_description.max_length_information': "When writing &laquo;meta description&raquo;, keep it between 140 and 160 characters - <strong>{remaining} remaining</strong>",

    // Medias
    'medias.page.title': "Medias",
    'medias.navigation.medias_and_pictures': "Media & Pictures",
    'medias.navigation.file_manager': "File manager",
    'medias.navigation.settings': "Settings",
    'medias.navigation.cache_management': "Cache management",
    'medias.navigation.empty_cache': "Empty the cache",
    'medias.empty_cache_modal.title': "Empty the cache",
    'medias.empty_cache_modal.info': "Are you sure to empty the cache ?",
    'medias.empty_cache_modal.validation_button': "Yes, empty the cache",

    // Page builder
    'page_builder.page.title': "Page contents",
    'page_builder.page.header.back_button': "Back",
    'page_builder.page.header.visualize_button': "See page",
    'page_builder.page.header.publish_button': "Publish",
    'page_builder.page.back_button_modal.title': "Confirmation",
    'page_builder.page.back_button_modal.content': "Are you sure you want to exit the page builder without saving your modifications ?",
    'page_builder.page.back_button_modal.confirm_button': "Yes, exit the page builder",
    'page_builder.settings.title': "Settings",
    'page_builder.settings.please_select': "Select one of your components in your page contents to change its settings 👉",
    'page_builder.settings.display_setting': "Display",
    'page_builder.settings.display.desktop': "Display on Desktop",
    'page_builder.settings.display.mobile': "Display on Mobile",
    'page_builder.settings.disposal_setting': "Disposition",
    'page_builder.settings.style_setting': "Style",
    'page_builder.settings.component_background.type.label': "Background type",
    'page_builder.settings.component_background.type.color': "Color",
    'page_builder.settings.component_background.type.image': "Picture",
    'page_builder.settings.component_background.size_type.label': "Background size",
    'page_builder.settings.component_background.size_type.manual': "Manual",
    'page_builder.settings.component_background.size_type.cover': "Cover",
    'page_builder.settings.component_background.size_type.contain': "Contain",
    'page_builder.settings.component_background.size.label': "Image size",
    'page_builder.settings.style.component_background_image.label': "Picture",
    'page_builder.settings.style.component_background': "Component background",
    'page_builder.settings.style.component_theme': "Component theme",
    'page_builder.settings.style.component_contrast': "Contrasted elements",
    'page_builder.settings.padding_setting': "Inner margins",
    'page_builder.components.title': "Composants",
    'page_builder.components.categories.layout': "Layout",
    'page_builder.components.categories.sections': "Sections",
    'page_builder.components.categories.texts': "Texts & paragraphs",
    'page_builder.components.categories.images_media': "Images & Medias",
    'page_builder.components.categories.contact-requests': "Contact forms",
    'page_builder.page_contents.placeholder': "In order to add any content in your page, drag and drop a component above this zone from the components list to your right 👉",
    'page_builder.page_contents.delete_page_content_modal.title': "Confirmation",
    'page_builder.page_contents.delete_page_content_modal.content': "Are you sure you want to delete this element from this page ?",
    'page_builder.page_contents.delete_page_content_modal.confirm_button': "Yes, delete it",
    'page_builder.component-block.themes.default': "Paramètres par défaut",
    'page_builder.component-block.themes.dark': "Dark mode",
    'page_builder.toast.success': "Page contents modifed successfully",

    // PageBuilder - Two columns type
    'page_builder.component-layout-two-columns-type.bloc_name': "Two columns",

    // PageBuilder - Three columns type
    'page_builder.component-layout-three-columns-type.bloc_name': "Three columns",

    // Page Builder - Accordion type
    'page_builder.component-accordion-type.bloc_name': "Accordion",
    'page_builder.component-accordion-type.question.label': "Question",
    'page_builder.component-accordion-type.answer.label': "Answer",
    'page_builder.component-accordion-type.items_group.label': "Items list",
    'page_builder.component-accordion-type.empty': "Add a new element to your list using the &laquo; Add a new element &raquo; button just below",
    'page_builder.component-accordion-type.items_group.add_button_label': "Add an element",
    'page_builder.component-accordion-type.settings.properties': "Accordion properties",
    'page_builder.component-accordion-type.settings.with_borders': "Display borders",

    // Page Builder - Text type
    'page_builder.component-text-type.bloc_name': "Standard text",
    'page_builder.component-text-type.content.label': "Content",

    // Page Builder - Slideshow type
    'page_builder.component-slideshow-type.bloc_name': "Pictures slideshow",
    'page_builder.component-slideshow-type.images_group.label': "Images group",
    'page_builder.component-slideshow-type.empty': "Add a new element to your slideshow using the field &laquo; Add new image &raquo; just below",
    'page_builder.component-slideshow-type.add_image.label': "Add new image",
    'page_builder.component-slideshow-type.add_image.button': "Upload",
    'page_builder.component-slideshow-type.image_title.label': "Title",
    'page_builder.component-slideshow-type.image_description.label': "Description",
    'page_builder.component-slideshow-type.settings.properties': "Slideshow properties",
    'page_builder.component-slideshow-type.settings.effect': "Effect",
    'page_builder.component-slideshow-type.settings.effect-slide': "Slide",
    'page_builder.component-slideshow-type.settings.effect-fade': "Fade",
    'page_builder.component-slideshow-type.settings.navigation': "Display navigation",
    'page_builder.component-slideshow-type.settings.pagination': "Pagination type",
    'page_builder.component-slideshow-type.settings.pagination-dots': "With dots",
    'page_builder.component-slideshow-type.settings.pagination-thumbnails': "With thumbnails",
    'page_builder.component-slideshow-type.settings.slidesPerView': "Nbr of slides per view",
    
    // Page Builder - Galery type
    'page_builder.component-gallery-type.bloc_name': "Galery",
    'page_builder.component-gallery-type.images_group.label': "Images group",
    'page_builder.component-gallery-type.empty': "Add a new element to your galery using the field &laquo; Add new image &raquo; just below",
    'page_builder.component-gallery-type.add_image.label': "Add new image",
    'page_builder.component-gallery-type.add_image.button': "Upload",
    'page_builder.component-gallery-type.image_title.label': "Title",
    'page_builder.component-gallery-type.image_description.label': "Description",
    'page_builder.component-gallery-type.settings.desktop-settings': "Desktop settings",
    'page_builder.component-gallery-type.settings.mobile-settings': "Mobile settings",
    'page_builder.component-gallery-type.settings.nbr-columns': "Columns number",
    'page_builder.component-gallery-type.settings.border-radius': "Border radius",
    'page_builder.component-gallery-type.settings.masonry': "Masonry",
    'page_builder.component-gallery-type.settings.masonry.yes': "Yes",
    'page_builder.component-gallery-type.settings.masonry.no': "No",

    // Page Builder - Sections type
    'page_builder.component-sections-call-to-action-type.bloc_name': "Call to Action zone",
    'page_builder.component-sections-call-to-action-type.title.label': "Title",
    'page_builder.component-sections-call-to-action-type.button_label.label': "Button label",
    'page_builder.component-sections-call-to-action-type.url.label': "URL",
    'page_builder.component-sections-hero-section-type.bloc_name': "Hero section",
    'page_builder.component-sections-hero-section-type.title.label': "Title (h1)",
    'page_builder.component-sections-hero-section-type.subtitle.label': "Subtitle",
    'page_builder.component-sections-hero-section-type.align.label': "Align",
    'page_builder.component-sections-hero-section-type.align.left': "Left",
    'page_builder.component-sections-hero-section-type.align.center': "Center",
    'page_builder.component-sections-hero-section-type.align.right': "Right",
    'page_builder.component-sections-hero-section-type.label-button.label': "Button label",
    'page_builder.component-sections-hero-section-type.url.label': "URL",
    'page_builder.component-sections-hero-section-type.settings.properties': "Hero Section Settings",
    'page_builder.component-sections-hero-section-type.display_logo_brand.label': "Brand logo",
    'page_builder.component-sections-hero-section-type.display_logo_brand.yes': "Dsiplay",
    'page_builder.component-sections-hero-section-type.display_logo_brand.no': "Do not display",
    'page_builder.component-sections-hero-section-type.button_style.label': "Style CTA button",
    
    // Page Builder - Player video type
    'page_builder.component-video-player-type.bloc_name': "Video Player",
    
    // Page Builder - Contact requests type
    'page_builder.component-contact-requests-type.bloc_name': "Contact requests",
    'page_builder.component-contact-requests-type.subjects.label': "Subjects",
    'page_builder.component-contact-requests-type.subjects_subject.label': "Contact request's object",
    'page_builder.component-contact-requests-type.subjects_email.label': "Mail",
    'page_builder.component-contact-requests-type.subjects_email.info': "Let this field empty in order to use the sender email address specified in the module <a>settings</a>",
    'page_builder.component-contact-requests-type.fields.label': "Fields",
    'page_builder.component-contact-requests-type.fields_input_type.label': "Field type",
    'page_builder.component-contact-requests-type.fields_label_name.label': "Field name",
    'page_builder.component-contact-requests-type.fields_input_placeholder.label': "Placeholder",
    'page_builder.component-contact-requests-type.items_group.add_button_label': "Add an element",

    // CONTACTS
    'contact_requests.page.title': "Contact requests",
    'contact_requests.navigation.contact_requests': "Contact requests",
    'contact_requests.navigation.contact_requests_list': "Contact requests list",
    'contact_requests.navigation.contact_requests_settings': "Settings",
    'contact_requests.check_status.info': "Are you sure you want to change the status to &laquo; Processed &raquo; ?",
    'contact_requests.check_status.validation_button': "Yes, this request has been processed",
    'contact_requests.delete_modal.info': "Are you sure you want to delete this contact request ?",
    'contact_requests.delete_modal.validation_button': "Yes, delete it",
    'contact_request_settings.page.title': "Settings",
    'contact_request_settings.page.title_2': "Contact request settings",
    'contact_request_settings.sender_email.label': "Sender email",
    'contact_request_settings.sender_email.info': "The email address used by default when no email address is specified for a given subject",
    'contact_request_settings.sender_email.placeholder': "john_doe@email.com",
    'contact_request_settings.sender_email.missing': "Sender email is missing",
    'contact_request_settings.sender_email.not_long_enough': "Sender email should have at least 4 caracters",
    'contact_request_settings.sender_name.label': "Sender name",
    'contact_request_settings.sender_name.placeholder': "John Doe",
    'contact_request_settings.sender_no_reply.label': "No reply email",
    'contact_request_settings.sender_no_reply.info': "The email address used when the mail doesn't need an answer from the receiver",
    'contact_request_settings.sender_no_reply.placeholder': "no_reply@email.com",
    'contact_request_settings.sender_no_reply.missing': "No reply email is missing",
    'contact_request_settings.sender_no_reply.not_long_enough': "No reply email should have at least 4 caracters",
    'contact_request_settings.success_page_id.label': "Confirmation page",
    'contact_request_settings.success_page_id.info': "The page where the user will be redirect after the contact request is sent",
    'contact_request_settings.success_page_id.missing': "The confirmation page should ne picked",
    'contact_request_settings.provider.label': "Provider",
    'contact_request_settings.provider.placeholder': "Choose an email provider",
    'contact_request_settings.provider_api_key.label': "Provider API Key",
    'contact_request_settings.provider_api_key.placeholder': "1111-2222-3333-xxxx-wwww-zzzz",
    'contact_request_settings.provider_template_id.label': "Provider Template ID",
    'contact_request_settings.provider_template_id.placeholder': "1234",
    'contact_request_settings.put.success': "Settings modified successfully",

    'contact_request_settings.api_errors.sender_name_missing': "Sender name is missing",
    'contact_request_settings.api_errors.sender_email_missing': "Sender email is missing",
    'contact_request_settings.api_errors.sender_email_invalid': "The sender email is not a valid email address",
    'contact_request_settings.api_errors.sender_no_reply_missing': "Sender No Reply is missing",
    'contact_request_settings.api_errors.sender_no_reply_invalid': "The sender no reply email is not a valid email address",
    'contact_request_settings.api_errors.provider_missing': "At least one provider should be picked",
    'contact_request_settings.api_errors.provider_is_not_supported': "The selected provider is not supported",
    'contact_request_settings.api_errors.provider_api_key_missing': "The provider api key is missing",
    'contact_request_settings.api_errors.provider_template_id_missing': "The provider template id is missing",

    // TOOLS
    'tools.api_errors.upload_file_extension_not_permitted': "L'extension du fichier que vous avez tenté de téléverser est erroné"
};