import * as React from 'react';
import {useDispatch} from 'react-redux';
import {modalRemoveActionCreator} from './Redux/modal.actions';

export const ModalOverlay: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const handleCloseModal = () => {
        dispatch(modalRemoveActionCreator());
    }

    return (
        <div className="modal--overlay" onClick={ handleCloseModal }></div>
    );
}