import Component from '../../Domain/Component';
import ComponentCategory from '../../Domain/ComponentCategory';
import ComponentTypeEnum from '../../Domain/Enum/ComponentTypeEnum';

class StaticComponentTypesRepository {

    public get = async (): Promise<ComponentCategory[]> => {

        return [
            //new ComponentCategory(
            //    'page_builder.components.categories.layout',
            //    'layout',
            //    [
            //        new Component(
            //            ComponentTypeEnum.LayoutTwoColumns
            //        ),
            //        new Component(
            //            ComponentTypeEnum.LayoutThreeColumns
            //        )
            //    ]
            //),
            new ComponentCategory(
                'page_builder.components.categories.texts',
                'texts',
                [
                    new Component(
                        ComponentTypeEnum.Text
                    ),
                    new Component(
                        ComponentTypeEnum.Accordion
                    )
                ]
            ),
            new ComponentCategory(
                'page_builder.components.categories.sections',
                'sections',
                [
                    new Component(
                        ComponentTypeEnum.sectionsHeroSection
                    ),
                    new Component(
                        ComponentTypeEnum.sectionsCallToAction
                    )
                ]
            ),
            new ComponentCategory(
                'page_builder.components.categories.images_media',
                'images_media',
                [
                    new Component(
                        ComponentTypeEnum.Slideshow
                    ),
                    new Component(
                        ComponentTypeEnum.Gallery
                    ),
                    new Component(
                        ComponentTypeEnum.VideoPlayer
                    )
                ]
            ),
            new ComponentCategory(
                'page_builder.components.categories.contact-requests',
                'contact-requests',
                [
                    new Component(
                        ComponentTypeEnum.ContactRequests
                    )
                ]
            )
        ];
    }

}

export default StaticComponentTypesRepository;