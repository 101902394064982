import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';
import { modalAddActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { HandleNavigation } from '../../Application/Forms/HandleNavigation';
import { GetNavigation } from '../../Application/GetNavigation';
import IconArrowUp from '../../../App/Infrastructure/Style/Svg/IconArrowUp';
import IconArrowDown from '../../../App/Infrastructure/Style/Svg/IconArrowDown';
import CreateNavigationModalController from './CreateNavigationModalController';
import DeleteNavigationModalController from './DeleteNavigationModalController';

export const NavigationController: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const [navigationId, setNavigationId] = useState<number>(0);
    const [navigationItemsOpened, setNavigationItemsOpened] = useState<boolean>(true);
    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            title: 'navigation.page.title',
            url: null
        }
    ];

    const handleChangeNavigation = (id: number, title: React.ReactNode): void => {
        setNavigationId(id);
    }

    const handleCreateNavigationModal = (): void => {
        dispatch(
            modalAddActionCreator(
                <CreateNavigationModalController />,
                'Créer un élément de navigation'
            )
        );
    }

    const handleDeleteNavigation = (id: number): void => {
        dispatch(
            modalAddActionCreator(
                <DeleteNavigationModalController id={ id } />,
                'modales.confirmation.title'
            )
        );
    }

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumbItems } />
            <div className="navigation">

                <div className="grid-wrapper">
                    <div className="page--header">
                        <h2><LocaleMessage id="navigation.page.title" /></h2>

                        <div className="page--actions">
                            <button className="button is-primary" onClick={ handleCreateNavigationModal }>Create</button>
                        </div>
                    </div>

                    <div className="navigation--columns">
                        <div className="navigation--columns-left">
                            <div className="portlet">
                                <div className="portlet--title">
                                    <div className="portlet--title-label">
                                        <LocaleMessage id="navigation.left.site_hierarchy" />
                                    </div>
                                    <div className="portlet--title-toolbar">
                                        <button type="button" className="button is-navigation-items-opener" onClick={() => setNavigationItemsOpened(!navigationItemsOpened)}>
                                            { navigationItemsOpened ? IconArrowUp : IconArrowDown }
                                        </button>
                                    </div>
                                </div>
                                <div className="portlet--body" style={{ display: `${ navigationItemsOpened ? 'block' : 'none' }` }}>
                                    <GetNavigation handleNavigationEdit={(id: number, title: React.ReactNode) => handleChangeNavigation(id, title)} />
                                </div>
                            </div>
                        </div>
                        {
                            navigationId !== 0 &&
                            <div className="navigation--columns-right">
                                <div className="portlet">
                                    <div className="portlet--title">
                                        <div className="portlet--title-label">
                                            Modification de la navigation
                                        </div>
                                        <div className="portlet--title-toolbar">
                                            <button type="button" className="button is-red" onClick={ () => handleDeleteNavigation(navigationId) }>
                                                <i className="material-icons">delete</i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="portlet--body">
                                        <HandleNavigation id={ navigationId } />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}