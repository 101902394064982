import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { IScaffoldingNavigationItem } from './IScaffoldingNavigationItem';

import IconFolder from '../../App/Infrastructure/Style/Svg/IconFolder';
import IconArrowDown from '../../App/Infrastructure/Style/Svg/IconArrowDown';

interface IScaffoldingNavigationCategory {
    item: IScaffoldingNavigationItem
}

const ScaffoldingNavigationCategory: React.FunctionComponent<IScaffoldingNavigationCategory> = ({ item }): React.ReactElement => {
    const navigationListRef = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useState<boolean>(false);

    useEffect(() => {
        //@ts-ignore
        const { scrollHeight } = navigationListRef.current !== null ? navigationListRef.current : 0;

        if (navigationListRef.current !== null) {
            if (isOpened) {
                navigationListRef.current.style.setProperty('--scaffolding-navigation-height', `0px`);
            } else {
                navigationListRef.current.style.setProperty('--scaffolding-navigation-height', `${ scrollHeight }px`);
            }
        }

    }, [isOpened]);

    const renderNavigationItems = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        item.childrens.forEach((item: IScaffoldingNavigationItem, index: number) => {
            elements.push(
                <NavLink className="scaffolding--navigation-items-list-item" to={ String(item.url) } activeClassName="is-active" exact={ true } key={ index }>
                    <LocaleMessage id={ item.title } />
                </NavLink>
            );
        });

        return elements;
    }

    return (
        <React.Fragment>
            <div className={ `scaffolding--navigation-category ${ isOpened ? 'is-opened' : '' }` } onClick={ () => setIsOpened(!isOpened) }>
                <div className="scaffolding--navigation-category-icon">
                    { IconFolder }
                </div>
                <div className="scaffolding--navigation-category-title">
                    <LocaleMessage id={ item.title } />
                </div>
                <div className="scaffolding--navigation-category-arrow">
                    { IconArrowDown }
                </div>
            </div>
            <div className="scaffolding--navigation-items-list" ref={ navigationListRef }>
                { renderNavigationItems() }
            </div>
        </React.Fragment>
    );
}

export default ScaffoldingNavigationCategory;