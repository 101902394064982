import {AnyAction} from 'redux';

import {appOpenMenuAction, AppOpenMenuAction} from './app.actions';
import {appCloseMenuAction, AppCloseMenuAction} from './app.actions';
import {appLightModeAction, AppLightModeAction} from './app.actions';
import {appDarkModeAction, AppDarkModeAction} from './app.actions';
import {AppBlackModeAction, appBlackModeAction} from './app.actions';

export interface ApplicationReduxState {
    menuOpened: boolean,
    theme: string
}

const defaultOSMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark-mode' : 'light-mode';
const initialApplicationReduxState: ApplicationReduxState = {
    menuOpened: false,
    theme: defaultOSMode
}

export const applicationReducer = (state: ApplicationReduxState = initialApplicationReduxState, action: AnyAction) => {
    switch(action.type) {

        case appOpenMenuAction: {
            return {
                ...state,
                menuOpened: (action as AppOpenMenuAction).payload.menuOpened
            };
        }

        case appCloseMenuAction: {
            return {
                ...state,
                menuOpened: (action as AppCloseMenuAction).payload.menuOpened
            }
        }

        case appLightModeAction: {
            return {
                ...state,
                theme: (action as AppLightModeAction).payload.theme
            }
        }

        case appDarkModeAction: {
            return {
                ...state,
                theme: (action as AppDarkModeAction).payload.theme
            }
        }

        case appBlackModeAction: {
            return {
                ...state,
                theme: (action as AppBlackModeAction).payload.theme
            }
        }
    }

    return state;
}