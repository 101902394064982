 import * as React from 'react';

export const HandleCreateTournament: React.FunctionComponent = () => {

    return (
        <div className="form-wizard m-t-10 m-b-30">
            <ul className="tabs">
                <li className="is-active">
                    <i className="fas fa-pencil"></i>&nbsp;Tournament
                </li>
                <li>
                    <i className="fas fa-pencil"></i>&nbsp;Bracket
                </li>
            </ul>
            <div className="tabs--tab-content">
                <div className="tabs--tab-pane is-active">
                    <form action="#" className="form-horizontal">
                        <div className="form--group">
                            <label htmlFor="tournamentName">Title</label>
                            <div className="form--group-input">
                                <input name="tournamentName" type="text" placeholder="La bombe ou la mort" />
                            </div>
                        </div>
                        <div className="form--group">
                            <label htmlFor="">Tournament type</label>
                            <div className="form--group-input">
                                <select name="" id="">
                                    <option value="bracket">Bracket</option>
                                    <option value="ladder">Ladder</option>
                                </select>
                            </div>
                        </div>
                        <div className="form--group">
                            <label htmlFor="" className="col-md-3 control-label">Status</label>
                            <div className="form--group-input">
                                <div className="radio radio-primary">
                                    <input type="radio" checked={ true } value="1" name="status" id="liste" />
                                    <label htmlFor="liste">Fiche + Liste</label>
                                    <input type="radio" value="2" name="status" id="fiche" />
                                    <label htmlFor="fiche">Fiche uniquement</label>
                                    <input type="radio" value="3" name="status" id="disabled" />
                                    <label htmlFor="disabled">Hors ligne</label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}