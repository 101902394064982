import React from 'react';

const IconDeviceMobile: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <path d="M20 7V17C20 21 19 22 15 22H9C5 22 4 21 4 17V7C4 3 5 2 9 2H15C19 2 20 3 20 7Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M14 5.5H10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M12.0002 19.1C12.8562 19.1 13.5502 18.406 13.5502 17.55C13.5502 16.694 12.8562 16 12.0002 16C11.1442 16 10.4502 16.694 10.4502 17.55C10.4502 18.406 11.1442 19.1 12.0002 19.1Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
);

export default IconDeviceMobile;