import React from 'react';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../../../../../store';

import PageContent from '../../../../Domain/PageContent';

import { IComponentTextData } from '../../../../Application/Forms/ComponentType/Texts/IComponentTextData';
import { pageBuilderPageContentSelector } from '../../../Redux/page-builder.selectors';

interface IDisplayComponentTextProps {
    id: string
}

const DisplayComponentText: React.FunctionComponent<IDisplayComponentTextProps> = ({ id }): React.ReactElement => {
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, id));
    const data: IComponentTextData = pageContent.data as IComponentTextData;

    return (
        <React.Fragment>
            <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: data.content }}></div>
        </React.Fragment>
    );
}

export default DisplayComponentText;