import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { modalRemoveActionCreator } from '../../../_utils/Modal/Redux/modal.actions';

import { HandleNavigation } from '../../Application/Forms/HandleNavigation';
import { navigationIsPostRequestSuccessSelector } from '../Redux/navigation.selectors';

const CreateNavigationModalController: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const isPostRequestSuccess = useSelector(navigationIsPostRequestSuccessSelector);

    const handleCloseModal = useCallback((): void => {
        dispatch(modalRemoveActionCreator());
    }, [dispatch]);

    useEffect(() => {
        if (isPostRequestSuccess) {
            handleCloseModal();
        }
    }, [isPostRequestSuccess, handleCloseModal]);

    return (
        <div className="navigation--modal">
            <HandleNavigation id={ undefined } />
        </div>
    );
}

export default CreateNavigationModalController;