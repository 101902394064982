import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Navigation from './Navigation';
import LogoBrand from '../Style/Svg/LogoBrand';

import { appMenuOpenedSelector } from '../Redux/App/app.selectors';
import { userCurrentInformation } from '../../../User/Infrastructure/Redux/user.selectors';
import { appCloseMenuActionCreator, appOpenMenuActionCreator } from '../Redux/App/app.actions';

export const Header: React.FunctionComponent = (): React.ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(userCurrentInformation);
    const mobileMenuOpened = useSelector(appMenuOpenedSelector);

    const handleOpenMobileMenu = (): void => {
        if (mobileMenuOpened) {
            dispatch(appCloseMenuActionCreator());
        } else {
            dispatch(appOpenMenuActionCreator());
        }
    }

    return (
        <header data-testid="app-header">
            <div className="header">
                <div className="grid-wrapper">
                    <div className="header--container">
                        <div className={ `header--mobile-menu ${mobileMenuOpened ? 'is-opened' : '' }` } onClick={() => handleOpenMobileMenu() }>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div className="header--brand">
                            <Link to="/">
                                { LogoBrand }
                            </Link>
                        </div>
                        <Navigation />
                        <div className="header--right">
                            <div className="header--profile">
                               {
                                    user !== null && 
                                    <div className="header--profile-user" onClick={() => history.push('/users/settings/info')}>
                                        <span> { `${user.firstName} ${user.lastName}` }</span> <div className="header--profile-user-avatar">{ `${ user.firstName.substring(0, 1) }${ user.lastName.substring(0, 1)}` }</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ `header--navigation-mobile ${ mobileMenuOpened ? 'is-opened' : '' }` }>
                <Navigation />
            </div>
        </header>
    );
};