import React, { useState } from 'react';
import { useEffectOnce } from 'react-use';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import Component from '../Domain/Component';
import ComponentCategory from '../Domain/ComponentCategory';

import ComponentDraggableItem from './ComponentDraggableItem';
import ComponentsDroppableColumn from './ComponentsDroppableColumn';
import GroupCollapse from '../Infrastructure/Partials/GroupCollapse';
import IconComponents from '../Infrastructure/Style/svg/IconComponents';
import LoadingSpinner from '../../App/Infrastructure/Style/Svg/LoadingSpinner';
import StaticComponentTypesRepository from '../Infrastructure/Repositories/StaticComponentTypesRepository';

const staticComponentTypesRepository: StaticComponentTypesRepository = new StaticComponentTypesRepository();

const GetComponents: React.FunctionComponent = (): React.ReactElement => {
    const [componentCategories, setComponentCategories] = useState<ComponentCategory[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInError, setIsInError] = useState<boolean>(false);

    useEffectOnce(() => {
        staticComponentTypesRepository.get()
            .then((componentCategories) => {
                setComponentCategories(componentCategories);
                setIsLoading(false);
            }, () => {
                setIsInError(true);
                setIsLoading(false);
            })
    });

    const renderComponentCategories = (): React.ReactElement[] => {
        if (isLoading) {

            return [<div className="icons is-loading" key={ 0 }>{ LoadingSpinner }</div>];
        }

        if (isInError) {

            return [<LocaleMessage id="app.something_went_wrong" key={ 0 } />];
        }

        let draggableIndex = 0;

        return componentCategories.map((componentCategorie: ComponentCategory, index: number) => {

            return (
                <GroupCollapse label={ componentCategorie.label } defaultState="opened" key={ index }>
                    <ComponentsDroppableColumn>
                        {
                            componentCategorie.components.map((component: Component) => {
                                draggableIndex++;
                                return <ComponentDraggableItem index={ draggableIndex } type={ component.type} key={ draggableIndex } />;
                            })
                        }
                    </ComponentsDroppableColumn>
                </GroupCollapse>
            )
        });
    }

    return (
        <div className="page-builder--components">
            <div className="page-builder--components-title">
                { IconComponents }&nbsp;<LocaleMessage id="page_builder.components.title" />
            </div>

            { renderComponentCategories() }
        </div>
    );
}

export default GetComponents;