import NavigationType from './NavigationType';
import NavigationCollection from './NavigationCollection';

class Navigation {

    public id: number;
    public title: string;
    public type: NavigationType;
    public pageId: number | null;
    public moduleSlug: string | null;
    public parentId: number;
    public order: number;
    public childrens: NavigationCollection | null;

    public constructor(id: number, title: string, type: NavigationType, pageId: number | null, moduleSlug: string | null, parentId: number, order: number, childrens: NavigationCollection | null) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.pageId = pageId;
        this.moduleSlug = moduleSlug;
        this.parentId = parentId;
        this.order = order;
        this.childrens = childrens;
    }
}

export default Navigation;