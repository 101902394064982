import { toast } from 'react-toastify';
import { store } from '../../../store';

import AppTheme from '../../Domain/AppTheme';
import AppSettings from '../../Domain/AppSettings';
import PageContent from '../../Domain/PageContent';
import PageContentsCollection from '../../Domain/PageContentsCollection';

import AppThemeRepository from '../Repositories/AppThemeRepository';
import AppSettingsRepository from '../Repositories/AppSettingsRepository';
import PageContentsRepository from '../Repositories/PageContentsRepository';
import { pageBuilderUpdateAllPageContentDataActionCreator } from './page-builder.actions';
import { pageBuilderAppSettingsSuccessActionCreator, pageBuilderAppThemeSuccessActionCreator, pageBuilderPageContentFailureActionCreator, pageBuilderPageContentSuccessActionCreator } from './page-builder-get.actions';

const appThemeRepository: AppThemeRepository = new AppThemeRepository();
const appSettingsRepository: AppSettingsRepository = new AppSettingsRepository();
const pageContentsRepository: PageContentsRepository = new PageContentsRepository();

export const getPageContentsService = (pageId: number): void => {

    pageContentsRepository.getPageContents(pageId)
        .then((pageContentsCollection: PageContentsCollection) => {

            const ids: string[] = [];
            const pageContentItems: Record<string, PageContent> = {};

            pageContentsCollection.getPageContentByPosition().forEach((pageContent: PageContent) => {
                pageContentItems[pageContent.id] = pageContent;
                ids.push(pageContent.id);
            });

            store.dispatch(pageBuilderPageContentSuccessActionCreator(pageContentsCollection.getPageContentByPosition()));
            store.dispatch(pageBuilderUpdateAllPageContentDataActionCreator(ids, pageContentItems));
        }, (error: any) => {

            store.dispatch(pageBuilderPageContentFailureActionCreator(error.response.data.message));
        });
}

export const getAppThemeService = (): void => {

    appThemeRepository.get()
        .then((theme: AppTheme) => {

            store.dispatch(pageBuilderAppThemeSuccessActionCreator(theme));
        }, () => {

            toast.error('App Theme load error', { position: 'bottom-center' });
        });
}

export const getAppSettingsService = (): void => {

    appSettingsRepository.get()
        .then((settings: AppSettings) => {

            store.dispatch(pageBuilderAppSettingsSuccessActionCreator(settings));
        }, () => {

            toast.error('App Settings load error', { position: 'bottom-center' });
        });
}