import { AxiosRequestConfig } from 'axios';
import { authHeader } from '../../../_utils/HttpClient/_helpers/auth-header';

import AuthToken from '../../Domain/AuthToken';
import HttpClient from '../../../_utils/HttpClient/HttpClient';

class AuthRepository {

    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);
    private httpClient: HttpClient = new HttpClient();

    public login = async (email: string, password: string): Promise<AuthToken> => {
        const data = new FormData();
        data.append('grant_type', "password");
        data.append('username', email);
        data.append('password', password);
        data.append('client_id', String(process.env.REACT_APP_CLIENT_ID));
        data.append('client_secret', String(process.env.REACT_APP_CLIENT_SECRET));

        const options: AxiosRequestConfig = {
            headers: {
                'Authorization' : authHeader(),
                'content-type'  : 'application/x-www-form-urlencoded'
            }
        }

        const response = await this.httpClient.post(this.baseUrl, `/token`, data, options);
        if (!response.data.hasOwnProperty('access_token')) {
            throw new Error('No access token found');
        }

        const authToken: AuthToken = {
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
            typeToken: response.data.token_type
        };

        return authToken;
    }

    public refreshToken = async (refreshToken: string): Promise<AuthToken> => {
        const data = new FormData();
        data.append('grant_type', "refresh_token");
        data.append('refresh_token', refreshToken);
        data.append('client_id', String(process.env.REACT_APP_CLIENT_ID));
        data.append('client_secret', String(process.env.REACT_APP_CLIENT_SECRET));

        const options: AxiosRequestConfig = {
            headers: {
                'Authorization' : authHeader(),
                'content-type'  : 'application/x-www-form-urlencoded'
            }
        }

        const response = await this.httpClient.post(this.baseUrl, `/token`, data, options);
        if (!response.data.hasOwnProperty('access_token')) {
            throw new Error('No access token found');
        }

        const authToken: AuthToken = {
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
            typeToken: response.data.token_type
        };

        return authToken;
    }

}

export default AuthRepository;