import React from 'react';

const IconVideoPlayer: React.ReactElement = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <g transform="translate(2.000000, 2.000000)">
            <path d="M20,13 L20,7 C20,2 18,0 13,0 L7,0 C2,0 0,2 0,7 L0,13 C0,18 2,20 7,20 L13,20 C18,20 20,18 20,13 Z"></path>
            <g id="IconVideoPlayerGroup" opacity="0.4" transform="translate(0.519530, 0.110350)">
                <line x1="4.4408921e-16" y1="5" x2="18.95997" y2="5"></line>
                <line x1="6" y1="-4.4408921e-16" x2="6" y2="4.86"></line>
                <line x1="12.96097" y1="-4.4408921e-16" x2="12.96097" y2="4.41"></line>
            </g>
            <path d="M7.75,12.4501 L7.75,11.2501 C7.75,9.7101 8.84,9.0801 10.17,9.8501 L11.21,10.4501 L12.25,11.0501 C13.58,11.8201 13.58,13.0801 12.25,13.8501 L11.21,14.4501 L10.17,15.0501 C8.84,15.8201 7.75,15.1901 7.75,13.6501 L7.75,12.4501 L7.75,12.4501 Z" opacity="0.4"></path>
        </g>
    </svg>
);

export default IconVideoPlayer;