import HttpClient from '../../../_utils/HttpClient/HttpClient';

import AppSettings from '../../Domain/AppSettings';

class AppSettingsRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    private fromJson = (response: any): AppSettings => {
        const settings = new AppSettings(
            response.site_name,
            response.site_logo,
            response.address,
            response.header_button,
            response.header_button_label,
            response.footer_button,
            response.footer_button_label,
            response.footer_text
        );

        return settings;
    }

    public get = async (): Promise<AppSettings> => {
        const response = await this.httpClient.get(this.baseUrl, `/settings`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No settings found');
        }

        return this.fromJson(response.data.docs);
    }

}

export default AppSettingsRepository;