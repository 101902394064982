import React from 'react';

const IconPageStyleContained: React.ReactElement = (
    <svg viewBox="0 0 100 100" version="1.1">
        <rect id="Rectangle" fillOpacity="0.05" x="0" y="0" width="100" height="100"></rect>
        <rect id="Rectangle" fillOpacity="0.1" x="15" y="25" width="70" height="75"></rect>
        <rect id="Rectangle-Copy-6" fillOpacity="0.05" x="0" y="30" width="15" height="70"></rect>
        <rect id="Rectangle-Copy-8" fillOpacity="0.05" x="25" y="10" width="50" height="4"></rect>
        <rect id="Rectangle-Copy-7" fillOpacity="0.05" x="85" y="30" width="15" height="70"></rect>
        <rect id="Rectangle-Copy-9" fillOpacity="0.05" x="15" y="45" width="70" height="1"></rect>
        <rect id="Rectangle-Copy-11" fillOpacity="0.05" x="15" y="66" width="70" height="1"></rect>
        <rect id="Rectangle-Copy-12" fillOpacity="0.05" x="15" y="87" width="70" height="1"></rect>
    </svg>
);

export default IconPageStyleContained;