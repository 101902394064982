import React, { useContext } from 'react';
import {useSelector} from 'react-redux';
import {IntlContext, IntlProvider, IntlShape} from 'react-intl';

import enUS from '../Translations/en-US';
import frFR from '../Translations/fr-FR';
import {localeSelector} from '../locale.selectors';

export const locales: {[key: string]: Record<string, string>} = {'en-US': enUS, 'fr-FR': {...enUS, ...frFR}};
//@ts-ignore
export const useLocale = () => useContext<IntlShape>(IntlContext);
export const LocaleProvider: React.FC = ({children}) => {
    const locale = useSelector(localeSelector);
    return (
        <IntlProvider locale={locale} messages={locales[locale]}>
            <React.Fragment>
                {children}
            </React.Fragment>
        </IntlProvider>
    );
};
