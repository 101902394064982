import HttpClient from '../../../_utils/HttpClient/HttpClient';
import ContactRequest from '../../Domain/ContactRequest';

import ContactRequestsCollection from '../../Domain/ContactRequestsCollection';
import { ContactRequestStatusEnum } from '../../Domain/Enum/ContactRequestStatusEnum';
import { IContactRequestsList } from '../../Domain/Scaffolding/IContactRequestsList';

class ContactRequestsRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    public get = async (page: number, query: string): Promise<IContactRequestsList> => {
        const response = await this.httpClient.get(this.baseUrl, `/contact-requests?page=${page}&${query}`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No contact-requests found');
        }

        const contactRequestsCollection = new ContactRequestsCollection();

        response.data.docs.forEach((contactRequest: any) => {
            contactRequestsCollection.push(
                this.fromJson(contactRequest)
            )
        });

        return {
            docs: contactRequestsCollection,
            pages: response.data.pages,
            total: response.data.total
        }
    }

    public getById = async(id: number): Promise<ContactRequest> => {
        const response = await this.httpClient.get(this.baseUrl, `/contact-requests/${id}`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No contact-request found');
        }

        return this.fromJson(response.data.docs[0]);
    }

    public put = async (id: number, status: ContactRequestStatusEnum): Promise<ContactRequest> => {
        const dataBody = new FormData();
        dataBody.append('status', status);

        const response = await this.httpClient.put(this.baseUrl, `/contact-requests/${id}`, dataBody);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No contact-request found');
        }

        return this.fromJson(response.data.docs[0]);
    }

    public delete = async (id: number): Promise<any> => {
        const response = await this.httpClient.delete(this.baseUrl, `/contact-requests/${id}`);

        return response;
    }

    private fromJson = (contactRequest: any): ContactRequest => {

        return new ContactRequest(
            contactRequest.id,
            contactRequest.subject,
            contactRequest.sender_email,
            contactRequest.content,
            contactRequest.status,
            contactRequest.created_date,
            contactRequest.modified_date,
            contactRequest.modified_by
        );
    }

}

export default ContactRequestsRepository;