import * as React from 'react';

const LogoPageBuilderIcon = (
    <svg viewBox="0 0 26 26">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g>
                <path d="M10.2555408,-4.14336123e-16 L15.7444592,4.14336123e-16 C19.3105342,-2.4074122e-16 20.6036791,0.371302445 21.9073828,1.06853082 C23.2110865,1.76575919 24.2342408,2.78891348 24.9314692,4.09261719 C25.6286976,5.39632089 26,6.68946584 26,10.2555408 L26,15.7444592 C26,19.3105342 25.6286976,20.6036791 24.9314692,21.9073828 C24.2342408,23.2110865 23.2110865,24.2342408 21.9073828,24.9314692 C20.6036791,25.6286976 19.3105342,26 15.7444592,26 L10.2555408,26 C6.68946584,26 5.39632089,25.6286976 4.09261719,24.9314692 C2.78891348,24.2342408 1.76575919,23.2110865 1.06853082,21.9073828 C0.371302445,20.6036791 1.60494146e-16,19.3105342 -2.76224082e-16,15.7444592 L2.76224082e-16,10.2555408 C-1.60494146e-16,6.68946584 0.371302445,5.39632089 1.06853082,4.09261719 C1.76575919,2.78891348 2.78891348,1.76575919 4.09261719,1.06853082 C5.39632089,0.371302445 6.68946584,2.4074122e-16 10.2555408,-4.14336123e-16 Z" fill="#E0E34F"></path>
                <circle fill="#1B1B1B" cx="18.25" cy="15.25" r="1.25"></circle>
                <path d="M11.280533,10.6489268 C11.280533,10.0349371 11.8418949,9.71917101 12.5611399,9.71917101 C13.3505552,9.71917101 13.9119172,10.0524797 14.2101407,10.719097 L15.8065138,9.82442637 C15.1925241,8.68415989 14.0171725,8 12.5611399,8 C11.6489268,8 10.8770541,8.24559586 10.2630644,8.73678757 C9.64907477,9.22797929 9.35085123,9.87705406 9.35085123,10.6840119 C9.3157661,12.5259808 11.0875648,13.0522577 12.4032569,13.3855663 C13.3330126,13.6136196 14.0698002,13.9469283 14.0698002,14.5082902 C14.0698002,15.157365 13.5786085,15.4906737 12.5786825,15.4906737 C11.5787565,15.4906737 10.9296817,15.0696522 10.6314582,14.2276093 L9,15.1749076 C9.56136196,16.4555145 10.8419689,17.2273872 12.5786825,17.2273872 C13.5610659,17.2273872 14.3680238,16.9817914 15.0170985,16.5081422 C15.6661733,16.0344931 15.999482,15.3678757 15.999482,14.5258328 C16.0345671,12.6487787 14.280311,12.0874168 12.9295337,11.7891932 C12.0173205,11.5260548 11.280533,11.2278313 11.280533,10.6489268 Z" fill="#1B1B1B" fillRule="nonzero"></path>
            </g>
        </g>
    </svg>
);

export default LogoPageBuilderIcon;