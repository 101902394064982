import React, { useEffect, useState } from 'react';

import IconGalery from '../../../App/Infrastructure/Style/Svg/IconGalery';
import IconPicture from '../../../App/Infrastructure/Style/Svg/IconPicture';

interface IDisplayFileProps {
    fileName: string,
    fileExtension: string,
    fileSize: number,
    path: string
}

const DisplayFile: React.FunctionComponent<IDisplayFileProps> = ({ fileName, fileExtension, fileSize, path }): React.ReactElement => {
    const [isImage, setIsImage] = useState<boolean>(false);

    useEffect(() => {
        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'bmp':
            case 'gif':
            case 'png':
                setIsImage(true);
                break;
        
            default:
                setIsImage(false);
                break;
        }
    }, [fileExtension]);

    const renderBackgroundImage = (): string | undefined => {
        const pathWithoutPublicString = path.replace('public/', '');
        const fileUrl: string = `${process.env.REACT_APP_URL}/${pathWithoutPublicString}${fileName}.${fileExtension}`;

        if (isImage) {

            return fileUrl;
        }

        return undefined;
    }

    const humanFileSize = (size: number): string => {
        if(size === 0){

            return '';
        }

        var i = Math.floor( Math.log(size) / Math.log(1024) );

        //@ts-ignore
        return `${ ( size / Math.pow(1024, i) ).toFixed(2) * 1 } ${ ['B', 'kB', 'MB', 'GB', 'TB'][i] }`
    }

    return (
        <div className={ `file-manager--list-file ${ isImage ? 'is-image' : '' }` } style={{ backgroundImage: `url(${ renderBackgroundImage() })` }}>
            <div className="file-manager--list-file-icon">
                { isImage ? IconPicture : IconGalery }
            </div>
            <div className="file-manager--list-file-details">
                { isImage ? <div className="file-manager--list-file-details-image"><img src={ renderBackgroundImage() } /></div> : <React.Fragment></React.Fragment> }
                <div className="file-manager--list-file-details-name">
                    { fileName }.{ fileExtension }
                </div>
                <div className="file-manager--list-file-details-items">
                    { humanFileSize(fileSize) }
                </div>
            </div>
        </div>
    )
}

export default DisplayFile;