import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContentsDroppableColumn from './PageContentsDroppableColumn';
import { pageBuilderPostService } from '../Infrastructure/Redux/page-builder-post.services';
import { pageBuilderOnDragEndService } from '../Infrastructure/Redux/page-builder.services';
import { pageBuilderModeEditorSelector, pageBuilderOnDragEndResultSelector } from '../Infrastructure/Redux/page-builder.selectors';
import { pageBuilderPageContentGetFailureSelector, pageBuilderPageContentGetLoadingSelector } from '../Infrastructure/Redux/page-builder-get.selectors';
import { pageBuilderIsPostFailureSelector, pageBuilderIsPostLoadingSelector, pageBuilderIsPostSuccessSelector } from '../Infrastructure/Redux/page-builder-post.selectors';

import LoadingSpinner from '../../App/Infrastructure/Style/Svg/LoadingSpinner';
import PageBuilderPreview from '../Infrastructure/Partials/PageBuilderPreview';
import { getAppSettingsService, getAppThemeService, getPageContentsService } from '../Infrastructure/Redux/page-builder-get.services';
import { pageBuilderSelectPageContentActionCreator } from '../Infrastructure/Redux/page-builder.actions';
import { pageBuilderPageContentLoadingActionCreator } from '../Infrastructure/Redux/page-builder-get.actions';
import { pageBuilderPageContentPostInitActionCreator } from '../Infrastructure/Redux/page-builder-post.actions';

interface IGetPageContentsProps {
    pageId: number
}

const GetPageContents: React.FunctionComponent<IGetPageContentsProps> = ({ pageId }): React.ReactElement => {
    const dispatch = useDispatch();
    const result                = useSelector(pageBuilderOnDragEndResultSelector);
    const editorMode            = useSelector(pageBuilderModeEditorSelector);
    const isGetQueryLoading     = useSelector(pageBuilderPageContentGetLoadingSelector);
    const isGetQueryFailure     = useSelector(pageBuilderPageContentGetFailureSelector);
    const isPostRequestLoading  = useSelector(pageBuilderIsPostLoadingSelector);
    const isPostRequestSuccess  = useSelector(pageBuilderIsPostSuccessSelector);
    const isPostRequestFailure  = useSelector(pageBuilderIsPostFailureSelector);

    useEffectOnce(() => {
        if (!isGetQueryLoading) {
            dispatch(pageBuilderSelectPageContentActionCreator(null));
            dispatch(pageBuilderPageContentLoadingActionCreator());
        }
    });

    useEffect(() => {
        if(isPostRequestLoading) {
            pageBuilderPostService(pageId);
        }
    }, [isPostRequestLoading, pageId]);

    useEffect(() => {
        if(isPostRequestSuccess) {
            toast.success(<LocaleMessage id="page_builder.toast.success" />, { position: 'bottom-center' });
            dispatch(pageBuilderPageContentPostInitActionCreator());
        }
    }, [isPostRequestSuccess, dispatch]);

    useEffect(() => {
        if(isPostRequestFailure) {
            toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });
        }
    }, [isPostRequestFailure]);

    useEffect(() => {
        if (result !== null) {
            pageBuilderOnDragEndService();
        }
    }, [result]);

    useEffect(() => {
        if (isGetQueryLoading === true) {
            getAppThemeService();
            getAppSettingsService();
            getPageContentsService(pageId);
        }
    }, [isGetQueryLoading, pageId]);

    const renderPageContentsDroppableColumn = (): React.ReactElement => {
        if (isGetQueryLoading) {

            return <div className="icons is-loading">{ LoadingSpinner }</div>;
        }

        if (isGetQueryFailure) {

            return <LocaleMessage id="app.something_went_wrong" />;
        }

        return (
            <div className="page-builder--viewport">
                <PageContentsDroppableColumn />
            </div>
        );
    }

    const renderPageContentsResult = (): React.ReactElement => {
        
        return (
            <div className={ `page-builder--web is-${editorMode}` }>
                <PageBuilderPreview />
            </div>
        );
    }

    return (
        <React.Fragment>
            { editorMode === 'editor' ? renderPageContentsDroppableColumn() : renderPageContentsResult() }
        </React.Fragment>
    );
}

export default GetPageContents;