import { AnyAction } from 'redux';

import NavigationCollection from '../../Domain/NavigationCollection';
import NavigationPagesCollection from '../../Domain/NavigationPagesCollection';

import {
    navigationRequestLoadingAction,
    navigationRequestSuccessAction,
    NavigationRequestSuccessAction,
    navigationRequestFailureAction,
    NavigationRequestFailureAction,
    navigationPostRequestLoadingAction,
    navigationPostRequestSuccessAction,
    navigationPostRequestFailureAction,
    NavigationPostRequestFailureAction,
    navigationDeleteRequestLoadingAction,
    navigationDeleteRequestSuccessAction,
    navigationDeleteRequestFailureAction,
    NavigationDeleteRequestFailureAction,
    navigationPagesRequestSuccessAction,
    NavigationPagesRequestSuccessAction,
} from './navigation.actions';

export interface NavigationReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    isPostRequestLoading: boolean,
    isPostRequestSuccess: boolean,
    isPostRequestFailure: boolean,
    isDeleteRequestLoading: boolean,
    isDeleteRequestSuccess: boolean,
    isDeleteRequestFailure: boolean,
    navigation: NavigationCollection | null,
    navigationPages: NavigationPagesCollection | null,
    errorMessage: string
}

const initialNavigationReduxState: NavigationReduxState = {
    isRequestLoading: true,
    isRequestSuccess: false,
    isRequestFailure: false,
    isPostRequestLoading: false,
    isPostRequestSuccess: false,
    isPostRequestFailure: false,
    isDeleteRequestLoading: false,
    isDeleteRequestSuccess: false,
    isDeleteRequestFailure: false,
    navigation: null,
    navigationPages: null,
    errorMessage: ''
}

export const navigationReducer = (state: NavigationReduxState = initialNavigationReduxState, action: AnyAction): NavigationReduxState => {
    switch(action.type) {
        case navigationRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                isPostRequestLoading: false,
                isPostRequestSuccess: false,
                isPostRequestFailure: false,
                isDeleteRequestLoading: false,
                isDeleteRequestSuccess: false,
                isDeleteRequestFailure: false,
                navigation: null,
                navigationPages: null,
                errorMessage: ''
            }

        case navigationRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                isPostRequestLoading: false,
                isPostRequestSuccess: false,
                isPostRequestFailure: false,
                isDeleteRequestLoading: false,
                isDeleteRequestSuccess: false,
                isDeleteRequestFailure: false,
                navigation: (action as NavigationRequestSuccessAction).payload.navigation,
                errorMessage: ''
            }

        case navigationRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                isPostRequestLoading: false,
                isPostRequestSuccess: false,
                isPostRequestFailure: false,
                isDeleteRequestLoading: false,
                isDeleteRequestSuccess: false,
                isDeleteRequestFailure: false,
                navigation: null,
                errorMessage: (action as NavigationRequestFailureAction).payload.errorMessage
            }

        case navigationPagesRequestSuccessAction:
            return {
                ...state,
                navigationPages: (action as NavigationPagesRequestSuccessAction).payload.navigationPages
            }

        case navigationPostRequestLoadingAction:
            return {
                ...state,
                isPostRequestLoading: true,
                isPostRequestSuccess: false,
                isPostRequestFailure: false
            }

        case navigationPostRequestSuccessAction:
            return {
                ...state,
                isPostRequestLoading: false,
                isPostRequestSuccess: true,
                isPostRequestFailure: false
            }

        case navigationPostRequestFailureAction:
            return {
                ...state,
                isPostRequestLoading: false,
                isPostRequestSuccess: false,
                isPostRequestFailure: true,
                errorMessage: (action as NavigationPostRequestFailureAction).payload.errorMessage
            }

        case navigationDeleteRequestLoadingAction:
            return {
                ...state,
                isDeleteRequestLoading: true,
                isDeleteRequestSuccess: false,
                isDeleteRequestFailure: false
            }

        case navigationDeleteRequestSuccessAction:
            return {
                ...state,
                isDeleteRequestLoading: false,
                isDeleteRequestSuccess: true,
                isDeleteRequestFailure: false
            }

        case navigationDeleteRequestFailureAction:
            return {
                ...state,
                isDeleteRequestLoading: false,
                isDeleteRequestSuccess: false,
                isDeleteRequestFailure: true,
                errorMessage: (action as NavigationDeleteRequestFailureAction).payload.errorMessage
            }
    }

    return state;
}