import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import GetComponents from '../../Application/GetComponents';
import GetComponentSettings from '../../Application/GetComponentSettings';

import PageBuilderHeader from '../Partials/PageBuilderHeader';
import { appThemeModeSelector } from '../../../App/Infrastructure/Redux/App/app.selectors';
import { pageBuilderOnDragEndActionCreator } from '../Redux/page-builder.actions';

const PageBuilderLayout: React.FunctionComponent = ({ children }): React.ReactElement => {
    const dispatch = useDispatch();
    const mode = useSelector(appThemeModeSelector);

    const handleDragEnd = (result: DropResult): void => {
        dispatch(pageBuilderOnDragEndActionCreator(result));
    }

    return (
        <div className={ `page-builder is-${ mode }` }>
            <PageBuilderHeader />
            <div className="page-builder--main">
                <DragDropContext onDragEnd={(result: DropResult) => handleDragEnd(result)}>
                    <GetComponentSettings />
                    <section className="page-builder--main-content">
                        { children }
                    </section>
                    <GetComponents />
                </DragDropContext>
            </div>
        </div>
    );
}

export default PageBuilderLayout;