import { store } from '../../../store';

import Page from '../../Domain/Page';

import PagesRepository from '../Repositories/PagesRepository';
import { pageGetRequestFailureActionCreator, pageGetRequestSuccessActionCreator } from './get.actions';

const pagesRepository: PagesRepository = new PagesRepository();

export const getPageByIdService = (pageId: number): void => {
    
    pagesRepository.getPage(pageId)
        .then((page: Page) => {

            store.dispatch(pageGetRequestSuccessActionCreator(page))
        }, (error: any) => {
            
            store.dispatch(pageGetRequestFailureActionCreator(error.response.data.message));
        });

}