import Version from './Version';

const BANNER_IMAGE = 'BannerImage';
const BADGE_IMAGE = 'BadgeImage';
const CARROUSEL_IMAGE = 'CarrouselImage';
const SELECT_IMAGE = 'SelectImage';
const THUMBNAIL_IMAGE = 'Thumbnail';
const CONTENT_IMAGE = 'ContentImage';

class Game {
    public _id: string;

    public constructor(
        public name: string,
        public overwolfId: number,
        public value: string,
        public slug: string,
        public regions: string[],
        public versions: Version[],
        public description: any,
        public platforms: string[],
        public active: boolean = false,
        public displayHolding: boolean = false,
        public showGameIdentifier: boolean = false,
        public playersCanFillScore: boolean = false,
        public statApiAvailable: boolean = false,
        public installPath: { darwin: string, windowsNT: string },
        public date_creation: Date
    ) {}

    public getBannerImage = (): string => {
        return BANNER_IMAGE;
    };

    public getBadgeImage = (): string => {
        return BADGE_IMAGE;
    };

    public getCarrouselImage = (): string => {
        return CARROUSEL_IMAGE;
    };

    public getSelectImage = (): string => {
        return SELECT_IMAGE;
    };

    public getThumbnailImage = (): string => {
        return THUMBNAIL_IMAGE;
    };

    public getContentImage = (): string => {
        return CONTENT_IMAGE;
    };
}

export default Game;