import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';
import { ScaffoldingNavigation } from '../../../_utils/Scaffolding/ScaffoldingNavigation';
import { IScaffoldingNavigationItem } from '../../../_utils/Scaffolding/IScaffoldingNavigationItem';

import GetFiles from '../../Application/GetFiles';
import GetCacheInformation from '../../Application/GetCacheInformation';

export const MediasController: React.FunctionComponent = (): React.ReactElement => {
    const location = useLocation();
    const [navigationPage, setNavigationPage] = useState<string>('/medias/files');
    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            title: 'medias.page.title',
            url: null
        }
    ];
    const scaffoldingNavigationItems: IScaffoldingNavigationItem[] = [
        {
            title: "medias.navigation.medias_and_pictures",
            url: null,
            childrens: [
                {
                    title: "medias.navigation.file_manager",
                    url: "/medias/files",
                    childrens: []
                },
                {
                    title: "medias.navigation.settings",
                    url: "/medias/settings",
                    childrens: []
                }
            ]
        },
        {
            title: "medias.navigation.cache_management",
            url: null,
            childrens: [
                {
                    title: "medias.navigation.empty_cache",
                    url: "/medias/cache",
                    childrens: []
                }
            ]
        }
    ];

    useEffect(() => {
        if (location !== null) {
            setNavigationPage(location.pathname);
        }
    }, [location]);

    const renderPage = (): React.ReactElement => {
        switch(navigationPage){
            case '/medias/files':
                return <GetFiles />;

            case '/medias/cache':
                return <GetCacheInformation />;

            default:
                return <React.Fragment>Coming Soon</React.Fragment>;
        }
    }

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumbItems } />

            <div className="grid-wrapper">
                <div className="scaffolding">
                    <div className="scaffolding--header">
                        <h2><LocaleMessage id="medias.page.title" /></h2>
                        <div className="scaffolding--header-actions"></div>
                    </div>
                    <div className="scaffolding--columns">
                        <div className="scaffolding--columns-navigation">
                            <ScaffoldingNavigation items={ scaffoldingNavigationItems } />
                        </div>
                        <div className="scaffolding--columns-content">
                            { renderPage() }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}