import { Action } from 'redux';

import Page from '../../Domain/Page';

export const pageGetInitAction = '@@pages/GET_INIT';
export const pageGetRequestLoadingAction = '@@pages/GET_REQUEST_LOADING';
export const pageGetRequestSuccessAction = '@@pages/GET_REQUEST_SUCCESS';
export const pageGetRequestFailureAction = '@@pages/GET_REQUEST_FAILURE';

export interface PageGetInitAction extends Action<typeof pageGetInitAction> {
    payload: {}
}

export interface PageGetRequestLoadingAction extends Action<typeof pageGetRequestLoadingAction> {
    payload: {}
}

export interface PageGetRequestSuccessAction extends Action<typeof pageGetRequestSuccessAction> {
    payload: {
        page: Page
    }
}

export interface PageGetRequestFailureAction extends Action<typeof pageGetRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const pageGetInitActionCreator = (): PageGetInitAction => {

    return {
        type: pageGetInitAction,
        payload: {}
    }
}

export const pageGetRequestLoadingActionCreator = (): PageGetRequestLoadingAction => {

    return {
        type: pageGetRequestLoadingAction,
        payload: {}
    }
}

export const pageGetRequestSuccessActionCreator = (page: Page): PageGetRequestSuccessAction => {

    return {
        type: pageGetRequestSuccessAction,
        payload: {
            page
        }
    }
}

export const pageGetRequestFailureActionCreator = (errorMessage: string): PageGetRequestFailureAction => {

    return {
        type: pageGetRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};