import initialComponentDefaultData from '../InitialComponentDefaultData';
import ContactRequestFieldTypeEnum from './Enum/ContactRequestFieldTypeEnum';
import IComponentContactRequestData from './IComponentContactRequestData';

export const initialComponentContactRequestData: IComponentContactRequestData = {
    subjects: [
        {
            contactEmail: undefined,
            subject: 'Sujet de contact'
        }
    ],
    fields: [
        {
            inputType: ContactRequestFieldTypeEnum.Text,
            inputPlaceholder: "John",
            labelName: "Prénom"
        },
        {
            inputType: ContactRequestFieldTypeEnum.Text,
            inputPlaceholder: "Doe",
            labelName: "Nom"
        },
        {
            inputType: ContactRequestFieldTypeEnum.Textarea,
            inputPlaceholder: "Votre message...",
            labelName: "Message"
        }
    ],
    ...initialComponentDefaultData
};