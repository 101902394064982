import React, { useState, useEffect } from "react";
import { ISegment } from "./ISegment";

interface ISegmentedControlProps {
    name: string,
    segments: ISegment[],
    onChangeSegment: (value: string, index: number) => void,
    defaultIndex: number,
    controlRef: React.LegacyRef<HTMLDivElement> | undefined
}

const SegmentedControl: React.FunctionComponent<ISegmentedControlProps> = ({ name, segments, onChangeSegment, defaultIndex = 0, controlRef }): React.ReactElement => {
    const [activeIndex, setActiveIndex] = useState<number>(defaultIndex);

    useEffect(() => {
        const activeSegmentRef = segments[activeIndex].ref;
        //@ts-ignore
        const { offsetWidth, offsetLeft } = activeSegmentRef.current;
        //@ts-ignore
        const { style } = controlRef.current;

        style.setProperty("--highlight-width", `${offsetWidth}px`);
        style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }, [activeIndex, onChangeSegment, controlRef, segments]);

    const onInputChange = (value: string, index: number): void => {
        setActiveIndex(index);
        onChangeSegment(value, index);
    };

    return (
        <div className="controls-container" ref={controlRef}>
            <div className={`controls`}>
                { segments?.map((segment: ISegment, index: number) => (
                    <div className={`segment ${ index === activeIndex ? 'active' : 'inactive' }`} ref={ segment.ref } key={ segment.value }>
                        <input
                        type="radio"
                        value={ segment.value }
                        id={ segment.value }
                        name={name}
                        onChange={() => onInputChange(segment.value, index)}
                        checked={ index === activeIndex }
                        />
                        <label htmlFor={ segment.value }>{ segment.label }</label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SegmentedControl;