import { AnyAction } from 'redux';

import Settings from '../../../Domain/Settings';

import { 
    settingsPutInitAction, 
    settingsPutRequestLoadingAction, 
    settingsPutRequestSuccessAction, 
    settingsPutRequestFailureAction, 
    SettingsPutRequestFailureAction
} from './put-settings.actions';

export interface PutSettingsReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    errors: []
}

const initialPutSettingsReduxState: PutSettingsReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: "",
    errors: []
}

export const PutSettingsReducer = (state: PutSettingsReduxState = initialPutSettingsReduxState, action: AnyAction): PutSettingsReduxState => {
    switch(action.type) {
        case settingsPutInitAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                errors: []
            };

        case settingsPutRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                errors: []
            };

        case settingsPutRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                errorMessage: "",
                errors: []
            };

        case settingsPutRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                errorMessage: (action as SettingsPutRequestFailureAction).payload.errorMessage,
                errors: (action as SettingsPutRequestFailureAction).payload.errors
            };

        default:
            return state;
    }
}