import { Action } from 'redux';

import Settings from '../../../Domain/Settings';

export const settingsGetInitAction = '@@app_settings/GET_INIT';
export const settingsGetRequestLoadingAction = '@@app_settings/GET_REQUEST_LOADING';
export const settingsGetRequestSuccessAction = '@@app_settings/GET_REQUEST_SUCCESS';
export const settingsGetRequestFailureAction = '@@app_settings/GET_REQUEST_FAILURE';

export interface SettingsGetInitAction extends Action<typeof settingsGetInitAction> {
    payload: {}
}

export interface SettingsGetRequestLoadingAction extends Action<typeof settingsGetRequestLoadingAction> {
    payload: {}
}

export interface SettingsGetRequestSuccessAction extends Action<typeof settingsGetRequestSuccessAction> {
    payload: {
        settings: Settings
    }
}

export interface SettingsGetRequestFailureAction extends Action<typeof settingsGetRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const settingsGetInitActionCreator = (): SettingsGetInitAction => {

    return {
        type: settingsGetInitAction,
        payload: {}
    }
}

export const settingsGetRequestLoadingActionCreator = (): SettingsGetRequestLoadingAction => {

    return {
        type: settingsGetRequestLoadingAction,
        payload: {}
    }
}

export const settingsGetRequestSuccessActionCreator = (settings: Settings): SettingsGetRequestSuccessAction => {

    return {
        type: settingsGetRequestSuccessAction,
        payload: {
            settings
        }
    }
}

export const settingsGetRequestFailureActionCreator = (errorMessage: string): SettingsGetRequestFailureAction => {

    return {
        type: settingsGetRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};