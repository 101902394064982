import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../../../../../store';
import { SwiperInstance } from 'react-id-swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCube, EffectFade, Navigation, Pagination, Thumbs } from 'swiper';

import PageContent from '../../../../Domain/PageContent';

import { IComponentSlideshowData } from '../../../../Application/Forms/ComponentType/ImagesAndMedias/IComponentSlideshowData';
import { IComponentSlideshowImageData } from '../../../../Application/Forms/ComponentType/ImagesAndMedias/IComponentSlideshowImageData';

import DisplayImage from '../../../../../_utils/Image/DisplayImage';
import { pageBuilderPageContentSelector } from '../../../Redux/page-builder.selectors';

interface IDisplayComponentSlideshowProps {
    id: string
}

const DisplayComponentSlideshow: React.FunctionComponent<IDisplayComponentSlideshowProps> = ({ id }): React.ReactElement => {
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, id));
    const data: IComponentSlideshowData = pageContent.data as IComponentSlideshowData;

    const { imagesGroup } = useMemo(() => data, [data]);
    const [mainSwiper, setMainSwiper] = useState<SwiperInstance | null>(null);
    const [thumbsSwiper, setThumbsSwiper] = useState<SwiperInstance | null>(null);
    const [slideIndex, setSlideIndex] = useState<number>(0);

    const [shouldSwiperBeRebuild, setShouldSwiperBeRebuild] = useState<boolean>(false);

    useEffect(() => {
        if (thumbsSwiper !== null && thumbsSwiper !== undefined) {
            thumbsSwiper.slideTo(slideIndex);
        }
    }, [slideIndex, thumbsSwiper]);

    const effectParam = data.effect;
    const navigationParam = data.navigation;
    const paginationParam = data.pagination;
    const slidesPerViewParam = data.slidesPerView;

    useEffect(() => {
        if (!shouldSwiperBeRebuild) {
            setMainSwiper(null);
            setThumbsSwiper(null);
            setSlideIndex(0);
            setShouldSwiperBeRebuild(true);
        }
    }, [effectParam, navigationParam, paginationParam]);

    useEffect(() => {
        if (shouldSwiperBeRebuild) {
            setTimeout(() => {
                setShouldSwiperBeRebuild(false);
            }, 5);
        }
    }, [shouldSwiperBeRebuild]);

    const swiperParams = {
        effect: effectParam,
        navigation: navigationParam,
        pagination: paginationParam === 'dots' ? true : false,
        slidesPerView: slidesPerViewParam,
        spaceBetween: 10,
        centeredSlides: slidesPerViewParam === 1 ? true : false
    }

    const renderSlideshowImages = (): React.ReactElement[] => {

        return imagesGroup.map((image: IComponentSlideshowImageData, index: number) => {

            return (
                <SwiperSlide key={ index }>
                    <div className="page-builder--web-slideshow-slide">
                        <div className="page-builder--web-slideshow-slide-overlay">
                            { image.title && <span>{ image.title }</span> }
                            { image.description && <p>{ image.description }</p> }
                        </div>
                        <DisplayImage filename={ image.image } width={ 1366 } height={ 768 } />
                    </div>
                </SwiperSlide>
            );
        });
    }

    const renderSlideshowThumbs = (): React.ReactElement[] => {

        return imagesGroup.map((image: IComponentSlideshowImageData, index: number) => {

            return (
                <SwiperSlide key={ index } className={ slideIndex === index ? 'is-active' : ''} onClick={() => mainSwiper?.slideTo(index)}>
                    <DisplayImage filename={ image.image } width={ 128 } height={ 96 } />
                </SwiperSlide>
            );
        });
    }

    return (
        <React.Fragment>
            {
                !shouldSwiperBeRebuild &&
                <Swiper
                    className={ `${ paginationParam === 'dots' ? 'has-dots' : '' }` }
                    modules={[Thumbs, Navigation, Pagination, EffectCube, EffectFade]}
                    thumbs={{ swiper: thumbsSwiper }}
                    onSlideChange={(swiper) => setSlideIndex(swiper.activeIndex)}
                    onSwiper={(swiper) => setMainSwiper(swiper)}
                    {...swiperParams}
                    >
                    { renderSlideshowImages() }
                </Swiper>
            }

            {
                (!shouldSwiperBeRebuild && paginationParam === 'thumbnails') &&
                <Swiper
                    className="page-builder--web-slideshow-thumbnails"
                    modules={[Thumbs]}
                    spaceBetween={ 10 }
                    slidesPerView="auto"
                    centeredSlides={ true }
                    onSwiper={(swiper) => setThumbsSwiper(swiper) }
                    >
                    { renderSlideshowThumbs() }
                </Swiper>
            }
        </React.Fragment>
    );
}

export default DisplayComponentSlideshow;