const TOURNAMENT_CATEGORY_BRACKET = 'bracket';
const TOURNAMENT_CATEGORY_LADDER = 'ladder';

class Category {

    static getCategoryBracket = (): string => {
        return TOURNAMENT_CATEGORY_BRACKET;
    }

    static getCategoryLadder = (): string => {
        return TOURNAMENT_CATEGORY_LADDER;
    }

}

export default Category;