import { store } from '../../../store';

import { IDataPageContent } from '../../Application/Forms/IDataPageContent';
import PageContentsRepository from '../Repositories/PageContentsRepository';
import { pageBuilderPageContentPostFailureActionCreator, pageBuilderPageContentPostSuccessActionCreator } from './page-builder-post.actions';

const pageContentsRepository: PageContentsRepository = new PageContentsRepository();

export const pageBuilderPostService = (pageId: number): void => {
    const { pageContentItems } = store.getState().pageBuilder;

    const dataArray: Record<string, IDataPageContent> = {};
    pageContentItems.ids.forEach((id: string, position: number) => {
        dataArray[id] = {
            id: pageContentItems.byId[id].databaseId,
            type: pageContentItems.byId[id].type,
            position: position,
            data: JSON.stringify(pageContentItems.byId[id].data)
        }
    });

    pageContentsRepository.postPageContents(pageId, pageContentItems.ids, dataArray)
        .then(() => {

            store.dispatch(pageBuilderPageContentPostSuccessActionCreator());
        }, (error: any) => {

            store.dispatch(pageBuilderPageContentPostFailureActionCreator(error.response.data.message));
        });
}