import React, { useState, useEffect } from 'react';

import Game from '../Domain/Game';
import { IGamesList } from '../Domain/Scaffolding/IGamesList';

import Pagination from '../../App/Infrastructure/Partials/Pagination';
import { GamesRepository } from '../Infrastructure/Repositories/GamesRepository';
import { useDispatch } from 'react-redux';
import { modalAddActionCreator } from '../../_utils/Modal/Redux/modal.actions';
import { EditGamesController } from '../Infrastructure/Pages/EditGamesController';
import { DeleteGameController } from '../Infrastructure/Pages/DeleteGameController';
import SearchFilters from '../../_utils/SearchFilters/SearchFilters';
import GetPopularGames from './GetPopularGames';

interface GetGamesProps {
    actualPage: number
}

export const GetGames: React.FunctionComponent<GetGamesProps> = ({ actualPage }) : React.ReactElement => {
    const dispatch = useDispatch();
    const gamesRepository: GamesRepository = React.useMemo(() => new GamesRepository(), []);
    const [games, setGames] = useState<Array<Game>>([]);
    const [pages, setPages] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInError, setIsInError] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        gamesRepository.get(10, actualPage - 1)
            .then((response: IGamesList) => {
                setGames(response.docs);
                setPages(response.pages);
                setIsLoading(false);
            }, (error: any) => {
                setIsInError(true);
                setIsLoading(false);
            });
    }, [actualPage, gamesRepository]);

    const showGame = (_id: string, name: string) => {
        dispatch(
            modalAddActionCreator(
                <EditGamesController _id={ _id } />,
                name,
                {
                    extraClass: 'modal--form-edit',
                    openingAnimationClass: 'is-shownFromBottom'
                }
            )
        );
    };

    const deleteGame = (_id: string, name: string) => {
        dispatch(
            modalAddActionCreator(
                <DeleteGameController _id={ _id } name={ name } />,
                `Delete ${name}`
            )
        );
    }

    const renderHeadTable = (): React.ReactElement => {

        return (
            <thead>
                <tr>
                    <th>Name</th>
                    <th>value</th>
                    <th>active</th>
                    <th className="is-actions-column">Actions</th>
                </tr>
            </thead>
        );
    };

    const renderPagination = (): React.ReactElement | null => {
        if (!isInError && !isLoading) {
            return <Pagination baseUrl="/games" pages={ pages } activePage={ actualPage } />;
        }

        return null;
    };

    const renderActiveStatus = (active: boolean): React.ReactElement => {
        if (!active) {
            return <span className="badge is-error">Unactive</span>;
        }

        return <span className="badge is-success">Active</span>;
    };

    const renderBody = (): React.ReactElement => {
        if (isLoading) {

            return (
                <tr>
                    <td colSpan={ 4 }>
                        Is Loading...
                    </td>
                </tr>
            );
        }

        if (isInError) {

            return (
                <tr>
                    <td colSpan={ 4 }>
                        An error has occured, please refresh
                    </td>
                </tr>
            );
        }

        return (
            <React.Fragment>
                { games.map((game: Game, index: number) => {

                    return (
                        <tr data-testid="games--game-item" key={ index }>
                            <td>
                                { game.name }
                            </td>
                            <td>
                                { game.value }
                            </td>
                            <td>
                                { renderActiveStatus(game.active) }
                            </td>
                            <td className="d-flex">
                                <button className="button is-primary is-small" onClick={ () => { showGame(game._id, game.name) }}>
                                    Edit
                                </button>
                                <button className="button is-red is-small" onClick={ () => { deleteGame(game._id, game.name) }}>
                                    Delete
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </React.Fragment>
        )

    }

    return (
        <React.Fragment>
            <GetPopularGames />
            <div className="portlet">
                <div className="portlet--body">
                    <SearchFilters availableTypes={ ['name', 'plateform'] } />
                    <div className="table-responsive">
                        <table className="has-row-details">
                            { renderHeadTable() }
                            <tbody>
                                { renderBody() }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            { renderPagination() }
        </React.Fragment>
    );
};