import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authLogOutCreator } from '../../../Auth/Infrastructure/Redux/auth.actions';
import { modalCloseAllActionCreator, modalRemoveActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { LocaleMessage } from '../Redux/Locale/Provider/LocaleMessage';

import IconWarning from '../Style/Svg/IconWarning';

const ConfirmationLogoutModal: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleLogoutProcess = (): void => {
        dispatch(authLogOutCreator());
        dispatch(modalCloseAllActionCreator());
    }

    const handleCloseModal = (): void => {
        dispatch(modalRemoveActionCreator());
    }

    return (
        <div className="modal--confirmation">
            <div className="modal--icon">
                { IconWarning }
            </div>

            <div className="modal--information">
                <LocaleMessage id="logout.modale.confirmation.info" />
            </div>

            <div className="modal--actions">
                <button className={ `button is-red ${ isLoading ? 'is-loading' : '' }` } onClick={ () => handleLogoutProcess() }>
                    <LocaleMessage id="logout.modale.confirmation.validation_button" />
                </button>
                <button className="button is-red is-transparent" onClick={ handleCloseModal }>
                    <LocaleMessage id="logout.modale.confirmation.cancel_button" />
                </button>
            </div>
        </div>
    );
}

export default ConfirmationLogoutModal;