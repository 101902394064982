import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { GetLocales } from '../../Application/GetLocales';

export const Footer: React.FunctionComponent = (): React.ReactElement => {

    return (
        <footer className="footer" data-testid="app-footer">
            <div className="footer--down">
                <div className="grid-wrapper">
                    <p>
                        <a href="https://www.symbioze.org/" target="_blank">Symbioze.org</a> | Hand-crafted &amp; <strong>made with Love</strong>
                    </p>
                    <div>
                        <NavLink to="/bootstrap" activeClassName="is-active">Bootstrap</NavLink>
                    </div>
                    <div className="footer--tools">
                        <GetLocales />
                    </div>
                </div>
            </div>
        </footer>
    );
};