import React, { useState } from 'react';
import slugify from 'slugify';
import { useIntl } from 'react-intl';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IData from './IData';
import FormValidationMessage from './FormValidationMessage';
import IconWarning from '../../App/Infrastructure/Style/Svg/IconWarning';

interface ICheckboxInputProps {
    label: string,
    inputName: string,
    inputData: IData<boolean|undefined>,
    onChange: (name: string, checked: boolean) => void,
    disabled?: boolean,
    additionalClassname?: string,
    additionalInformation?: string
}

const CheckboxInput: React.FunctionComponent<ICheckboxInputProps> = ({ label, inputName, inputData, onChange, disabled, additionalClassname, additionalInformation }): React.ReactElement => {
    const intl = useIntl();
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, checked } = event.currentTarget;

        onChange(name, checked);
    }

    return (
        <div className={ `input ${ isFocused ? 'is-focused' : '' } ${ additionalClassname !== undefined ? additionalClassname : '' }` }>
            <div className="input--container is-checkbox">
                <label htmlFor={ slugify(inputName) }>
                    <LocaleMessage id={ label } />
                </label>
                <div className="input--checkbox">
                    <input
                        type="checkbox"
                        id={ slugify(inputName) }
                        name={ inputName }
                        checked={ inputData[inputName].inputValue as boolean }
                        onChange={ (event) => handleOnChange(event) }
                        onFocus={ () => setIsFocused(true) }
                        onBlur={ () => setIsFocused(false) }
                        disabled={ disabled !== undefined ? disabled : false }
                        />
                </div>
            </div>

            { additionalInformation !== undefined && <blockquote className="input--additional-information">{ IconWarning }&nbsp;<span dangerouslySetInnerHTML={{ __html: String(additionalInformation) }}></span></blockquote> }
            
            { (inputData[inputName] !== undefined && inputData[inputName].validation.inError) && <FormValidationMessage message={ inputData[inputName].validation.message } /> }
        </div>
    )
}

export default CheckboxInput;