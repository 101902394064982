import { Action } from 'redux';

export const pageDeleteInitAction = '@@pages/DELETE_INIT';
export const pageDeleteRequestLoadingAction = '@@pages/DELETE_REQUEST_LOADING';
export const pageDeleteRequestSuccessAction = '@@pages/DELETE_REQUEST_SUCCESS';
export const pageDeleteRequestFailureAction = '@@pages/DELETE_REQUEST_FAILURE';

export interface PageDeleteInitAction extends Action<typeof pageDeleteInitAction> {
    payload: {}
}

export interface PageDeleteRequestLoadingAction extends Action<typeof pageDeleteRequestLoadingAction> {
    payload: {}
}

export interface PageDeleteRequestSuccessAction extends Action<typeof pageDeleteRequestSuccessAction> {
    payload: {}
}

export interface PageDeleteRequestFailureAction extends Action<typeof pageDeleteRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const pageDeleteInitActionCreator = (): PageDeleteInitAction => {

    return {
        type: pageDeleteInitAction,
        payload: {}
    }
}

export const pageDeleteRequestLoadingActionCreator = (): PageDeleteRequestLoadingAction => {

    return {
        type: pageDeleteRequestLoadingAction,
        payload: {}
    }
}

export const pageDeleteRequestSuccessActionCreator = (): PageDeleteRequestSuccessAction => {

    return {
        type: pageDeleteRequestSuccessAction,
        payload: {}
    }
}

export const pageDeleteRequestFailureActionCreator = (errorMessage: string): PageDeleteRequestFailureAction => {

    return {
        type: pageDeleteRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};