import React from 'react';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../../../../../store';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import FormGroup from '../../../../../_utils/Forms/FormGroup';
import RangeInput from '../../../../../_utils/Forms/RangeInput';
import GroupCollapse from '../../../../Infrastructure/Partials/GroupCollapse';

import { IComponentData } from '../IComponentData';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';
import IconNumberSlidesPerViewSetting from '../../../../Infrastructure/Style/svg/IconNumberSlidesPerViewSetting';

interface IHandleComponentSlideshowSettingsProps {
    pageContentId: string,
    onChange: (inputName: string, value: string | boolean) => void
}

const HandleComponentSlideshowSettings: React.FunctionComponent<IHandleComponentSlideshowSettingsProps> = ({ pageContentId, onChange }): React.ReactElement => {
    const pageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentData<boolean> = pageContent.data as IComponentData<boolean>;

    return (
        <React.Fragment>
            <GroupCollapse label="page_builder.component-slideshow-type.settings.properties">
                <div className="page-builder--settings-list">
                    <div className="page-builder--contextual-group-item-label">
                        <LocaleMessage id="page_builder.component-slideshow-type.settings.effect" />
                    </div>
                    <FormGroup additionalClassname="is-vertical">
                        <div className="form--group-select">
                            <select name="effect" id="slideshow-effect" defaultValue={ String(data.effect) } onChange={(event) => onChange('effect', event.currentTarget.value)}>
                                <LocaleMessage id={ `page_builder.component-slideshow-type.settings.effect-slide` }>
                                    {(message) => <option value="slide">{ message }</option>}
                                </LocaleMessage>
                                <LocaleMessage id={ `page_builder.component-slideshow-type.settings.effect-fade` }>
                                    {(message) => <option value="fade">{ message }</option>}
                                </LocaleMessage>
                            </select>
                        </div>
                    </FormGroup>
                    <div className="page-builder--contextual-group-item-label">
                        <LocaleMessage id="page_builder.component-slideshow-type.settings.pagination" />
                    </div>
                    <FormGroup additionalClassname="is-vertical">
                        <div className="form--group-select">
                            <select name="pagination" id="slideshow-pagination" defaultValue={ String(data.pagination) } onChange={(event) => onChange('pagination', event.currentTarget.value)}>
                                <LocaleMessage id={ `page_builder.component-slideshow-type.settings.pagination-dots` }>
                                    {(message) => <option value="dots">{ message }</option>}
                                </LocaleMessage>
                                <LocaleMessage id={ `page_builder.component-slideshow-type.settings.pagination-thumbnails` }>
                                    {(message) => <option value="thumbnails">{ message }</option>}
                                </LocaleMessage>
                            </select>
                        </div>
                    </FormGroup>
                    <div className="page-builder--settings-list">
                        <div className="page-builder--contextual-group-item-label">
                            <LocaleMessage id="page_builder.component-slideshow-type.settings.slidesPerView" />
                        </div>
                        <FormGroup additionalClassname="has-svg">
                            <RangeInput inputName="slidesPerView" label={ IconNumberSlidesPerViewSetting } value={ Number(data.slidesPerView) } min={ 1 } max={ 4 } step={ 1 } unit="PIC" onInputChange={(name, value) => onChange(name, value)} />
                        </FormGroup>
                    </div>
                    <FormGroup>
                        <React.Fragment>
                            <label htmlFor="slideshow-navigation"><LocaleMessage id="page_builder.component-slideshow-type.settings.navigation" /></label>
                            <div className="form--group-input">
                                <input
                                    type="checkbox"
                                    id="slideshow-navigation"
                                    name="navigation"
                                    onChange={(event) => { onChange('navigation', event.currentTarget.checked)}}
                                    defaultChecked={ data.navigation as boolean } />
                            </div>
                        </React.Fragment>
                    </FormGroup>
                </div>
            </GroupCollapse>
        </React.Fragment>
    );
}

export default HandleComponentSlideshowSettings;