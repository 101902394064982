import React from 'react';

const IconPages: React.ReactElement = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M17 13.4V16.4C17 20.4 15.4 22 11.4 22H7.6C3.6 22 2 20.4 2 16.4V12.6C2 8.6 3.6 7 7.6 7H10.6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M16.9996 13.4H13.7996C11.3996 13.4 10.5996 12.6 10.5996 10.2V7L16.9996 13.4Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M7 5C7 3.34 8.34 2 10 2H16" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M21.9995 8V14.19C21.9995 15.74 20.7395 17 19.1895 17" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <path opacity="0.4" d="M22 8H19C16.75 8 16 7.25 16 5V2L22 8Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
);

export default IconPages;