import React from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import GetPageContents from '../../Application/GetPageContents';
import ExitPageBuilderModal from './Modals/ExitPageBuilderModal';
import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { modalAddActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { pageBuilderPageContentPostLoadingActionCreator } from '../Redux/page-builder-post.actions';

type PageBuilderControllerParams = {
    pageId: string | undefined
}

export const PageBuilderController: React.FunctionComponent<PageBuilderControllerParams> = (): React.ReactElement => {
    const dispatch = useDispatch();
    const { pageId } = useParams<PageBuilderControllerParams>();

    const breadcrumbItems = [
        {
            title: "page.page.title",
            url: "/pages"
        },
        {
            title: pageId !== undefined ? "page.page.edit_title" : "page.page.create_title",
            url: `/pages/${pageId}/edit`
        },
        {
            title: "page_builder.page.title",
            url: null
        }
    ];

    const handleBackButton = (): void => {
        dispatch(
            modalAddActionCreator(
                <ExitPageBuilderModal pageId={ pageId } />,
                'page_builder.page.back_button_modal.title'
            )
        );
    }

    const handlePublishButton = (): void => {
        dispatch(pageBuilderPageContentPostLoadingActionCreator());
    }

    return (
        <div className="page-builder--main-content-page">
            <Breadcrumb items={ breadcrumbItems } />

            <div className="grid-wrapper">
                <div className="page--header">
                    <h2><LocaleMessage id="page_builder.page.title" /></h2>

                    <div className="list-page--header-actions">
                        <button className="button is-primary is-transparent is-outlined is-small" onClick={() => handleBackButton()}>
                            <LocaleMessage id="page_builder.page.header.back_button" />
                        </button>
                        <button className="button is-primary is-small" onClick={() => handlePublishButton()}>
                            <LocaleMessage id="page_builder.page.header.publish_button" />
                        </button>
                    </div>
                </div>

                <GetPageContents pageId={ Number(pageId) } />
            </div>
        </div>
    );
}