import React, { useEffect, useMemo, useRef } from 'react';
import { AppReduxState } from '../../../../../store';
import { useSelector } from 'react-redux';

import PageContent from '../../../../Domain/PageContent';

import DisplayImage from '../../../../../_utils/Image/DisplayImage';
import { IComponentGalleryData } from '../../../../Application/Forms/ComponentType/ImagesAndMedias/IComponentGalleryData';
import { IComponentGalleryImageData } from '../../../../Application/Forms/ComponentType/ImagesAndMedias/IComponentGalleryImageData';
import { pageBuilderModeEditorSelector, pageBuilderPageContentSelector } from '../../../Redux/page-builder.selectors';

interface IDisplayComponentGalleryProps {
    id: string
}

const DisplayComponentGallery: React.FunctionComponent<IDisplayComponentGalleryProps> = ({ id }): React.ReactElement => {
    const viewport = useSelector(pageBuilderModeEditorSelector);
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, id));
    const data: IComponentGalleryData = pageContent.data as IComponentGalleryData;
    
    const { imagesGroup } = useMemo(() => data, [data]);

    const borderRadius = viewport === 'desktop' ? data.borderRadiusDesktop : data.borderRadiusMobile;

    //@TODO --- define max width in settings ?
    const width = viewport === 'desktop' ? (1320 / data.nbrColumnsDesktop) * 2 : (380 / data.nbrColumnsMobile) * 2;
    const height = data.masonry ? undefined : (width / 1.3);
    const nbrColumns = viewport === 'desktop' ? Number(data.nbrColumnsDesktop) : Number(data.nbrColumnsMobile);

    const itemRefs = useRef<HTMLDivElement[]>([]);
    itemRefs.current = [];
    
    useEffect(() => {
        itemRefs.current.forEach((ref: HTMLDivElement | null) => {
            if (ref !== null) {
                ref.style.marginTop = '0';
            }
        });
        if (data.masonry) {
            setTimeout(() => {
                handleMasonryTiles();
            }, 300);
        }
    }, [imagesGroup, viewport, nbrColumns, itemRefs, data.masonry]);

    const addToRefs = (element: HTMLDivElement) => {
        if (element !== null) {
            itemRefs.current.push(element);
        }
    }

    const handleMasonryTiles = (): void => {
        if (imagesGroup.length > nbrColumns) {
            itemRefs.current.forEach((ref: HTMLDivElement | null, index: number) => {
                if (ref !== null) {
                    ref.style.marginTop = '0';

                    if (index >= nbrColumns) {

                        if (itemRefs.current[index - nbrColumns] !== null) {
                            setTimeout(() => {
                                const bottomEdgeItemAbove = itemRefs.current[index - nbrColumns].getBoundingClientRect().bottom;
                                const topEdgeCurrentItem = ref.getBoundingClientRect().top;

                                ref.style.marginTop = `${ bottomEdgeItemAbove + 20 - topEdgeCurrentItem }px`;
                            }, 101 * index);
                        }
                    }
                }
            });
        } else {
            console.warn('not enough element to masonry');
        }
    }

    const renderGallery = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        imagesGroup.forEach((image: IComponentGalleryImageData, index: number) => {
            elements.push(
                <div className="page-builder--web-gallery-item" key={ index } ref={ addToRefs }>
                    <div
                        className="page-builder--web-gallery-item-image"
                        style={
                            {
                                borderRadius: `${ borderRadius }px`
                            }
                        }>
                        <DisplayImage filename={ image.image } width={ width } height={ height } />

                        { data.positionInformation === "inside" && renderItemInfo(image) }
                    </div>
                    { data.positionInformation === "below" && renderItemInfo(image) }
                </div>
            );
        });

        return elements;
    }

    const renderItemInfo = (image: IComponentGalleryImageData): React.ReactElement => {

        return (
            <div
                className="page-builder--web-gallery-item-info"
                style={
                    {
                        borderBottomLeftRadius: `${ borderRadius }px`,
                        borderBottomRightRadius: `${ borderRadius }px`
                    }
                }>
                <div className="page-builder--web-gallery-item-info-title">{ image.title }</div>
                <div className="page-builder--web-gallery-item-info-description">{ image.description }</div>
            </div>
        );
    }

    return (
        <div
            className="page-builder--web-gallery-list"
            style={
                {
                    gridTemplateColumns: `repeat(${ nbrColumns }, 1fr)`,
                    gridTemplateRows: `${ data.masonry ? 'masonry' : 'none' }`
                }
            }>
                { renderGallery() }
        </div>
    );
}

export default DisplayComponentGallery;