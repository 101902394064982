import React from 'react';

const IconSettingSliders: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <g opacity="0.4">
            <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
        <path d="M15.5703 18.4996V14.5996" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M15.5703 7.45V5.5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M15.5707 12.6502C17.0066 12.6502 18.1707 11.4861 18.1707 10.0502C18.1707 8.61426 17.0066 7.4502 15.5707 7.4502C14.1348 7.4502 12.9707 8.61426 12.9707 10.0502C12.9707 11.4861 14.1348 12.6502 15.5707 12.6502Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M8.42969 18.4998V16.5498" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M8.42969 9.4V5.5" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
        <path d="M8.43008 16.5496C9.86602 16.5496 11.0301 15.3855 11.0301 13.9496C11.0301 12.5137 9.86602 11.3496 8.43008 11.3496C6.99414 11.3496 5.83008 12.5137 5.83008 13.9496C5.83008 15.3855 6.99414 16.5496 8.43008 16.5496Z" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
    </svg>
);

export default IconSettingSliders;