import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import SelectInput from '../../../_utils/Forms/SelectInput';
import { localeSelector } from '../../../App/Infrastructure/Redux/Locale/locale.selectors';
import { appThemeModeSelector } from '../../../App/Infrastructure/Redux/App/app.selectors';
import { localeChangeActionCreator } from '../../../App/Infrastructure/Redux/Locale/locale.actions';
import { appBlackModeActionCreator, appDarkModeActionCreator, appLightModeActionCreator } from '../../../App/Infrastructure/Redux/App/app.actions';

const HandleUserSettings: React.FunctionComponent = (): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const locale = useSelector(localeSelector);
    const theme = useSelector(appThemeModeSelector);

    const handleChangeLocale = (name: string, value: string) => {
        dispatch(localeChangeActionCreator(value));
    }

    const handleChangeMode = (name: string, value: string) => {
        switch(value) {
            case 'black-mode':
                dispatch(appBlackModeActionCreator());
                break;
                
            case 'dark-mode':
                dispatch(appDarkModeActionCreator());
                break;

            default:
                dispatch(appLightModeActionCreator());
                break;
        }
    }

    const generateLangRecords = (): Record<string, string> => {
        const langRecords: Record<string, string> = {};

        langRecords['fr-FR'] = intl.formatMessage({ id: `locales.french` });
        langRecords['en-US'] = intl.formatMessage({ id: `locales.english` });

        return langRecords;
    }

    const generateThemeModeRecords = (): Record<string, string> => {
        const themeModeRecords: Record<string, string> = {};

        themeModeRecords['light-mode'] = intl.formatMessage({ id: `app.modes.light` });
        themeModeRecords['dark-mode'] = intl.formatMessage({ id: `app.modes.dark` });
        themeModeRecords['black-mode'] = intl.formatMessage({ id: `app.modes.black` });

        return themeModeRecords;
    }

    return (
        <React.Fragment>
            <SelectInput inputName="lang" label="Lang" inputValue={ locale } choices={ generateLangRecords() } onChange={(name, value) => handleChangeLocale(name, value)} />
            <SelectInput inputName="mode" label="Mode" inputValue={ theme } choices={ generateThemeModeRecords() } onChange={(name, value) => handleChangeMode(name, value)} />
        </React.Fragment>
    );
}

export default HandleUserSettings;