import { IColor } from '../../../Domain/IColor';

export const initialColors: IColor[] = [
    {
        hex: 'FFFFFF'
    },
    {
        hex: '000000'
    },
    {
        hex: 'FF9900'
    },
    {
        hex: 'FF0000'
    },
    {
        hex: 'E0E34F'
    }
];