import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { LocaleMessage } from '../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IconWarning from '../../../../App/Infrastructure/Style/Svg/IconWarning';
import { modalCloseAllActionCreator } from '../../../../_utils/Modal/Redux/modal.actions';

interface IExitPageBuilderModalProps {
    pageId: string | undefined
}

const ExitPageBuilderModal: React.FunctionComponent<IExitPageBuilderModalProps> = ({ pageId }): React.ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleExitPageBuilderModal = (): void => {
        history.push(`/pages/${pageId}/edit`);
        handleCloseModal();
    }

    const handleCloseModal = (): void => {
        dispatch(modalCloseAllActionCreator());
    }

    return (
        <div className="modal--confirmation">
            <div className="modal--icon">{ IconWarning }</div>
            <div className="modal--information">
                <LocaleMessage id="page_builder.page.back_button_modal.content" />
            </div>

            <div className="modal--actions">
                <button className="button is-primary" onClick={()=>handleExitPageBuilderModal()}>
                    <LocaleMessage id="page_builder.page.back_button_modal.confirm_button" />
                </button>
                <button className="button is-primary is-transparent" onClick={()=>handleCloseModal()}>
                    <LocaleMessage id="modales.confirmation.cancel" />
                </button>
            </div>
        </div>
    );
}

export default ExitPageBuilderModal;