import React from 'react';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';

import AppTheme from '../../Domain/AppTheme';

import DisplayHeader from './PageBuilderWeb/DisplayHeader';
import DisplayFooter from './PageBuilderWeb/DIsplayFooter';
import DisplayComponent from './PageBuilderWeb/DisplayComponent';

import { navigationSelector } from '../../../Navigation/Infrastructure/Redux/navigation.selectors';
import { pageBuilderAppThemeSelector } from '../Redux/page-builder-get.selectors';
import { navigationGetStructureService } from '../../../Navigation/Infrastructure/Redux/navigation.services';
import { pageBuilderModeEditorSelector, pageBuilderPageContentByIdsSelector, pageBuilderPageContentIdsSelector } from '../Redux/page-builder.selectors';

const PageBuilderPreview: React.FunctionComponent = (): React.ReactElement => {
    const theme: AppTheme = useSelector(pageBuilderAppThemeSelector);
    const viewport: string = useSelector(pageBuilderModeEditorSelector);
    const navigation = useSelector(navigationSelector);
    const pageContentIds = useSelector(pageBuilderPageContentIdsSelector);
    const pageContentByIds = useSelector(pageBuilderPageContentByIdsSelector);

    useEffectOnce(() => {
        navigationGetStructureService();
    });

    const hexToRgb = (hex: string): string => {
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;

        return `${r}, ${g}, ${b}`;
    }

    const renderAllWebElements = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        pageContentIds.forEach((pageContentId: string, index: number) => {
            const pageContent = pageContentByIds[pageContentId]!;

            if ((viewport === 'desktop' && pageContent.data.displayOnDesktop) || (viewport === 'mobile' && pageContent.data.displayOnMobile)) {
                elements.push(<DisplayComponent pageContentId={ pageContentId } key={ index } />);
            }
        });

        return elements;
    }

    return (
        <div 
            className={ `viewport has-${theme.headerVariant}-header` }
            style={
                { 
                    '--primary-color': `#${ theme.primaryColor }`,
                    '--primary-color-rgb': `${ hexToRgb(theme.primaryColor) }`,
                    '--secondary-color': `#${ theme.secondaryColor }`,
                    '--secondary-color-rgb': `${ hexToRgb(theme.primaryColor) }`,
                    '--header-color': `#${ theme.headerColor }`,
                    '--header-color-rgb': `${ hexToRgb(theme.headerColor) }`,
                    '--header-text-color': `#${ theme.headerTextColor }`,
                    '--text-color': `#${ theme.textColor }`,
                    '--text-color-rgb': `${ hexToRgb(theme.textColor) }`,
                    '--text-headers-color': `#${ theme.textHeadersColor }`,
                    '--footer-color': `#${ theme.footerColor }`
                } as React.CSSProperties
            }>
            { navigation && <DisplayHeader navigation={ navigation[0].childrens } /> }
            <div className="viewport--content">
                { renderAllWebElements() }
            </div>
            { navigation && <DisplayFooter navigation={ navigation[1].childrens } />}
        </div>
    )
}

export default PageBuilderPreview;