import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContentsRepository from '../../../../Infrastructure/Repositories/PageContentsRepository';

interface IonImageUploadedProps {
    onImageUploaded: (path: string) => void
}

const pageContentsRepository: PageContentsRepository = new PageContentsRepository();
const HandleComponentGalleryImage: React.FunctionComponent<IonImageUploadedProps> = ({ onImageUploaded }): React.ReactElement => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = (): void => {
        if (file !== null) {
            setIsLoading(true);
            pageContentsRepository.uploadImageHandler(file)
                .then((response: any) => {
                    if(fileInputRef.current !== null) {
                        fileInputRef.current.value = '';
                    }
                    setFile(null);
                    setIsLoading(false);

                    onImageUploaded( response.data.location);
                }, (error) => {
                    toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });
                });
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { files } = event.currentTarget;

        if (files !== null) {

            setFile(files[0]);
        }
    }

    return (
        <div className="page-builder--content-gallery-add-form">
            <div className="form--group is-vertical">
                <label htmlFor="gallery-image"><LocaleMessage id="page_builder.component-gallery-type.add_image.label" /></label>

                <div className="form--group-input">
                    <input ref={ fileInputRef } type="file" id="gallery-image" onChange={(event) => handleFileChange(event)} />
                    <button type="button" className={ `button is-primary is-small ${ file === null ? 'is-disabled' : '' } ${ isLoading ? 'is-loading' : '' }` } onClick={ handleSubmit }>
                        <LocaleMessage id="page_builder.component-gallery-type.add_image.button" />
                    </button>
                </div>
            </div>    
        </div>
    );
}

export default HandleComponentGalleryImage;