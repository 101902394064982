import React from 'react';
import { EditGames } from '../../Application/Forms/EditGames';

interface IEditGamesControllerProps {
    _id: string,
}

export const EditGamesController: React.FunctionComponent<IEditGamesControllerProps> = ({ _id }): React.ReactElement => {
    return (
        <div className="games--edit">
            <div className="grid-wrapper">
                <EditGames _id={ _id }/>
            </div>
        </div>
    );
};