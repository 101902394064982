import Navigation from './Navigation';

class NavigationCollection extends Array<Navigation> {

    public generateRecordsForSelectInput = (): Record<string, string> | Record<string, Record<string, string>> => {

        return this.generateGroups();
    }

    private generateGroups = (): Record<string, Record<string, string>> => {
        const navigationGroupsRecord: Record<string, Record<string, string>> = {};

        this.forEach((_navigation: Navigation) => {
            navigationGroupsRecord[_navigation.title] = this.generateBranch(_navigation, 0);
        });

        return navigationGroupsRecord;
    }

    private generateBranch = (_navigation: Navigation, depth: number): Record<string, string> => {
        const navigationItemsRecord: Record<string, string> = {};
        const newDepth = depth + 1;

        this.forEach((_navigation: Navigation) => {
            navigationItemsRecord[_navigation.id] = `--${_navigation.title}`
        });

        return navigationItemsRecord;
    }

    public flattenNavigation = (): Navigation[] => {

        return this.getChildrens(this);
    }

    private getChildrens = (navigations: Navigation[], depth: number = -1): Navigation[] => {
        const flattenNavigation: Navigation[] = [];
        const nextDepth = depth + 1;

        navigations.forEach((navigation: Navigation) => {
            const newNavigation = new Navigation(
                navigation.id,
                `${ "--".repeat(nextDepth) } ${ navigation.title }`,
                navigation.childrens
            );
            flattenNavigation.push(newNavigation);

            if(navigation.childrens) {
                const childrens = this.getChildrens(navigation.childrens, nextDepth);

                childrens.forEach((navigation: Navigation) => {
                    flattenNavigation.push(navigation);
                });
            }
        });

        return flattenNavigation;
    }
}

export default NavigationCollection;