export enum LightModeColors {
    SYMBIOZE = '#FECE2E',
    SYMBIOZE_RGB = '224, 227, 79',
    DEFAULT_TEXT = '#050A0F',
    NEUTRAL_100 = '#FFFFFF',
    NEUTRAL_100_RGB = '255, 255, 255',
    NEUTRAL_200 = '#EBE9EB',
    NEUTRAL_200_RGB = '235, 233, 235',
    NEUTRAL_300 = '#D9D7D9',
    NEUTRAL_300_RGB = '217, 215, 217',
    NEUTRAL_400 = '#B7B5B7',
    NEUTRAL_400_RGB = '183, 181, 183',
    NEUTRAL_500 = '#746F72',
    NEUTRAL_500_RGB = '116, 111, 114',
}