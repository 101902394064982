import * as React from 'react';

import {HandleCreateTournament} from '../../Application/Forms/HandleCreateTournament';

interface ICreateTournamentControllerProps {
    type: string
}

export const CreateTournamentController: React.FunctionComponent<ICreateTournamentControllerProps> = ({ type }): React.ReactElement => {

    return (
        <React.Fragment>
            <div className="tournaments">
                <div className="grid-wrapper">
                    <div className="row">
                        <div className="grid-col-14">
                            <h2>Create tournament : { type }</h2>
                        </div>
                        <div className="grid-col-4">
                            <button type="button" className="button is-primary">
                                <i className="fa fa-save"></i>&nbsp;Save
                            </button>
                        </div>
                    </div>

                    <HandleCreateTournament />

                    <div className="row">
                        <div className="grid-col-18">
                            <button type="button" className="button is-primary">
                                <i className="fa fa-save"></i>&nbsp;Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CreateTournamentController;