import ComponentTypeEnum from './Enum/ComponentTypeEnum';

class Component {

    public type: ComponentTypeEnum;

    public constructor(type: ComponentTypeEnum) {
        this.type = type;
    }

}

export default Component;