import * as React from 'react';

const LogoBrand = (
    <svg viewBox="0 0 105 26" version="1.1">
        <g id="logo-brand-icon">
            <path d="M10.2555408,-4.14336123e-16 L15.7444592,4.14336123e-16 C19.3105342,-2.4074122e-16 20.6036791,0.371302445 21.9073828,1.06853082 C23.2110865,1.76575919 24.2342408,2.78891348 24.9314692,4.09261719 C25.6286976,5.39632089 26,6.68946584 26,10.2555408 L26,15.7444592 C26,19.3105342 25.6286976,20.6036791 24.9314692,21.9073828 C24.2342408,23.2110865 23.2110865,24.2342408 21.9073828,24.9314692 C20.6036791,25.6286976 19.3105342,26 15.7444592,26 L10.2555408,26 C6.68946584,26 5.39632089,25.6286976 4.09261719,24.9314692 C2.78891348,24.2342408 1.76575919,23.2110865 1.06853082,21.9073828 C0.371302445,20.6036791 1.60494146e-16,19.3105342 -2.76224082e-16,15.7444592 L2.76224082e-16,10.2555408 C-1.60494146e-16,6.68946584 0.371302445,5.39632089 1.06853082,4.09261719 C1.76575919,2.78891348 2.78891348,1.76575919 4.09261719,1.06853082 C5.39632089,0.371302445 6.68946584,2.4074122e-16 10.2555408,-4.14336123e-16 Z" fill="#E0E34F"></path>
            <circle fill="#232425" cx="18.25" cy="15.25" r="1.25"></circle>
            <path d="M11.280533,10.6489268 C11.280533,10.0349371 11.8418949,9.71917101 12.5611399,9.71917101 C13.3505552,9.71917101 13.9119172,10.0524797 14.2101407,10.719097 L15.8065138,9.82442637 C15.1925241,8.68415989 14.0171725,8 12.5611399,8 C11.6489268,8 10.8770541,8.24559586 10.2630644,8.73678757 C9.64907477,9.22797929 9.35085123,9.87705406 9.35085123,10.6840119 C9.3157661,12.5259808 11.0875648,13.0522577 12.4032569,13.3855663 C13.3330126,13.6136196 14.0698002,13.9469283 14.0698002,14.5082902 C14.0698002,15.157365 13.5786085,15.4906737 12.5786825,15.4906737 C11.5787565,15.4906737 10.9296817,15.0696522 10.6314582,14.2276093 L9,15.1749076 C9.56136196,16.4555145 10.8419689,17.2273872 12.5786825,17.2273872 C13.5610659,17.2273872 14.3680238,16.9817914 15.0170985,16.5081422 C15.6661733,16.0344931 15.999482,15.3678757 15.999482,14.5258328 C16.0345671,12.6487787 14.280311,12.0874168 12.9295337,11.7891932 C12.0173205,11.5260548 11.280533,11.2278313 11.280533,10.6489268 Z" fill="#232425" fillRule="nonzero"></path>
        </g>
        <g id="logo-brand-symbioze" transform="translate(34.000000, 6.000000)" fill="#8D9195" fillRule="nonzero">
            <path d="M1.99226548,5.37911679 C1.99226548,4.84273762 2.48266929,4.56688548 3.11099917,4.56688548 C3.80062953,4.56688548 4.29103334,4.85806274 4.55156036,5.44041727 L5.9461462,4.65883619 C5.40976703,3.66270346 4.38298405,3.06502381 3.11099917,3.06502381 C2.31409298,3.06502381 1.63978774,3.27957548 1.10340857,3.70867881 C0.567029405,4.13778215 0.306502381,4.70481155 0.306502381,5.40976703 C0.275852143,7.01890453 1.82368917,7.4786581 2.9730731,7.76983536 C3.78530441,7.96906191 4.42895941,8.26023917 4.42895941,8.75064298 C4.42895941,9.31767239 3.99985608,9.60884965 3.12632429,9.60884965 C2.2527925,9.60884965 1.6857631,9.24104679 1.42523607,8.50544108 L0,9.33299751 C0.49040381,10.4517312 1.6091375,11.1260364 3.12632429,11.1260364 C3.98453096,11.1260364 4.68948643,10.9114848 5.25651584,10.4977066 C5.82354524,10.0839283 6.11472251,9.50157382 6.11472251,8.7659681 C6.14537274,7.12618036 4.61286084,6.63577655 3.43282667,6.37524953 C2.63592048,6.14537274 1.99226548,5.88484572 1.99226548,5.37911679 Z"></path>
            <path d="M11.1413616,8.82726858 L8.85791882,3.26425036 L7.04955477,3.26425036 L10.29848,10.7888838 L10.2218544,10.942135 C9.8080762,11.9995682 9.22572168,12.5052972 8.18361358,12.4439967 L8.18361358,13.9918337 C9.94600227,14.0991095 11.1873369,13.1489522 11.9229426,11.1720118 L14.8653655,3.26425036 L13.1029768,3.26425036 L11.1413616,8.82726858 Z"></path>
            <path d="M25.0412445,3.06502381 C23.9838113,3.06502381 23.2022303,3.46347691 22.6965013,4.27570822 C22.2214226,3.46347691 21.501142,3.06502381 20.5203344,3.06502381 C19.5548519,3.06502381 18.8192462,3.43282667 18.3288424,4.1837575 L18.3288424,3.26425036 L16.6737295,3.26425036 L16.6737295,10.9268099 L18.3288424,10.9268099 L18.3288424,6.63577655 C18.3288424,5.3024912 19.0337979,4.61286084 20.0299306,4.61286084 C20.9494378,4.61286084 21.4704918,5.2258656 21.4704918,6.28329882 L21.4704918,10.9268099 L23.1256047,10.9268099 L23.1256047,6.63577655 C23.1256047,5.28716608 23.7692597,4.61286084 24.7960426,4.61286084 C25.7155498,4.61286084 26.2825792,5.2258656 26.2825792,6.28329882 L26.2825792,10.9268099 L27.9376921,10.9268099 L27.9376921,6.17602298 C27.9376921,4.27570822 26.772983,3.06502381 25.0412445,3.06502381 Z"></path>
            <path d="M34.8186705,3.06502381 C33.6539615,3.06502381 32.7651046,3.49412715 32.1367747,4.35233381 L32.1367747,0.199226548 L30.4816618,0.199226548 L30.4816618,10.9268099 L32.1367747,10.9268099 L32.1367747,9.82340132 C32.7651046,10.6969331 33.6539615,11.1260364 34.8186705,11.1260364 C35.8761037,11.1260364 36.7649606,10.7429085 37.5158915,9.96132739 C38.2668223,9.17974632 38.6346252,8.21426382 38.6346252,7.09553013 C38.6346252,5.96147132 38.2668223,5.01131393 37.5158915,4.22973286 C36.7649606,3.44815179 35.8761037,3.06502381 34.8186705,3.06502381 Z M32.826405,8.85791882 C32.3666515,8.38284013 32.1367747,7.8004856 32.1367747,7.09553013 C32.1367747,6.39057465 32.3666515,5.80822012 32.826405,5.34846655 C33.2861586,4.87338786 33.8685131,4.64351108 34.5581435,4.64351108 C35.2477738,4.64351108 35.8301284,4.87338786 36.2898819,5.34846655 C36.7496355,5.80822012 36.9795123,6.39057465 36.9795123,7.09553013 C36.9795123,7.8004856 36.7496355,8.38284013 36.2898819,8.85791882 C35.8301284,9.31767239 35.2477738,9.54754918 34.5581435,9.54754918 C33.8685131,9.54754918 33.2861586,9.31767239 32.826405,8.85791882 Z"></path>
            <path d="M42.4659049,1.80836405 C42.8950083,1.37926072 42.8950083,0.735605715 42.48123,0.3218275 C42.0674518,-0.107275833 41.3931466,-0.107275833 40.9793684,0.3218275 C40.5655902,0.735605715 40.5655902,1.37926072 40.9793684,1.80836405 C41.4084717,2.22214226 42.0521267,2.22214226 42.4659049,1.80836405 Z M42.5578556,10.9268099 L42.5578556,3.26425036 L40.9027428,3.26425036 L40.9027428,10.9268099 L42.5578556,10.9268099 Z"></path>
            <path d="M48.8564796,11.1260364 C49.9905384,11.1260364 50.9406958,10.7429085 51.7222768,9.96132739 C52.5038579,9.17974632 52.902311,8.22958894 52.902311,7.09553013 C52.902311,5.96147132 52.5038579,5.01131393 51.7222768,4.22973286 C50.9406958,3.44815179 49.9905384,3.06502381 48.8564796,3.06502381 C47.7377459,3.06502381 46.7722634,3.44815179 45.9906823,4.22973286 C45.2091012,5.01131393 44.8106481,5.96147132 44.8106481,7.09553013 C44.8106481,8.22958894 45.2091012,9.17974632 45.9906823,9.96132739 C46.7722634,10.7429085 47.7377459,11.1260364 48.8564796,11.1260364 Z M47.1553914,8.82726858 C46.6956378,8.36751501 46.465761,7.78516048 46.465761,7.09553013 C46.465761,6.40589977 46.6956378,5.82354524 47.1553914,5.36379167 C47.6151449,4.9040381 48.1821743,4.67416131 48.8564796,4.67416131 C49.5307848,4.67416131 50.0978142,4.9040381 50.5575678,5.36379167 C51.0173214,5.82354524 51.2471981,6.40589977 51.2471981,7.09553013 C51.2471981,7.78516048 51.0173214,8.36751501 50.5575678,8.82726858 C50.0978142,9.28702215 49.5307848,9.51689894 48.8564796,9.51689894 C48.1821743,9.51689894 47.6151449,9.28702215 47.1553914,8.82726858 Z"></path>
            <polygon points="60.2583682 4.36765893 60.2583682 3.26425036 54.5880741 3.26425036 54.5880741 4.8274125 58.0209008 4.8274125 54.4348229 9.82340132 54.4348229 10.9268099 60.4116193 10.9268099 60.4116193 9.36364775 56.6876154 9.36364775"></polygon>
            <path d="M69.6679913,7.8004856 C69.7139666,7.50930834 69.7292917,7.27943155 69.7292917,7.11085524 C69.7292917,5.99212155 69.3614889,5.02663905 68.6412083,4.24505798 C67.9209277,3.46347691 67.0014205,3.06502381 65.8826868,3.06502381 C64.7026527,3.06502381 63.7218451,3.44815179 62.9709142,4.22973286 C62.2199834,4.99598881 61.8368554,5.9461462 61.8368554,7.09553013 C61.8368554,8.26023917 62.2199834,9.22572168 62.9862393,9.99197763 C63.7524953,10.7429085 64.7639532,11.1260364 66.0052878,11.1260364 C67.4918243,11.1260364 68.610558,10.5743322 69.3614889,9.45559846 L67.9975533,8.65869227 C67.5531248,9.28702215 66.8941447,9.60884965 66.0206129,9.60884965 C64.748628,9.60884965 63.8137958,8.96519465 63.5685939,7.8004856 L69.6679913,7.8004856 Z M63.5532688,6.45187513 C63.7831455,5.28716608 64.6413522,4.56688548 65.8673617,4.56688548 C66.9554452,4.56688548 67.828977,5.21054048 68.0588538,6.45187513 L63.5532688,6.45187513 Z" transform="translate(65.783074, 7.095530) rotate(-35.000000) translate(-65.783074, -7.095530) "></path>
        </g>
    </svg>
);

export default LogoBrand;