import { Action } from 'redux';

import PagesCollection from '../../Domain/PagesCollection';

export const pagesGetRequestLoadingAction = '@@pages/REQUEST_LOADING';
export const pagesGetRequestSuccessAction = '@@pages/REQUEST_SUCCESS';
export const pagesGetRequestFailureAction = '@@pages/REQUEST_FAILURE';

export interface PagesGetRequestLoadingAction extends Action<typeof pagesGetRequestLoadingAction> {
    payload: {}
}

export interface PagesGetRequestSuccessAction extends Action<typeof pagesGetRequestSuccessAction> {
    payload: {
        pages: PagesCollection,
        nbrPages: number,
        total: number
    }
}

export interface PagesGetRequestFailureAction extends Action<typeof pagesGetRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const pagesGetRequestLoadingActionCreator = (): PagesGetRequestLoadingAction => {

    return {
        type: pagesGetRequestLoadingAction,
        payload: {}
    }
};

export const pagesGetRequestSuccessActionCreator = (pages: PagesCollection, nbrPages: number, total: number): PagesGetRequestSuccessAction => {

    return {
        type: pagesGetRequestSuccessAction,
        payload: {
            pages,
            nbrPages,
            total
        }
    }
}

export const pagesGetRequestFailureActionCreator = (errorMessage: string): PagesGetRequestFailureAction => {

    return {
       type: pagesGetRequestFailureAction,
       payload: {
           errorMessage: errorMessage
       } 
    }
}