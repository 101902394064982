import React, { useMemo } from 'react';
import { GamesRepository } from '../Repositories/GamesRepository';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { modalCloseAllActionCreator } from '../../../_utils/Modal/Redux/modal.actions';

export interface IDeleteGameControllerProps {
    _id: string,
    name: string
}

export const DeleteGameController: React.FunctionComponent<IDeleteGameControllerProps> = ({ _id, name }): React.ReactElement => {
    const gamesRepository: GamesRepository = useMemo(() => new GamesRepository(), []);
    const dispatch = useDispatch();

    const deleteGame = (_id: string, name: string) => {
        gamesRepository.delete(_id).then(
            (r) => {
                toast.success(`${name} deleted`);
            }, (err: any) => {
                toast.error('Error');
            });
            dispatch(modalCloseAllActionCreator());
    }

    return (
        <div>
             <div className="card" onClick={() => deleteGame(_id, name['en'])}>
                <div className="card--content">
                    <div className="card--content-subtitle">Safe delete</div>
                    <div className="card--content-title">Yes</div>
                </div>
                <div className="card--icon">
                    <i className="material-icons">delete</i>
                </div>
            </div>

            <div className="card" onClick={() => dispatch(modalCloseAllActionCreator())}>
                <div className="card--content">
                    <div className="card--content-subtitle">Go back</div>
                    <div className="card--content-title">No</div>
                </div>
                <div className="card--icon">
                    <i className="material-icons">arrow_back</i>
                </div>
            </div>
        </div>
    );
};