import NavigationType from '../../Domain/NavigationType';
import IDataNavigation from './IDataNavigation';

class ValidateNavigation {

    private validateTitle = (data: IDataNavigation): IDataNavigation => {
        if (data.title.inputValue.length === 0) {
            data.title.validation.inError = true;
            data.title.validation.message = 'navigation.title.missing';

            return data;
        }

        if (data.title.inputValue.length < 5) {
            data.title.validation.inError = true;
            data.title.validation.message = 'navigation.title.not_long_enough';

            return data;
        }

        return data;
    }

    private validatePage = (data: IDataNavigation): IDataNavigation => {
        if (data.type.inputValue === NavigationType.Page && data.pageId.inputValue === '') {
            data.pageId.validation.inError = true;
            data.pageId.validation.message = 'navigation.page_type.missing';

            return data;
        }
        data.pageId.validation.inError = false;

        return data;
    }

    private validateModule = (data: IDataNavigation): IDataNavigation => {
        if (data.type.inputValue === NavigationType.Module && data.moduleSlug.inputValue === '') {
            data.moduleSlug.validation.inError = true;
            data.moduleSlug.validation.message = 'navigation.module_type.missing';

            return data;
        }
        data.moduleSlug.validation.inError = false;

        return data;
    }

    public validateForm = (data: IDataNavigation): IDataNavigation => {
        data = this.validateTitle(data);
        data = this.validatePage(data);
        data = this.validateModule(data);

        return data;
    }

    public canBeSubmitted = (data: IDataNavigation): boolean => {
        return  this.validateForm(data) &&
               Object.keys(data).every((field) => !data[field].validation.inError);
    }

}

export default ValidateNavigation;