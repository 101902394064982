import HttpClient from '../../../_utils/HttpClient/HttpClient';

import ContactRequestSettings from '../../Domain/ContactRequestSettings';
import IDataContactRequestSettings from '../../Application/Forms/IDataContactRequestSettings';

class ContactRequestSettingsRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    public get = async(): Promise<ContactRequestSettings> => {
        const response = await this.httpClient.get(this.baseUrl, `/contact-requests/settings`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No contact-requests settings found');
        }

        return new ContactRequestSettings(
            response.data.docs.sender_name,
            response.data.docs.sender_email,
            response.data.docs.sender_no_reply,
            response.data.docs.success_page_id,
            response.data.docs.provider,
            response.data.docs.provider_api_key,
            response.data.docs.provider_template_id
        );
    }

    public put = async(data: IDataContactRequestSettings): Promise<any> => {
        const dataBody = new FormData();
        dataBody.append('sender_name', data.senderName.inputValue);
        dataBody.append('sender_email', data.senderEmail.inputValue);
        dataBody.append('sender_no_reply', data.senderNoReply.inputValue);
        dataBody.append('success_page_id', data.successPageId.inputValue);
        dataBody.append('provider', data.provider.inputValue);
        dataBody.append('provider_api_key', data.providerAPIKey.inputValue);
        dataBody.append('provider_template_id', String(data.providerTemplateId.inputValue));

        const response = await this.httpClient.put(this.baseUrl, `/contact-requests/settings`, dataBody);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No contact-requests settings found');
        }

        return response;
    }

}

export default ContactRequestSettingsRepository;