import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import AppTheme from '../../../Domain/AppTheme';
import Navigation from '../../../../Navigation/Domain/Navigation';
import AppSettings from '../../../Domain/AppSettings';
import NavigationCollection from '../../../../Navigation/Domain/NavigationCollection';

import LogoBrand from '../../../../App/Infrastructure/Style/Svg/LogoBrand';
import { pageBuilderAppSettingsSelector, pageBuilderAppThemeSelector } from '../../Redux/page-builder-get.selectors';
import DisplayImage from '../../../../_utils/Image/DisplayImage';

interface IDisplayHeaderProps {
    navigation: NavigationCollection | null
}

const DisplayHeader: React.FunctionComponent<IDisplayHeaderProps> = ({ navigation }): React.ReactElement => {
    const theme: AppTheme = useSelector(pageBuilderAppThemeSelector);
    const settings: AppSettings = useSelector(pageBuilderAppSettingsSelector);

    const [menuMobileOpened, setMenuMobileOpened] = useState<boolean>(false);

    const renderNavigation = (navigationCollection: NavigationCollection): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];
        
        navigationCollection.forEach((navigation: Navigation, index: number) => {
            if(navigation.childrens !== null && navigation.childrens.length > 0) {
                elements.push(
                    <li className="has-dropdown" key={ index }>
                        { navigation.title }
                        <div className="viewport--header-dropdown">
                            <ul>
                                { renderNavigation(navigation.childrens) }
                            </ul>
                        </div>
                    </li>
                );
            } else {
                elements.push(
                    <li key={ index }>
                        <a href="#" onClick={() => void(0)}>
                            { navigation.title }
                        </a>
                    </li>
                );
            }
        });

        return elements;
    }

    return (
        <header className={ `viewport--header is-${ theme.headerVariant } ${ theme.headerBackgroundBlur ? 'is-blurred' : '' } ${ menuMobileOpened ? 'is-menu-opened' : '' }` }>
            <div className="grid-wrapper">
                <div className="viewport--header-hamburger" onClick={() => setMenuMobileOpened(!menuMobileOpened)}>
                    <svg viewBox="0 0 100 100">
                        <path className="viewport--header-hamburger-line is-line-1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                        <path className="viewport--header-hamburger-line is-line-2" d="M 20,50 H 80" />
                        <path className="viewport--header-hamburger-line is-line-3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
                    </svg>
                </div>
                <div className="viewport--header-logo">
                    <DisplayImage filename={ settings.siteLogo } width={ 120 } height={ 80 } extension="png" />
                </div>
                <div className="viewport--header-navigation">
                    <ul>
                        { navigation !== null && renderNavigation(navigation) }
                    </ul>
                </div>
                {
                    settings.headerButton &&
                    <div className="viewport--header-actions">
                        <button type="button" className="button">
                            { settings.headerButtonLabel }
                        </button>
                    </div>
                }
            </div>
        </header>
    );
}

export default DisplayHeader;