import PageContent from './PageContent';

class PageContentsCollection extends Array<PageContent> {

    public getPageContentByPosition(): PageContentsCollection {

        return this.sort((prevContent: PageContent, currentContent: PageContent) => {
            return prevContent.position < currentContent.position ? -1 : 1;
        });
    }

}

export default PageContentsCollection;