import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { appCloseMenuActionCreator } from '../Redux/App/app.actions';

import IconArrowDown from '../Style/Svg/IconArrowDown';

const Navigation: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();

    const handleCallback = useCallback(() => {
        dispatch(appCloseMenuActionCreator());
    }, []);

    return (
        <div className="header--navigation">
            <ul>
                <li>
                    <NavLink to="/" exact={ true } activeClassName="is-active" onClick={handleCallback}>
                        Dashboard
                    </NavLink>
                </li>
                <li className="has-dropdown">
                    <strong>Contenu du site { IconArrowDown }</strong>
                    <ul>
                        <li>
                            <NavLink to="/navigation" activeClassName="is-active" onClick={handleCallback}>
                                <span>Navigation</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/pages" activeClassName="is-active" onClick={handleCallback}>
                                <span>Pages</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink to="/contact-requests" activeClassName="is-active" onClick={handleCallback}>
                        Contact
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/medias/files" activeClassName="is-active" onClick={handleCallback}>Medias</NavLink>
                </li>
                <li>
                    <NavLink to="/users" activeClassName="is-active" onClick={handleCallback}>Users</NavLink>
                </li>
                <li>
                    <NavLink to="/settings" activeClassName="is-active" onClick={handleCallback}>Settings</NavLink>
                </li>
            </ul>
        </div>
    );
}

export default Navigation;