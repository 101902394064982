import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import GetContactRequests from '../../Application/GetContactRequests';
import HandleContactRequestSettings from '../../Application/Forms/HandleContactRequestSettings';
import { IScaffoldingNavigationItem } from '../../../_utils/Scaffolding/IScaffoldingNavigationItem';
import { ScaffoldingNavigation } from '../../../_utils/Scaffolding/ScaffoldingNavigation';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const ContactRequestsController: React.FunctionComponent = (): React.ReactElement => {
    const query = useQuery();
    const location = useLocation();
    const [navigationPage, setNavigationPage] = useState<string>('/contact-requests');
    const breadcrumbItems = [
        {
            title: "contact_requests.page.title",
            url: null
        }
    ];

    const scaffoldingNavigationItems: IScaffoldingNavigationItem[] = [
        {
            title: "contact_requests.navigation.contact_requests",
            url: null,
            childrens: [
                {
                    title: "contact_requests.navigation.contact_requests_list",
                    url: "/contact-requests",
                    childrens: []
                },
                {
                    title: "contact_requests.navigation.contact_requests_settings",
                    url: "/contact-requests/settings",
                    childrens: []
                }
            ]
        }
    ];

    useEffect(() => {
        if (location !== null) {
            setNavigationPage(location.pathname);
        }
    }, [location]);
    
    const renderPage = (): React.ReactElement => {
        const defaultPage = query.get('page') !== null ? Number(query.get('page')): 1;

        switch(navigationPage) {
            case '/contact-requests':
                return <GetContactRequests actualPage={ defaultPage } />;
            
            case '/contact-requests/settings':
                return <HandleContactRequestSettings />;

            default:
                return <React.Fragment>Coming Soon</React.Fragment>;
        }
    }

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumbItems } />

            <div className="grid-wrapper">
                <div className="scaffolding">
                    <div className="scaffolding--header">
                        <h2><LocaleMessage id="contact_requests.page.title" /></h2>
                        <div className="scaffoling--header-actions"></div>
                    </div>

                    <div className="scaffolding--columns">
                        <div className="scaffolding--columns-navigation">
                            <ScaffoldingNavigation items={ scaffoldingNavigationItems } />
                        </div>

                        <div className="scaffolding--columns-content">
                            { renderPage() }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}