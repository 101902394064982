import { AnyAction } from 'redux';
import { searchFiltersAddTagAction, searchFiltersRemoveTagAction, SearchFiltersAddTagAction } from './searchFilters.actions';

export interface FilterTag {
    type: string,
    value: string
}

export interface SearchFiltersReduxState {
    tags: FilterTag[]
}

const initialSearchFiltersReduxState: SearchFiltersReduxState = {
    tags: []
}

export const searchFiltersReducer = (state: SearchFiltersReduxState = initialSearchFiltersReduxState, action: AnyAction): SearchFiltersReduxState => {

    switch (action.type){
        case searchFiltersAddTagAction:
            const typeToAdd = (action as SearchFiltersAddTagAction).payload.type;
            const value = (action as SearchFiltersAddTagAction).payload.value;
            const tagsToAdd = addNewTagToExistingTags([ ...state.tags ], typeToAdd, value);

            return {
                tags: tagsToAdd
            }

        case searchFiltersRemoveTagAction:
            const typeToRemove = (action as SearchFiltersAddTagAction).payload.type;
            let tagsWhenRemove = state.tags;
            tagsWhenRemove = tagsWhenRemove.filter(item => item.type !== typeToRemove);

            return {
                tags: tagsWhenRemove
            }
    }

    return state;
}

const addNewTagToExistingTags = (tags: FilterTag[], type: string, value: string): FilterTag[] => {
    const isTypeAlreadyExist = tags.find(item => item.type === type);
    if (isTypeAlreadyExist !== undefined) {
        tags = tags.filter(item => item.type !== type);
        tags.push({ type: type, value });
    } else {
        tags.push({ type: type, value });
    }

    return tags;
}