import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';
import { ScaffoldingNavigation } from '../../../_utils/Scaffolding/ScaffoldingNavigation';
import { IScaffoldingNavigationItem } from '../../../_utils/Scaffolding/IScaffoldingNavigationItem';
import GetGeneralSettings from '../../Application/GetGeneralSettings';
import GetPersonalInformation from '../../Application/GetPersonalInformation';
import ConfirmationLogoutModal from '../../../App/Infrastructure/Pages/ConfirmationLogoutModal';

import { modalAddActionCreator } from '../../../_utils/Modal/Redux/modal.actions';

export const SettingsController: React.FunctionComponent = (): React.ReactElement => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [navigationPage, setNavigationPage] = useState<string>('/users/settings/info');
    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            title: 'users.settings.page.title',
            url: null
        }
    ];

    const scaffoldingNavigationItems: IScaffoldingNavigationItem[] = [
        {
            title: "users.settings.navigation.my_account",
            url: null,
            childrens: [
                {
                    title: "users.settings.navigation.personal_information",
                    url: "/users/settings/info",
                    childrens: []
                },
                {
                    title: "users.settings.navigation.password",
                    url: "/users/settings/password",
                    childrens: []
                }
            ]
        },
        {
            title: "users.settings.navigation.settings",
            url: null,
            childrens: [
                {
                    title: "users.settings.navigation.general_settings",
                    url: "/users/settings/settings",
                    childrens: []
                }
            ]
        }
    ];

    useEffect(() => {
        if (location !== null) {
            setNavigationPage(location.pathname);
        }
    }, [location]);

    const handleDisplayLogoutModale = (): void => {
        dispatch(
            modalAddActionCreator(
                <ConfirmationLogoutModal />,
                'logout.modale.confirmation.title'
            )
        );
    }

    const renderPage = (): React.ReactElement => {
        switch(navigationPage){
            case '/users/settings/info':
                return <GetPersonalInformation />;

            case '/users/settings/settings':
                return <GetGeneralSettings />

            default:
                return <React.Fragment>Coming Soon</React.Fragment>;
        }
    }

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumbItems } />

            <div className="grid-wrapper">
                <div className="scaffolding">
                    <div className="scaffolding--header">
                        <h2><LocaleMessage id="users.settings.page.title" /></h2>
                        <div className="scaffolding--header-actions"></div>
                    </div>

                    <div className="scaffolding--columns">
                        <div className="scaffolding--columns-navigation">
                            <ScaffoldingNavigation items={ scaffoldingNavigationItems } />
                            <hr />
                            <button type="button" className="button is-red is-full-width is-outlined" onClick={ () => handleDisplayLogoutModale() }>
                                Déconnexion
                            </button>
                        </div>
                        <div className="scaffolding--columns-content">
                            { renderPage() }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}