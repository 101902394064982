import React, { useState } from 'react';
import slugify from 'slugify';
import { useIntl } from 'react-intl';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IData from './IData';
import FormValidationMessage from './FormValidationMessage';
import IconWarning from '../../App/Infrastructure/Style/Svg/IconWarning';

interface ITextareaInputProps {
    label: string,
    inputName: string,
    inputData: IData<string|undefined>,
    onChange: (name: string, value: string) => void,
    inputType?: 'email' | 'text',
    placeholder?: string,
    disabled?: boolean,
    additionalClassname?: string,
    additionalInformation?: string
}

const TextareaInput: React.FunctionComponent<ITextareaInputProps> = ({ label, inputName, inputData, placeholder, onChange, disabled, additionalClassname, additionalInformation }): React.ReactElement => {
    const intl = useIntl();
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        const { name, value } = event.currentTarget;

        onChange(name, value);
    }

    const placeholderI18n = placeholder !== undefined ? intl.formatMessage({ id: placeholder }) : '';

    return (
        <div className={ `input ${ isFocused ? 'is-focused' : '' } ${ additionalClassname !== undefined ? additionalClassname : '' }` }>
            <div className="input--container">
                <label htmlFor={ slugify(inputName) }>
                    <LocaleMessage id={ label } />
                </label>
                <div className="input--textarea">
                    <textarea
                        id={ slugify(inputName) }
                        name={ inputName }
                        value={ inputData[inputName] !== undefined ? inputData[inputName].inputValue : "" }
                        placeholder={ placeholderI18n }
                        rows={ 3 }
                        disabled={ disabled !== undefined ? disabled : false }
                        onChange={ (event) => handleOnChange(event) }
                        onFocus={ () => setIsFocused(true) }
                        onBlur={ () => setIsFocused(false) }
                        />
                </div>
            </div>

            { (inputData[inputName] !== undefined && inputData[inputName].validation.inError) && <FormValidationMessage message={ inputData[inputName].validation.message } /> }

            { additionalInformation !== undefined && <blockquote className="input--additional-information">{ IconWarning }&nbsp;<span dangerouslySetInnerHTML={{ __html: String(additionalInformation) }}></span></blockquote> }
        </div>
    )
}

export default TextareaInput;