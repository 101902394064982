import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import User from '../../Domain/User';

import { UsersRepository } from '../../Infrastructure/Repositories/UsersRepository';

enum Tabs {
    INFO = 'info',
    ACCOUNT = 'account',
    GAMES = 'games',
    MEDIAS = 'medias'
}

interface IHandleUserProps {
    _id: string
}

export const HandleUser: React.FunctionComponent<IHandleUserProps> = ({ _id }): React.ReactElement => {
    const usersRepository: UsersRepository = React.useMemo(() => new UsersRepository(), []);
    const [tab, setTab] = useState<Tabs>(Tabs.INFO);
    const [edit, setEdit] = useState<boolean>(false);
    const [user, setUser] = useState<User>();

    useEffect(() => {
        usersRepository.getUserById(_id)
            .then((response: User) => {
                setUser(response);
            }, (error: any) => {
        });
    }, [_id, usersRepository]);

    const onChange = (event: React.FormEvent<HTMLInputElement>): void => {
        if (user) {
            setEdit(true);
            setUser(Object.assign({}, user, {
                [event.currentTarget.name]: event.currentTarget.value,
            }));
        }
    };

    const saveUser = async () => {
        if (user) {
            const res = await usersRepository.updateUser(user);
            if (res) {
                setEdit(false);
                toast.success('User Saved');
            } else {
                toast.error('User not saved');
            }
            usersRepository.getUserById(_id)
                .then((response: User) => {
                    setUser(response);
                }, (error: any) => {
            });
        }
    };

    const renderUserForm = (): React.ReactElement => {
        if (!user) {

            return (
                <div>
                    No user found
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="form--group">
                    <label htmlFor="_id">_id</label>
                    <div className="form--group-text">{ user.id }</div>
                </div>
                <div className="form--group">
                    <label htmlFor="email">Email</label>
                    <div className="form--group-input">
                        <input id="email" name="email" type="email" value={ user.email } onChange={(event) => { onChange(event)}}/>
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="firstName">First name</label>
                    <div className="form--group-input">
                        <input id="firstName" name="firstName" type="text" value={ user.firstName } onChange={(event) => {onChange(event)}}/>
                    </div>
                </div>
                <div className="form--group">
                    <label htmlFor="lastName">Last name</label>
                    <div className="form--group-input">
                        <input id="lastName" name="lastName" type="text" value={ user.lastName } onChange={(event) => {onChange(event)}}/>
                    </div>
                </div>
            </React.Fragment>
        )
    };

    const renderSwitchTabs = (tab: Tabs): React.ReactElement => {
        switch (tab) {
            case Tabs.INFO:
                return renderUserForm();
            default:
                return renderUserForm();
        }
    };

    const renderSaveUserButton = (): React.ReactElement | null => {
        if (!edit) {
            return null;
        }

        return (
            <button className="button is-primary" onClick={ () => { saveUser() }}>
                Save
            </button>
        );
    };

    return (
        <div className="portlet">
            <div className="portlet--title">
                <div className="portlet--title-label">
                    Edit { user?.username }
                </div>
                <div className="portlet--title-toolbar">
                    <button type="button" className="button is-red">
                        <i className="material-icons">delete</i>
                    </button>
                </div>
            </div>
            <div className="portlet--body">
                <ul className="tabs">
                    <li className={ tab === Tabs.INFO ? 'is-active' : '' } onClick={() => {setTab(Tabs.INFO) }}>
                        <i className="fas fa-pencil"></i>&nbsp;Infos
                    </li>
                    <li className={ tab === Tabs.ACCOUNT ? 'is-active' : '' } onClick={() => {setTab(Tabs.ACCOUNT) }}>
                        <i className="fas fa-pencil"></i>&nbsp;Account
                    </li>
                    <li className={ tab === Tabs.GAMES ? 'is-active' : '' } onClick={() => {setTab(Tabs.GAMES) }}>
                        <i className="fas fa-pencil"></i>&nbsp;Games
                    </li>
                </ul>
                { renderSaveUserButton() }
                <div className="p-3">
                    <form>
                        { renderSwitchTabs(tab) }
                    </form>
                </div>
            </div>
        </div>
    );
};