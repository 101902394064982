import React, { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { modalRemoveActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { navigationDeleteItemService } from '../Redux/navigation.services';
import { navigationDeleteRequestLoadingActionCreator } from '../Redux/navigation.actions';
import { navigationIsDeleteRequestFailureSelector, navigationIsDeleteRequestLoadingSelector, navigationIsDeleteRequestSuccessSelector } from '../Redux/navigation.selectors';

interface IDeleteNavigationModalControllerProps {
    id: number
}

const DeleteNavigationModalController: React.FunctionComponent<IDeleteNavigationModalControllerProps> = ({ id }): React.ReactElement => {
    const dispatch = useDispatch();
    const isDeleteRequestLoading = useSelector(navigationIsDeleteRequestLoadingSelector);
    const isDeleteRequestFailure = useSelector(navigationIsDeleteRequestFailureSelector);
    const isDeleteRequestSuccess = useSelector(navigationIsDeleteRequestSuccessSelector);

    const handleCloseModal = useCallback(() => {
        dispatch(modalRemoveActionCreator());
    }, [dispatch]);

    const handleDeleteNavigationItem = (): void => {
        dispatch(navigationDeleteRequestLoadingActionCreator());
    }

    useEffect(() => {
        if (isDeleteRequestLoading) {
            navigationDeleteItemService(id);
        }

        if (isDeleteRequestSuccess) {
            handleCloseModal();
        }

        if(isDeleteRequestFailure) {
            toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });
        }
    }, [id, isDeleteRequestLoading, isDeleteRequestSuccess, isDeleteRequestFailure, handleCloseModal]);

    return(
        <div className="modal--confirmation">
            <div className="modal--information">
                <LocaleMessage id="navigation.delete.info" />
            </div>

            <div className="modal--actions">
                <button className={ `button is-primary ${isDeleteRequestLoading ? 'is-loading' : ''}` } onClick={ handleDeleteNavigationItem }>
                    <LocaleMessage id="modales.confirmation.delete" />
                </button>
                <button className="button is-primary is-transparent" onClick={ handleCloseModal }>
                    <LocaleMessage id="modales.confirmation.cancel" />
                </button>
            </div>
        </div>
    );
}

export default DeleteNavigationModalController;