import { Action } from 'redux';

import AuthToken from '../../Domain/AuthToken';

export const authRequestLoadingAction = '@@auth/REQUEST_LOADING';
export const authRequestSuccessAction = '@@auth/REQUEST_SUCCESS';
export const authRequestFailureAction = '@@auth/REQUEST_FAILURE';
export const authLogOutAction = '@@auth/LOG_OUT';

export interface AuthRequestLoadingAction extends Action<typeof authRequestLoadingAction> {
    payload: {}
}

export interface AuthRequestSuccessAction extends Action<typeof authRequestSuccessAction> {
    payload: {
        authToken: AuthToken
    }
}

export interface AuthRequestFailureAction extends Action<typeof authRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

export interface AuthLogOutAction extends Action<typeof authLogOutAction> {
    payload: {}
}

export const authRequestLoadingActionCreator = (): AuthRequestLoadingAction => {

    return {
        type: authRequestLoadingAction,
        payload: {}
    }
};

export const authRequestSuccessActionCreator = (): AuthRequestSuccessAction => {
    const accessToken   = localStorage.getItem('accessToken') as string;
    const refreshToken  = localStorage.getItem('refreshToken') as string;
    const typeToken     = localStorage.getItem('typeToken') as string;

    const authToken = new AuthToken(
        accessToken,
        refreshToken,
        typeToken
    );

    return {
        type: authRequestSuccessAction,
        payload: {
            authToken: authToken
        }
    }
};

export const authRequestFailureActionCreator = (errorMessage: string): AuthRequestFailureAction => {

    return {
        type: authRequestFailureAction,
        payload: {
            errorMessage: `login.errors.${ errorMessage }`
        }
    }
};

export const authLogOutCreator = (): AuthLogOutAction => {

    return {
        type: authLogOutAction,
        payload: {}
    }
};