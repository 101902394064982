import { Action } from 'redux';

import NavigationCollection from '../../Domain/NavigationCollection';
import NavigationPagesCollection from '../../Domain/NavigationPagesCollection';

export const navigationRequestLoadingAction = '@@navigation/REQUEST_LOADING';
export const navigationRequestSuccessAction = '@@navigation/REQUEST_SUCCESS';
export const navigationRequestFailureAction = '@@navigation/REQUEST_FAILURE';

export const navigationPostRequestLoadingAction = '@@navigation/POST_REQUEST_LOADING';
export const navigationPostRequestSuccessAction = '@@navigation/POST_REQUEST_SUCCESS';
export const navigationPostRequestFailureAction = '@@navigation/POST_REQUEST_FAILURE';

export const navigationDeleteRequestLoadingAction = '@@navigation/DELETE_REQUEST_LOADING';
export const navigationDeleteRequestSuccessAction = '@@navigation/DELETE_REQUEST_SUCCESS';
export const navigationDeleteRequestFailureAction = '@@navigation/DELETE_REQUEST_FAILURE';

export const navigationPagesRequestSuccessAction = '@@navigation/PAGES_REQUEST_SUCCESS';

// --- ActionType declaration
export interface NavigationRequestLoadingAction extends Action<typeof navigationRequestLoadingAction> {
    payload: {}
}

export interface NavigationRequestSuccessAction extends Action<typeof navigationRequestSuccessAction> {
    payload: {
        navigation: NavigationCollection
    }
}

export interface NavigationPagesRequestSuccessAction extends Action<typeof navigationPagesRequestSuccessAction> {
    payload: {
        navigationPages: NavigationPagesCollection
    }
}

export interface NavigationRequestFailureAction extends Action<typeof navigationRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

export interface NavigationPostRequestLoadingAction extends Action<typeof navigationPostRequestLoadingAction> {
    payload: {}
}

export interface NavigationPostRequestSuccessAction extends Action<typeof navigationPostRequestSuccessAction> {
    payload: {}
}

export interface NavigationPostRequestFailureAction extends Action<typeof navigationPostRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

export interface NavigationDeleteRequestLoadingAction extends Action<typeof navigationDeleteRequestLoadingAction> {
    payload: {}
}

export interface NavigationDeleteRequestSuccessAction extends Action<typeof navigationDeleteRequestSuccessAction> {
    payload: {}
}

export interface NavigationDeleteRequestFailureAction extends Action<typeof navigationDeleteRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const navigationRequestLoadingActionCreator = (): NavigationRequestLoadingAction => {

    return {
        type: navigationRequestLoadingAction,
        payload: {}
    }
};

export const navigationRequestSuccessActionCreator = (navigation: NavigationCollection): NavigationRequestSuccessAction => {

    return {
        type: navigationRequestSuccessAction,
        payload: {
            navigation: navigation
        }
    }
}

export const navigationPagesRequestSuccessActionCreator = (navigationPages: NavigationPagesCollection): NavigationPagesRequestSuccessAction => {

    return {
        type: navigationPagesRequestSuccessAction,
        payload: {
            navigationPages
        }
    }
}

export const navigationRequestFailureActionCreator = (errorMessage: string): NavigationRequestFailureAction => {

    return {
       type: navigationRequestFailureAction,
       payload: {
           errorMessage: errorMessage
       } 
    }
}

export const navigationPostRequestLoadingActionCreator = (): NavigationPostRequestLoadingAction => {

    return {
        type: navigationPostRequestLoadingAction,
        payload: {}
    }
};

export const navigationPostRequestSuccessActionCreator = (): NavigationPostRequestSuccessAction => {

    return {
        type: navigationPostRequestSuccessAction,
        payload: {}
    }
}

export const navigationPostRequestFailureActionCreator = (errorMessage: string): NavigationPostRequestFailureAction => {

    return {
       type: navigationPostRequestFailureAction,
       payload: {
           errorMessage: errorMessage
       } 
    }
}

export const navigationDeleteRequestLoadingActionCreator = (): NavigationDeleteRequestLoadingAction => {

    return {
        type: navigationDeleteRequestLoadingAction,
        payload: {}
    }
};

export const navigationDeleteRequestSuccessActionCreator = (): NavigationDeleteRequestSuccessAction => {

    return {
        type: navigationDeleteRequestSuccessAction,
        payload: {}
    }
}

export const navigationDeleteRequestFailureActionCreator = (errorMessage: string): NavigationDeleteRequestFailureAction => {

    return {
       type: navigationDeleteRequestFailureAction,
       payload: {
           errorMessage: errorMessage
       } 
    }
}