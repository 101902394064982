import React from 'react';
import { useParams } from 'react-router';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import HandlePage from '../../Application/Forms/HandlePage';
import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';

type PageEditControllerParams = {
    pageId: string
}

export const PageEditController: React.FunctionComponent = (): React.ReactElement => {
    const { pageId } = useParams<PageEditControllerParams>();

    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            title: "page.page.title",
            url: "/pages"
        },
        {
            title: pageId !== undefined ? "page.page.edit_title" : "page.page.create_title",
            url: null
        }
    ];

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumbItems } />
            <div className="pages">
                <div className="grid-wrapper">
                    <div className="page--header">
                        <h2><LocaleMessage id={ pageId !== undefined ? "page.page.edit_title" : "page.page.create_title" } /></h2>

                        <div className="page--header-actions">&nbsp;</div>
                    </div>

                    <div className="page--content">
                        <HandlePage pageId={ pageId !== undefined ? Number(pageId) : null } />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}