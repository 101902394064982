import HttpClient from '../../../_utils/HttpClient/HttpClient';

import ContactRequestPage from '../../Domain/ContactRequestPage';
import ContactRequestPagesCollection from '../../Domain/ContactRequestPagesCollection';

class PagesRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    public get = async (): Promise<ContactRequestPagesCollection> => {
        const response = await this.httpClient.get(this.baseUrl, `/navigation/pages`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No contact requests pages found');
        }

        const contactRequestPagesCollection = new ContactRequestPagesCollection();

        response.data.docs.forEach((navigationPage: any) => {
            contactRequestPagesCollection.push(
                new ContactRequestPage(
                    navigationPage.id,
                    navigationPage.title
                )
            )
        });

        return contactRequestPagesCollection;
    }

}

export default PagesRepository;