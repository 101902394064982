import {Action} from 'redux';

export const searchFiltersAddTagAction = '@@searchFilters/ADD_TAG';
export const searchFiltersRemoveTagAction = '@@searchFilters/REMOVE_TAG';

export interface SearchFiltersAddTagAction extends Action<typeof searchFiltersAddTagAction> {
    payload: {
        type: string,
        value: string
    }
}

export interface SearchFiltersRemoveTagAction extends Action<typeof searchFiltersRemoveTagAction> {
    payload: {
        type: string
    }
}

export const searchFiltersAddTagActionCreator = (type: string, value: string): SearchFiltersAddTagAction => ({
    type: searchFiltersAddTagAction,
    payload: {
        type: type,
        value: value
    }
});

export const searchFiltersRemoveTagActionCreator = (type: string): SearchFiltersRemoveTagAction => ({
    type: searchFiltersRemoveTagAction,
    payload: {
        type: type
    }
});