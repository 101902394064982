import { AnyAction } from 'redux';

import { 
    contactRequestDeleteInitAction, 
    contactRequestDeleteRequestLoadingAction, 
    contactRequestDeleteRequestSuccessAction, 
    contactRequestDeleteRequestFailureAction, 
    ContactRequestDeleteRequestFailureAction
} from './delete-contact-request.actions';

export interface DeleteContactRequestReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string
}

const iutialPostContactRequestReduxState: DeleteContactRequestReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: ""
}

export const DeleteContactRequestReducer = (state: DeleteContactRequestReduxState = iutialPostContactRequestReduxState, action: AnyAction): DeleteContactRequestReduxState => {
    switch(action.type) {
        case contactRequestDeleteInitAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: ""
            };

        case contactRequestDeleteRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: ""
            }

        case contactRequestDeleteRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                errorMessage: ""
            }
        case contactRequestDeleteRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                errorMessage: (action as ContactRequestDeleteRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}