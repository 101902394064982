import HttpClient from '../../../_utils/HttpClient/HttpClient';

import Directory from '../../Domain/Directory';
import File from '../../Domain/File';

class FilesFromDirectoryRepository {

    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);
    private httpClient: HttpClient = new HttpClient();

    public getFilesFromDirectory = async (path: string): Promise<Directory> => {
        const response = await this.httpClient.get(this.baseUrl, `/files?path=${path}`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No files found');
        }

        const files: File[] = [];
        const directories: Directory[] = [];
        if (response.data.docs.folders !== undefined) {
            response.data.docs.folders.forEach((folder: any) => {
                directories.push(
                    new Directory(
                        folder.folder_name,
                        folder.path,
                        [],
                        []
                    )
                );
            });
        }

        if (response.data.docs.files !== undefined) {
            response.data.docs.files.forEach((file: any) => {
                files.push(
                    new File(
                        file.file_name,
                        file.file_extension,
                        file.file_size,
                        path
                    )
                );
            });
        }

        return new Directory(
            '.',
            path,
            directories,
            files
        );
    }

}

export default FilesFromDirectoryRepository;