import { AnyAction } from 'redux';

import Theme from '../../../Domain/Theme';

import { 
    themePutInitAction, 
    themePutRequestLoadingAction, 
    themePutRequestSuccessAction, 
    themePutRequestFailureAction, 
    ThemePutRequestFailureAction
} from './put-theme.actions';

export interface PutThemeReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    errors: []
}

const initialPutThemeReduxState: PutThemeReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: "",
    errors: []
}

export const PutThemeReducer = (state: PutThemeReduxState = initialPutThemeReduxState, action: AnyAction): PutThemeReduxState => {
    switch(action.type) {
        case themePutInitAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                errors: []
            };

        case themePutRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                errors: []
            }

        case themePutRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                errorMessage: "",
                errors: []
            }

        case themePutRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                errorMessage: (action as ThemePutRequestFailureAction).payload.errorMessage,
                errors: (action as ThemePutRequestFailureAction).payload.errors
            }

        default:
            return state;
    }
}