import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { AppReduxState } from '../../../../../store';

import { IComponentData } from '../IComponentData';
import RangeInput from '../../../../../_utils/Forms/RangeInput';
import SelectInput from '../../../../../_utils/Forms/SelectInput';
import GroupCollapse from '../../../../Infrastructure/Partials/GroupCollapse';

import IconScreenHeight from '../../../../Infrastructure/Style/svg/IconScreenHeight';
import { pageBuilderPageContentSelector } from '../../../../Infrastructure/Redux/page-builder.selectors';

interface IHandleComponentSectionsHeroSectionSettingsProps {
    pageContentId: string,
    onChange: (inputName: string, value: string | number) => void
}

const HandleComponentSectionsHeroSectionSettings: React.FunctionComponent<IHandleComponentSectionsHeroSectionSettingsProps> = ({ pageContentId, onChange }): React.ReactElement => {
    const intl = useIntl();
    const pageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const data: IComponentData<string | number> = pageContent.data as IComponentData<string | number>;

    const displayLogoBrandRecords = (): Record<string, string> => {
        const displayLogoBrandRecords: Record<string, string> = {};

        displayLogoBrandRecords['no'] = intl.formatMessage({ id: `page_builder.component-sections-hero-section-type.display_logo_brand.no` });
        displayLogoBrandRecords['yes'] = intl.formatMessage({ id: `page_builder.component-sections-hero-section-type.display_logo_brand.yes` });

        return displayLogoBrandRecords;
    }

    const alignElementsRecords = (): Record<string, string> => {
        const alignElementsRecords: Record<string, string> = {};

        alignElementsRecords['left'] = intl.formatMessage({ id: `page_builder.component-sections-hero-section-type.align.left` });
        alignElementsRecords['center'] = intl.formatMessage({ id: `page_builder.component-sections-hero-section-type.align.center` });
        alignElementsRecords['right'] = intl.formatMessage({ id: `page_builder.component-sections-hero-section-type.align.right` });

        return alignElementsRecords;
    }

    const buttonStyleRecords = (): Record<string, string> => {
        const buttonStyleRecords: Record<string, string> = {};

        buttonStyleRecords['normal'] = "Normal";
        buttonStyleRecords['dark'] = "Dark";
        buttonStyleRecords['white'] = "White";

        return buttonStyleRecords;
    }

    return (
        <GroupCollapse label="page_builder.component-sections-hero-section-type.settings.properties">
            <div className="page-builder--settings-list">
                <SelectInput inputName="displayLogoBrand" label="page_builder.component-sections-hero-section-type.display_logo_brand.label" inputValue={ data.displayLogoBrand as string } choices={ displayLogoBrandRecords() } onChange={(name, value) => { onChange(name, value) }} />
                
                <SelectInput inputName="align" label="page_builder.component-sections-hero-section-type.align.label" inputValue={ data.align as string } choices={ alignElementsRecords() } onChange={(name, value) => { onChange(name, value) }} />
                
                <SelectInput inputName="buttonStyle" label="page_builder.component-sections-hero-section-type.button_style.label" inputValue={ data.buttonStyle as string } choices={ buttonStyleRecords() } onChange={(name, value) => { onChange(name, value) }} />

                <div className="page-builder--contextual-group-item-label">
                    Desktop
                </div>
                <div className="form--group has-svg">
                    <RangeInput inputName="minHeightDesktop" label={ IconScreenHeight } min={ 0 } max={ 100 } step={ 10 } unit="VH" value={ data.minHeightDesktop as number } onInputChange={ (name, value) => onChange(name, Number(value)) } />
                </div>
                <br />
                <div className="page-builder--contextual-group-item-label">
                    Mobile
                </div>
                <div className="form--group has-svg">
                    <RangeInput inputName="minHeightMobile" label={ IconScreenHeight } min={ 0 } max={ 100 } step={ 10 } unit="VH" value={ data.minHeightMobile as number } onInputChange={ (name, value) => onChange(name, Number(value)) } />
                </div>
            </div>
        </GroupCollapse>
    );
}

export default HandleComponentSectionsHeroSectionSettings;