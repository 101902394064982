import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { LocaleMessage } from '../Redux/Locale/Provider/LocaleMessage';

import HandleTheme from '../../Application/Forms/Theme/HandleTheme';
import HandleSettings from '../../Application/Forms/Settings/HandleSettings';
import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';
import { ScaffoldingNavigation } from '../../../_utils/Scaffolding/ScaffoldingNavigation';
import { IScaffoldingNavigationItem } from '../../../_utils/Scaffolding/IScaffoldingNavigationItem';

export const SettingsController: React.FunctionComponent = (): React.ReactElement => {
    const location = useLocation();
    const [navigationPage, setNavigationPage] = useState<string>('/settings');
    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            title: 'app.settings.page.title',
            url: null
        }
    ];
    const scaffoldingNavigationItems: IScaffoldingNavigationItem[] = [
        {
            title: 'app.settings.navigation.settings',
            url: null,
            childrens: [
                {
                    title: 'app.settings.navigation.settings.general_settings',
                    url: '/settings',
                    childrens: []
                }
            ]
        },
        {
            title: 'app.settings.navigation.customisation',
            url: null,
            childrens: [
                {
                    title: 'app.settings.navigation.customisation.theme',
                    url: '/settings/theme',
                    childrens: []
                }
            ]
        }
    ]; 

    useEffect(() => {
        if (location !== null) {
            setNavigationPage(location.pathname);
        }
    }, [location]);

    const renderPage = (): React.ReactElement => {
        switch(navigationPage){
            case '/settings':
                return <HandleSettings />;

            case '/settings/theme':
                return <HandleTheme />;

            default:
                return <React.Fragment>Coming Soon</React.Fragment>;
        }
    }

    return (
        <div className="app-settings">
            <Breadcrumb items={ breadcrumbItems } />

            <div className="grid-wrapper">
                <div className="scaffolding">
                    <div className="scaffolding--header">
                        <h2><LocaleMessage id="app.settings.page.title" /></h2>
                        <div className="scaffolding--header-actions"></div>
                    </div>

                    <div className="scaffolding--columns">
                        <div className="scaffolding--columns-navigation">
                            <ScaffoldingNavigation items={ scaffoldingNavigationItems } />
                        </div>
                        <div className="scaffolding--columns-content">
                            { renderPage() }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}