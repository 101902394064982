import { AnyAction } from 'redux';
import ContactRequest from '../../Domain/ContactRequest';

import { 
    contactRequestPutInitAction, 
    contactRequestPutRequestLoadingAction, 
    contactRequestPutRequestSuccessAction, 
    ContactRequestPutRequestSuccessAction,
    contactRequestPutRequestFailureAction, 
    ContactRequestPutRequestFailureAction
} from './put-contact-request.actions';

export interface PutContactRequestReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    contactRequest: ContactRequest | null
}

const iutialPostContactRequestReduxState: PutContactRequestReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: "",
    contactRequest: null
}

export const PutContactRequestReducer = (state: PutContactRequestReduxState = iutialPostContactRequestReduxState, action: AnyAction): PutContactRequestReduxState => {
    switch(action.type) {
        case contactRequestPutInitAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: "",
                contactRequest: null
            };

        case contactRequestPutRequestLoadingAction:
            return {
                ...state,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                contactRequest: null,
                errorMessage: ""
            }

        case contactRequestPutRequestSuccessAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                contactRequest: (action as ContactRequestPutRequestSuccessAction).payload.contactRequest,
                errorMessage: ""
            }
        case contactRequestPutRequestFailureAction:
            return {
                ...state,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                contactRequest: null,
                errorMessage: (action as ContactRequestPutRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}