import React, { useCallback, useEffect, useRef, useState } from 'react';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IconArrowDown from '../Style/svg/IconArrowDown';

interface IGroupCollapse {
    label: string,
    children: React.ReactElement,
    defaultState?: 'opened' | 'closed'
}

const GroupCollapse: React.FunctionComponent<IGroupCollapse> = ({ label, children, defaultState }): React.ReactElement => {
    const [hidden, setHidden] = useState<boolean>(defaultState === undefined || defaultState === 'closed' ? true : false);
    const contentRef = useRef<HTMLDivElement>(null);
    const childrensRef = useRef<HTMLDivElement>(null);

    const handleHeightProperty = useCallback(() => {
        //@ts-ignore
        const { scrollHeight } = childrensRef.current !== null ? childrensRef.current : 0;

        if (contentRef.current !== null) {
            if (hidden) {
                contentRef.current.style.setProperty('--group-content-height', `0px`);
            } else {
                contentRef.current.style.setProperty('--group-content-height', `${ scrollHeight }px`);
            }
        }
    }, [hidden, contentRef, childrensRef]);

    useEffect(() => {
        handleHeightProperty();
    }, [hidden, handleHeightProperty]);

    useEffect(() => {
        handleHeightProperty();
    }, [children]);

    return (
        <div className={ `page-builder--contextual-group ${ hidden ? 'is-hidden' : '' }`}>
            <div className="page-builder--contextual-group-title" onClick={() => setHidden(!hidden)}>
                { IconArrowDown }<LocaleMessage id={ label } />
            </div>

            <div className="page-builder--contextual-group-content" ref={ contentRef }>
                <div ref={ childrensRef }>
                    { children }
                </div>
            </div>
        </div>
    );
}

export default GroupCollapse;