import { AnyAction } from 'redux';
import AppSettings from '../../Domain/AppSettings';

import AppTheme from '../../Domain/AppTheme';
import PageContent from '../../Domain/PageContent';

import { 
    pageBuilderPageContentLoadingAction,
    pageBuilderPageContentSuccessAction,
    PageBuilderPageContentSuccessAction,
    pageBuilderPageContentFailureAction,
    PageBuilderPageContentFailureAction,
    pageBuilderAppThemeSuccessAction,
    PageBuilderAppThemeSuccessAction,
    pageBuilderAppSettingsSuccessAction,
    PageBuilderAppSettingsSuccessAction
} from './page-builder-get.actions';

export interface PageBuilderQueryReduxState {
    pageContents: PageContent[],
    appTheme: AppTheme,
    appSettings: AppSettings,
    isQueryLoading: boolean,
    isQuerySuccess: boolean,
    isQueryFailure: boolean,
    errorMessage: string
}

const initialPageBuilderQueryReduxState: PageBuilderQueryReduxState = {
    pageContents: [],
    appTheme: new AppTheme('000000', '000000', '000000', '000000', 'static', 'left', '000000', '000000', false, '000000'),
    appSettings: new AppSettings('', '', '', false, '', false, '', ''),
    isQueryLoading: true,
    isQuerySuccess: false,
    isQueryFailure: false,
    errorMessage: ''
}

export const PageBuilderQueryReducer = (state: PageBuilderQueryReduxState = initialPageBuilderQueryReduxState, action: AnyAction): PageBuilderQueryReduxState => {
    switch (action.type) {
        case pageBuilderPageContentLoadingAction:
            {
                return {
                    ...state,
                    pageContents: [],
                    isQueryLoading: true,
                    isQuerySuccess: false,
                    isQueryFailure: false,
                    errorMessage: ''
                }
            }

        case pageBuilderPageContentSuccessAction:
            {
                return {
                    ...state,
                    pageContents: (action as PageBuilderPageContentSuccessAction).payload.pageContents,
                    isQueryLoading: false,
                    isQuerySuccess: true,
                    isQueryFailure: false,
                    errorMessage: ''
                }
            }

        case pageBuilderPageContentFailureAction:
            {
                return {
                    ...state,
                    isQueryLoading: false,
                    isQuerySuccess: false,
                    isQueryFailure: true,
                    errorMessage: (action as PageBuilderPageContentFailureAction).payload.errorMessage
                }
            }

        case pageBuilderAppThemeSuccessAction:
            {
                return {
                    ...state,
                    appTheme: (action as PageBuilderAppThemeSuccessAction).payload.theme
                }
            }

        case pageBuilderAppSettingsSuccessAction:
            {
                return {
                    ...state,
                    appSettings: (action as PageBuilderAppSettingsSuccessAction).payload.settings
                }
            }

        default:
            return {
                ...state
            }
    }
}