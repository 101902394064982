import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { useAsync, useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IData from '../../../../_utils/Forms/IData';
import Settings from '../../../Domain/Settings';
import Navigation from '../../../Domain/Navigation';
import IDataSettings from './IDataSettings';
import ValidateSettings from './ValidateSettings';
import { initialDataSettings } from './InitialDataSettings';
import NavigationCollection from '../../../Domain/NavigationCollection';
import NavigationRepository from '../../../Infrastructure/Repositories/NavigationRepository';

import TextInput from '../../../../_utils/Forms/TextInput';
import FileInput from '../../../../_utils/Forms/FileInput';
import SelectInput from '../../../../_utils/Forms/SelectInput';
import InputsGroup from '../../../../_utils/Forms/InputsGroup';
import TextareaInput from '../../../../_utils/Forms/TextareaInput';
import CheckboxInput from '../../../../_utils/Forms/CheckboxInput';
import SegmentedControl from '../../../../_utils/SegmentedControl/SegmentedControl';
import { getSettingsService } from '../../../Infrastructure/Redux/Settings/get-settings.services';
import { putSettingsService } from '../../../Infrastructure/Redux/Settings/put-settings.services';
import { settingsPutInitActionCreator, settingsPutRequestLoadingActionCreator } from '../../../Infrastructure/Redux/Settings/put-settings.actions';
import { settingsGetInitActionCreator, settingsGetRequestLoadingActionCreator } from '../../../Infrastructure/Redux/Settings/get-settings.actions';
import { getSettingsIsRequestFailureSelector, getSettingsIsRequestLoadingSelector, getSettingsIsRequestSuccessSelector, settingsSelector } from '../../../Infrastructure/Redux/Settings/get-settings.selectors';
import { putSettingsIsRequestFailureSelector, putSettingsIsRequestLoadingSelector, putSettingsIsRequestSuccessSelector, putSettingsRequestErrorsSelector } from '../../../Infrastructure/Redux/Settings/put-settings.selectors';

const validateSettings: ValidateSettings = new ValidateSettings();
const navigationRepository: NavigationRepository = new NavigationRepository();

const HandleSettings: React.FunctionComponent = (): React.ReactElement => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [tab, setTab] = useState<string>('info');
    const [data, setData] = useState<IDataSettings>(initialDataSettings);
    const [navigation, setNavigation] = useState<NavigationCollection>(new NavigationCollection());

    const settings = useSelector(settingsSelector);
    const isSettingsRequestLoading = useSelector(getSettingsIsRequestLoadingSelector);
    const isSettingsRequestSuccess = useSelector(getSettingsIsRequestSuccessSelector);
    const isSettingsRequestFailure = useSelector(getSettingsIsRequestFailureSelector);
    const putSettingsRequestLoading = useSelector(putSettingsIsRequestLoadingSelector);
    const putSettingsRequestSuccess = useSelector(putSettingsIsRequestSuccessSelector);
    const putSettingsRequestFailure = useSelector(putSettingsIsRequestFailureSelector);
    const errors = useSelector(putSettingsRequestErrorsSelector);

    const refSegmentControls = useRef(null);
    const refSegmentControlsSeo = useRef(null);
    const refSegmentControlsInfo = useRef(null);
    const refSegmentControlsHeader = useRef(null);
    const refSegmentControlsFooter = useRef(null);

    useAsync(async () => {
        const response = await navigationRepository.get()
            .then((navigation: NavigationCollection) => {
                setNavigation(navigation);
            }, () => {
                toast.error(<LocaleMessage id="app.something_went_wrong" />);
            });

        return response;
    });

    const handleEditData = useCallback((settings: Settings) => {
        const newData: IDataSettings = Object.assign({}, data, {
            siteName: {
                inputValue: settings.siteName,
                validation: { inError: false, message: '' }
            },
            siteLogo: {
                inputValue: settings.siteLogo,
                validation: { inError: false, message: '' }
            },
            siteFavicon: {
                inputValue: settings.siteFavicon,
                validation: { inError: false, message: '' }
            },
            address: {
                inputValue: settings.address,
                validation: { inError: false, message: '' }
            },
            headerButton: {
                inputValue: settings.headerButton,
                validation: { inError: false, message: '' }
            },
            headerButtonLabel: {
                inputValue: settings.headerButtonLabel,
                validation: { inError: false, message: '' }
            },
            headerButtonNavigationType: {
                inputValue: settings.headerButtonNavigationType,
                validation: { inError: false, message: '' }
            },
            headerButtonNavigationId: {
                inputValue: settings.headerButtonNavigationId !== undefined ? settings.headerButtonNavigationId : '',
                validation: { inError: false, message: '' }
            },
            headerButtonNavigationMailto: {
                inputValue: settings.headerButtonNavigationMailto,
                validation: { inError: false, message: '' }
            },
            headerButtonNavigationUrl: {
                inputValue: settings.headerButtonNavigationUrl,
                validation: { inError: false, message: '' }
            },
            footerButton: {
                inputValue: settings.footerButton,
                validation: { inError: false, message: '' }
            },
            footerButtonLabel: {
                inputValue: settings.footerButtonLabel,
                validation: { inError: false, message: '' }
            },
            footerButtonNavigationId: {
                inputValue: settings.footerButtonNavigationId !== undefined ? settings.footerButtonNavigationId : '',
                validation: { inError: false, message: '' }
            },
            footerText: {
                inputValue: settings.footerText,
                validation: { inError: false, message: '' }
            },
            siteMetaTitle: {
                inputValue: settings.siteMetaTitle,
                validation: { inError: false, message: '' }
            },
            siteMetaDescription: {
                inputValue: settings.siteMetaDescription,
                validation: { inError: false, message: '' }
            },
            siteDefaultOpenGraph: {
                inputValue: settings.siteDefaultOpenGraph,
                validation: { inError: false, message: '' }
            }
        });

        setData(newData);
    }, [data, setData]);

    // --- Redux GET
    useEffectOnce(() => {
        dispatch(settingsGetInitActionCreator());
        dispatch(settingsGetRequestLoadingActionCreator());
    });

    useEffect(() => {
        if (isSettingsRequestLoading) {
            getSettingsService();
        }
    }, [isSettingsRequestLoading]);

    useEffect(() => {
        if (isSettingsRequestFailure) {
            toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });
        }
    }, [isSettingsRequestFailure]);

    useEffect(() => {
        if (settings !== null && isSettingsRequestSuccess) {
            handleEditData(settings);
            dispatch(settingsGetInitActionCreator());
        }
    }, [dispatch, handleEditData, settings, isSettingsRequestSuccess]);

    // --- Redux PUT
    useEffect(() => {
        if (putSettingsRequestLoading) {
            putSettingsService(data);
        }
    }, [putSettingsRequestLoading]);

    useEffect(() => {
        if (putSettingsRequestSuccess) {
            toast.success(<LocaleMessage id="app.settings.site.settings.form.success" />, { position: 'bottom-center' });
            dispatch(settingsPutInitActionCreator());
        }
    }, [putSettingsRequestSuccess]);

    useEffect(() => {
        if (putSettingsRequestFailure) {
            setData(validateSettings.handleApiResponse(data, errors));

            toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });
            dispatch(settingsPutInitActionCreator());
        }
    }, [dispatch, putSettingsRequestFailure]);

    // --- Handle Form states
    const handleOnChange = (name: string, value: File | string | number | boolean | undefined): void => {
        const newData: IDataSettings = Object.assign({}, data, {
            [name]: {
                inputValue: value,
                validation: { inError: false, message: '' }
            }
        });

        setData(validateSettings.validateForm(newData));
    }

    const handSubmit = (): void => {
        if(!isSettingsRequestLoading && !putSettingsRequestLoading && validateSettings.canBeSubmitted(data)) {
            dispatch(settingsPutRequestLoadingActionCreator());
        }
    }

    const generateHeaderButtonNavigationRecord = (): Record<string, Record<string, string>> => {
        const headerButtonNavigationRecords: Record<string, Record<string, string>> = {};

        navigation.forEach((navigationItem: Navigation) => {
            const elements = new NavigationCollection(...navigationItem.childrens);
            const navigationFlattened = elements.flattenNavigation();

            headerButtonNavigationRecords[String(navigationItem.title)] = generateLol(navigationFlattened);
        });

        return headerButtonNavigationRecords;
    }

    const generateheaderButtonNavigationTypeRecord = (): Record<string, string> => {
        const headerButtonNavigationTypeRecords: Record<string, string> = {};

        headerButtonNavigationTypeRecords['internal'] = intl.formatMessage({ id: `app.settings.site.settings.form.header_button_navigation_type.choice_internal`});
        headerButtonNavigationTypeRecords['mailto'] = intl.formatMessage({ id: `app.settings.site.settings.form.header_button_navigation_type.choice_mailto`});
        headerButtonNavigationTypeRecords['manual'] = intl.formatMessage({ id: `app.settings.site.settings.form.header_button_navigation_type.choice_manual`});

        return headerButtonNavigationTypeRecords;
    }

    const generateLol = (navigationsItems: Navigation[]): Record<string, string> => {
        const headerButtonNavigationRecords: Record<string, string> = {};

        navigationsItems.forEach((navigationItem: Navigation) => {
            headerButtonNavigationRecords[String(navigationItem.id)] = navigationItem.title;
        });

        return headerButtonNavigationRecords;
    }

    const renderBody = (): React.ReactElement => {

        return (
            <React.Fragment>
                <div className="form--segmented-controls">
                    <SegmentedControl
                        name="app-settings-settings-tabs"
                        segments={
                            [
                                {
                                    label: <LocaleMessage id="app.settings.site.settings.tabs.info" />,
                                    value: 'info',
                                    ref: refSegmentControlsInfo
                                },
                                {
                                    label: <LocaleMessage id="app.settings.site.settings.tabs.header" />,
                                    value: 'header',
                                    ref: refSegmentControlsHeader
                                },
                                {
                                    label: <LocaleMessage id="app.settings.site.settings.tabs.footer" />,
                                    value: 'footer',
                                    ref: refSegmentControlsFooter
                                },
                                {
                                    label: <LocaleMessage id="app.settings.site.settings.tabs.seo" />,
                                    value: 'seo',
                                    ref: refSegmentControlsSeo
                                }
                            ]
                        }
                        defaultIndex={ 0 }
                        controlRef={ refSegmentControls }
                        onChangeSegment={(value) => setTab(value)}
                    />
                </div>

                { tab === 'seo' && renderSeoTab() }
                { tab === 'info' && renderInfoTab() }
                { tab === 'header' && renderHeaderTab() }
                { tab === 'footer' && renderFooterTab() }
            </React.Fragment>
        )
    }

    const renderInfoTab = (): React.ReactElement => {
        const siteLogoMessage = intl.formatMessage({ id: 'app.settings.site.settings.form.site_logo.additional_information' });
        const siteFaviconMessage = intl.formatMessage({ id: 'app.settings.site.settings.form.site_favicon.additional_information' });

        return (
            <div>
                <InputsGroup name="Information">
                    <React.Fragment>
                        <TextInput
                            inputName="siteName"
                            label="app.settings.site.settings.form.site_name.label"
                            inputValue={ data.siteName.inputValue }
                            placeholder="app.settings.site.settings.form.site_name.placeholder"
                            errorMessage={ data.siteName.validation.inError ? data.siteName.validation.message : undefined }
                            onChange={(name, value) => handleOnChange(name, value)} />
                        <TextareaInput inputName="address" label="app.settings.site.settings.form.address.label" inputData={ data as IData<string> } onChange={(name, value) => handleOnChange(name, value)} />
                    </React.Fragment>
                </InputsGroup>
                <InputsGroup name="Logo & Favicon">
                    <React.Fragment>
                        <FileInput inputName="siteLogo" label="app.settings.site.settings.form.site_logo.label" inputValue={ typeof data.siteLogo.inputValue === 'string' ? data.siteLogo.inputValue : undefined } additionalInformation={ siteLogoMessage } onFileChange={(name, value) => handleOnChange(name, value)} />
                        <FileInput inputName="siteFavicon" label="app.settings.site.settings.form.site_favicon.label" inputValue={ typeof data.siteFavicon.inputValue === 'string' ? data.siteFavicon.inputValue : undefined } additionalInformation={ siteFaviconMessage } onFileChange={(name, value) => handleOnChange(name, value)} />
                    </React.Fragment>
                </InputsGroup>
            </div>
        )
    }

    const renderSeoTab = (): React.ReactElement => {
        const remainingCharsTitle =  data.siteMetaTitle.inputValue !== undefined ? 60 - data.siteMetaTitle.inputValue.length : 60;
        const remainingCharsTitleColor = remainingCharsTitle < 20 ? 'red' : 'yellow';
        const siteMetaTitleMessage = intl.formatMessage({ id: 'app.settings.site.settings.form.site_meta_title.additional_information' }, { remaining: remainingCharsTitle, strong1: `<strong style="color: ${ remainingCharsTitleColor }">`, strong2: '</strong>' });

        const remainingCharsDescription =  data.siteMetaDescription.inputValue !== undefined ? 160 - data.siteMetaDescription.inputValue.length : 160;
        const remainingCharsDescriptionColor = remainingCharsDescription < 30 ? 'red' : 'yellow';
        const siteMetaDescriptionMessage = intl.formatMessage({ id: 'app.settings.site.settings.form.site_meta_description.additional_information' }, { remaining: remainingCharsDescription, strong1: `<strong style="color: ${ remainingCharsDescriptionColor }">`, strong2: '</strong>' });

        const siteDefaultOpenGraphMessage = intl.formatMessage({ id: 'app.settings.site.settings.form.site_default_open_graph.additional_information' });

        return (
            <React.Fragment>
                <InputsGroup name="MetaTags">
                    <React.Fragment>
                        <TextInput
                            inputName="siteMetaTitle"
                            label="app.settings.site.settings.form.site_meta_title.label"
                            inputValue={ data.siteMetaTitle.inputValue }
                            errorMessage={ data.siteMetaTitle.validation.inError ? data.siteMetaTitle.validation.message : undefined }
                            additionalInformation={ siteMetaTitleMessage }
                            onChange={(name, value) => handleOnChange(name, value)} />
                        <TextareaInput inputName="siteMetaDescription" label="app.settings.site.settings.form.site_meta_description.label" inputData={ data as IData<string> } additionalInformation={ siteMetaDescriptionMessage } onChange={(name, value) => handleOnChange(name, value)} />
                    </React.Fragment>
                </InputsGroup>
                <InputsGroup name="OpenGraph">
                    <React.Fragment>
                        <FileInput inputName="siteDefaultOpenGraph" label="app.settings.site.settings.form.site_default_open_graph.label" inputValue={ typeof data.siteDefaultOpenGraph.inputValue === 'string' ? data.siteDefaultOpenGraph.inputValue : undefined } additionalInformation={ siteDefaultOpenGraphMessage } onFileChange={(name, value) => handleOnChange(name, value)} />
                    </React.Fragment>
                </InputsGroup>
            </React.Fragment>
        );
    }

    const renderHeaderTab = (): React.ReactElement => {
        const headerButtonNavigationIdMessage = intl.formatMessage({ id: 'app.settings.site.settings.form.header_button_navigation_id.additional_information' });

        return (
            <React.Fragment>
                <CheckboxInput inputName="headerButton" label="app.settings.site.settings.form.header_button.label" inputData={ data as IData<boolean> } onChange={(name, value) => handleOnChange(name, value)} />

                {
                    data.headerButton.inputValue &&
                    <React.Fragment>
                        <TextInput
                            inputName="headerButtonLabel"
                            label="app.settings.site.settings.form.header_button_label.label"
                            inputValue={ data.headerButtonLabel.inputValue }
                            errorMessage={ data.headerButtonLabel.validation.inError ? data.headerButtonLabel.validation.message : undefined }
                            placeholder="app.settings.site.settings.form.header_button_label.placeholder"
                            onChange={(name, value) => handleOnChange(name, value)} />

                        <SelectInput inputName="headerButtonNavigationType" label="app.settings.site.settings.form.header_button_navigation_type.label" inputValue={ data.headerButtonNavigationType.inputValue } choices={ generateheaderButtonNavigationTypeRecord() } onChange={(name, value) => handleOnChange(name, value)} />

                        {
                            data.headerButtonNavigationType.inputValue === 'internal' &&
                            <SelectInput inputName="headerButtonNavigationId" label="app.settings.site.settings.form.header_button_navigation_id.label" inputValue={ String(data.headerButtonNavigationId.inputValue) } errorMessage={ data.headerButtonNavigationId.validation.inError ? data.headerButtonNavigationId.validation.message : undefined } additionalInformation={ headerButtonNavigationIdMessage } choices={ generateHeaderButtonNavigationRecord() } onChange={(name, value) => handleOnChange(name, value)} />
                        }
                        {
                            data.headerButtonNavigationType.inputValue === 'manual' &&
                            <TextInput inputName="headerButtonNavigationUrl" label="app.settings.site.settings.form.header_button_navigation_url.label" inputValue={ data.headerButtonNavigationUrl.inputValue } errorMessage={ data.headerButtonNavigationUrl.validation.inError ? data.headerButtonNavigationUrl.validation.message : undefined } onChange={(name, value) => handleOnChange(name, value)} />
                        }
                        {
                            data.headerButtonNavigationType.inputValue === 'mailto' &&
                            <TextInput inputName="headerButtonNavigationMailto" label="app.settings.site.settings.form.header_button_navigation_mailto.label" inputValue={ data.headerButtonNavigationMailto.inputValue } errorMessage={ data.headerButtonNavigationMailto.validation.inError ? data.headerButtonNavigationMailto.validation.message : undefined } onChange={(name, value) => handleOnChange(name, value)} />
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }

    const renderFooterTab = (): React.ReactElement => {
        const footerTextMessage = intl.formatMessage({ id: 'app.settings.site.settings.form.footer_text.additional_information' });
        const footerButtonNavigationIdMessage = intl.formatMessage({ id: 'app.settings.site.settings.form.footer_button_navigation_id.additional_information' });

        return (
            <React.Fragment>
                <CheckboxInput inputName="footerButton" label="app.settings.site.settings.form.footer_button.label" inputData={ data as IData<boolean> } onChange={(name, value) => handleOnChange(name, value)} />

                {
                    data.footerButton.inputValue &&
                    <React.Fragment>
                        <TextInput
                            inputName="footerButtonLabel"
                            label="app.settings.site.settings.form.footer_button_label.label"
                            inputValue={ data.footerButtonLabel.inputValue }
                            errorMessage={ data.footerButtonLabel.validation.inError ? data.footerButtonLabel.validation.message : undefined }
                            placeholder="app.settings.site.settings.form.footer_button_label.placeholder"
                            onChange={(name, value) => handleOnChange(name, value)} />
                        <SelectInput inputName="footerButtonNavigationId" label="app.settings.site.settings.form.footer_button_navigation_id.label" inputValue={ String(data.footerButtonNavigationId.inputValue) } errorMessage={ data.footerButtonNavigationId.validation.inError ? data.footerButtonNavigationId.validation.message : undefined} additionalInformation={ footerButtonNavigationIdMessage } choices={ generateHeaderButtonNavigationRecord() } onChange={(name, value) => handleOnChange(name, value)} />
                        <TextInput
                            inputName="footerText"
                            label="app.settings.site.settings.form.footer_text.label"
                            inputValue={ data.footerText.inputValue }
                            errorMessage={ data.footerText.validation.inError ? data.footerText.validation.message : undefined }
                            placeholder="app.settings.site.settings.form.footer_text.placeholder"
                            additionalInformation={ footerTextMessage }
                            onChange={(name, value) => handleOnChange(name, value)} />
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="portlet is-compact">
                <div className="portlet--title">
                    <div className="portlet--title-label">
                        <LocaleMessage id="app.settings.settings.theme.page.title" />
                    </div>
                    <div className="portlet--title-toolbar"></div>
                </div>

                <div className="portlet--body">
                    { renderBody() }
                </div>
            </div>

            <button type="button" className={ `button is-primary ${ isSettingsRequestLoading ? 'is-disabled' : '' } ${ putSettingsRequestLoading ? 'is-loading' : '' }` } onClick={() => handSubmit()}>
                Enregistrer
            </button>
        </React.Fragment>
    );
}

export default HandleSettings;