import React from 'react';

const IconSettingsImageSize: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <g opacity="0.4">
            <path d="M18 6L6 18" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M18 10V6H14" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M6 14V18H10" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
    </svg>
);

export default IconSettingsImageSize;