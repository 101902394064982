import React from 'react';
import { IScaffoldingNavigationItem } from './IScaffoldingNavigationItem';
import ScaffoldingNavigationCategory from './ScaffoldingNavigationCategory';

interface IScaffoldingNavigationProps {
    items: IScaffoldingNavigationItem[]
}

export const ScaffoldingNavigation: React.FunctionComponent<IScaffoldingNavigationProps> = ({ items }): React.ReactElement => {

    const renderCategories = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        items.forEach((item: IScaffoldingNavigationItem, index: number) => {
            elements.push(<ScaffoldingNavigationCategory item={ item } key={ index }/>);
        });

        return elements;
    }

    return (
        <React.Fragment>
            { renderCategories() }
        </React.Fragment>
    );
}