import React from 'react';

const IconSettingsOpacity: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        <g opacity="0.4">
            <path d="M2.0293 8.5H21.9993" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M2.0293 15.5H21.9993" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M8.50977 21.9898V2.00977" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            <path d="M15.5098 21.9898V2.00977" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
        </g>
    </svg>
);

export default IconSettingsOpacity;