import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Breadcrumb } from '../../../_utils/Breadcrumb/Breadcrumb';
import { IBreadcrumbItem } from '../../../_utils/Breadcrumb/IBreadcrumbItem';
import LoadingSpinner from '../Style/Svg/LoadingSpinner';

export const BootstrapController: React.FunctionComponent = (): React.ReactElement => {
    const breadcrumbItems: IBreadcrumbItem[] = [
        {
            title: 'Bootstrap',
            url: null
        }
    ];

    return (
        <React.Fragment>
            <Breadcrumb items={ breadcrumbItems } />
            <section className="bootstrap">
                <div className="grid-wrapper">
                    <h1>Bootstrap</h1>

                    <h2>Badges</h2>
                    <div className="bootstrap--badges">
                        <span className="badge">Badge</span>
                        <span className="badge is-facebook">Facebook</span>
                        <span className="badge is-twitter">Twitter</span>
                        <span className="badge is-google">Google</span>
                        <span className="badge is-success">Success</span>
                        <span className="badge is-error">Error</span>
                        <span className="badge is-trial">Trial</span>
                    </div>

                    <hr/>

                    <h2>Buttons</h2>
                    <div className="bootstrap--buttons">
                        <button className="button">Action</button>
                        <button className="button is-primary">Primary button</button>
                        <button className="button is-red">Red button</button>
                        <button className="button is-loading">Loading button</button>
                        <button className="button is-primary is-loading">Primary loading button</button>
                    </div>

                    <hr/>

                    <h2>Toasts</h2>
                    <div className="bootstrap--buttons">
                        <button type="button" onClick={ () => toast('Wow so easy !') } className="button secondary">TOAST</button>
                        <button type="button" onClick={ () => toast.success('Good job !') } className="button is-success">Success</button>
                        <button type="button" onClick={ () => toast.error('Oops, something goes wrong !') } className="button is-error">Error</button>
                    </div>
                    <br /><br />
                    <blockquote>For more information, check <a href="https://github.com/fkhadra/react-toastify#usage" target="_blank" rel="noopener noreferrer">the repository</a> for documentation</blockquote>

                    <hr/>

                    <h2>Portlets</h2>
                    <div className="bootstrap--portlets">
                        <div className="grid-col-8">
                            <div className="portlet">
                                <div className="portlet--title">
                                    <div className="portlet--title-label">
                                        Portlet title
                                    </div>
                                    <div className="portlet--title-toolbar">
                                        <button type="button" className="button">
                                            <i className="material-icons">cloud_download</i>
                                        </button>
                                        <button type="button" className="button">
                                            <i className="material-icons">face</i>
                                        </button>
                                    </div>
                                </div>
                                <div className="portlet--body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio similique, temporibus sapiente quibusdam ad odio mollitia natus quos magni at delectus deserunt aut doloremque nihil corporis earum eligendi atque fugiat.</p>
                                </div>
                            </div>
                        </div>
                        <div className="grid-col-2"></div>
                        <div className="grid-col-8">
                            <div className="portlet is-compact">
                                <div className="portlet--title">
                                    <div className="portlet--title-label">
                                        Portlet title
                                    </div>
                                    <div className="portlet--title-toolbar">
                                        <button type="button" className="button">
                                            <i className="material-icons">cloud_download</i>
                                        </button>
                                        <button type="button" className="button">
                                            <i className="material-icons">face</i>
                                        </button>
                                    </div>
                                </div>
                                <div className="portlet--body">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio similique, temporibus sapiente quibusdam ad odio mollitia natus quos magni at delectus deserunt aut doloremque nihil corporis earum eligendi atque fugiat.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <h2>Pagination</h2>
                    <div className="bootstrap--pagination">
                        <div className="grid-col-9">
                            <h3>Classic pagination</h3>
                            <ul className="pagination">
                                <li className="pagination--item">
                                    <Link to="#" className="is-disabled">
                                        Prev
                                    </Link>
                                </li>
                                { [...Array(3).keys()].map((index: number) => {
                                    return (
                                        <li className="pagination--item" key={ index }>
                                            <Link to="#" className={ index === 0 ? 'is-active' : '' }>
                                                { index + 1 }
                                            </Link>
                                        </li>
                                    );
                                })}
                                <li className="pagination--item">
                                    <Link to="#">
                                        Next
                                    </Link>
                                </li>
                            </ul>

                            <h3>Centered pagination</h3>
                            <ul className="pagination is-justify-centered">
                                <li className="pagination--item">
                                    <Link to="#" className="is-disabled">
                                        Prev
                                    </Link>
                                </li>
                                { [...Array(3).keys()].map((index: number) => {
                                    return (
                                        <li className="pagination--item" key={ index }>
                                            <Link to="#" className={ index === 0 ? 'is-active' : '' }>
                                                { index + 1 }
                                            </Link>
                                        </li>
                                    );
                                })}
                                <li className="pagination--item">
                                    <Link to="#">
                                        Next
                                    </Link>
                                </li>
                            </ul>
                            
                            <h3>Right pagination</h3>
                            <ul className="pagination is-justify-end">
                                <li className="pagination--item">
                                    <Link to="#" className="is-disabled">
                                        Prev
                                    </Link>
                                </li>
                                { [...Array(3).keys()].map((index: number) => {
                                    return (
                                        <li className="pagination--item" key={ index }>
                                            <Link to="#" className={ index === 0 ? 'is-active' : '' }>
                                                { index + 1 }
                                            </Link>
                                        </li>
                                    );
                                })}
                                <li className="pagination--item">
                                    <Link to="#">
                                        Next
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="grid-col-9">
                            <h3>Responsive &amp; Full width pagination</h3>
                            <ul className="pagination is-full-length">
                                <li className="pagination--item">
                                    <Link to="#" className="is-disabled">
                                        Prev
                                    </Link>
                                </li>
                                { [...Array(10).keys()].map((index: number) => {
                                    return (
                                        <li className="pagination--item" key={ index }>
                                            <Link to="#" className={ index === 0 ? 'is-active' : '' }>
                                                { index + 1 }
                                            </Link>
                                        </li>
                                    );
                                })}
                                <li className="pagination--item">
                                    <Link to="#">
                                        Next
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <hr/>

                    <h2>Forms</h2>
                    <div className="grid-col-9">
                        <form>
                            <div className="form--group">
                                <label htmlFor="input1">Input text</label>
                                <div className="form--group-input">
                                    <input id="input1" type="text" placeholder="Input text"/>
                                </div>
                            </div>
                            <div className="form--group">
                                <label htmlFor="input2">Select</label>
                                <div className="form--group-select">
                                    <select id="input2">
                                        <option value="value1">Value 1</option>
                                        <option value="value2">Value 2</option>
                                        <option value="value3">Value 3</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>

                    <hr />
                    <h2>Icons</h2>
                    <div className="icons is-loading">
                        { LoadingSpinner }
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}