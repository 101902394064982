import React from 'react';

import IconFolderBold from '../../../App/Infrastructure/Style/Svg/IconFolderBold';

interface IDisplayFolderProps {
    folderName: string,
    path: string,
    onDoubleClick(newPath: string): void
}

const DisplayFolder: React.FunctionComponent<IDisplayFolderProps> = ({ folderName, path, onDoubleClick }): React.ReactElement => {

    return (
        <div className="file-manager--list-folder" onDoubleClick={() => onDoubleClick(path)}>
            <div className="file-manager--list-folder-icon">
                { IconFolderBold }
            </div>
            <div className="file-manager--list-folder-details">
                <div className="file-manager--list-folder-details-name">
                    { folderName }
                </div>
                <div className="file-manager--list-folder-details-items">
                    <strong>12</strong> items
                </div>
            </div>
        </div>
    );
}

export default DisplayFolder;