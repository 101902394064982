import { AnyAction } from 'redux';
import AuthToken from '../../Domain/AuthToken';

import {
    authRequestLoadingAction,
    AuthRequestSuccessAction,
    authRequestSuccessAction,
    authRequestFailureAction,
    authLogOutAction,
    AuthRequestFailureAction
} from './auth.actions';

export interface AuthReduxState {
    isLoggedIn: boolean,
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string,
    authToken: AuthToken | null
}

const initialAuthReduxState: AuthReduxState = {
    isLoggedIn: false,
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: '',
    authToken: null
}

export const authReducer = (state: AuthReduxState = initialAuthReduxState, action: AnyAction): AuthReduxState => {
    switch(action.type) {
        case authRequestLoadingAction:
            return {
                isLoggedIn: false,
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: '',
                authToken: null
            }

        case authRequestSuccessAction:
            return {
                isLoggedIn: true,
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                errorMessage: '',
                authToken: (action as AuthRequestSuccessAction).payload.authToken
            }

        case authRequestFailureAction:
            return {
                isLoggedIn: false,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                errorMessage: (action as AuthRequestFailureAction).payload.errorMessage,
                authToken: null
            }

        case authLogOutAction:
            return {
                isLoggedIn: false,
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: '',
                authToken: null
            }
    }

    return state;
}