import { Action } from 'redux';

export const themePutInitAction = '@@app_theme/PUT_INIT';
export const themePutRequestLoadingAction = '@@app_theme/PUT_REQUEST_LOADING';
export const themePutRequestSuccessAction = '@@app_theme/PUT_REQUEST_SUCCESS';
export const themePutRequestFailureAction = '@@app_theme/PUT_REQUEST_FAILURE';

export interface ThemePutInitAction extends Action<typeof themePutInitAction> {
    payload: {}
}

export interface ThemePutRequestLoadingAction extends Action<typeof themePutRequestLoadingAction> {
    payload: {}
}

export interface ThemePutRequestSuccessAction extends Action<typeof themePutRequestSuccessAction> {
    payload: {}
}

export interface ThemePutRequestFailureAction extends Action<typeof themePutRequestFailureAction> {
    payload: {
        errorMessage: string,
        errors: []
    }
}

// --- ActionCreator declaration
export const themePutInitActionCreator = (): ThemePutInitAction => {

    return {
        type: themePutInitAction,
        payload: {}
    }
}

export const themePutRequestLoadingActionCreator = (): ThemePutRequestLoadingAction => {

    return {
        type: themePutRequestLoadingAction,
        payload: {}
    }
}

export const themePutRequestSuccessActionCreator = (): ThemePutRequestSuccessAction => {

    return {
        type: themePutRequestSuccessAction,
        payload: {}
    }
}

export const themePutRequestFailureActionCreator = (errorMessage: string, errors: []): ThemePutRequestFailureAction => {

    return {
        type: themePutRequestFailureAction,
        payload: {
            errorMessage,
            errors
        }
    }
};