import { store } from '../../../../store';

import Settings from '../../../Domain/Settings';
import SettingsRepository from '../../Repositories/SettingsRepository';

import { settingsGetRequestFailureActionCreator, settingsGetRequestSuccessActionCreator } from './get-settings.actions';

const settingsRepository: SettingsRepository = new SettingsRepository();

export const getSettingsService = (): void => {

    settingsRepository.get()
        .then((response: Settings) => {

            store.dispatch(settingsGetRequestSuccessActionCreator(response));
        }, (error: any) => {

            store.dispatch(settingsGetRequestFailureActionCreator(error.response.data.message));
        });

}