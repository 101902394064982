class AppSettings {

    public siteName: string;
    public siteLogo: string;
    public address: string;
    public headerButton: boolean;
    public headerButtonLabel: string;
    public footerButton: boolean;
    public footerButtonLabel: string;
    public footerText: string;

    public constructor(
        siteName: string,
        siteLogo: string,
        address: string,
        headerButton: boolean,
        headerButtonLabel: string,
        footerButton: boolean,
        footerButtonLabel: string,
        footerText: string
    ) {
        this.siteName = siteName;
        this.siteLogo = siteLogo;
        this.address = address;
        this.headerButton = headerButton;
        this.headerButtonLabel = headerButtonLabel;
        this.footerButton = footerButton;
        this.footerButtonLabel = footerButtonLabel;
        this.footerText = footerText;
    }

}

export default AppSettings;