import { RoleEnum } from './Enum/RoleEnum';

class User {

    public id: string;
    public email: string;
    public username: string;
    public firstName: string;
    public lastName: string;
    public role: RoleEnum;

    constructor(id: string, email: string, username: string, firstName: string, lastName: string, role: RoleEnum) {
        this.id = id;
        this.email = email;
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
        this.role = role;
    }

}

export default User;