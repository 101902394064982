import Discipline from "./Discipline";

class Tournament {

    public id: number;
    public title: string;
    public isPremium: boolean;
    public format: string;
    public startDate: Date;
    public registrationDate: Date;
    public registrationEndDate: Date;
    public discipline: Discipline;
    public minPlayerNumber: number;
    public maxPlayerNumber: number;
    public registeredPlayerNumber: number;
    public cashValue: number;
    public giftValue: number;
    public currency: string;

    public constructor(
        id: number,
        title: string,
        isPremium: boolean,
        format: string,
        startDate: Date,
        registrationDate: Date,
        registrationEndDate: Date,
        discipline: Discipline,
        minPlayerNumber: number,
        maxPlayerNumber: number,
        registeredPlayerNumber: number,
        cashValue: number,
        giftValue: number,
        currency: string
    ) {
        this.id = id;
        this.title = title;
        this.isPremium = isPremium;
        this.format = format;
        this.startDate = startDate;
        this.registrationDate = registrationDate;
        this.registrationEndDate = registrationEndDate;
        this.discipline = discipline;
        this.minPlayerNumber = minPlayerNumber;
        this.maxPlayerNumber = maxPlayerNumber;
        this.registeredPlayerNumber = registeredPlayerNumber;
        this.cashValue = cashValue;
        this.giftValue = giftValue;
        this.currency = currency;
    }

}

export default Tournament;