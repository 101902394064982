import * as React from 'react';
import {useDispatch} from 'react-redux';

import Category from '../../Domain/Category';
import CreateTournamentController from './CreateTournamentController';
import IconBracketComponent from '../../../App/Infrastructure/Style/Svg/IconBracket';
import IconLadderComponent from '../../../App/Infrastructure/Style/Svg/IconLadder';
import {modalAddActionCreator} from '../../../_utils/Modal/Redux/modal.actions';
import { ComingSoonController } from '../../../App/Infrastructure/Pages/ComingSoonController';

export const ChooseTournamentTypeController: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();

    const handleGoToCreateTournament = (tournamentCategory: string): void => {
        dispatch(
            modalAddActionCreator(
                <CreateTournamentController type={ tournamentCategory } />, 
                'Créer un tournoi', 
                {
                    extraClass: 'modal--form-edit',
                    openingAnimationClass: 'is-shownFromBottom'
                }
            )
        );
    }

    const handleGoToComingSoon = (): void => {
        dispatch(
            modalAddActionCreator(
                <ComingSoonController />,
                'Coming soon'
            )
        );
    }

    return (
        <div className="tournament--modale-choose-type">
            <div className="card" onClick={ () => handleGoToCreateTournament(Category.getCategoryBracket()) }>
                <div className="card--content">
                    <div className="card--content-subtitle">Type de tournoi</div>
                    <div className="card--content-title">Bracket</div>
                </div>
                <div className="card--icon">
                    { IconBracketComponent }
                </div>
            </div>

            <div className="card" onClick={ () => handleGoToComingSoon() }>
                <div className="card--content">
                    <div className="card--content-subtitle">Type de tournoi</div>
                    <div className="card--content-title">Ladder</div>
                </div>
                <div className="card--icon">
                    { IconLadderComponent }
                </div>
            </div>
        </div>
    );

}