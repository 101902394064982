import React, { useMemo } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { LocaleMessage } from '../../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContent from '../../../../Domain/PageContent';
import ComponentTypeEnum from '../../../../Domain/Enum/ComponentTypeEnum';

import HandleComingSoon from '../HandleComingSoon';
import HandleComponentText from '../Texts/HandleComponentText';
import HandleComponentAccordion from '../Texts/HandleComponentAccordion';
import HandleComponentGallery from '../ImagesAndMedias/HandleComponentGallery';
import IconArrowDown from '../../../../Infrastructure/Style/svg/IconArrowDown';
import HandleComponentSlideshow from '../ImagesAndMedias/HandleComponentSlideshow';


interface IHandleComponentTwoColumnsProps {
    pageContent: PageContent
}

const HandleComponentTwoColumns: React.FunctionComponent<IHandleComponentTwoColumnsProps> = ({ pageContent }): React.ReactElement => {
    const { firstColumn } = useMemo(() => pageContent.data, [pageContent.data]);
    const { secondColumn } = useMemo(() => pageContent.data, [pageContent.data]);

    const renderEmptyColumn = (): React.ReactElement => {

        return (
            <div className="page-builder--content-accordion-empty">
                <p><LocaleMessage id="page_builder.component-accordion-type.empty" /></p>
                <div className="icons">
                    { IconArrowDown }
                </div>
            </div>
        );
    }

    const renderDraggableElements = (elements: PageContent[], draggableId: string) => {
        
        return elements.map((element: PageContent, index: number) => {

            return (
                <Draggable draggableId={ String(draggableId + index) } index={ index } key={ index }>
                    {(provided) =>
                        <div {...provided.draggableProps} ref={ provided.innerRef } className="beautifuldnd--item">
                            <div className="portlet">
                                <div className="portlet--title">
                                    <div className="portlet--title-label" { ...provided.dragHandleProps }>
                                        Element #{ index }
                                    </div>
                                    <div className="portlet--title-toolbar"></div>
                                </div>

                                <div className="portlet--body">
                                    { renderPageContent(element) }
                                </div>
                            </div>
                        </div>
                    }
                </Draggable>
            );
        })
    }

    const renderPageContent = (pageContent: PageContent): React.ReactElement => {
        
        switch(pageContent.type) {

            case ComponentTypeEnum.Text:
                return <HandleComponentText pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.Accordion:
                return <HandleComponentAccordion pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.Gallery:
                return <HandleComponentGallery pageContentId={ pageContent.id } />;

            case ComponentTypeEnum.Slideshow:
                return <HandleComponentSlideshow pageContentId={ pageContent.id } />;

            default:
                return <HandleComingSoon />;

        }
    }

    return (
        <div className="page-builder--content-two-columns">
            <div className={ `form--group` }>
                <div className="grid-col-50p">
                    <Droppable droppableId={ `first-two-column-drop` } direction="vertical" type="list">
                        {(provided) => 
                            <div ref={ provided.innerRef } style={{ minHeight: 50 }}>
                                { 
                                    //@ts-ignore
                                    renderDraggableElements(firstColumn, 'first-two-column-drag-') 
                                }
                                { provided.placeholder }
                            </div>
                        }
                    </Droppable>
                    { renderEmptyColumn() }
                </div>

                <div className="grid-col-50p">
                    <Droppable droppableId={ `second-two-column-drop` } direction="vertical" type="list">
                        {(provided) => 
                            <div ref={ provided.innerRef } style={{ minHeight: 50 }}>
                                { 
                                    //@ts-ignore
                                    renderDraggableElements(secondColumn, 'second-two-column-drag-') 
                                }
                                { provided.placeholder }
                            </div>
                        }
                    </Droppable>
                    { renderEmptyColumn() }
                </div>
            </div>
        </div>
    );
}

export default HandleComponentTwoColumns;