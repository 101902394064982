import React, { useState } from 'react';
import slugify from 'slugify';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { IColor } from '../../App/Domain/IColor';

import IconWarning from '../../App/Infrastructure/Style/Svg/IconWarning';
import FormValidationMessage from './FormValidationMessage';

interface IColorPickerInputProps {
    label: string,
    inputName: string,
    inputValue: string,
    errorMessage?: string,
    onChange: (name: string, value: string) => void,
    disabled: boolean,
    colors?: IColor[],
    additionalInformation?: string
}

const ColorPickerInput: React.FunctionComponent<IColorPickerInputProps> = ({ label, inputName, inputValue, errorMessage, onChange, disabled, colors, additionalInformation }): React.ReactElement => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.currentTarget;

        onChange(name, value);
    }

    const renderColors = (): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        colors?.forEach((color: IColor, index: number) => {
            elements.push(
                <div className={ `input--color-picker-list-item ${ color.hex === inputValue ? 'is-active' : '' }` } style={{ backgroundColor: `#${color.hex}` }} onClick={() => !disabled ? onChange(inputName, color.hex) : void(0) } key={ index }></div>
            );
        });

        return elements;
    }

    return (
        <div className={ `input ${ isFocused ? 'is-focused' : '' }` }>
            <div className="input--container">
                <label htmlFor={ slugify(inputName) }>
                    <LocaleMessage id={ label } />
                </label>
                <div className="input--color-picker">
                    <input
                        id={ slugify(inputName) }
                        type="text"
                        name={ inputName }
                        value={ inputValue }
                        onChange={(event) => handleOnChange(event)}
                        onFocus={ () => setIsFocused(true) }
                        onBlur={ () => setIsFocused(false) }
                        disabled={ disabled }
                        />
                </div>

            </div>

            { colors !== undefined && <div className="input--color-picker-list">{ renderColors() }</div> }

            { errorMessage !== undefined && <FormValidationMessage message={ errorMessage } /> }

            { additionalInformation !== undefined && <blockquote className="input--additional-information">{ IconWarning }&nbsp;<span dangerouslySetInnerHTML={{ __html: String(additionalInformation) }}></span></blockquote> }
        </div>
    );
}

export default ColorPickerInput;