class Navigation {

    public id: number;
    public title: string;
    public childrens: Navigation[];

    public constructor(id: number, title: string, childrens: Navigation[]) {
        this.id = id;
        this.title = title;
        this.childrens = childrens;
    }

}

export default Navigation;