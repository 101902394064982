import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IconWarning from '../../../App/Infrastructure/Style/Svg/IconWarning';
import { modalRemoveActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { checkStatusContactRequestService } from '../Redux/put-contact-request.services';
import { contactRequestPutInitActionCreator, contactRequestPutRequestLoadingActionCreator } from '../Redux/put-contact-request.actions';
import { putContactRequestIsRequestFailureSelector, putContactRequestIsRequestLoadingSelector, putContactRequestIsRequestSuccessSelector } from '../Redux/put-contact-request.selectors';

interface IConfirmationCheckStatusModalProps {
    id: number
}

const ConfirmationCheckStatusModal: React.FunctionComponent<IConfirmationCheckStatusModalProps> = ({ id }): React.ReactElement => {
    const dispatch = useDispatch();
    const isLoading = useSelector(putContactRequestIsRequestLoadingSelector);
    const isInError = useSelector(putContactRequestIsRequestFailureSelector);
    const requestSucceeded = useSelector(putContactRequestIsRequestSuccessSelector);

    const handleCloseModal = (): void => {
        dispatch(modalRemoveActionCreator());
    }

    const handleCheckStatus = (): void => {
        if (!isLoading) {
            dispatch(contactRequestPutRequestLoadingActionCreator());
        }
    }

    useEffect(() => {
        if (isLoading) {
            checkStatusContactRequestService(id);
        }
    }, [id, isLoading]);

    useEffect(() => {
        if (isInError) {
            toast.error(<LocaleMessage id="app.something_went_wrong" />);
        }
    }, [isInError]);

    useEffect(() => {
        if (requestSucceeded) {
            dispatch(contactRequestPutInitActionCreator());
            dispatch(modalRemoveActionCreator());
        }
    }, [requestSucceeded, dispatch]);

    return (
        <div className="modal--confirmation">
            <div className="modal--icon">
                { IconWarning }
            </div>
            <div className="modal--information">
                <LocaleMessage id="contact_requests.check_status.info" />
            </div>

            <div className="modal--actions">
                <button className={ `button is-primary ${isLoading ? 'is-loading' : ''}` } onClick={ handleCheckStatus }>
                    <LocaleMessage id="contact_requests.check_status.validation_button" />
                </button>
                <button className="button is-primary is-transparent" onClick={ handleCloseModal }>
                    <LocaleMessage id="modales.confirmation.cancel" />
                </button>
            </div>
        </div>
    );
}

export default ConfirmationCheckStatusModal;