import { AnyAction } from 'redux';

import { 
    pagePostInitAction, 
    pagePostRequestLoadingAction, 
    pagePostRequestSuccessAction, 
    pagePostRequestFailureAction, 
    PagePostRequestFailureAction
} from './postpage.actions';

export interface PostPageReduxState {
    isRequestLoading: boolean,
    isRequestSuccess: boolean,
    isRequestFailure: boolean,
    errorMessage: string
}

const initialPostPageReduxState: PostPageReduxState = {
    isRequestLoading: false,
    isRequestSuccess: false,
    isRequestFailure: false,
    errorMessage: ""
}

export const PostPageReducer = (state: PostPageReduxState = initialPostPageReduxState, action: AnyAction): PostPageReduxState => {
    switch(action.type) {
        case pagePostInitAction:
            return {
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: ""
            };

        case pagePostRequestLoadingAction:
            return {
                isRequestLoading: true,
                isRequestSuccess: false,
                isRequestFailure: false,
                errorMessage: ""
            }

        case pagePostRequestSuccessAction:
            return {
                isRequestLoading: false,
                isRequestSuccess: true,
                isRequestFailure: false,
                errorMessage: ""
            }
        case pagePostRequestFailureAction:
            return {
                isRequestLoading: false,
                isRequestSuccess: false,
                isRequestFailure: true,
                errorMessage: (action as PagePostRequestFailureAction).payload.errorMessage
            }

        default:
            return state;
    }
}