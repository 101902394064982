import React, {useState} from 'react';

import Tournament from '../../Domain/Tournament';

interface ITournamentRowProps {
    tournament: Tournament
}

export const TournamentRow: React.FunctionComponent<ITournamentRowProps> = ({ tournament }): React.ReactElement => {
    const [isOpened, setIsOpened] = useState<boolean>(false);

    const toggleDetails = (): void => {
        setIsOpened(!isOpened);
    }

    const renderDetails = (): React.ReactElement | null => {
        if (isOpened) {

            return (
                <tr className="row-details">
                    <td colSpan={ 8 }>
                        <div className="row-details--content">
                            <strong>Type :</strong> { tournament.format }
                        </div>
                    </td>
                </tr>
            );
        }

        return null;
    }

    const rowClassName = isOpened ? 'shown' : '';
    const tagClassname = tournament.isPremium ? ' is-premium' : '';

    return (
        <React.Fragment>
            <tr className={ rowClassName }>
                <td style={{ cursor: 'pointer' }} onClick={ () => toggleDetails() }>
                    &gt;
                </td>
                <td>
                    <div className="games--icon">
                    </div>
                </td>
                <td>
                    { tournament.title }
                </td>
                <td>
                    <div className={ `tag${tagClassname}` }>
                        { tournament.isPremium ? <React.Fragment>Premium</React.Fragment> : 'Free tournament' }
                    </div>
                </td>
                <td>
                    <span className="badge is-success">Active</span>
                    </td>
                <td>
                    <strong>{ `${tournament.cashValue} ${tournament.currency}` }</strong>
                </td>
                <td>
                    { new Intl.DateTimeFormat('fr', { weekday: 'long', month: 'long', day: 'numeric' }).format(tournament.startDate) }
                </td>
                <td>
                    <div className="actions-buttons">
                        <button type="button" className="button">
                            Edit
                        </button>
                    </div>
                </td>
            </tr>
            { renderDetails() }
        </React.Fragment>
    );
}