import React from 'react';

const IconSettingsPaddingTop: React.ReactElement = (
    <svg viewBox="0 0 24 24" fill="none">
        <g strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
            <g transform="translate(3.000000, 1.000000)" strokeWidth="1.5">
                <line x1="0" y1="20.5" x2="18" y2="20.5"></line>
                <line x1="0" y1="0.5" x2="18" y2="0.5"></line>
                <g opacity="0.4" transform="translate(6.169920, 3.390140)">
                    <line x1="2.83008" y1="1.10986" x2="2.83008" y2="9.10986"></line>
                    <polyline points="5.65998 2.83 2.82998 0 0 2.83"></polyline>
                </g>
            </g>
        </g>
    </svg>
);

export default IconSettingsPaddingTop;