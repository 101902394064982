import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { LocaleMessage } from '../../Infrastructure/Redux/Locale/Provider/LocaleMessage';

import IDataLogin from './IDataLogin';
import ValidateLogin from './ValidateLogin';
import TextInput from '../../../_utils/Forms/TextInput';
import PasswordInput from '../../../_utils/Forms/PasswordInput';

import AuthService from '../../../Auth/Infrastructure/Redux/auth.services';
import { ComingSoonController } from '../../Infrastructure/Pages/ComingSoonController';
import { modalAddActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { authRequestLoadingActionCreator } from '../../../Auth/Infrastructure/Redux/auth.actions';
import { authErrorMessageSelector, authIsRequestFailureSelector, authIsRequestLoadingSelector, authIsRequestSuccessSelector } from '../../../Auth/Infrastructure/Redux/auth.selectors';

const initialData: IDataLogin = {
    email: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    password: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    }
}

const authService: AuthService = new AuthService();
const validateLogin: ValidateLogin = new ValidateLogin();

const HandleLogin: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const [loginData, setLoginData] = useState<IDataLogin>(initialData);
    const [canBeSubmitted, setCanBeSubmitted] = useState<boolean>(validateLogin.canBeSubmitted(loginData));
    const isLoginLoading = useSelector(authIsRequestLoadingSelector);
    const isLoginFailed = useSelector(authIsRequestFailureSelector);
    const isLoginSucceded = useSelector(authIsRequestSuccessSelector);
    const errorMessage = useSelector(authErrorMessageSelector);

    useEffect(() => {
        setCanBeSubmitted(validateLogin.canBeSubmitted(loginData));
    }, [loginData]);

    useEffect(() => {
        if(isLoginLoading) {
            authService.authServiceAuthenticate(loginData.email.inputValue, loginData.password.inputValue);
        }
    }, [isLoginLoading, loginData.email.inputValue, loginData.password.inputValue]);

    useEffect(() => {
        if (isLoginSucceded) {
            toast.success(<LocaleMessage id="login.toast.success" />, { position: 'bottom-center' });
        }
    }, [isLoginSucceded]);

    useEffect(() => {
        if (isLoginFailed) {
            toast.error(<LocaleMessage id={ errorMessage } values={{ seconds: '360' }} />, { position: 'bottom-center' });
        }
    }, [isLoginFailed, errorMessage]);

    const handlePasswordLostModal = (): void => {
        dispatch(
            modalAddActionCreator(
                <ComingSoonController />,
                'Password lost'
            )
        );
    }

    const handleSubmit = (event: React.FormEvent): void => {
        event.preventDefault();

        if (canBeSubmitted) {
            dispatch(authRequestLoadingActionCreator());
        }
    }

    const handleChange = (name: string, value: string): void => {

        const newData: IDataLogin = Object.assign({}, loginData, {
            [name]: {
                inputValue: value,
                validation: { inError: false, message: '' }
            }
        });

        setLoginData(validateLogin.validateForm(newData));
    }

    return (
        <form onSubmit={ handleSubmit }>
            <TextInput
                label="login.email.label"
                inputName="email"
                inputType="email"
                placeholder="login.email.placeholder"
                inputValue={ loginData.email.inputValue }
                errorMessage={ loginData.email.validation.inError ? loginData.email.validation.message : undefined }
                onChange={(name, value) => handleChange(name, value)} />

            <PasswordInput label="login.password.label" inputName="password" placeholder="login.password.placeholder" inputData={ loginData } additionalClassname="has-no-margin" onChange={(name, value) => handleChange(name, value)} />

            <div className="login--main-password-lost" onClick={() => handlePasswordLostModal() }>
                <LocaleMessage id="login.password_lost.link_label">
                    {(message) => <span>{ message }</span>}
                </LocaleMessage>
            </div>

            <button type="submit" className={ `button is-primary ${ !canBeSubmitted ? 'is-disabled' : '' } ${ isLoginLoading ? 'is-loading' : '' }` }>
                Login
            </button>
        </form>
    );
}

export default HandleLogin;