import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppReduxState } from '../../../../store';
import { LocaleMessage } from '../../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import PageContent from '../../../Domain/PageContent';
import ComponentTypeEnum from '../../../Domain/Enum/ComponentTypeEnum';
import ComponentBackgroundTypeEnum from '../../../Domain/Enum/ComponentBackgroundTypeEnum';
import ComponentBackgroundSizeTypeEnum from '../../../Domain/Enum/ComponentBackgroundSizeTypeEnum';
import ComponentBackgroundPositionEnum from '../../../Domain/Enum/ComponentBackgroundPositionEnum';

import IconSettingSliders from '../../Style/svg/IconSettingSliders';
import { IComponentTextData } from '../../../Application/Forms/ComponentType/Texts/IComponentTextData';
import { pageBuilderSelectPageContentActionCreator } from '../../Redux/page-builder.actions';
import { pageBuilderModeEditorSelector, pageBuilderPageContentSelectedSelector, pageBuilderPageContentSelector } from '../../Redux/page-builder.selectors';

// TEXTS
import DisplayComponentText from './Texts/DisplayComponentText';
import DisplayComponentAccordion from './Texts/DisplayComponentAccordion';

// MEDIA
import DisplayComponentGallery from './Medias/DisplayComponentGallery';
import DisplayComponentSlideshow from './Medias/DisplayComponentSlideshow';

// CONTACT REQUEST
import DisplayComponentContactRequest from './ContactRequests/DisplayComponentContactRequests';

// SECTIONS
import DisplayComponentSectionsHeroSection from './Sections/DisplayComponentSectionsHeroSection';
import DisplayComponentSectionsCallToAction from './Sections/DisplayComponentSectionsCallToAction';

interface IDisplayComponentProps {
    pageContentId: string
}

const DisplayComponent: React.FunctionComponent<IDisplayComponentProps> = ({ pageContentId }): React.ReactElement => {
    const dispatch = useDispatch();
    const viewport = useSelector(pageBuilderModeEditorSelector);
    const pageContent: PageContent = useSelector((state: AppReduxState) => pageBuilderPageContentSelector(state, pageContentId));
    const pageContentData: IComponentTextData = pageContent.data as IComponentTextData;
    const pageContentSelected: string | null = useSelector(pageBuilderPageContentSelectedSelector);

    const handleSelect = (): void => {
        dispatch(pageBuilderSelectPageContentActionCreator(pageContentId));
    }

    const renderComponentPreview = (): React.ReactElement => {
        switch(pageContent.type) {
            case ComponentTypeEnum.Text:
                return <DisplayComponentText id={ pageContentId } />;

            case ComponentTypeEnum.Accordion:
                return <DisplayComponentAccordion id={ pageContentId } />;

            case ComponentTypeEnum.Gallery:
                return <DisplayComponentGallery id={ pageContentId } />;

            case ComponentTypeEnum.Slideshow:
                return <DisplayComponentSlideshow id={ pageContentId } />;

            case ComponentTypeEnum.ContactRequests:
                return <DisplayComponentContactRequest id={ pageContentId } />;

            case ComponentTypeEnum.sectionsHeroSection:
                return <DisplayComponentSectionsHeroSection id={ pageContentId } />;

            case ComponentTypeEnum.sectionsCallToAction:
                return <DisplayComponentSectionsCallToAction id={ pageContentId } />;

            default:
                return <React.Fragment></React.Fragment>;
        }
    }

    const pageContentType = pageContent.type.replace('-type', '');
    const paddingTop = viewport === 'desktop' ? pageContentData.paddingTop : pageContentData.mobilePaddingTop;
    const paddingLeft = viewport === 'desktop' ? pageContentData.paddingLeft : pageContentData.mobilePaddingLeft;
    const paddingRight = viewport === 'desktop' ? pageContentData.paddingRight : pageContentData.mobilePaddingRight;
    const paddingBottom = viewport === 'desktop' ? pageContentData.paddingBottom : pageContentData.mobilePaddingBottom;
    const backgroundImage = `${ pageContentData.backgroundType === ComponentBackgroundTypeEnum.Image ? `url(${process.env.REACT_APP_URL}/image-resizer/w_${ pageContentData.backgroundImageWidth },h_${ pageContentData.backgroundImageHeight },c_1,q_95,o_jpeg/${process.env.REACT_APP_URL}${ pageContentData.backgroundImage })` : '' }`;
    const backgroundImageOverlay = pageContentData.backgroundImageOverlay !== undefined ? String(pageContentData.backgroundImageOverlay / 100) : '0.3';
    const backgroundImagePosition = `${ pageContentData.backgroundPosition !== undefined ? pageContentData.backgroundPosition : ComponentBackgroundPositionEnum.CenterCenter }`;

    const backgroundImageSize = pageContentData.backgroundImageSize !== undefined ? `${ pageContentData.backgroundImageWidth * pageContentData.backgroundImageSize / 100 }px ${ pageContentData.backgroundImageHeight * pageContentData.backgroundImageSize / 100 }px` : '320px 240px';
    const backgroundImageSizeFinal = `${ pageContentData.backgroundImageSizeType !== undefined && pageContentData.backgroundImageSizeType !== ComponentBackgroundSizeTypeEnum.Manual ? pageContentData.backgroundImageSizeType : `${ backgroundImageSize }` }`;

    return (
        <div 
            className={ `page-builder--web-${ pageContentType } is-${ pageContentData.theme }-theme ${ pageContentSelected === pageContentId ? 'is-selected' : '' } ${ pageContentData.backgroundType === ComponentBackgroundTypeEnum.Image ? 'has-background-image' : '' }` }
            style={
                {
                    '--block-overlay-color': `#${ pageContentData.background }`,
                    '--block-overlay-opacity': backgroundImageOverlay,
                    paddingTop: `${ paddingTop }px`,
                    paddingBottom: `${ paddingBottom }px`,
                    backgroundColor: `#${ pageContentData.background }`,
                    backgroundImage: `${ backgroundImage }`,
                    backgroundSize: `${ backgroundImageSizeFinal }`,
                    backgroundPosition: `${ backgroundImagePosition }`
                } as React.CSSProperties
            }>
            <div className="page-builder--preview-comp">
                <div className="page-builder--preview-comp-border"></div>
                <div className="page-builder--preview-comp-name"><LocaleMessage id={ `page_builder.component-${ pageContent.type }.bloc_name` } /></div>
                <button className="button is-small is-primary is-page-content-selector-button" onClick={ handleSelect }>{ IconSettingSliders }</button>
            </div>
            <div className="grid-wrapper">
                <div className="container" style={{ paddingLeft: `${ paddingLeft }px`, paddingRight: `${ paddingRight }px` }}>
                    { renderComponentPreview() }
                </div>
            </div>
        </div>
    );
}

export default DisplayComponent;