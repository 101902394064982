import React, { useState } from 'react';
import slugify from 'slugify';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import FormValidationMessage from './FormValidationMessage';
import IconWarning from '../../App/Infrastructure/Style/Svg/IconWarning';

interface ISelectInputProps {
    inputName: string,
    inputValue: string,
    choices: Record<string, string> | Record<string, Record<string, string>>,
    onChange: (name: string, value: string) => void,
    errorMessage?: string,
    label?: string,
    emptyPlaceholder?: string,
    disabled?: boolean,
    additionalInformation?: string,
    additionalClassname?: string
}

const SelectInput: React.FunctionComponent<ISelectInputProps> = ({ label, inputName, inputValue, errorMessage, choices, onChange, emptyPlaceholder, disabled, additionalInformation, additionalClassname }): React.ReactElement => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const { name, value } = event.currentTarget;

        onChange(name, value);
    }

    const renderChoices = (choices: Record<string, string> | Record<string, Record<string, string>>): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];
        
        let index = 0;
        for (const value in choices) {
            if (typeof choices[value] === 'object') {
                elements.push(
                    <optgroup label={ value } key={ index }>
                        {
                            //@ts-ignore
                            renderChoices(choices[value])
                        }
                    </optgroup>
                );
            } else {
                elements.push(
                    <option value={ value } key={ index }>
                        { choices[value] }
                    </option>
                );
            }

            index++;
        }

        return elements;
    }

    return (
        <React.Fragment>
            <div className={ `input ${ isFocused ? 'is-focused' : '' } ${ disabled !== undefined && disabled === true ? 'is-disabled' : '' } ${ additionalClassname !== undefined ? additionalClassname : '' }` }>
                <div className="input--container">
                    <label htmlFor={ slugify(inputName) }>
                        <LocaleMessage id={ label } />
                    </label>
                    <div className="input--select">
                        <select 
                            name={ inputName }
                            id={ slugify(inputName) }
                            value={ inputValue }
                            disabled={ disabled !== undefined ? disabled : false }
                            onChange={ (event) => handleOnChange(event) }
                            onFocus={ () => setIsFocused(true) }
                            onBlur={ () => setIsFocused(false) }
                            >
                            { emptyPlaceholder !== undefined && <LocaleMessage id={ emptyPlaceholder }>{(message) => <option value="">{ message }</option>}</LocaleMessage> }

                            { renderChoices(choices) }
                        </select>
                    </div>
                </div>
                
                { errorMessage !== undefined && <FormValidationMessage message={ errorMessage } /> }

                { additionalInformation !== undefined && <blockquote className="input--additional-information">{ IconWarning }&nbsp;<span dangerouslySetInnerHTML={{ __html: String(additionalInformation) }}></span></blockquote> }
            </div>
        </React.Fragment>
    )
}

export default SelectInput;