import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';
import AvatarInput from '../../_utils/Forms/AvatarInput';
import TextInput from '../../_utils/Forms/TextInput';

import User from '../Domain/User';
import { userCurrentInformation } from '../Infrastructure/Redux/user.selectors';

const GetPersonalInformation: React.FunctionComponent = (): React.ReactElement => {
    const user: User | null = useSelector(userCurrentInformation);

    return (
        <div className="users--settings-info">
            <div className="portlet">
                <div className="portlet--title">
                    <div className="portlet--title-label">
                        <LocaleMessage id="users.settings.navigation.personal_information" />
                    </div>
                </div>

                <div className="portlet--body">
                    <AvatarInput firstname={ user !== null ? user.firstName : '' } lastname={ user !== null ? user.lastName : '' } />
                    <TextInput
                        inputName="email"
                        inputType="email"
                        label="users.settings.personal_information.email.label"
                        inputValue={ user !== null ? user.email : '' }
                        onChange={ () => void(0) }
                        disabled={ true } />

                    <TextInput
                        inputName="firstName"
                        inputType="text"
                        label="users.settings.personal_information.firstname.label"
                        inputValue={ user !== null ? user.firstName : '' }
                        onChange={ () => void(0) }
                        disabled={ true } />

                    <TextInput
                        inputName="lastName"
                        inputType="text"
                        label="users.settings.personal_information.lastname.label"
                        inputValue={ user !== null ? user.lastName : '' }
                        onChange={ () => void(0) }
                        disabled={ true } />
                </div>
            </div>
        </div>
    );
}

export default GetPersonalInformation;