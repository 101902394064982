import React from 'react';
import { LocaleMessage } from '../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

interface IAvatarInputProps {
    firstname: string,
    lastname: string,
    inputValue?: string
}

const AvatarInput: React.FunctionComponent<IAvatarInputProps> = ({ firstname, lastname }): React.ReactElement => {

    return(
        <div className="input">
            <div className="input--container">
                <label htmlFor="avatar">
                    <LocaleMessage id="users.settings.personal_information.avatar.label" />
                </label>

                <div className="users--settings-info-avatar">
                    { `${ firstname.substring(0, 1) }${ lastname.substring(0, 1)}` }
                </div>
            </div>
        </div>
    );
}

export default AvatarInput;