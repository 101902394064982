import { Action } from 'redux';

import ContactRequest from '../../Domain/ContactRequest';

export const contactRequestsGetInitAction = '@@contact_requests/GET_INIT';
export const contactRequestsGetRequestLoadingAction = '@@contact_requests/GET_REQUEST_LOADING';
export const contactRequestsGetRequestSuccessAction = '@@contact_requests/GET_REQUEST_SUCCESS';
export const contactRequestsGetRequestFailureAction = '@@contact_requests/GET_REQUEST_FAILURE';

export interface ContactRequestsGetInitAction extends Action<typeof contactRequestsGetInitAction> {
    payload: {}
}

export interface ContactRequestsGetRequestLoadingAction extends Action<typeof contactRequestsGetRequestLoadingAction> {
    payload: {}
}

export interface ContactRequestsGetRequestSuccessAction extends Action<typeof contactRequestsGetRequestSuccessAction> {
    payload: {
        contactRequests: ContactRequest[],
        nbrPages: number
        total: number
    }
}

export interface ContactRequestsGetRequestFailureAction extends Action<typeof contactRequestsGetRequestFailureAction> {
    payload: {
        errorMessage: string
    }
}

// --- ActionCreator declaration
export const contactRequestsGetInitActionCreator = (): ContactRequestsGetInitAction => {

    return {
        type: contactRequestsGetInitAction,
        payload: {}
    }
}

export const contactRequestsGetRequestLoadingActionCreator = (): ContactRequestsGetRequestLoadingAction => {

    return {
        type: contactRequestsGetRequestLoadingAction,
        payload: {}
    }
}

export const contactRequestsGetRequestSuccessActionCreator = (contactRequests: ContactRequest[], nbrPages: number, total: number): ContactRequestsGetRequestSuccessAction => {

    return {
        type: contactRequestsGetRequestSuccessAction,
        payload: {
            contactRequests,
            nbrPages,
            total
        }
    }
}

export const contactRequestsGetRequestFailureActionCreator = (errorMessage: string): ContactRequestsGetRequestFailureAction => {

    return {
        type: contactRequestsGetRequestFailureAction,
        payload: {
            errorMessage
        }
    }
};