import React, {useState, useLayoutEffect} from 'react';

import Tournament from '../Domain/Tournament';

import {TournamentRow} from '../Infrastructure/Partials/TournamentRow';
import RestTournamentRepository from '../Infrastructure/RestTournamentRepository';

const restTournamentRepository: RestTournamentRepository = new RestTournamentRepository();

export const GetTournaments: React.FunctionComponent = (): React.ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isInError, setIsInError] = useState<boolean>(false);
    const [tournaments, setTournaments] = useState<Tournament[]>([]);

    useLayoutEffect(() => {
        restTournamentRepository.getTournaments()
            .then((tournaments: Tournament[]) => {
                setIsLoading(false);
                setTournaments(tournaments);
            }, () => {
                setIsLoading(false);
                setIsInError(true);
            });
    }, []);

    const renderHeadTable = (): React.ReactElement => {

        return (
            <thead>
                <tr>
                    <th style={{width: '30px' }}></th>
                    <th style={{width: '50px' }}>Jeu</th>
                    <th>Title</th>
                    <th style={{width: '170px' }}>Prix</th>
                    <th style={{width: '110px' }}>Status</th>
                    <th style={{width: '120px' }} className="sorting_disabled">Price</th>
                    <th style={{width: '150px' }} className="sorting_disabled">Last Update</th>
                    <th style={{width: '90px' }} className="sorting_disabled">Actions</th>
                </tr>
            </thead>
        );
    }

    const renderTournaments = (): React.ReactElement[] => {

        return tournaments.map((tournament: Tournament, index: number) => {

            return (
                <React.Fragment key={ index }>
                    <TournamentRow tournament={ tournament } />
                </React.Fragment>
            );
        });
    }

    if (isLoading) {

        return (
            <div className="table-responsive">
                <table className="has-row-details">
                    { renderHeadTable() }
                    <tbody>
                        <tr>
                            <td colSpan={ 8 }>
                                LOADING
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    if (isInError) {

        return (
            <div className="table-responsive">
                <table className="has-row-details">
                    { renderHeadTable() }
                    <tbody>
                        <tr>
                            <td colSpan={ 8 }>
                                Une erreur est survenue, veuillez rafraichir la page
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }


    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="has-row-details">
                    { renderHeadTable() }
                    <tbody>
                        { renderTournaments() }
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default GetTournaments;