enum ComponentTypeEnum {
    LayoutTwoColumns = 'layout-two-columns-type',
    LayoutThreeColumns = 'layout-three-columns-type',
    Accordion = 'accordion-type',
    Text = 'text-type',
    Slideshow = 'slideshow-type',
    Gallery = 'gallery-type',
    VideoPlayer = 'video-player-type',
    ContactRequests = 'contact-requests-type',
    sectionsHeroSection = 'sections-hero-section-type',
    sectionsCallToAction = 'sections-call-to-action-type'
}

export default ComponentTypeEnum;