import IDataContactRequestSettings from './IDataContactRequestSettings';

class ValidateContactRequestSettings {

    private setErrorMessage = (data: IDataContactRequestSettings, field: string, message: string): IDataContactRequestSettings => {
        data[field] = {
            inputValue: data[field].inputValue,
            validation: {
                inError: true,
                message: message
            }
        }

        return data;
    }

    private validateSenderEmail = (data: IDataContactRequestSettings): IDataContactRequestSettings => {
        if (data.senderEmail.inputValue.length === 0) {
            this.setErrorMessage(data, 'senderEmail', 'contact_request_settings.sender_email.missing');

            return data;
        }

        if (data.senderEmail.inputValue.length < 5) {
            this.setErrorMessage(data, 'senderEmail', 'contact_request_settings.sender_email.not_long_enough');
        }

        return data;
    }

    private validateSenderNoReply = (data: IDataContactRequestSettings): IDataContactRequestSettings => {
        if (data.senderNoReply.inputValue.length === 0) {
            this.setErrorMessage(data, 'senderNoReply', 'contact_request_settings.sender_no_reply.missing');

            return data;
        }

        if (data.senderNoReply.inputValue.length < 5) {
            this.setErrorMessage(data, 'senderNoReply', 'contact_request_settings.sender_no_reply.not_long_enough');
        }

        return data;
    }

    private validateSuccessPageId = (data: IDataContactRequestSettings): IDataContactRequestSettings => {
        if (data.successPageId.inputValue === '') {
            this.setErrorMessage(data, 'successPageId', 'contact_request_settings.success_page_id.missing');

            return data;
        }

        return data;
    }

    public validateForm = (data: IDataContactRequestSettings): IDataContactRequestSettings => {
        data = this.validateSenderEmail(data);
        data = this.validateSenderNoReply(data);
        data = this.validateSuccessPageId(data);

        return data;
    }

    public handleApiResponse = (data: IDataContactRequestSettings, errors: any): IDataContactRequestSettings => {
        if (errors.sender_name !== undefined) {
            data = this.setErrorMessage(data, 'senderName', errors.sender_name[0].code);
        }
        if (errors.sender_email !== undefined) {
            data = this.setErrorMessage(data, 'senderEmail', errors.sender_email[0].code);
        }
        if (errors.sender_no_reply !== undefined) {
            data = this.setErrorMessage(data, 'senderNoReply', errors.sender_no_reply[0].code);
        }
        if (errors.success_page_id !== undefined) {
            data = this.setErrorMessage(data, 'successPageId', errors.success_page_id[0].code);
        }

        if (errors.provider !== undefined) {
            data = this.setErrorMessage(data, 'provider', errors.provider[0].code);
        }
        if (errors.provider_api_key !== undefined) {
            data = this.setErrorMessage(data, 'providerAPIKey', errors.provider_api_key[0].code);
        }
        if (errors.provider_template_id !== undefined) {
            data = this.setErrorMessage(data, 'providerTemplateId', errors.provider_template_id[0].code);
        }

        return data;
    }

    public canBeSubmitted = (data: IDataContactRequestSettings): boolean => {
        return  this.validateSenderEmail(data) &&
            this.validateSenderNoReply(data) &&
            this.validateSuccessPageId(data) &&
            Object.keys(data).every((field) => !data[field].validation.inError);
    }

}

export default ValidateContactRequestSettings;