import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { LocaleMessage } from '../../../App/Infrastructure/Redux/Locale/Provider/LocaleMessage';

import { modalRemoveActionCreator } from '../../../_utils/Modal/Redux/modal.actions';
import { pagesGetRequestLoadingActionCreator } from '../Redux/getpages.actions';
import { pageDeleteInitActionCreator, pageDeleteRequestLoadingActionCreator } from '../Redux/deletepage.actions';
import { deletePageIsRequestFailureSelector, deletePageIsRequestLoadingSelector, deletePageIsRequestSuccessSelector } from '../Redux/deletepage.selectors';
import { deletePageService } from '../Redux/deletepage.services';

interface IDeletePageModalControllerProps {
    pageId: number
}

const DeletePageModalController: React.FunctionComponent<IDeletePageModalControllerProps> = ({ pageId }): React.ReactElement => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isRequestLoading = useSelector(deletePageIsRequestLoadingSelector);
    const isRequestSuccess = useSelector(deletePageIsRequestSuccessSelector);
    const isRequestFailure = useSelector(deletePageIsRequestFailureSelector);

    useEffectOnce(() => {
        dispatch(pageDeleteInitActionCreator());
    });

    useEffect(() => {
        if (isRequestLoading && pageId !== null) {
            deletePageService(pageId);
        }
    }, [pageId, isRequestLoading]);

    useEffect(() => {
        if (isRequestFailure) {
            toast.error(<LocaleMessage id="app.something_went_wrong" />, { position: 'bottom-center' });

            dispatch(pageDeleteInitActionCreator());
        }
    }, [dispatch, isRequestFailure]);

    useEffect(() => {
        if (isRequestSuccess) {
            toast.success(<LocaleMessage id="page.delete.success" />, { position: 'bottom-center' });

            dispatch(pagesGetRequestLoadingActionCreator());
            dispatch(modalRemoveActionCreator());
            dispatch(pageDeleteInitActionCreator());
            history.push('/pages');
        }
    }, [dispatch, history, isRequestSuccess]);

    const handleCloseModal = (): void => {
        dispatch(modalRemoveActionCreator());
    }

    const handleDeletePage = (): void => {
        if (!isRequestLoading) {
            dispatch(pageDeleteRequestLoadingActionCreator());
        }
    }

    return (
        <div className="modal--confirmation">
            <div className="modal--information">
                <LocaleMessage id="page.delete.info" />
            </div>

            <div className="modal--actions">
                <button className={ `button is-primary ${isRequestLoading ? 'is-loading' : ''}` } onClick={ handleDeletePage }>
                    <LocaleMessage id="modales.confirmation.delete" />
                </button>
                <button className="button is-primary is-transparent" onClick={ handleCloseModal }>
                    <LocaleMessage id="modales.confirmation.cancel" />
                </button>
            </div>
        </div>
    );
}

export default DeletePageModalController;