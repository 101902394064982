import React from 'react';
import { useSelector } from 'react-redux';

import Navigation from '../../../../Navigation/Domain/Navigation';
import AppSettings from '../../../Domain/AppSettings';
import NavigationCollection from '../../../../Navigation/Domain/NavigationCollection';

import DisplayImage from '../../../../_utils/Image/DisplayImage';
import IconArrowRight from '../../../../App/Infrastructure/Style/Svg/IconArrowRight';
import { pageBuilderAppSettingsSelector } from '../../Redux/page-builder-get.selectors';

interface IDisplayFooterProps {
    navigation: NavigationCollection | null
}

const DisplayFooter: React.FunctionComponent<IDisplayFooterProps> = ({ navigation }): React.ReactElement => {
    const settings: AppSettings = useSelector(pageBuilderAppSettingsSelector);

    const renderColumnsNavigation = (navigationCollection: NavigationCollection): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        navigationCollection.forEach((navigation: Navigation, index: number) => {
            if(navigation.childrens !== null && navigation.childrens.length > 0) {
                elements.push(
                    <div className="viewport--footer-content-columns-column" key={ index }>
                        <div className="viewport--footer-content-title">
                            { navigation.title }
                        </div>

                        <ul>
                            { renderNavigation(navigation.childrens) }
                        </ul>
                    </div>
                );
            }
        });

        return elements;
    }

    const renderNavigation = (navigationCollection: NavigationCollection): React.ReactElement[] => {
        const elements: React.ReactElement[] = [];

        navigationCollection.forEach((navigation: Navigation, index: number) => {
            if(navigation.childrens !== null && navigation.childrens.length === 0) {
                elements.push(
                    <li key={ index }>
                        <a href="#" onClick={() => void(0)}>
                            { navigation.title }
                        </a>
                    </li>
                );
            }
        });

        return elements;
    }

    return (
        <div className="viewport--footer">
            <div className="viewport--footer-content">
                <div className="grid-wrapper">
                    <div className="viewport--footer-content-columns">
                        <div className="viewport--footer-content-columns-column">
                            <DisplayImage filename={ settings.siteLogo } width={ 120 } height={ 28 } />
                            {
                                settings.address !== "" &&
                                <div className="viewport--footer-content-info">
                                    <div className="viewport--footer-content-title">Siège social</div>
                                    <div className="viewport--footer-content-info-address" dangerouslySetInnerHTML={{ __html: settings.address }}></div>
                                </div>
                            }
                        </div>
                        { navigation !== null && renderColumnsNavigation(navigation) }
                        {
                            settings.footerButton &&
                            <div className="viewport--footer-content-columns-column">
                                <div className="viewport--footer-content-title">Contactez-nous</div>
                                <div className="viewport--footer-content-subtitle" dangerouslySetInnerHTML={{ __html: settings.footerText }}></div>
                                <div className="viewport--footer-content-info-email">
                                    <a href="#" onClick={() => void(0)}>{ settings.footerButtonLabel } { IconArrowRight }</a>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="viewport--footer-down">
                <div className="grid-wrapper">
                    { settings.siteName } | ©2022 Tous les droits sont réservés
                </div>
            </div>
        </div>
    );
}

export default DisplayFooter;