import IDataPage from './IDataPage';

export const initialDataPage: IDataPage = {
    id: {
        inputValue: undefined,
        validation: {
            inError: false,
            message: ''
        }
    },
    title: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    banner: {
        inputValue: undefined,
        validation: {
            inError: false,
            message: ''
        }
    },
    createdDate: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    modifiedDate: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    seoTitle: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    },
    seoDescription: {
        inputValue: '',
        validation: {
            inError: false,
            message: ''
        }
    }
};