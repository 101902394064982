import { Action } from 'redux';

export const settingsPutInitAction = '@@app_settings/PUT_INIT';
export const settingsPutRequestLoadingAction = '@@app_settings/PUT_REQUEST_LOADING';
export const settingsPutRequestSuccessAction = '@@app_settings/PUT_REQUEST_SUCCESS';
export const settingsPutRequestFailureAction = '@@app_settings/PUT_REQUEST_FAILURE';

export interface SettingsPutInitAction extends Action<typeof settingsPutInitAction> {
    payload: {}
}

export interface SettingsPutRequestLoadingAction extends Action<typeof settingsPutRequestLoadingAction> {
    payload: {}
}

export interface SettingsPutRequestSuccessAction extends Action<typeof settingsPutRequestSuccessAction> {
    payload: {}
}

export interface SettingsPutRequestFailureAction extends Action<typeof settingsPutRequestFailureAction> {
    payload: {
        errorMessage: string,
        errors: []
    }
}

// --- ActionCreator declaration
export const settingsPutInitActionCreator = (): SettingsPutInitAction => {

    return {
        type: settingsPutInitAction,
        payload: {}
    }
}

export const settingsPutRequestLoadingActionCreator = (): SettingsPutRequestLoadingAction => {

    return {
        type: settingsPutRequestLoadingAction,
        payload: {}
    }
}

export const settingsPutRequestSuccessActionCreator = (): SettingsPutRequestSuccessAction => {

    return {
        type: settingsPutRequestSuccessAction,
        payload: {}
    }
}

export const settingsPutRequestFailureActionCreator = (errorMessage: string, errors: []): SettingsPutRequestFailureAction => {

    return {
        type: settingsPutRequestFailureAction,
        payload: {
            errorMessage,
            errors
        }
    }
};