import React from 'react';

const IconHandle = (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
        <path d="M3 7H21" strokeWidth="1.5" strokeLinecap="round"></path>
        <path opacity="0.4" d="M3 12H21" strokeWidth="1.5" strokeLinecap="round"></path>
        <path d="M3 17H21" strokeWidth="1.5" strokeLinecap="round"></path>
    </svg>
);

export default IconHandle;