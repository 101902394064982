import React, { useRef } from 'react';

import GroupCollapse from '../../../../Infrastructure/Partials/GroupCollapse';
import SegmentedControl from '../../../../../_utils/SegmentedControl/SegmentedControl';

interface IHandleComponentTwoColumnsSettingsProps {
    onChange: (inputName: string, value: string) => void
}

const HandleComponentTwoColumnsSettings: React.FunctionComponent<IHandleComponentTwoColumnsSettingsProps> = ({ onChange }): React.ReactElement => {


    return (
        <React.Fragment>
            <GroupCollapse label="page_builder.settings.disposal_setting">
                <React.Fragment>
                    <div className="page-builder--contextual-group-item">
                        <div className="page-builder--contextual-group-item-label">Taille des colonnes</div>
                        <SegmentedControl 
                            name="columns-width"
                            segments={[{label: "1-3", value: "1-3", ref: useRef(null)}, {label: "2-2", value: "2-2", ref: useRef(null)}, {label: "3-1", value: "3-1", ref: useRef(null)}]}
                            defaultIndex={ 0 }
                            controlRef={ useRef(null) }
                            onChangeSegment={ (value) => onChange('columns-with', value) }
                            />
                    </div>

                    <div className="page-builder--contextual-group-item">
                        <div className="page-builder--contextual-group-item-label">Affichage en mobile</div>
                        <SegmentedControl 
                            name="mobile-display"
                            segments={[{label: "Hor", value: "horizontal", ref: useRef(null)}, {label: "Vert", value: "vertical", ref: useRef(null)}]}
                            defaultIndex={ 0 }
                            controlRef={ useRef(null) }
                            onChangeSegment={ (value) => onChange('columns-display', value) }
                        />
                    </div>
                </React.Fragment>
            </GroupCollapse>
        </React.Fragment>
    );
}

export default HandleComponentTwoColumnsSettings;