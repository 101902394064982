import * as React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import France from '../Infrastructure/Style/Svg/Flags/France';
import UnitedStates from '../Infrastructure/Style/Svg/Flags/UnitedStates';

import {LocaleMessage} from '../Infrastructure/Redux/Locale/Provider/LocaleMessage';
import {localeSelector} from '../Infrastructure/Redux/Locale/locale.selectors';
import {localeChangeActionCreator} from '../Infrastructure/Redux/Locale/locale.actions';

export const GetLocales: React.FunctionComponent = (): React.ReactElement => {
    const locale = useSelector(localeSelector);
    const dispatch = useDispatch();

    const handleChangeLocale = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
        const lang = event.currentTarget.value;
    
        dispatch(localeChangeActionCreator(lang));
    }, [dispatch]);

    const renderActualLocale = (): React.ReactNode => {
        switch(locale) {
            case 'fr-FR':
                return <React.Fragment><LocaleMessage id="locales.french" />&nbsp;{ France }</React.Fragment>;

            default:
            case 'en-US':
                return <React.Fragment><LocaleMessage id="locales.english" />&nbsp;{ UnitedStates }</React.Fragment>;
        }
    }

    return (
        <div className="footer--tools-locales">
            { renderActualLocale() }
            <select onChange={ handleChangeLocale } value={ locale }>
                <LocaleMessage id="locales.french">{ (message) => <option value="fr-FR">{ message }</option> }</LocaleMessage>
                <LocaleMessage id="locales.english">{ (message) => <option value="en-US">{ message }</option> }</LocaleMessage>
            </select>
        </div>
    );
};