import { AnyAction } from 'redux';

import { 
    pageBuilderPageContentPostInitAction, 
    pageBuilderPageContentPostLoadingAction, 
    pageBuilderPageContentPostSuccessAction,
    pageBuilderPageContentPostFailureAction, 
    PageBuilderPageContentPostFailureAction
} from './page-builder-post.actions';

export interface PageBuilderRequestReduxState {
    isPostLoading: boolean,
    isPostSuccess: boolean,
    isPostFailure: boolean,
    errorMessage: string
}

const initialPageBuilderRequestReduxState: PageBuilderRequestReduxState = {
    isPostLoading: false,
    isPostSuccess: false,
    isPostFailure: false,
    errorMessage: ''
}

export const PageBuilderRequestReducer = (state: PageBuilderRequestReduxState = initialPageBuilderRequestReduxState, action: AnyAction): PageBuilderRequestReduxState => {
    switch (action.type) {
        case pageBuilderPageContentPostInitAction:
            {
                return {
                    ...state,
                    isPostLoading: false,
                    isPostSuccess: false,
                    isPostFailure: false,
                    errorMessage: ''
                }
            }

        case pageBuilderPageContentPostLoadingAction:
            {
                return {
                    ...state,
                    isPostLoading: true,
                    isPostSuccess: false,
                    isPostFailure: false,
                    errorMessage: ''
                }
            }

        case pageBuilderPageContentPostSuccessAction:
            {
                return {
                    ...state,
                    isPostLoading: false,
                    isPostSuccess: true,
                    isPostFailure: false,
                    errorMessage: ''
                }
            }

        case pageBuilderPageContentPostFailureAction:
            {
                return {
                    ...state,
                    isPostLoading: false,
                    isPostSuccess: false,
                    isPostFailure: true,
                    errorMessage: (action as PageBuilderPageContentPostFailureAction).payload.errorMessage
                }
            }

        default:
            return {
                ...state
            }
    }
}