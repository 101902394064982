import HttpClient from '../../../_utils/HttpClient/HttpClient';

import Navigation from '../../Domain/Navigation';
import NavigationCollection from '../../Domain/NavigationCollection';

class NavigationRepository {

    private httpClient: HttpClient = new HttpClient();
    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);

    public get = async (): Promise<NavigationCollection> => {
        const response = await this.httpClient.get(this.baseUrl, `/navigation`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No navigation found');
        }

        return this.generateNavigationTree(response.data.docs);
    };

    private generateNavigationTree = (elements: any): NavigationCollection => {
        const navigationItems: NavigationCollection = new NavigationCollection();

        elements.forEach((navigation: any) => {
            const node = this.generateBranch(navigation);
            navigationItems.push(node);
        });

        return navigationItems;
    }

    private generateBranch = (element: any): Navigation => {

        return new Navigation(
            element.id,
            element.title,
            element.childrens ? this.generateNavigationTree(element.childrens) : []
        )
    }

}

export default NavigationRepository;