import HttpClient from '../../../_utils/HttpClient/HttpClient';

import Page from '../../Domain/Page';
import PagesCollection from '../../Domain/PagesCollection';
import { IPagesList } from '../../Domain/Scaffolding/IPagesList';

import IDataPage from '../../Application/Forms/IDataPage';

class PagesRepository {

    private baseUrl: string = String(process.env.REACT_APP_API_BASE_URL);
    private httpClient: HttpClient = new HttpClient();

    public get = async (page: number, query: string): Promise<IPagesList> => {
        const response = await this.httpClient.get(this.baseUrl, `/pages?page=${page}&${query}`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No pages found');
        }

        const pagesCollection = new PagesCollection();

        response.data.docs.forEach((page: any) => {
            pagesCollection.push(
                this.fromJson(page)
            );
        });

        const pagesList: IPagesList = {
            docs: pagesCollection,
            pages: response.data.pages,
            total: response.data.total
        }

        return pagesList;
    }

    public getPage = async (id: number): Promise<Page> => {
        const response = await this.httpClient.get(this.baseUrl, `/pages/${id}`);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No page found');
        }

        return this.fromJson(response.data.docs);
    }

    public delete = async (id: number): Promise<any> => {
        const response = await this.httpClient.delete(this.baseUrl, `/pages/${id}`);

        return response;
    }

    public post = async (data: IDataPage): Promise<IPagesList> => {
        const dataBody = new FormData();
        dataBody.append('title', data.title.inputValue);
        dataBody.append('seo_title', data.seoTitle.inputValue !== undefined ? data.seoTitle.inputValue : '');
        dataBody.append('seo_description', data.seoDescription.inputValue !== undefined ? data.seoDescription.inputValue : '');
        if(data.banner.inputValue !== undefined) {
            dataBody.append('banner', data.banner.inputValue);
        }

        const response = await this.httpClient.post(this.baseUrl, `/pages`, dataBody);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No page found');
        }

        const pagesCollection = new PagesCollection();

        response.data.docs.forEach((page: any) => {
            pagesCollection.push(
                this.fromJson(page)
            );
        });

        const pagesList: IPagesList = {
            docs: pagesCollection,
            pages: response.data.pages,
            total: response.data.total
        }

        return pagesList;
    }

    public put = async (id: number, data: IDataPage): Promise<IPagesList> => {
        const dataBody = new FormData();
        dataBody.append('title', data.title.inputValue);
        dataBody.append('seo_title', data.seoTitle.inputValue !== undefined ? data.seoTitle.inputValue : '');
        dataBody.append('seo_description', data.seoDescription.inputValue !== undefined ? data.seoDescription.inputValue : '');
        if(data.banner.inputValue !== undefined) {
            dataBody.append('banner', data.banner.inputValue);
        }

        const response = await this.httpClient.put(this.baseUrl, `/pages/${id}`, dataBody);
        if (!response.data.hasOwnProperty('docs')) {
            throw new Error('No page found');
        }

        const pagesCollection = new PagesCollection();

        response.data.docs.forEach((page: any) => {
            pagesCollection.push(
                this.fromJson(page)
            );
        });

        const pagesList: IPagesList = {
            docs: pagesCollection,
            pages: response.data.pages,
            total: response.data.total
        }

        return pagesList;
    }

    private fromJson = (page: any): Page => {

        return new Page(
            page.id,
            page.title,
            page.banner !== "" ? page.banner : undefined,
            page.created_date,
            page.created_by,
            page.seo_title !== undefined ? page.seo_title : null,
            page.seo_description !== undefined ? page.seo_description : null,
            page.modified_date !== undefined ? page.modified_date : null,
            page.modified_by !== undefined ? page.modified_by : null
        );
    }

}

export default PagesRepository;